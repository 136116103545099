import { IconSource } from '../Icon/type';

const TickMark: IconSource = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.14286 0C0.95939 0 0 0.95939 0 2.14286V17.8571C0 19.0406 0.95939 20 2.14286 20H17.8571C19.0406 20 20 19.0406 20 17.8571V2.14286C20 0.95939 19.0406 0 17.8571 0H2.14286ZM15.2959 8.15301C15.8538 7.59512 15.8538 6.6906 15.2959 6.13271C14.738 5.57481 13.8335 5.57481 13.2756 6.13271L8.57143 10.8368L6.72444 8.98985C6.16655 8.43196 5.26203 8.43196 4.70413 8.98985C4.14624 9.54774 4.14624 10.4523 4.70413 11.0102L7.56128 13.8673C8.11917 14.4252 9.02369 14.4252 9.58158 13.8673L15.2959 8.15301Z'
      fill='currentColor'
    />
  </svg>
);

export default TickMark;
