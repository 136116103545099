'use client';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';

import {
  ColorThemeModeClassNames,
  ItemSizingThemeModeClassNames,
  RadiusThemeModeClassNames,
  SpacingThemeModeClassNames,
  StrokeWidthThemeModeClassNames,
  TypographyThemeModeClassNames,
} from '@aftership/design-tokens';

import { ThemeContainerContext } from './AppContainerContext';
import { useCustomThemeVars } from './themeStore';
import { ThemeProviderProps } from './type';

import { AS_THEME_ROOT } from '../../constant';
import { clsx } from '../../utils/clsx';

const ThemeProvider = ({
  children,
  customThemeVars: defaultCustomThemeVars,
  className,
  ...props
}: PropsWithChildren<ThemeProviderProps>) => {
  const {
    colorThemeMode,
    typographyThemeMode,
    spacingThemeMode,
    borderSizingThemeMode,
    itemSizingThemeMode,
    radiusThemeMode,
  } = props;

  const customThemeVars = useCustomThemeVars();
  const [portalContainer, setPortalContainer] = useState<HTMLDivElement | null>(null);

  const containerRef = useCallback((node: HTMLDivElement | null) => {
    setPortalContainer(node);
  }, []);

  const ctxValue = useMemo(() => {
    return {
      portalContainer,
    };
  }, [portalContainer]);

  const themeStyle = useMemo(() => {
    if (customThemeVars) {
      return assignInlineVars(customThemeVars);
    } else if (defaultCustomThemeVars) {
      return assignInlineVars(defaultCustomThemeVars);
    }
  }, [customThemeVars, defaultCustomThemeVars]);

  return (
    <div
      ref={containerRef}
      id={AS_THEME_ROOT}
      className={clsx([
        ColorThemeModeClassNames[colorThemeMode || 'Light'],
        TypographyThemeModeClassNames[typographyThemeMode || 'Desktop'],
        SpacingThemeModeClassNames[spacingThemeMode || 'Mode1'],
        StrokeWidthThemeModeClassNames[borderSizingThemeMode || 'Mode1'],
        ItemSizingThemeModeClassNames[itemSizingThemeMode || 'Mode1'],
        RadiusThemeModeClassNames[radiusThemeMode || 'Mode1'],
        className,
      ])}
      style={themeStyle}
    >
      <ThemeContainerContext.Provider value={ctxValue}>{children}</ThemeContainerContext.Provider>
    </div>
  );
};

export default ThemeProvider;
