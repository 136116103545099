import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request.ts';
import { ResponseBody } from '../request/response';
import { SelectionGrouping } from '../types';

export type GetItemSelectionGroupingInput = {
  input: {
    token: string;
  };
};

export const getItemSelectionGrouping = fromPromise(
  async ({ input: { token } }: GetItemSelectionGroupingInput) => {
    const res = await request<ResponseBody<SelectionGrouping>>(
      `${v4hostName()}/item-selection-groupings`,
      {
        method: 'POST',
        headers: {
          'returns-authorization': token,
        },
      },
    );
    return res?.data?.item_selection_groupings ?? [];
  },
);
