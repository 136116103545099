'use client';

import { textRecipe } from './texts.css';
import { TextProps } from './type';

import { sprinkles } from '../../sprinkles/index.css';
import { clsx } from '../../utils/clsx';
import {
  StyleHandlers,
  baseStyleProps,
  primitiveValue,
  useStyleProps,
} from '../../utils/styleProps';

const textStylePropsHandler: StyleHandlers = {
  ...baseStyleProps,
  color: ['color', primitiveValue],
  wordBreak: ['wordBreak', primitiveValue],
  textDecoration: ['textDecoration', primitiveValue],
  whiteSpace: ['whiteSpace', primitiveValue],
  textOverflow: ['textOverflow', primitiveValue],
};

const Text = ({
  children,
  className,
  variant,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  elementType: Element = 'span',
  textAlign,
  ...rest
}: TextProps) => {
  const { styleProps } = useStyleProps(rest, textStylePropsHandler);
  return (
    <Element
      {...styleProps}
      className={clsx(textRecipe({ variant }), sprinkles({ textAlign }), className)}
    >
      {children}
    </Element>
  );
};

export default Text;
