export let APP_ENV = 'production';

export const updateAppEnv = (env: string) => {
  APP_ENV = env;
};

const ShopperV4APIUrls: Record<string, string> = {
  testing: 'https://api.automizely.io/rc/v4/shopper',
  staging: 'https://staging-api.automizely.com/rc/v4/shopper',
  production: 'https://api.automizely.com/rc/v4/shopper',
  development: 'https://api.automizely.io/rc/v4/shopper',
  'testing-pear': 'https://testing-pear-api.automizely.io/rc/v4/shopper',
  'release-pear': 'https://release-pear-api.automizely.io/rc/v4/shopper',
  'release-incy': 'https://release-incy-api.automizely.io/rc/v4/shopper',
  'release-kiwi': 'https://release-kiwi-api.automizely.io/rc/v4/shopper',
  'release-tidy': 'https://release-tidy-api.automizely.io/rc/v4/shopper',
  'release-nike': 'https://release-nike-api.automizely.io/rc/v4/shopper',
  'release-core': 'https://release-core-api.automizely.io/rc/v4/shopper',
};

const ShopperV3APIUrls: Record<string, string> = {
  testing: 'https://api.automizely.io/returnscenter/v3/shopper',
  staging: 'https://staging-api.automizely.com/returnscenter/v3/shopper',
  production: 'https://api.automizely.com/returnscenter/v3/shopper',
  development: 'https://api.automizely.io/returnscenter/v3/shopper',
  'testing-pear': 'https://testing-pear-api.automizely.io/returnscenter/v3/shopper',
  'release-pear': 'https://release-pear-api.automizely.io/returnscenter/v3/shopper',
  'release-incy': 'https://release-incy-api.automizely.io/returnscenter/v3/shopper',
  'release-kiwi': 'https://release-kiwi-api.automizely.io/returnscenter/v3/shopper',
  'release-tidy': 'https://release-tidy-api.automizely.io/returnscenter/v3/shopper',
  'release-nike': 'https://release-nike-api.automizely.io/returnscenter/v3/shopper',
  'release-core': 'https://release-core-api.automizely.io/returnscenter/v3/shopper',
};

const ShopperV2APIUrls: Record<string, string> = {
  testing: 'https://api.returnscenter.io/v2/shopper',
  staging: 'https://staging-api.returnscenter.com/v2/shopper',
  production: 'https://api.returnscenter.com/v2/shopper',
  development: 'https://api.returnscenter.io/v2/shopper',
  'testing-pear': 'https://testing-pear-api.returnscenter.io/v2/shopper',
  'release-pear': 'https://release-pear-api.returnscenter.io/v2/shopper',
  'release-incy': 'https://release-incy-api.returnscenter.io/v2/shopper',
  'release-kiwi': 'https://release-kiwi-api.returnscenter.io/v2/shopper',
  'release-tidy': 'https://release-tidy-api.returnscenter.io/v2/shopper',
  'release-nike': 'https://release-nike-api.returnscenter.io/v2/shopper',
  'release-core': 'https://release-core-api.returnscenter.io/v2/shopper',
};

const ShopperAPIUrls: Record<string, string> = {
  testing: 'https://api.returnscenter.io/shopper/v1',
  staging: 'https://staging-api.returnscenter.com/shopper/v1',
  production: 'https://api.returnscenter.com/shopper/v1',
  development: 'https://api.returnscenter.io/shopper/v1',
  'testing-pear': 'https://testing-pear-api.returnscenter.io/shopper/v1',
  'release-pear': 'https://release-pear-api.returnscenter.io/shopper/v1',
  'release-incy': 'https://release-incy-api.returnscenter.io/shopper/v1',
  'release-kiwi': 'https://release-kiwi-api.returnscenter.io/shopper/v1',
  'release-tidy': 'https://release-tidy-api.returnscenter.io/shopper/v1',
  'release-nike': 'https://release-nike-api.returnscenter.io/shopper/v1',
  'release-core': 'https://release-core-api.returnscenter.io/shopper/v1',
};
const internalAPIUrls: Record<string, string> = {
  testing: 'http://prod-rc.as-in.io/rc/v4/internal',
  staging: 'http://staging-prod-rc.as-in.com/rc/v4/internal',
  production: 'http://prod-rc.as-in.com/rc/v4/internal',
  development: 'http://prod-rc.as-in.io/rc/v4/internal',
  'testing-pear': 'http://testing-pear-prod-rc.as-in.io/rc/v4/internal',
  'release-pear': 'http://release-pear-prod-rc.as-in.io/rc/v4/internal',
  'release-incy': 'http://release-incy-prod-rc.as-in.io/rc/v4/internal',
  'release-kiwi': 'http://release-kiwi-prod-rc.as-in.io/rc/v4/internal',
  'release-tidy': 'http://release-tidy-prod-rc.as-in.io/rc/v4/internal',
  'release-nike': 'http://release-nike-prod-rc.as-in.io/rc/v4/internal',
  'release-core': 'http://release-core-prod-rc.as-in.io/rc/v4/internal',
};

const ConnectorsAPIUrls: Record<string, string> = {
  testing: 'http://pltf-connectors.as-in.io/connectors/v2',
  staging: 'http://staging-pltf-connectors.as-in.com/connectors/v2',
  production: 'http://pltf-connectors.as-in.com/connectors/v2',
  development: 'https://testing-incy-platform.automizelyapi.io/connectors/v2',
  'testing-pear': 'https://platform.automizelyapi.io/connectors/v2',
  'release-pear': 'https://platform.automizelyapi.io/connectors/v2',
  'release-incy': 'https://platform.automizelyapi.io/connectors/v2',
  'release-kiwi': 'https://platform.automizelyapi.io/connectors/v2',
  'release-tidy': 'https://platform.automizelyapi.io/connectors/v2',
  'release-nike': 'https://platform.automizelyapi.io/connectors/v2',
  'release-core': 'https://platform.automizelyapi.io/connectors/v2',
};

export const getReturnPageUrlByUserName = (username: string) => {
  if (APP_ENV === 'production') {
    return `${username}.returnscenter.com`;
  }
  return `${username}.returnscenter.io`;
};

export const connectorsEndpoint = () => ConnectorsAPIUrls[APP_ENV];

export const shopperEndpoint = () => ShopperAPIUrls[APP_ENV];

export const shopperV2Endpoint = () => ShopperV2APIUrls[APP_ENV];

export const shopperV3Endpoint = () => ShopperV3APIUrls[APP_ENV];

export const shopperV4Endpoint = () => ShopperV4APIUrls[APP_ENV];

export const internalEndpoint = () => internalAPIUrls[APP_ENV];

export const getReCaptchaKey = () => {
  return APP_ENV !== 'production' && APP_ENV !== 'staging'
    ? '6LduBaYUAAAAAF2oTQgSsVGVggl7ofu5EUb-qruE'
    : '6LexBKYUAAAAAEsaw4SsgIGzzHOVjgOGM_3MBIwW';
};
