export enum GrayFeatureKey {
  ShowExternalOrder = 'show_external_order',
  DisabledWholeReturnWhenPartialReturn = 'disabled_whole_return_when_partial_return',
  BlockSearchEngine = 'block_search_engine',
  ReplaceOnStore = 'replace_on_store',
  // Qantas 定制需求 隐藏 Returns page summary & 所有价格（Item selection，Resolution，Review，Detail page）
  HiddenSummaryAndPriceAlways = 'hidden_summary_and_price_always',
  HiddenBundleProductItemPrice = 'hidden_bundle_product_item_price',
  EnabledGetAdditionalLabel = 'enabled_get_additional_label',
  DisabledLoopQueryLabelStatus = 'disabled_loop_query_label_status',
  ShowProductFullValuePrice = 'show_product_full_value_price',
  HiddenHappyReturnViewAllLocation = 'hidden_happy_return_view_all_location',
  controlInventory = 'control_inventory',
  HiddenSumaryRefundTotal = 'hidden_summary_refund_total',
}

export type IGrayFeatureEnabledMap = Partial<Record<GrayFeatureKey, boolean>>;
