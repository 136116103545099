import { get } from 'lodash-es';
import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Select, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';
import { InputFieldErrorType } from 'returns-logics';

import useDevice from '@/hooks/useDevice';
import { IErrorItem } from '@/types/error';

import { mobileSelectClassName } from './index.css';

export type Option = {
  label: string;
  value: string;
};

export type Props = {
  control: Control<any>;
  placeholder?: string;

  name: string;
  defaultValue?: string;
  errors?: Record<string, InputFieldErrorType>;
  errorMapping?: Record<string, IErrorItem>;

  options: Array<Option>;
  onSelectChange?: (v: string, name: string) => void;
};

function FormSelect({
  control,
  name,
  options,
  errors,
  errorMapping,
  placeholder,
  onSelectChange,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useDevice().mobile;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, formState }) => {
        const error = errors?.[name];

        const msg =
          error && errorMapping?.[name]
            ? t(errorMapping?.[name]?.[error['code']])
            : get(formState?.errors, name)?.message;

        return (
          <Flex direction='column' gap={2}>
            <Select
              onChange={(value) => {
                onChange(value);
                onSelectChange?.(value as string, name);
              }}
              options={options}
              value={value}
              placeholder={placeholder}
              className={isMobile ? mobileSelectClassName : undefined}
            />
            {msg && (
              <Text variant='caption1' color={ColorVars['Red']['Red 1000']}>
                {msg}
              </Text>
            )}
          </Flex>
        );
      }}
    />
  );
}

export default FormSelect;
