/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  or,
  raise,
  setup,
} from 'xstate';

import { Children, Event, Input, ReturnDetailContext } from './type';

import {
  DropoffStatus,
  Resolution,
  ReturnMethodSlug,
  ReturnStatus,
  ShippingStatus,
  SupportDropoffSlug,
} from '../../constant';
import {
  batchGetDropoffLocations,
  getDropoffLocations,
  getReturnById,
  getReturnMethodById,
  getReturnMethodsAll,
} from '../../promise';
import { getDropoffs, pollingDropoffs } from '../../promise/label';
import { getReturnByRMAId } from '../../promise/rma';
import {
  getCourierList,
  getDisplayQrCodeSlugInfo,
  getShipmentsById,
  pollingShipment,
  postShipmentsById,
} from '../../promise/shipment';
import { ErrorResponse } from '../../request/ErrorResponse';
import { ShipmentsInfo } from '../../types';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';
import { returnMethodSlugToDropoffSlug } from '../../utils/returnMethod';
import generateAdditionalLabel from '../generateAdditionalLabel';

export const returnDetailSubFlow = setup({
  actors: {
    getReturnByRMAId,
    getReturnById,
    getCourierList,
    getShipmentsById,
    getReturnMethodsAll,
    postShipmentsById,
    getReturnMethodById,
    getDropoffs,
    batchGetDropoffLocations,
    getDropoffLocations,
    pollingShipments: pollingShipment,
    generateAdditionalLabel,
    pollingDropoff: pollingDropoffs,
    getDisplayQrCodeSlugInfo,
  },
  types: {
    context: {} as ReturnDetailContext,
    input: {} as Input,
    events: {} as Event,
    children: {} as Children,
  },
  guards: {
    isRMAId: ({ context }) => {
      return Boolean(context?.rmaId);
    },
    isRequestCouries: ({ context }) => {
      return (
        (context.returnDetail?.status === ReturnStatus.APPROVED &&
          (context.returnDetail?.return_method_slug === ReturnMethodSlug.CustomerCourier ||
            context.returnDetail?.return_method_slug === ReturnMethodSlug.RetailerLabel ||
            context.returnDetail?.return_method_slug === ReturnMethodSlug.CarrierPickup)) ||
        context.returnDetail?.status === ReturnStatus.SHIPPED ||
        context?.returnDetail?.shipping_status === ShippingStatus.InTransit ||
        context?.returnDetail?.shipping_status === ShippingStatus.Delivered
      );
    },
    isApproved: ({ context }) => {
      return context?.returnDetail?.status === ReturnStatus.APPROVED;
    },
    isHappyReturnsOrRetailRework: ({ context }) => {
      return !!(
        (context?.returnDetail?.return_method_slug === ReturnMethodSlug.HappyReturns ||
          context?.returnDetail?.return_method_slug === ReturnMethodSlug.RetailRework) &&
        ![DropoffStatus.Creating, DropoffStatus.Failed].includes(
          context?.returnDetail?.dropoff_status,
        )
      );
    },
    isRequestReturnMethods: ({ context }) => {
      return Boolean(
        context?.returnDetail &&
          context?.returnDetail.status === ReturnStatus.APPROVED &&
          context?.returnDetail.shipping_status !== ShippingStatus.Received,
      );
    },
    isRequestGetShipmentById: ({ context }) => {
      return !!(
        context?.returnDetail &&
        (context.returnDetail.return_method_slug === ReturnMethodSlug.RetailerLabel ||
          context.returnDetail.return_method_slug === ReturnMethodSlug.CarrierPickup ||
          context.returnDetail.shipping_status === ShippingStatus.InTransit ||
          context.returnDetail.shipping_status === ShippingStatus.Delivered ||
          context.returnDetail?.status === ReturnStatus.SHIPPED)
      );
    },
    isRequestGetDisplayQrCodeSlugInfo: ({ context }) => {
      return !!(
        context?.returnDetail &&
        context?.returnDetail.status === ReturnStatus.APPROVED &&
        context.returnDetail.return_method_slug === ReturnMethodSlug.RetailerLabel
      );
    },

    isNeedToPollingDropoff: ({ context }) => {
      return (
        !!context?.returnDetail?.return_method_slug &&
        [ReturnMethodSlug.HappyReturns, ReturnMethodSlug.RetailRework].includes(
          context?.returnDetail?.return_method_slug,
        ) &&
        !context.grayFeature?.disabledLoopQueryLabelStatus &&
        (!!context.returnDetail?.automation_context?.auto_happy_returns_dropoff_generation
          ?.enabled ||
          !!context.returnDetail?.automation_context?.auto_retail_reworks_dropoff_generation
            ?.enabled) &&
        (context.dropoffs?.length === 0 ||
          !!context.dropoffs?.some((dropoff) => dropoff.status === 'creating'))
      );
    },

    isNeedToPollingLabel: ({ context }) => {
      return (
        context.returnDetail?.return_method_slug === ReturnMethodSlug.RetailerLabel &&
        !context.grayFeature?.disabledLoopQueryLabelStatus &&
        !!context.returnDetail?.automation_context?.auto_label_generation?.enabled &&
        (context.shipments?.length === 0 ||
          !!context.shipments?.some(
            (shipment) => shipment.auto_label_generation_status === 'creating',
          ))
      );
    },

    isNeedToPollingAdditionalLabel: ({ context }) => {
      const labelsWithAdditionalLabel =
        context.shipments?.reduce?.((acc, shipment) => {
          return [...acc, ...shipment.additional_labels];
        }, [] as ShipmentsInfo[]) ?? [];

      return (
        context.returnDetail?.return_method_slug === ReturnMethodSlug.RetailerLabel &&
        !!context?.grayFeature?.enabledGetAdditionalLabel &&
        !!context.returnDetail?.automation_context?.auto_label_generation?.enabled &&
        (labelsWithAdditionalLabel?.length === 0 ||
          !!labelsWithAdditionalLabel?.some(
            (label) => label.auto_label_generation_status === 'creating',
          ))
      );
    },
  },
}).createMachine({
  initial: 'getReturnByRMAId',
  context: ({ input }) => ({ ...input, shipments: [], dropoffs: [] }),
  states: {
    getReturnByRMAId: {
      initial: 'descision',
      states: {
        descision: {
          always: [
            {
              guard: 'isRMAId',
              target: 'loading',
            },
            {
              target: 'error',
            },
          ],
        },
        loading: {
          tags: 'loading',
          invoke: {
            id: 'getReturnByRMAId',
            src: 'getReturnByRMAId',
            input: ({ context }) => {
              return {
                token: context?.token!,
                rmaId: context?.rmaId!,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({
                returnDetail: ({ event, context }) => {
                  const rma = event.output;
                  // 如果是换货，需要把换货商品的 tags 赋值给 exchange_items，展示客户定制的特殊 tag
                  // [RTC-20150] - https://aftership.atlassian.net/browse/RTC-20150
                  if (rma?.resolution === Resolution.ReplaceTheSameItem) {
                    const productTags = rma?.items[0].tags || [];
                    rma?.exchange_items.forEach((item) => (item.tags = productTags));
                  }

                  if (context?.grayFeature?.hiddenSummaryAndPriceAlways) {
                    rma?.items.forEach((item) => {
                      if (item?.discounted_base_price_set) {
                        item.discounted_base_price_set.presentment_money = null;
                      }
                      if (item?.base_price_set) {
                        item.base_price_set.presentment_money = null;
                      }
                    });
                    rma?.exchange_items.forEach((item) => {
                      if (item?.price_set) {
                        item.price_set.presentment_money = null;
                      }
                    });
                  }
                  return rma;
                },
                shippingAddress: ({ event }) => {
                  return event.output?.exchange_order_shipping_address;
                },
                returnId: ({ event }) => {
                  return event.output?.id;
                },
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          tags: 'success',
          type: 'final',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: {
        target: 'fetchDescisionData',
      },
    },

    fetchDescisionData: {
      id: 'fetchDescisionData',
      type: 'parallel',
      states: {
        getReturnMethodById: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getReturnMethodById',
                src: 'getReturnMethodById',
                input: ({ context }) => {
                  return {
                    token: context?.token!,
                    returnMethodId: context?.returnDetail?.return_method_id!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    returnMethod: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        getShipmentsById: {
          initial: 'descision',
          states: {
            descision: {
              always: [
                {
                  guard: 'isRequestGetShipmentById',
                  target: 'loading',
                },
                {
                  target: 'success',
                },
              ],
            },
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getShipmentsById',
                src: 'getShipmentsById',
                input: ({ context }) => {
                  return {
                    returnId: context?.returnDetail?.id!,
                    token: context?.token!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    shipments: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        getDisplayQrCodeSlugInfo: {
          initial: 'descision',
          states: {
            descision: {
              always: [
                {
                  guard: 'isRequestGetDisplayQrCodeSlugInfo',
                  target: 'loading',
                },
                {
                  target: 'success',
                },
              ],
            },
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getDisplayQrCodeSlugInfo',
                src: 'getDisplayQrCodeSlugInfo',
                input: ({ context }) => {
                  return {
                    returnId: context?.returnDetail?.id!,
                    token: context?.token!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    displayQrCodeSlug: ({ event }) => {
                      return event.output?.reduce((prev, cur) => {
                        if (!cur?.dropoff_link) return prev;
                        return {
                          ...prev,
                          [cur.slug]: {
                            name: cur.name,
                            dropOffUrl: cur.dropoff_link,
                          },
                        };
                      }, {});
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        getDropoffs: {
          initial: 'descision',
          states: {
            descision: {
              always: [
                {
                  guard: 'isApproved',
                  target: 'loading',
                },
                {
                  target: 'success',
                },
              ],
            },
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getDropoffs',
                src: 'getDropoffs',
                input: ({ context }) => {
                  return {
                    returnId: context?.returnId!,
                    token: context?.token!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    dropoffs: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },

        getCourierList: {
          initial: 'descision',
          states: {
            descision: {
              always: [
                {
                  guard: 'isRequestCouries',
                  target: 'loading',
                },
                {
                  target: 'success',
                },
              ],
            },
            loading: {
              tags: 'loading',
              invoke: {
                id: 'getCourierList',
                src: 'getCourierList',
                input: ({ context }) => {
                  return {
                    token: context?.token!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    couriers: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
              tags: 'success',
            },
            error: {
              tags: 'error',
            },
          },
        },
        batchGetDropoffLocations: {
          description: '获取 dropoff method 下的 location',
          initial: 'descision',
          states: {
            descision: {
              always: [
                {
                  guard: 'isHappyReturnsOrRetailRework',
                  target: 'loading',
                },
                {
                  target: 'success',
                },
              ],
            },
            loading: {
              tags: 'loading',
              invoke: {
                src: 'batchGetDropoffLocations',
                input: ({ context }) => ({
                  token: context?.token!,
                  orderId: context?.orderId!,
                  slugs: [
                    // @ts-ignore
                    returnMethodSlugToDropoffSlug(context?.returnDetail?.return_method_slug!),
                  ],
                }),
                onDone: {
                  target: 'success',
                  actions: assign({
                    dropoffLocations: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: { type: 'final', tags: 'success' },
            error: { tags: 'error' },
          },
        },
      },
      onDone: {
        target: 'pollingLabelOrDropoff',
      },
    },
    pollingLabelOrDropoff: {
      type: 'parallel',
      states: {
        // 轮询 label or additional label
        pollingShipments: {
          initial: 'descionPollingShipments',
          states: {
            descionPollingShipments: {
              always: [
                {
                  target: 'polling',
                  guard: or(['isNeedToPollingLabel', 'isNeedToPollingAdditionalLabel']),
                },
                {
                  target: 'done',
                },
              ],
            },
            polling: {
              invoke: {
                id: 'pollingShipments',
                src: 'pollingShipments',
                input: ({ context }) => {
                  return {
                    requestPayload: {
                      returnId: context.returnId!,
                      token: context.token!,
                    },
                  };
                },
                onDone: {
                  target: 'pollingDone',
                  actions: assign({
                    shipments: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
                onError: {},
              },
            },
            pollingDone: {
              invoke: {
                src: 'getReturnByRMAId',
                input: ({ context }) => {
                  return {
                    token: context?.token!,
                    rmaId: context?.rmaId!,
                  };
                },
                onDone: {
                  target: 'done',
                  actions: assign({
                    returnDetail: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
              },
            },
            done: {
              type: 'final',
            },
          },
        },

        // 轮询 drop off
        pollingDropoff: {
          initial: 'descisionPollingDropoff',
          states: {
            descisionPollingDropoff: {
              always: [
                {
                  target: 'polling',
                  guard: 'isNeedToPollingDropoff',
                },
                {
                  target: 'done',
                },
              ],
            },
            polling: {
              invoke: {
                src: 'pollingDropoff',
                input: ({ context }) => {
                  return {
                    requestPayload: {
                      token: context.token!,
                      returnId: context.returnId!,
                    },
                  };
                },
                onDone: {
                  target: 'pollingDone',
                  actions: assign({
                    dropoffs: ({ event }) => event.output,
                  }),
                },
              },
            },
            pollingDone: {
              invoke: {
                src: 'getReturnByRMAId',
                input: ({ context }) => {
                  return {
                    token: context?.token!,
                    rmaId: context?.rmaId!,
                  };
                },
                onDone: {
                  target: 'done',
                  actions: assign({
                    returnDetail: ({ event }) => {
                      return event.output;
                    },
                  }),
                },
              },
            },
            done: {
              type: 'final',
            },
          },
        },
      },
      onDone: {
        target: 'fetchDescisionDataDone',
      },
    },
    fetchDescisionDataDone: {},

    postShipmentsById: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'postShipmentsById',
            src: 'postShipmentsById',
            input: ({ context, event }) => {
              assertEvent(event, 'POST_SHIPMENTS');
              return {
                token: context?.token!,
                returnId: context?.returnId!,
                courierSlug: event.data.courierSlug,
                trackingNumber: event.data.trackingNumber,
              };
            },
            onDone: {
              target: 'success',
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: {
          type: 'final',
          tags: 'success',
        },
        error: {
          tags: 'error',
        },
      },
      onDone: {
        target: 'getReturnByRMAId',
      },
    },
    loadNearbyLocations: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'getDropoffLocations',
            input: ({ context, event }) => {
              assertEvent(event, 'LOAD_NEARBY_LOCATIONS');
              return {
                token: context.token!,
                orderId: context.orderId!,
                latitude: event.data.latitude,
                longitude: event.data.longitude,
                slug: SupportDropoffSlug.HappyReturns,
              };
            },
            onDone: {
              target: 'success',
              actions: assign({ nearbyLocations: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
              },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    // TODO: 感觉这个应该是单独存在，在 UI 层去启动这个 flow，而不是在这里
    addAdditionalLabel: {
      invoke: {
        id: 'generateAdditionalLabel',
        src: 'generateAdditionalLabel',
        input: ({ context, event }) => {
          assertEvent(event, 'ADD_MORE_LABEL');
          return {
            token: context.token!,
          };
        },
        onDone: {
          actions: assign({
            shipments: ({ event }) => event.output,
          }),
        },
      },
    },
  },

  on: {
    REFETCH_RETURN_DETAIL: {
      target: '.getReturnByRMAId',
    },
    POST_SHIPMENTS: {
      target: '.postShipmentsById',
    },
    SET_RMA_ID: {
      actions: [
        assign({
          rmaId: ({ event }) => {
            return event.data.rmaId;
          },
        }),
        raise({
          type: 'REFETCH_RETURN_DETAIL',
        }),
      ],
    },
    LOAD_NEARBY_LOCATIONS: { target: '.loadNearbyLocations' },

    ADD_MORE_LABEL: {
      target: '.addAdditionalLabel',
    },
  },
});

export type ReturnDetailSubFlowActorRef = ActorRefFrom<typeof returnDetailSubFlow>;

export type ReturnDetailSubFlowSnapshot = SnapshotFrom<typeof returnDetailSubFlow>;
