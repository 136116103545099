import React from 'react';

import { Box, Button } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice.ts';

export interface FillReturnFooterProps {
  isDisabled: boolean;
  onPress: VoidFunction;
  btnText: string;
  isLoading?: boolean;
}
const FillReturnFooter = ({
  isDisabled,
  onPress,
  btnText,
  isLoading = false,
}: FillReturnFooterProps) => {
  const isMobile = useDevice().mobile;
  return (
    <Box
      paddingX={isMobile ? SpacingVars['4'] : '54px'}
      paddingTop={SpacingVars['4']}
      paddingBottom={SpacingVars[isMobile ? '4' : '8']}
    >
      <Button size={'large'} isDisabled={isDisabled} onPress={onPress} isLoading={isLoading}>
        {btnText}
      </Button>
    </Box>
  );
};

export default FillReturnFooter;
