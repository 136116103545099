import Image from 'next/image';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Modal, ModalBody, ModalHeader, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

const warningImage = require('@/assets/warning_outlined.svg').default;

export interface MissAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const MissAddressModal = ({ isOpen, onClose }: MissAddressModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader divider title={t('page.error.missingShippingAddress')} onClose={onClose} />
      <ModalBody>
        <Flex direction={'column'} alignItems={'center'} gap={SpacingVars['6']}>
          <Image src={warningImage.src} width={100} height={100} alt={'warning'} />
          <Text textAlign={'center'}>{t('page.details.missingShippingAddress')}</Text>
        </Flex>
      </ModalBody>
    </Modal>
  );
};

export default MissAddressModal;
