import React from 'react';

import { BackgroundColor, Box, DimensionValue } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

export interface Props {
  backgroundColor?: BackgroundColor;
  spacing?: DimensionValue;
  direction?: 'horizontal' | 'vertical';
}
const Divider = ({
  spacing = SpacingVars['6'],
  direction = 'vertical',
  backgroundColor = ColorVars.Grey['Grey 400'],
}: Props) => {
  const style =
    direction === 'vertical'
      ? {
          height: '1px',
          marginTop: spacing,
          marginBottom: spacing,
        }
      : {
          width: '1px',
          height: '13px',
        };
  return <Box {...style} backgroundColor={backgroundColor} />;
};

export default Divider;
