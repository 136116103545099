'use client';

import DropdownBase from './DropdownBase';
import { DropdownProps } from './type';

import { clsx } from '../../utils/clsx';
import { withCls } from '../../utils/withCls';

const Dropdown = (props: DropdownProps) => {
  return (
    <DropdownBase className={clsx(withCls('Dropdown'), props.className)} {...props}></DropdownBase>
  );
};

export default Dropdown;
