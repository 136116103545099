import { assign } from 'xstate';

import { mockExchangeOrRefundSubFlow } from './exchangeOrRefundSubFlow.ts';

import { resolutionSubFlow } from '../../core/machine/ResolutionSubFlow';
import { getMatchingMethod, getMatchingResolutions } from '../promise';
import { defaultSelectedItems } from '../requestData/order/defaultOrder.ts';

export const mockResolutionSubFlow = resolutionSubFlow.provide({
  actors: {
    getMatchingResolutions: getMatchingResolutions,
    getMatchingMethod: getMatchingMethod,
    exchangeOrRefundSubFlow: mockExchangeOrRefundSubFlow,
  },
  actions: {
    updatePreviewData: assign({
      selectedItems: defaultSelectedItems,
    }),
  },
});

export default mockResolutionSubFlow;
