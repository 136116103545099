import i18next, { Resource } from 'i18next';
import { getI18n } from 'react-i18next';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { skipTranslatePlugin } from './plugins';
import { I18NEXT_NAMESPACE } from './utils';

export const initI18NextForClient = (lng: string, resources: Resource, fallbackLng?: string) => {
  if (!getI18n()?.isInitialized) {
    document.documentElement.lang = lng;
    i18next
      .use(skipTranslatePlugin({ skipTranslateLang: fallbackLng }))
      .use(initReactI18next)
      // @ts-ignore
      // meerkat 的类型定义影响到了 i18next
      .init({
        postProcess: ['skipTranslatePlugin'],
        lng: lng,
        ns: [I18NEXT_NAMESPACE],
        defaultNS: I18NEXT_NAMESPACE,
        resources,
        fallbackLng,
        interpolation: {
          escapeValue: false,
          prefix: '${',
          suffix: '}',
        },
        preload: [],
      });
  }
};
