import {
  connectorsEndpoint,
  internalEndpoint,
  shopperEndpoint,
  shopperV2Endpoint,
  shopperV3Endpoint,
  shopperV4Endpoint,
} from '../../config';

export const hostName = shopperEndpoint;

export const v2hostName = shopperV2Endpoint;

export const v3hostName = shopperV3Endpoint;

export const v4hostName = shopperV4Endpoint;
export const internalHostname = internalEndpoint;

export const connectorHostName = connectorsEndpoint;

// export const SHOP_HOST_NAME = 'shopHostName';
