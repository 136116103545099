import { useMainFlowContext, useSelector } from 'returns-logics/react';

import { useFieldAWithMemo } from '@/hooks/useFieldAWithMemo';

export const useReturnsListSubFlow = () => {
  const mainFlow = useMainFlowContext();

  const returnListSubFlow = useSelector(mainFlow, (state) => state.children.returnListSubFlow);

  const data = useSelector(returnListSubFlow, (state) => {
    if (!state) return;

    return {
      id: returnListSubFlow?.id,
      context: state?.context,
      currentStep: {
        name: state?.value,
        isLoading: state?.hasTag?.('loading'),
      },
      snapshot: state,
      ref: returnListSubFlow,
      on: returnListSubFlow?.on?.bind(returnListSubFlow),
      dispatch: returnListSubFlow?.send,
      matches: state?.matches?.bind(state),
      children: state?.children,
    };
  });

  return useFieldAWithMemo(data) as NonNullable<typeof data>;
};
