import { ActorRefFrom, SnapshotFrom, assertEvent, assign, setup } from 'xstate';

import { getClickwrap } from '../promise/getClickwrapConfig';
import { IClickwrap } from '../types';

export interface Context {
  clickwrapData?: IClickwrap;
}

type PreviewEvent = {
  type: 'UPDATE_MOCK_CLICKWRAP';
  data: {
    clickwrapData: IClickwrap;
  };
};

type Event =
  | {
      type: 'GET_CLICKWRAP';
      data: {
        orgId: string;
      };
    }
  | PreviewEvent;

export const clickwrapSubFlow = setup({
  types: {
    context: {} as Context,
    events: {} as Event,
  },
  actors: {
    getClickwrap,
  },
  actions: {
    updateContextInPreview: () => {},
  },
}).createMachine({
  initial: 'idle',

  states: {
    idle: {
      on: {
        GET_CLICKWRAP: 'getClickwrap',
      },
    },
    getClickwrap: {
      initial: 'loading',
      states: {
        loading: {
          tags: ['loading'],
          invoke: {
            src: 'getClickwrap',
            input: ({ event }) => {
              assertEvent(event, 'GET_CLICKWRAP');
              return { orgId: event.data.orgId };
            },
            onDone: {
              target: 'success',
              actions: assign({
                clickwrapData: ({ event }) => event.output,
              }),
            },
            onError: 'error',
          },
        },
        success: {
          type: 'final',
        },
        error: {
          type: 'final',
        },
      },
    },
  },
  on: {
    UPDATE_MOCK_CLICKWRAP: {
      actions: 'updateContextInPreview',
    },
  },
});

export type ClickwrapSubFlowActorRef = ActorRefFrom<typeof clickwrapSubFlow>;

export type ClickwrapSubFlowSnapshot = SnapshotFrom<typeof clickwrapSubFlow>;
