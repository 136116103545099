import { fromPromise } from 'xstate';

import { QuestionWorkFlowData } from '../../core';
import { GetQuestionsInput } from '../../core/promise/questions';
import { defaultQuestion } from '../requestData/question/defaultQuestion';

export const getQuestions = fromPromise<
  QuestionWorkFlowData['questions'],
  GetQuestionsInput['input']
>(async () => {
  return defaultQuestion;
});
