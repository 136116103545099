import { useMemo } from 'react';

import { useMemoGiftReturn } from './useMemoGiftReturn';

export type GiftReturnCurrentStep =
  | 'orderLookupInput'
  | 'formWithoutOrderInfo'
  | 'formWithOrderInfo'
  | 'submmited';

export const useGiftReturnCurrentStep = (): GiftReturnCurrentStep | null => {
  const giftReturnSubFlow = useMemoGiftReturn();

  const showOrderLookupInput =
    giftReturnSubFlow?.matches?.('orderLookupInput') ||
    giftReturnSubFlow?.matches?.('fetchOrderInfo');

  const showFormWithoutOrderInfo = giftReturnSubFlow?.matches?.('formWithoutOrderInfo');

  const showFormWithOrderInfo = giftReturnSubFlow?.matches?.('formWithOrderInfo');

  const showSubmitted = giftReturnSubFlow?.matches?.('submmited');

  const result = useMemo(() => {
    if (showOrderLookupInput) {
      return 'orderLookupInput';
    }
    if (showFormWithoutOrderInfo) {
      return 'formWithoutOrderInfo';
    }
    if (showFormWithOrderInfo) {
      return 'formWithOrderInfo';
    }
    if (showSubmitted) {
      return 'submmited';
    }
    return null;
  }, [showOrderLookupInput, showFormWithoutOrderInfo, showFormWithOrderInfo, showSubmitted]);

  return result;
};
