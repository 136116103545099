import { useTranslation } from 'react-i18next';

import { Box, ChevronDown, ChevronTop, Flex, Icon, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

interface AccordionProps {
  isFold: boolean;
  setIsFold?: (isFold: boolean) => void;
  isPreview?: boolean;
}
const Accordion = ({ isPreview, isFold, setIsFold }: AccordionProps) => {
  const { t } = useTranslation();
  return (
    <Box>
      <div
        onClick={() => {
          if (!isPreview) setIsFold?.(!isFold);
        }}
      >
        <Flex gap={SpacingVars['1']} direction='row' alignItems='center'>
          <Text>{t('v2.summary.fold.desc')}</Text>
          <Icon source={isFold ? ChevronDown : ChevronTop} />
        </Flex>
      </div>
    </Box>
  );
};

export default Accordion;
