import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  sendParent,
  setup,
} from 'xstate';

import { ReturnListContext, ReturnListEvent, ReturnListInput } from './types';

import { getReturns } from '../../promise';
import {
  deleteDraftReturnsActor,
  getDraftReturnByIdActor,
  getDraftReturnsActor,
} from '../../promise/review';
import { ErrorResponse } from '../../request/ErrorResponse';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';

const POLLING_MAX_COUNT = 10;

export const returnListSubFlow = setup({
  types: {
    context: {} as ReturnListContext,
    input: {} as ReturnListInput,
    events: {} as ReturnListEvent,
  },
  actors: { getReturns, getDraftReturnsActor, deleteDraftReturnsActor, getDraftReturnByIdActor },
  guards: {
    isStopPolling: ({ context }) => {
      return context.pollCount >= POLLING_MAX_COUNT || !!context?.draftReturn?.return_id;
    },
  },
  actions: {
    updateContextInPreview: () => {},
    updateOrdersInPreviewMode: () => {},
  },
}).createMachine({
  initial: 'validateData',
  entry: 'updateOrdersInPreviewMode',
  context: ({ input }) => ({
    ...input,
    returns: [],
    draftReturns: [],
    pollCount: 0,
  }),
  states: {
    validateData: {
      tags: 'loading',
      always: [
        {
          guard: ({ context }) => !context.orderId,
          actions: sendParent({ type: 'GO_TO_ORDER_LOOKUP' }),
        },
        { target: 'loadAllReturns' },
      ],
    },
    loadAllReturns: {
      id: 'loadAllReturns',
      type: 'parallel',
      states: {
        getReturns: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getReturns',
                input: ({ context }) => ({
                  order_id: context.orderId,
                  token: context.token || '',
                }),
                onDone: {
                  target: 'success',
                  actions: assign({ returns: ({ event }) => event.output }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  { target: 'error' },
                ],
              },
            },
            success: { type: 'final', tags: 'success' },
            error: { tags: 'error' },
          },
        },
        getDraftReturns: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getDraftReturnsActor',
                input: ({ context }) => ({
                  token: context?.token,
                  orderId: context.orderId,
                }),
                onDone: {
                  target: 'success',
                  actions: assign({ draftReturns: ({ event }) => event.output?.draft_returns }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  { target: 'error' },
                ],
              },
            },
            success: { type: 'final', tags: 'success' },
            error: { tags: 'error' },
          },
        },
      },
    },
    getDraftReturnById: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getDraftReturnByIdActor',
            input: ({ event, context }) => {
              assertEvent(event, 'CHECK_DRAFT_RETURN');
              return { token: context?.token, draftId: event?.data?.draftId };
            },
            onDone: { target: 'success' },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final', tags: 'success' },
        error: { tags: 'error' },
      },
    },
    deleteDraftReturn: {
      initial: 'loading',
      entry: assign({
        currentDraftId: ({ event }) => {
          assertEvent(event, 'DELETE_DRAFT_RETURN');
          return event?.data?.draftId;
        },
      }),
      states: {
        loading: {
          invoke: {
            src: 'deleteDraftReturnsActor',
            input: ({ context, event }) => {
              assertEvent(event, 'DELETE_DRAFT_RETURN');
              return { token: context?.token, draftId: event?.data?.draftId! };
            },
            onDone: {
              target: 'success',
              actions: assign(({ context }) => ({
                currentDraftId: undefined,
                draftReturns: context.draftReturns.filter(
                  (item) => item.id !== context.currentDraftId,
                ),
              })),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              {
                target: 'error',
                actions: assign(({ event }) => ({
                  deleteDraftReturnError: event.error as ErrorResponse,
                })),
              },
            ],
          },
        },
        success: { tags: 'success', always: { target: '#loadAllReturns' } },
        error: { tags: 'error', always: { target: '#loadAllReturns' } },
      },
    },
    pollingGetDraftReturn: {
      initial: 'fetching',
      // 初始化 pollCount
      entry: assign(({ event }) => {
        assertEvent(event, 'POLLING_GET_DRAFT_RETURN');
        return {
          pollCount: 0,
          currentDraftId: event.data.draftId,
        };
      }),
      states: {
        fetching: {
          tags: 'loading',
          invoke: {
            id: 'getDraftReturnByIdActor',
            src: 'getDraftReturnByIdActor',
            input: ({ context }) => ({
              token: context?.token!,
              draftId: context.currentDraftId!,
            }),
            onDone: {
              target: 'delay',
              actions: assign({ draftReturn: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        delay: {
          after: { 1000: { target: 'checking' } },
        },
        checking: {
          always: [
            { target: 'success', guard: 'isStopPolling' },
            {
              target: 'fetching',
              actions: assign({
                pollCount: ({ context }) => context.pollCount + 1,
              }),
            },
          ],
        },
        success: { tags: 'success', type: 'final' },
        error: { tags: 'error' },
      },
    },
    stopPollingDraftReturn: {
      initial: 'success',
      states: {
        success: { type: 'final', tags: 'success' },
      },
    },
  },
  on: {
    RELOAD_RETURNS: { target: '.loadAllReturns' },
    CHECK_DRAFT_RETURN: { target: '.getDraftReturnById' },
    DELETE_DRAFT_RETURN: { target: '.deleteDraftReturn' },
    POLLING_CANCEL_DRAFT_RETURN: { target: '.stopPollingDraftReturn' },
    POLLING_GET_DRAFT_RETURN: { target: '.pollingGetDraftReturn' },
    RESET_ERROR: { actions: assign({ deleteDraftReturnError: undefined }) },
    UPDATE_RETURN_LIST_IN_PREVIEW: { actions: 'updateContextInPreview' },
  },
});

export type ReturnListSubFlowActorRef = ActorRefFrom<typeof returnListSubFlow>;
export type ReturnListSubFlowSnapshot = SnapshotFrom<typeof returnListSubFlow>;
