import { useCallback, useEffect, useRef, useState } from 'react';

export const useTimeout = ({ callback, delay }: { callback?: () => void; delay: number }) => {
  const timeoutRef = useRef<NodeJS.Timeout | number | undefined>(undefined);
  const callbackRef = useRef(callback);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isStop, setIsStop] = useState(false);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
      setIsWaiting(false);
    }
  }, []);

  const stop = useCallback(() => {
    clear();
    setIsStop(true);
  }, [clear]);

  const start = useCallback(() => {
    if (delay === undefined) return;
    clear();
    setIsWaiting(true);
    setIsStop(false);
    timeoutRef.current = setTimeout(
      () => (callbackRef.current?.(), setIsWaiting(false)),
      Math.max(0, delay),
    );
  }, [delay, clear]);

  return { isWaiting, isStop, start, clear, stop };
};
