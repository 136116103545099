import { t } from 'i18next';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { Flex } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import FormInput from '@/components/Form/FormInput';
import PhoneInput from '@/components/PhoneInput';

// import { PhoneNumberTextField } from '../../preview/components/WithPreviewSection/index';

export const PhoneNumberInput = ({ control }: { control: Control<any> }) => (
  <Flex gap={SpacingVars['2']}>
    <Controller
      control={control}
      name='callingCodeData'
      render={({ field: { onChange, value } }) => {
        return <PhoneInput value={value} onChange={onChange} />;
      }}
    />
    <FormInput
      control={control}
      name='phoneNumber'
      autoComplete='off'
      clearable
      placeholder={t('page.landing.phoneNumber')}
      fullWidth
      hiddenError
    />
  </Flex>
);
