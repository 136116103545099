export function getOpacityColors<T extends Array<number>>(color: string, opacities: T): string[] {
  // 正则表达式用于检测颜色格式
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/i;
  const rgbaRegex = /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*([0-1]?(?:\.\d+)?|1)\)$/i;
  const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/i;
  const hslaRegex = /^hsla\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s*([0-1]?(?:\.\d+)?|1)\)$/i;
  const hslRegex = /^hsl\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%\)$/i;

  let colors: string[] = [];

  if (hexRegex.test(color)) {
    // 处理 HEX 格式
    const rgb = hexToRgb(color);
    opacities.forEach((opacity, index) => {
      colors[index] = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
    });
  } else if (rgbaRegex.test(color) || rgbRegex.test(color)) {
    // 处理 RGBA 或 RGB 格式
    const baseColor = color.replace(
      /rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*[0-1]?(?:\.\d+)?|1)?\)/i,
      '$1, $2, $3',
    );
    opacities.forEach((opacity, index) => {
      colors[index] = `rgba(${baseColor}, ${opacity})`;
    });
  } else if (hslaRegex.test(color) || hslRegex.test(color)) {
    // 处理 HSLA 或 HSL 格式
    const baseColor = color.replace(
      /hsla?\((\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%(?:,\s*[0-1]?(?:\.\d+)?|1)?\)/i,
      '$1, $2%, $3%',
    );
    opacities.forEach((opacity, index) => {
      colors[index] = `hsla(${baseColor}, ${opacity})`;
    });
  } else {
    throw new Error('Unsupported color format');
  }

  return colors;
}

// 将 HEX 颜色转换为 RGB
function hexToRgb(hex: string) {
  let r = 0,
    g = 0,
    b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex.substring(1, 3), 16);
    g = parseInt(hex.substring(3, 5), 16);
    b = parseInt(hex.substring(5, 7), 16);
  }
  return { r, g, b };
}
