import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Facebook, IconSource, Instagram, X } from '@aftership/design-system';
import { MenuItem, Shops } from 'returns-logics';

const getIsSiteUrl = (url: string) => url.startsWith('http://') || url.startsWith('https://');

const useShopUrls = (shopInfo?: Shops) => {
  const { t } = useTranslation();
  const { social_facebook, social_twitter, social_instagram, contact_url, terms_url, privacy_url } =
    shopInfo || {};

  const socialLinks = useMemo(() => {
    // name 不需要多语言
    const socialLinks: Array<{ url: string; icon: IconSource; name: string }> = [];
    if (social_facebook) {
      socialLinks.push({
        url: `https://facebook.com/${social_facebook}`,
        icon: Facebook,
        name: 'Facebook',
      });
    }
    if (social_twitter) {
      socialLinks.push({ url: `https://x.com/${social_twitter}`, icon: X, name: 'X' });
    }
    if (social_instagram) {
      socialLinks.push({
        url: `https://instagram.com/${social_instagram}`,
        icon: Instagram,
        name: 'Instagram',
      });
    }
    return socialLinks;
  }, [social_facebook, social_instagram, social_twitter]);

  const footerLinks = useMemo(() => {
    const links: MenuItem[] = [];
    if (contact_url) {
      const contactUrl = getIsSiteUrl(contact_url) ? contact_url : `mailto:${contact_url}`;
      links.push({ url: contactUrl, name: t('page.footer.contactUs') });
    }
    if (terms_url) {
      links.push({ url: terms_url, name: t('page.footer.terms') });
    }
    if (privacy_url) {
      links.push({ url: privacy_url, name: t('page.footer.privacy') });
    }
    return links;
  }, [contact_url, terms_url, privacy_url, t]);

  return useMemo(() => {
    return {
      socialLinks,
      footerLinks,
    };
  }, [socialLinks, footerLinks]);
};

export default useShopUrls;
