import React from 'react';

function getSnapshot() {
  return false;
}

function getServerSnapshot() {
  return true;
}

function subscribe(): () => void {
  // noop
  return () => {};
}

export function useIsSSR(): boolean {
  // In React 18, we can use useSyncExternalStore to detect if we're server rendering or hydrating.
  if (typeof React['useSyncExternalStore'] === 'function') {
    return React['useSyncExternalStore'](subscribe, getSnapshot, getServerSnapshot);
  }

  return typeof window === 'undefined';
}
