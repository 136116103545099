import { IconSource } from './type.ts';

/**
 * @description 小一些的左箭头，用于 StepCard onBack
 * @size 24 x 24
 */
const LeftChevronThin: IconSource = () => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m15 5-6.823 6.823a.25.25 0 0 0 0 .354L15 19'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default LeftChevronThin;
