import { Uploader } from '@aftership/design-system';
import { Reason, ReturnImage } from 'returns-logics';

export const checkFillDetailInfoBtnAvailable = (
  reasons: Reason[],
  selectedReasonId: string,
  uploadState: Uploader['status'] | 'idle',
  images: ReturnImage[] | undefined,
  comments: string,
) => {
  const selectedReason = reasons.find((reason) => reason.id === selectedReasonId)!;
  const {
    comments_enabled: commentsEnabled,
    comments_required: commentsRequired,
    images_enabled: imagesEnabled,
    images_required: imagesRequired,
  } = selectedReason!;
  const commentsInValid = commentsEnabled && commentsRequired && !comments;
  const imagesInValid = imagesEnabled && imagesRequired && !images?.length;
  return commentsInValid || imagesInValid || uploadState === 'error';
};
