import { isArray } from 'lodash-es';
import { ReactNode } from 'react';

import { Box, Flex } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

const ModalButtonGroup = ({
  mode,
  children,
}: {
  mode?: 'desktop' | 'mobile';
  children: ReactNode | ReactNode[];
}) => {
  const { mobile } = useDevice();

  const isMobile = mode ? mode === 'mobile' : mobile;

  return (
    <Flex
      direction={isMobile ? 'column' : 'row'}
      gap={isMobile ? SpacingVars['4'] : SpacingVars['6']}
      paddingX={isMobile ? SpacingVars['8'] : 0}
      paddingTop={isMobile ? SpacingVars['4'] : void 0}
      paddingBottom={isMobile ? SpacingVars['8'] : void 0}
    >
      {/* todo 待优化 */}
      {isArray(children) ? (
        !isMobile ? (
          children
            ?.map((child, index) => (
              <Box flex={1} key={index}>
                {child}
              </Box>
            ))
            ?.reverse()
        ) : (
          children?.map((child, index) => (
            <Box flex={1} key={index}>
              {child}
            </Box>
          ))
        )
      ) : (
        <Box flex={1}>{children}</Box>
      )}
    </Flex>
  );
};

export default ModalButtonGroup;
