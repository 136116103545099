export const maxHappyReturnSelectedItems = 9;

export enum Weekday {
  SUN = 'Sun',
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
}

export enum ItemResolution {
  Exchange = 'exchange',
  Refund = 'refund',
}

export enum ReturnCostOption {
  HIDDEN = 'hidden',
  FREE = 'free',
  CUSTOM = 'custom',
  EstimatedLabel = 'estimated_label_cost',
  PercentageValue = 'percentage_of_return_value',
}

export enum ReturnMethodSlug {
  CustomerCourier = 'customer_courier',
  InStore = 'in_store',
  RetailerLabel = 'retailer_label',
  GreenReturn = 'green_return',
  HappyReturns = 'happy_returns',
  RetailRework = 'retail_reworks',
  CarrierPickup = 'carrier_pickup',
}

// 这里的 slug 会传到 shipping 那边，所以值会与上面 returns 定义的 slug 不一致
export enum SupportDropoffSlug {
  HappyReturns = 'happy-returns',
  RetailRework = 'retail-reworks',
}

export enum DistanceUnit {
  KM = 'km',
  MI = 'mi',
}

export const WeekdayArray: Weekday[] = [
  Weekday.MON,
  Weekday.TUE,
  Weekday.WED,
  Weekday.THU,
  Weekday.FRI,
  Weekday.SAT,
  Weekday.SUN,
];

// 不传时默认为 3 条
// retail rework 要求最多展示 50 条
export const defaultLocationCount = 3;
export const locationCountMapping = {
  [SupportDropoffSlug.HappyReturns]: defaultLocationCount,
  [SupportDropoffSlug.RetailRework]: 50,
};
