'use client';

import { PropsWithChildren } from 'react';
import { Radio as AriaRadio } from 'react-aria-components';

import { radioClass, radioDefaultRenderIconRecipe, radioDefaultRenderRecipe } from './Radio.css';
import { RadioProps } from './type';

import { clsx } from '../../utils/clsx';

const Radio = (props: PropsWithChildren<RadioProps>) => {
  const { renderChildren, children, className, ...rest } = props;
  const defaultRender = (
    <span className={clsx(radioDefaultRenderRecipe())}>
      <span className={clsx(radioDefaultRenderIconRecipe())}></span>
      <span>{children}</span>
    </span>
  );

  return (
    <AriaRadio className={clsx(radioClass, className)} {...rest}>
      {renderChildren || defaultRender}
    </AriaRadio>
  );
};

export default Radio;
