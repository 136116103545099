'use client';

import { Button, FileTrigger, FileTriggerProps } from 'react-aria-components';

import {
  previewContainerRecipe,
  uploadItemRecipe,
  uploadTriggerMessageRecipe,
  uploadTriggerRecipe,
} from './FileUpload.css';
import { fileUploadThemeClassName } from './FileUploadToken.css';
import UploadPreview from './UploadPreview';
import Plus from './icon/PlusIcon';
import useUpload, { UploadAction, UploadError, Uploader } from './useUpload';

import { clsx } from '../../utils/clsx';
import { isTrueAndPass } from '../../utils/isTrueAndPass';
import { Icon } from '../Icon';

export interface FileUploaderProps<T extends any> extends Omit<FileTriggerProps, 'onSelect'> {
  /**
   * The action to upload the file, it can be a URL or a function
   * @example
   * ```tsx
   * // Via URL
   * action: 'https://example.com/upload'
   *
   * // Via function
   * action: async (put, file) => {
   *  const presignedUrl = await put('https://example.com/upload');
   *  return put(presignedUrl, { data: file });
   * }
   *
   * ```
   */
  action: UploadAction<T>;
  /**
   * Limit the number of files to upload
   * @default 10
   */
  maxCount?: number;
  /**
   * Limit the size of each file in MB
   * @default 5 MB
   */
  limitSize?: number;
  /**
   * Support initial files for the uploader to preview
   * @example ['https://example.com/file1.jpg', 'https://example.com/file2.jpg']
   */
  value?: string[];
  /**
   * Triggered when files uploaded successfully.
   * @param uploaders
   * @returns {void}
   */
  onChange?: (uploaders: Uploader<T>[]) => void;
  /**
   * Reminder
   * @default false
   * e.g.
   * <span>
   *   Max. size: <br />5 MB
   * </span>
   */
  tips?: React.ReactNode;
  onError?: (error: UploadError) => void;
  /**
   * Multiple uploads of the same image when allowSameFile = true
   * @default false
   */
  allowSameFile?: boolean;
}

const MAX_FILE_SIZE = 5;
function FileUploader<T>(props: FileUploaderProps<T>) {
  const {
    action,
    limitSize = MAX_FILE_SIZE,
    maxCount = 10,
    allowsMultiple = true,
    value = [],
    onChange,
    onError,
    tips,
    allowSameFile = false,
    ...ariaTriggerProps
  } = props;

  const { upload, uploaders, remove } = useUpload<T>({
    action,
    limitSize,
    onError,
    onUploaded: onChange,
    files: value,
    allowSameFile,
  });

  const handleSelect = (files: FileList | null) => {
    if (!files) return;
    if (uploaders.length + files.length > maxCount) {
      onError?.({
        type: 'limit-length',
        error: new Error(`file Quantity exceeds limit : ${maxCount}, will be truncated.`),
      });
    }
    // 截断超出数量限制的文件
    const allowedFiles = Array.from(files).slice(0, maxCount - uploaders.length);

    allowedFiles.forEach((file) => {
      upload(file);
    });
  };

  const triggerVisible = uploaders.length < maxCount;

  return (
    <div className={clsx(fileUploadThemeClassName, previewContainerRecipe())}>
      {triggerVisible ? (
        <FileTrigger {...ariaTriggerProps} allowsMultiple={allowsMultiple} onSelect={handleSelect}>
          <Button className={clsx(uploadItemRecipe(), uploadTriggerRecipe())}>
            <Icon source={Plus} color='currentColor' size='24px' />
            {isTrueAndPass(
              tips !== undefined,
              <div className={uploadTriggerMessageRecipe()}>{tips}</div>,
            )}
          </Button>
        </FileTrigger>
      ) : null}

      {uploaders.map((uploader) => (
        <UploadPreview
          {...uploader}
          key={uploader.key}
          onRemove={() => remove(uploader.key)}
          src={uploader.thumbnail}
        />
      ))}
    </div>
  );
}

export default FileUploader;
