import { Flex, Modal, ModalBody, ModalHeader, Spinner, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

interface StripeInProgressModalProps {
  isOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  message: string;
}

export const StripeInProgressModal = ({
  isOpen,
  title,
  message,
  onCancel,
}: StripeInProgressModalProps) => {
  const { mobile } = useDevice();

  const Container = () => {
    return (
      <Flex
        direction='column'
        justifyContent='center'
        alignItems='center'
        gap={SpacingVars['2']}
        height={'100%'}
      >
        <Spinner size='60px' />
        <Text variant='body1'>{message}</Text>
      </Flex>
    );
  };

  if (mobile) {
    return (
      <MobilePopup isOpen={isOpen} title={title} onClose={onCancel}>
        <Container />
      </MobilePopup>
    );
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={title} onClose={onCancel} suffixIcon={<></>} />

      <ModalBody>
        <Container />
      </ModalBody>
    </Modal>
  );
};
