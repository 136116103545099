/* eslint-disable @typescript-eslint/naming-convention */
export enum PreviewSectionName {
  // Main page
  orderNumberPrompt = 'mainOrderNumberInput',
  emailPrompt = 'mainOrderEmailInput',
  MAIN_ORDER_POSTAL_CODE_INPUT = 'mainOrderPostalCodeInput',
  MAIN_ORDER_PHONE_NUMBER_INPUT = 'mainOrderPhoneNumberInput',
  MAIN_FORM_TITLE_TEXT = 'mainFormTitleText',
  MAIN_FIND_YOUR_ORDER_BUTTON = 'mainFindYourOrderButton',
  MAIN_GIFT_RETURN_BUTTON = 'mainGiftReturnButton',
  MAIN_POLICY_SUMMARY_TEXT = 'mainPolicySummaryText',
  MAIN_CLICKWRAP = 'mainClickwrap',
  MAIN_MARKETING_ASSETS = 'mainMarketingAssets',

  // ReturnsList
  RETURNS_ITEM_PRIMARY_TITLE = 'returnsItemPrimaryTitle',

  // RequestReturns
  REQUEST_RETURNS_PAGE_TITLE = 'requestReturnsPageTitle',
  NON_RETURNABLE_SECTION = 'nonReturnableSection',

  // Resolution
  RESOLUTION_PAGE_TITLE = 'resolutionPageTitle',

  // Shipping
  SHIPPING_PAGE_TITLE = 'shippingPageTitle',
  SHIPPING_ITEM = 'shippingItem',

  // Returns Request Review
  REQUEST_REVIEW_PAGE_TITLE = 'requestReviewPageTitle',
  RETURN_ITEMS_SECTION_TITLE = 'returnItemsSectionTitle',
  // RETURN_REASON_LABEL = 'returnReasonLabel',
  EXCHANGE_ITEMS_SECTION_TITLE = 'exchangeItemsSectionTitle',
  RESOLUTION_SECTION_TITLE = 'resolutionSectionTitle',
  SHIPPING_SECTION_TITLE = 'shippingSectionTitle',
  REVIEW_SUMMARY_SECTION = 'reviewSummarySection',
}
