import { fromPromise } from 'xstate';

import { GetDraftReturnPayload, GetDraftReturnsResponse, Returns } from '../../core';
import { GetReturnsPayload } from '../../core/promise';
import { defaultReturns } from '../requestData/returns/defaultReturns';

// 获取 returns 列表
export const getReturns = fromPromise<Returns['returns'], GetReturnsPayload>(async () => {
  return defaultReturns;
});

export const getDraftReturnsActor = fromPromise<
  GetDraftReturnsResponse,
  GetDraftReturnPayload['input']
>(async () => {
  return { draft_returns: [] };
});
