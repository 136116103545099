import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

import { useCachedAppProxy } from './useCachedAppProxy';

function addSlashPath(oldPath: string): string {
  return oldPath.startsWith('/') ? oldPath : `/${oldPath}`;
}

function getPrefixedPath(path: string, pathPrefix: string): string {
  if (!pathPrefix) return addSlashPath(path);
  return pathPrefix + addSlashPath(path);
}

interface Route {
  params?: Record<string, string>;
  pathname: string;
  asPath?: string;
}

export function useUniversalRouting() {
  const router = useRouter();
  const params = router.query;
  const searchParams = useMemo(
    () => new URLSearchParams(router.asPath.split('?')[1] || ''),
    [router.asPath],
  );
  const { pathPrefix } = useCachedAppProxy();

  const navigate = ({ params, pathname, asPath }: Route): Promise<boolean> => {
    const pathWithPrefix = getPrefixedPath(asPath || pathname, pathPrefix);

    return router.push(
      {
        pathname,
        query: params,
      },
      pathWithPrefix,
    );
  };

  const replace = ({ params, pathname, asPath }: Route): Promise<boolean> => {
    const pathWithPrefix = getPrefixedPath(asPath || pathname, pathPrefix);

    return router.replace(
      {
        pathname,
        query: params,
      },
      pathWithPrefix,
    );
  };

  const goBack = (): void => {
    router.back();
  };

  const getSearchParam = useCallback(
    <T = string>(param: string, defaultValue?: T): T => {
      const value = searchParams?.get(param);
      return (value !== undefined ? value : defaultValue) as T;
    },
    [searchParams],
  );

  const getParam = <T = string>(param: string, defaultValue?: T): T => {
    const value = params?.[param];
    return (value !== undefined ? value : defaultValue) as T;
  };

  return {
    navigate,
    replace,
    goBack,
    getSearchParam,
    getParam,
    pathname: router.pathname,
    events: router.events,
    beforePopState: router.beforePopState,
  };
}

export function useGetPrefixedLink(path: string): string {
  const { pathPrefix = '' } = useCachedAppProxy();
  return getPrefixedPath(path, pathPrefix);
}

export function useGetPrefiedLink(path: string) {
  const { pathPrefix = '' } = useCachedAppProxy();

  return pathPrefix + addSlashPath(path);
}
