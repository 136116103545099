import getConfig from 'next/config';

import { connectorHostName } from '@/constants/hostname';
import { request } from '@/utils/request';

interface IStore {
  connections: { organization: { id: string } }[];
}

const {
  serverRuntimeConfig: { AM_API_KEY },
} = getConfig();

export default async function getStores(appKey: string) {
  const uri = `${connectorHostName}/connections?app_key=${appKey}&app_name=returnscenter&app_platform=shopify`;

  const response = await request<IStore>(uri, {
    headers: { 'am-api-key': AM_API_KEY, 'Content-Type': 'application/json' },
  });
  return response.data || Promise.reject(response);
}

export async function getOrgIdByAppKey(appKey: string) {
  const stores = await getStores(appKey);

  return stores.connections[0].organization.id;
}
