import { IconSource } from './type.ts';

/**
 * @description 右箭头，用于 item selection
 * @size 16 x 16
 */
const RightChevron: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m6 13 4.823-4.823a.25.25 0 0 0 0-.354L6 3'
        stroke='currentColor'
        strokeWidth={1.75}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default RightChevron;
