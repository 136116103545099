import { PropsWithChildren } from 'react';

import { clsx } from '../../utils/clsx';
import { cardSectionRecipe } from './Card.css';
import { CardSectionProps } from './type';

const CardSection = ({ className, children }: PropsWithChildren<CardSectionProps>) => {
  return <div className={clsx(cardSectionRecipe(), className)}>{children}</div>;
};

export default CardSection;
