import {
  ProductEnhanceQuantity,
  ProductForReplace,
  ProductOption,
  Variant,
  VariantForReplace,
  VariantOption,
} from '../types/products';

/**
 * Options 排序规则
 * @param option1
 * @param option2
 */
const compareProductOption = (option1: ProductOption, option2: ProductOption) =>
  Number(option1.position) - Number(option2.position);

/**
 * 如果经过前端计算,不符合最小库存的要求,需要把 variant 的 availability 转换为不可用,并设置原因
 * @param variant
 * @param isInventorySafety
 */
function convertVariantAvailability(variant: Variant, isInventorySafety: boolean) {
  return isInventorySafety
    ? { available: variant.availability.available, all_of: variant.availability.all_of }
    : {
        available: false,
        all_of: {
          ...variant.availability.all_of,
          minimum_inventory_level_passed: false,
        },
      };
}

/**
 * 根据 hideUnselectableVariant 决定是否需要 remove 不需要展示的 options
 * @param productOptions
 * @param variants
 * @param hideUnselectableVariant
 */
export const removeOptionsIfNecessary = (
  productOptions: ProductOption[],
  variants: VariantForReplace[],
  hideUnselectableVariant: boolean,
) => {
  // Sort the product options
  const sortedOptions = productOptions.sort(compareProductOption);

  // Filter valid variants if necessary
  const validVariants = hideUnselectableVariant
    ? variants.filter((variant) => variant.isValid)
    : variants;

  return sortedOptions.map<ProductOption>((option) => {
    // Filter option values based on whether they are valid in any variant
    const filteredValues = option.values.filter((value) => {
      if (!hideUnselectableVariant) {
        return true;
      }

      return validVariants.some((variant) => {
        return variant.options.some((skuOption) => {
          return skuOption.name === option.name && skuOption.value === value;
        });
      });
    });
    // Return the updated option
    return {
      ...option,
      values: filteredValues,
    };
  });
};

const transformVariant = (
  item: ProductEnhanceQuantity,
  variant: Variant,
  minInventory: number,
): VariantForReplace => {
  // 允许超卖或者退还指定数量后还满足最小库存
  const isInventorySafety =
    variant.allow_backorder ||
    item.selectedQuantity <= variant.online_available_quantity - minInventory;
  const availability = convertVariantAvailability(variant, isInventorySafety);
  return {
    productId: item.external_id,
    productTitle: item.title,
    allowBackorder: variant.allow_backorder,
    availability: availability,
    options: variant.options,
    online_available_quantity: variant.online_available_quantity,
    variantId: variant.external_id,
    variantTitle: variant.title,
    variantCoverUrl: variant.image_urls?.[0],
    isValid: variant.availability.available,
    replacePriceDiffirenceSet: variant.replace_price_difference_set,
    priceSet: variant.price_set,
  };
};
/**
 *
 * @param item 服务端 products 返回的 product 数据
 * @param hideUnselectableVariant 是否隐藏不可选的 variant, 意味着要隐藏相关的 options
 * @param minInventoryCalculated 是否限制了最小库存, 如果是,前端需要判断减去当前退货的数量后,是否超卖
 */
export const transformProductItem = (
  item: ProductEnhanceQuantity,
  hideUnselectableVariant: boolean,
  minInventoryCalculated: number,
): ProductForReplace => {
  const linkProductItems = item.product_links.map<ProductEnhanceQuantity>((product) => ({
    ...product,
    selectedQuantity: item.selectedQuantity,
  }));
  const variantsForReplace = item.variants.map<VariantForReplace>((variant) =>
    transformVariant(item, variant, minInventoryCalculated),
  );
  linkProductItems.forEach((product) => {
    const linkVariants = product.variants.map((variant) =>
      transformVariant(product, variant, minInventoryCalculated),
    );
    variantsForReplace.push(...linkVariants);
  });
  return {
    productId: item.external_id,
    productTitle: item.title,
    productCoverUrl: item.image_urls[0],
    options: removeOptionsIfNecessary(
      item.filtered_options,
      variantsForReplace,
      hideUnselectableVariant,
    ),
    variants: variantsForReplace,
  };
};

/**
 * 生成选中 option 后的 SKUAttrItem, 需要因此一些 Options
 * @param newSelectedOptions 选中的 Options
 * @param initialOptions 初始化时的 SKUAttrItem
 * @param variants  Variants 过滤 valid  为 true
 */
export const getNewAvailableOptionsWhenSelected = (
  newSelectedOptions: Array<VariantOption>,
  initialOptions: Array<ProductOption>,
  variants: VariantForReplace[],
): Array<ProductOption> => {
  const validVariants = variants.filter((variant) => variant.isValid);
  if (newSelectedOptions.length === 0) {
    return initialOptions;
  } else {
    return initialOptions.map<ProductOption>((item) => {
      // 除了当前 Option ,剩余还选中的 Options
      const restSelectedOptions = newSelectedOptions.filter((option) => option.name !== item.name);
      // 当前 Option 下的 spec 是否展示的规则:
      // 从 variants 中过滤出*含有*剩余选中 Options 的 variant
      const validVariantsForThisSpec = validVariants.filter((variant) => {
        return restSelectedOptions.every((selectedOption) => {
          return variant.options.some(
            (variantOption) =>
              selectedOption.name === variantOption.name &&
              selectedOption.value === variantOption.value,
          );
        });
      });
      // 能在这些 variant 的 Options 中,找到当前 spec
      const newValues = item.values.filter((spec) => {
        return validVariantsForThisSpec.some((variant) => {
          return variant.options.some(
            (option) => option.name === item.name && option.value === spec,
          );
        });
      });
      return {
        name: item.name,
        values: newValues,
        position: item.position,
      };
    });
  }
};

/**
 * 根据选中的"属性-规格", 从 variant 列表中找到指定的 variant
 * @param variants variant
 * @param selectOptions 当前选择的 "属性-规格" 集合
 * @param initialOptions 商品所有 "属性-规格" 集合
 */
export const findVariantByOption = (
  variants: Array<VariantForReplace>,
  selectOptions: Array<VariantOption>,
  initialOptions: ProductOption[],
) => {
  if (selectOptions.length < initialOptions.length) {
    return undefined;
  }
  let retVariants: Array<VariantForReplace> = variants.slice();
  selectOptions.forEach((option) => {
    retVariants = retVariants.filter((v) =>
      v.options.some(
        (skuOption) => skuOption.name === option.name && skuOption.value === option.value,
      ),
    );
  });
  return retVariants[0];
};
