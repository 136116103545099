'use client';

import { GridListItem } from 'react-aria-components';

import { listBoxItemRecipe } from './ListBox.css';
import { ListBoxItemProps } from './type';

import { clsx } from '../../utils/clsx';
import { useStyleProps } from '../../utils/styleProps';

const ListBoxItem = ({ children, className, ...props }: ListBoxItemProps) => {
  const { styleProps } = useStyleProps(props);
  return (
    <GridListItem className={clsx(listBoxItemRecipe(), className)} {...styleProps} {...props}>
      {children}
    </GridListItem>
  );
};

export default ListBoxItem;
