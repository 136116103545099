import { ReactNode, createContext } from 'react';

import type getInitialProps from '@/features/returns/resources/getInitialProps';

type PromiseType<T> = T extends Promise<infer P> ? P : T;
type ReturnsContextValue = PromiseType<ReturnType<typeof getInitialProps>>['initialProps'];

interface ReturnsContextProps {
  children: ReactNode;
  data: ReturnsContextValue;
}

export const ReturnsContext = createContext<ReturnsContextValue | null>(null);

const ReturnsProvider = ({ data, children }: ReturnsContextProps) => {
  return <ReturnsContext.Provider value={data}>{children}</ReturnsContext.Provider>;
};

export default ReturnsProvider;
