import { IconSource } from '../../Icon/type';

const X: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none'>
      <path
        d='M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z'
        fill='currentColor'
        fillOpacity={0.75}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.61872 16.6187C8.27701 16.9604 7.72299 16.9604 7.38128 16.6187C7.03957 16.277 7.03957 15.723 7.38128 15.3813L10.7626 12L7.38128 8.61872C7.03957 8.27701 7.03957 7.72299 7.38128 7.38128C7.72299 7.03957 8.27701 7.03957 8.61872 7.38128L12 10.7626L15.3813 7.38128C15.723 7.03957 16.277 7.03957 16.6187 7.38128C16.9604 7.72299 16.9604 8.27701 16.6187 8.61872L13.2374 12L16.6187 15.3813C16.9604 15.723 16.9604 16.277 16.6187 16.6187C16.277 16.9604 15.723 16.9604 15.3813 16.6187L12 13.2374L8.61872 16.6187Z'
        fill='white'
      />
    </svg>
  );
};

export default X;
