import { useCallback } from 'react';

import { useReturnMethodSubFlow } from './useReturnMethodSubFlow';

export const useGetNearByLocation = () => {
  const returnMethodFlow = useReturnMethodSubFlow();
  const { dispatch, context, matches } = returnMethodFlow || {};

  const handleLoadNearbyLocations = useCallback(
    (latitude: number, longitude: number) => {
      dispatch?.({ type: 'LOAD_NEARBY_LOCATIONS', data: { latitude, longitude } });
    },
    [dispatch],
  );

  return {
    nearbyLocations: context?.nearbyLocations,
    handleLoadNearbyLocations,
    isNearbyLoading: matches?.({ loadNearbyLocations: 'loading' }),
  };
};
