import {
  previewContainerRecipe,
  uploadItemRecipe,
  uploadPreviewImage,
  uploadRemoveIconRecipe,
  uploadingMaskRecipe,
} from './FileUpload.css';
import ErrorIcon from './icon/ErrorIcon';
import X from './icon/X';
import { Uploader } from './useUpload';

import { clsx } from '../../utils/clsx';
import { Button } from '../Button';
import { Icon } from '../Icon';
import { Spinner } from '../Spinner';

export interface UploadPreviewProps extends Uploader {
  src: string;
  onRemove: () => void;
}

const UploadPreview = (props: UploadPreviewProps) => {
  const { onRemove, src, file, status } = props;
  const loading = status === 'uploading';

  return (
    <div className={clsx(uploadItemRecipe(), previewContainerRecipe({ status }))}>
      {status === 'error' ? (
        <Icon source={ErrorIcon} size='24px' color='currentColor' />
      ) : (
        <img src={src} alt={file.name} className={uploadPreviewImage()} />
      )}
      {loading ? (
        <div className={uploadingMaskRecipe()}>
          <Spinner color='currentColor'></Spinner>
        </div>
      ) : null}
      <Button variant='plain' onPress={onRemove} className={uploadRemoveIconRecipe()}>
        <Icon source={X} size='24px' color='currentColor' />
      </Button>
    </div>
  );
};

export default UploadPreview;
