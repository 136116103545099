import { IconSource } from './type';

const CrossFill: IconSource = () => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fill='#EFF0F1' d='M8 0a8 8 0 0 1 8 8 8 8 0 0 1-8 8 8 8 0 0 1-8-8 8 8 0 0 1 8-8' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.648 5.019a.444.444 0 1 0-.628.628L7.372 8 5.02 10.352a.444.444 0 1 0 .628.628L8 8.628l2.352 2.352a.444.444 0 0 0 .628-.628L8.628 8l2.352-2.352a.444.444 0 0 0-.628-.628L8 7.372z'
        fill='currentColor'
      />
    </svg>
  );
};

export default CrossFill;
