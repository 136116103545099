import { IContext } from './types.ts';

import type { IEventSelectItemDone } from '../ItemSelectionSubFlow';

export const createIEventSelectItemDone = (context: IContext): IEventSelectItemDone => {
  const selectedReason = context.reasonGroup.return_reasons.find(
    (reason) => reason.id === context.selectedReasonId,
  );
  const selectedSubReason = selectedReason?.subreasons?.find(
    (subReason) => subReason.key === context.selectedSubReasonId,
  );
  return {
    type: 'SELECT_ITEM_DONE',
    data: {
      selectedItem: {
        itemId: context.activeOrderItem.external_id,
        parentId: context.activeOrderItem.external_parent_id,
        variantId: context.activeOrderItem.external_variant_id,
        productId: context.activeOrderItem.external_product_id,
        productTitle: context.activeOrderItem.product_title,
        productTags: context.activeOrderItem.tags,
        productUrl: context.activeOrderItem.product_url,
        productCoverUrl: context.activeOrderItem.image_urls?.[0],
        variantTitle: context.activeOrderItem.variant_title,
        price: context.activeOrderItem.discounted_base_price_set.presentment_money,
        originPrice: context.activeOrderItem.base_price_set.presentment_money,
        quantity: context.quantity ?? 1,
        selectedReasonId: context.selectedReasonId!,
        selectedReason: selectedReason?.name ?? '',
        selectedSubReasonId: context.selectedSubReasonId,
        selectedSubReason: selectedSubReason?.name,
        comment: context.comment,
        returnImages: context.returnImages,
        frontEndPrice: context.activeOrderItem.frontEndPrice,
      },
    },
  };
};
