import { useMemo } from 'react';

import { useMediaQuery } from '@/hooks/useMediaQuery';

import { useUniversalRouting } from './useUniversalRouting';

import { BREAK_POINTS } from '../components/BreakPointProvider';

export const SpecialPafeBreakPoint = {
  review: {
    min: BREAK_POINTS.sm + 1,
    max: 1080,
    regex: /^\/review.*/,
  },
  returnDetail: {
    min: BREAK_POINTS.sm + 1,
    max: 1080,
    regex: /^\/return-detail.*/,
  },
};

const matchPath = (pathname: string, regex: RegExp) => regex.test(pathname);

const useSpecialPoint = () => {
  const router = useUniversalRouting();
  const specialDesktopForReview = useMediaQuery(
    `only screen and (min-width : ${SpecialPafeBreakPoint.review.min}px) and (max-width : ${SpecialPafeBreakPoint.review.max}px)`,
  );
  const specialDesktopForReturnDetail = useMediaQuery(
    `only screen and (min-width : ${SpecialPafeBreakPoint.returnDetail.min}px) and (max-width : ${SpecialPafeBreakPoint.returnDetail.max}px)`,
  );

  const specialDesktop = useMemo(
    () => ({
      review:
        specialDesktopForReview && matchPath(router.pathname, SpecialPafeBreakPoint.review.regex),
      returnDetail:
        specialDesktopForReturnDetail &&
        matchPath(router.pathname, SpecialPafeBreakPoint.returnDetail.regex),
    }),
    [router.pathname, specialDesktopForReturnDetail, specialDesktopForReview],
  );

  return {
    specialDesktop,
  };
};

export default useSpecialPoint;
