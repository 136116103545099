import React, { useContext } from 'react';

interface SharePropsContextProvider {
  children: React.ReactNode;
  hideSummary: boolean;
}

interface SharePropsContextType {
  hideSummary: boolean;
}

export const SharePropsContext = React.createContext<SharePropsContextType>(
  {} as SharePropsContextType,
);

export const SharePropsProvider = ({ children, hideSummary }: SharePropsContextProvider) => {
  return (
    <SharePropsContext.Provider value={{ hideSummary }}>{children}</SharePropsContext.Provider>
  );
};

export const useSharePropsContext = () => {
  const context = useContext(SharePropsContext);
  return context;
};
