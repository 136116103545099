import { fromPromise } from 'xstate';

import { ShopEligibilityInput } from '../../core/promise/shopEligibilityRules';
import { ShopEligibilityRulesData } from '../../core/types/shopEligibilityRules';
import { defaultShopEligibilityRules } from '../requestData/shopEligibilityRules/defaultShopEligibilityRules';

export const getShopEligibilityRules = fromPromise<
  ShopEligibilityRulesData,
  ShopEligibilityInput['input']
>(async () => {
  return defaultShopEligibilityRules;
});
