import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  setup,
} from 'xstate';

import {
  ResolveIntentionDataChildren,
  ResolveIntentionDataContext,
  ResolveIntentionDataEvent,
  ResolveIntentionDataInput,
  ResolveIntentionDataOutput,
} from './types';

import { MAX_ADD_QUANTITY, Resolution } from '../../constant';
import { getProductDetails } from '../../promise/exchangeInApp';
import { getIntentionData, updateIntentionData } from '../../promise/intention';
import { ErrorResponse } from '../../request/ErrorResponse';
import { IReturnIntentions } from '../../types/intention';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';
import { IExchangeItem } from '../exchangeInAppSubFlow/types';

export const resolveIntentionData = setup({
  types: {
    context: {} as ResolveIntentionDataContext,
    input: {} as ResolveIntentionDataInput,
    output: {} as ResolveIntentionDataOutput,
    events: {} as ResolveIntentionDataEvent,
    children: {} as ResolveIntentionDataChildren,
  },
  actors: { getIntentionData, getProductDetails, updateIntentionData },
}).createMachine({
  context: ({ input }) => input,
  output: ({ context }) => ({
    exchangeItems: context.exchangeItems || [],
    intentionData: context.intentionData,
  }),
  initial: 'getIntentionData',
  states: {
    getIntentionData: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getIntentionData',
            input: ({ context }) => ({ intentionId: context.intentionId, token: context.token }),
            onDone: {
              target: 'success',
              actions: assign({
                intentionData: ({ event }) => event.output,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: [
        {
          guard: ({ context }) => (context.intentionData?.exchange_items || []).length <= 0,
          target: 'done',
        },
        {
          target: 'getProductDetails',
          guard: ({ context }) => (context.intentionData?.exchange_items || []).length > 0,
        },
      ],
    },
    getProductDetails: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getProductDetails',
            input: ({ context }) => ({
              externalIds: (context.intentionData?.exchange_items || [])
                .map((item) => item.external_product_id)
                .join(','),
              token: context.token,
            }),
            onDone: {
              target: 'success',
              actions: assign({
                exchangeItems: ({ event, context }) =>
                  (event.output?.products || [])
                    .map((product) => {
                      const exchangeItems = context.intentionData?.exchange_items
                        ?.map((obj) => {
                          if (obj.external_product_id === product.external_id) {
                            const variant = product?.variants?.find(
                              (productVariant) =>
                                productVariant.external_id === obj.external_variant_id,
                            );
                            return {
                              variant,
                              ...obj,
                              title: product.title,
                            };
                          }
                        })
                        ?.filter(Boolean);

                      if (!exchangeItems) {
                        return [];
                      }
                      return exchangeItems?.map((exchangeItem) => ({
                        productId: exchangeItem?.external_product_id,
                        variantId: exchangeItem?.external_variant_id,
                        quantity: exchangeItem?.quantity,
                        productTitle: exchangeItem?.title,
                        price: exchangeItem?.variant?.price,
                        originPrice: exchangeItem?.variant?.compare_at_price,
                        variantTitle: exchangeItem?.variant?.title,
                        productCoverUrl: exchangeItem?.variant?.image_url,
                        resolution: Resolution.ExchangeForAnything,
                        maxQuantity: exchangeItem?.variant?.allow_backorder
                          ? MAX_ADD_QUANTITY
                          : Number(exchangeItem?.variant?.available_quantity) < 1
                            ? 1
                            : exchangeItem?.variant?.available_quantity,
                      }));
                    })
                    ?.flat()
                    // fixme: tsconfig 有些问题，导致 tsc 没法正确推断 filter 的类型
                    .filter((item) => item !== null) as IExchangeItem[],
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: { target: 'done' },
    },
    updateIntentionData: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'updateIntentionData',
            input: ({ context, event }) => {
              assertEvent(event, 'UPDATE_INTENTION_DATA');
              return {
                token: context.token,
                payload: {
                  ...(context.intentionData as IReturnIntentions),
                  exchange_items: event.data.exchangeItems,
                },
              };
            },
            onDone: {
              target: 'success',
              actions: assign({ intentionData: ({ event }) => event.output }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
      onDone: {},
    },
    done: { type: 'final' },
  },
  on: {
    UPDATE_INTENTION_DATA: { target: '.updateIntentionData' },
  },
});

export type ResolveIntentionDataActorRef = ActorRefFrom<typeof resolveIntentionData>;
export type ResolveIntentionDataSnapshot = SnapshotFrom<typeof resolveIntentionData>;
