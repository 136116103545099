import { Control, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import FormInput from '@/components/Form/FormInput';

export type Props<T extends FieldValues> = {
  control: Control<T>;
};

const FormItemForReplaceOption = <T extends FieldValues>({ control }: Props<T>) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={SpacingVars['2']}>
      <Text variant={'subtitle1'}>{t('page.gift.description.whichOption')}</Text>
      <FormInput
        control={control}
        placeholder={t('page.gift.placeholder.option')}
        name={'option'}
        fullWidth={true}
      />
    </Flex>
  );
};

export default FormItemForReplaceOption;
