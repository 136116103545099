import { useTranslation } from 'react-i18next';

import { Card, CardHeader, CardSection, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { Divider } from '@/components/Divider';

import { commentStyle } from './ExchangeNote.css';

import useCartStyle from '../../hooks/useCartStyle';
import useHideSummaryWidth from '../../hooks/useHideSummaryWidth';

const ExchangeNote = ({ comment }: { comment?: string }) => {
  const { t } = useTranslation();
  const { normalStyle, mobileNoOutlineClassname, isMobile } = useCartStyle();
  const hideSummaryWidth = useHideSummaryWidth();
  return (
    <>
      {isMobile && <Divider spacing={SpacingVars['1']} />}

      <Card
        {...normalStyle}
        width={hideSummaryWidth || normalStyle.width}
        className={mobileNoOutlineClassname}
      >
        <CardHeader>
          <Text variant='title3'>{t('page.description.exchangeNotes')}</Text>
        </CardHeader>

        <CardSection>
          <Text variant='body2' className={commentStyle}>
            {comment}
          </Text>
        </CardSection>
      </Card>
    </>
  );
};

export default ExchangeNote;
