import Image from 'next/image';
import React from 'react';

import { Flex, Icon, Select, Text, TwoArrow, clsx } from '@aftership/design-system';
import {
  selectTriggerRecipe,
  selectTriggerWrapperRecipe,
} from '@aftership/design-system/components/Select/Select.css';
import { SpacingVars } from '@aftership/design-tokens';
import { CountryItem } from 'returns-logics';
import { useCountries } from 'returns-logics/react';

import useDevice from '@/hooks/useDevice';

import { mobileSelectClassName } from '../Form/index.css';

export const formatCountriesToOptions = (countries?: CountryItem[]) => {
  return (
    countries
      ?.filter((item) => Boolean(item.calling_code))
      .map(({ name, calling_code, flag_url, code }) => ({
        // 不用 calling code 作为 value，是因为会重复
        value: code,
        label: code,
        render: () => {
          return (
            <Flex gap={SpacingVars['4']} width={288}>
              <Image src={flag_url} alt='country icon' width={32} height={24} />
              <Flex flex={1}>
                <Text wordBreak='break-word'>{name}</Text>
              </Flex>
              <Text>{calling_code}</Text>
            </Flex>
          );
        },
      })) ?? []
  );
};

type CallingCodeData = {
  countryCode: string;
  callingCode: string;
};

type CallingPhoneCodeProps = {
  value?: CallingCodeData;
  onChange: (value: CallingCodeData) => void;
};

// FIXME: 有类型问题
const CallingPhoneCode = ({ value, onChange }: CallingPhoneCodeProps) => {
  const {
    data: { countries },
  } = useCountries();

  const isMobile = useDevice().mobile;

  const callingCodeOptions = formatCountriesToOptions(Object.values(countries ?? {}));

  return (
    <Flex>
      <Select
        options={callingCodeOptions}
        value={value?.countryCode}
        className={isMobile ? mobileSelectClassName : undefined}
        // @ts-ignore
        onChange={(v: string) => {
          const selectedCountry = countries?.[v];
          onChange({
            callingCode: selectedCountry?.calling_code ?? '',
            countryCode: selectedCountry?.code ?? '',
          });
        }}
        customActivatorContent={(value) => {
          const selectedOptionData = value?.selectedText && countries?.[value?.selectedText];

          return (
            <div className={selectTriggerWrapperRecipe()}>
              <span className={clsx(selectTriggerRecipe({ placeholder: value.isPlaceholder }))}>
                {value.isPlaceholder || !selectedOptionData ? (
                  '-'
                ) : (
                  <Flex width={'max-content'} gap={SpacingVars['1']}>
                    <Image
                      src={selectedOptionData?.flag_url ?? ''}
                      alt='country icon'
                      width={28}
                      height={24}
                    />
                    <Text>{selectedOptionData?.calling_code}</Text>
                  </Flex>
                )}
              </span>
              <Icon source={TwoArrow}></Icon>
            </div>
          );
        }}
      />
    </Flex>
  );
};

export default CallingPhoneCode;
