import { IExchangeItem, Resolution } from '../../../core';
import { defaultReturns } from '../returns/defaultReturns';

export const defaulExchangeItems: IExchangeItem[] = defaultReturns?.[0]?.exchange_items?.map(
  (exchangeItem) => ({
    productId: exchangeItem?.external_product_id,
    variantId: exchangeItem?.external_variant_id,
    quantity: exchangeItem?.quantity,
    productTitle: exchangeItem?.product_title,
    price: exchangeItem?.price_set?.presentment_money,
    originPrice: exchangeItem?.price_set?.presentment_money,
    variantTitle: exchangeItem?.variant_title,
    productCoverUrl: '',
    resolution: Resolution.ExchangeForAnything,
    maxQuantity: 1,
  }),
);
