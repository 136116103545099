'use client';

import { Checkbox as AriaCheckbox } from 'react-aria-components';

import {
  checkboxBoxClassname,
  checkboxIconWrapperRecipe,
  checkboxRecipe,
  checkboxTextClassname,
  checkboxWrapperRecipe,
} from './Checkbox.css';
import TickMark from './TickMark';
import { CheckboxProps } from './type';

import { clsx } from '../../utils/clsx';
import { Icon } from '../Icon';

const IconSize = {
  large: '20px',
  default: '16px',
};

const Checkbox = ({ children, iconSize = 'default', ...props }: CheckboxProps) => {
  return (
    <AriaCheckbox {...props} className={clsx(checkboxWrapperRecipe())}>
      <>
        <div className={checkboxBoxClassname}>
          <div className={checkboxRecipe({ size: iconSize })}>
            <div className={checkboxIconWrapperRecipe()}>
              <Icon source={TickMark} color='currentColor' size={IconSize[iconSize]}></Icon>
            </div>
          </div>
        </div>
        <span className={checkboxTextClassname}>{children}</span>
      </>
    </AriaCheckbox>
  );
};

export default Checkbox;
