export type AttaProps =
  | {
      preview: true;
      schema: Record<PathName, string>;
    }
  | {
      preview: false;
      code: Record<PathName, string>;
      deps: Record<PathName, string>;
    };

export enum PathName {
  OrderLookup = '/',
  RequestReturns = '/request-returns',
  ReturnResolution = '/return-resolution',
  ReturnMethod = '/return-method',
  Review = '/review',
  ReturnsList = '/returns-list',
  GiftReturn = '/gift-return',
  ReturnPolicy = '/return-policy',
}

export enum ComponentName {
  ReturnsPageLayout = 'ReturnsPageLayout',
  ReturnsPageContainer = 'ReturnsPageContainer',
  ReturnsPageHeader = 'ReturnsPageHeader',
  ReturnsPageFooter = 'ReturnsPageFooter',
  OrderLookup = 'OrderLookup',
  ItemSelection = 'ItemSelection',
  Resolution = 'Resolution',
  ReturnMethod = 'ReturnMethod',
  Review = 'Review',
  ReturnsList = 'ReturnsList',
  GiftReturn = 'GiftReturn',
  ReturnPolicy = 'ReturnPolicy',
}
