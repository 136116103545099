import { assertEvent, assign } from 'xstate';

import mockSelectSingleItemSubFlow from './selectSingleItemSubFlow';

import { itemSelectionSubFlow } from '../../core/machine/ItemSelectionSubFlow';
import {
  getItemSelectionGrouping,
  getOrder,
  getQuestions,
  getReasons,
  getReturns,
  getShopEligibilityRules,
} from '../promise';

const mockItemSelectionSubFlow = itemSelectionSubFlow.provide({
  actors: {
    selectSingleItemSubFlow: mockSelectSingleItemSubFlow,
    getOrder: getOrder,
    getReturns: getReturns,
    getShopEligibilityRules,
    getReasons: getReasons,
    getItemSelectionGrouping,
    getQuestions,
  },
  actions: {
    updateNonReturnReason: assign(({ context, event }) => {
      assertEvent(event, 'PREVIEW_UPDATE_NON_RETURN_REASON');
      return {
        nonReturnableItems: context.nonReturnableItems?.map((item) => ({
          ...item,
          reasons: [
            {
              reason: event.data.reason,
            },
          ],
        })),
      };
    }),
  },
});

export default mockItemSelectionSubFlow;
