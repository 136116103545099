import { ErrorActorEvent, assign, setup } from 'xstate';

import { addAdditionalLabel } from '../../promise/additionalLabel';
import { pollingShipment } from '../../promise/shipment';
import { ErrorResponse } from '../../request/ErrorResponse';
import { RequestErrorType, ShipmentsInfo } from '../../types';
import { RequestError } from '../../utils/errorHandler';

type Events =
  | {
      type: 'updateAdditionalLabelQuantiy';
      data: {
        returnId: string;
        quantity: number;
      };
    }
  | {
      type: 'pollingShipmentsStatus';
      data: {
        returnId: string;
      };
    };

type Input = {
  token: string;
};

type Context = {
  token: string;
  returnId: string;
  quantity: number;
  shipments?: ShipmentsInfo[];
  error?: RequestErrorType;
};

type Children = {
  pollingShipments: 'pollingShipments';
};

type Output = ShipmentsInfo[];

const generateAdditionalLabel = setup({
  types: {
    input: {} as Input,
    context: {} as Context,
    events: {} as Events,
    children: {} as Children,
    output: {} as Output,
  },
  actors: {
    addAdditionalLabel: addAdditionalLabel,
    pollingShipments: pollingShipment,
  },
}).createMachine({
  context: ({ input }) => {
    return {
      token: input.token,
      returnId: '',
      quantity: 0,
    };
  },
  initial: 'extraLabelQuantityInput',
  output: ({ context }) => {
    return context.shipments ?? [];
  },
  states: {
    extraLabelQuantityInput: {},
    submitLabelQuantity: {
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            id: 'addAdditionalLabel',
            src: 'addAdditionalLabel',
            input: ({ context }) => {
              return {
                token: context.token,
                payload: {
                  returnId: context.returnId,
                  quantity: context.quantity,
                },
              };
            },
            onDone: {
              target: 'success',
            },
            onError: {
              target: 'error',
            },
          },
        },
        error: {
          entry: assign({
            error: ({ event, self }) =>
              new RequestError(
                self.getSnapshot().value,
                (event as unknown as ErrorActorEvent<ErrorResponse>).error.code,
                (event as unknown as ErrorActorEvent<ErrorResponse>).error.message,
              ),
          }),
        },
        success: {
          type: 'final',
          // always: {
          //   target: '#pollingShipmentsStatus',
          // },
        },
      },
      onDone: {
        target: 'pollingShipmentsStatus',
      },
    },
    pollingShipmentsStatus: {
      id: 'pollingShipmentsStatus',

      invoke: {
        id: 'pollingShipments',
        src: 'pollingShipments',
        input: ({ context }) => {
          return {
            requestPayload: {
              returnId: context.returnId,
              token: context.token,
            },
          };
        },
        onDone: {
          target: 'done',
          actions: assign({
            shipments: ({ event }) => event.output,
          }),
        },
        onError: {},
      },
    },
    done: {
      type: 'final',
    },
  },
  on: {
    updateAdditionalLabelQuantiy: {
      target: '.submitLabelQuantity',
      actions: assign(({ event, context }) => {
        return {
          ...context,
          returnId: event.data.returnId,
          quantity: event.data.quantity,
        };
      }),
    },
    pollingShipmentsStatus: {
      target: '.pollingShipmentsStatus',
      actions: assign(({ event, context }) => {
        return {
          ...context,
          returnId: event.data.returnId,
        };
      }),
    },
  },
});

export default generateAdditionalLabel;
