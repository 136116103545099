'use client';

import React from 'react';
import xss from 'xss';

export interface IRichTextRenderProps {
  componentType?: string;
  content: string;
  className?: string;
  style?: React.CSSProperties;
  id?: string;
}

const RichTextRender = ({
  content,
  className,
  style,
  componentType = 'div',
  id,
}: IRichTextRenderProps) => {
  return React.createElement(componentType, {
    id,
    className,
    style,
    dangerouslySetInnerHTML: {
      __html: xss(content),
    },
  });
};

export default RichTextRender;
