'use client';

import React from 'react';

import { Box, Flex, Text } from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';

import { EllipsisText } from '@/components/EllipsisText';
import { ImageWithFallback } from '@/components/ImageWithFallback';
import { toCurrency } from '@/utils/price';

import { Product } from '../types';

const defaultTitle = 'Default Title';

const ProductItem = (props: { product: Product }) => {
  const { product } = props;
  const name = product.name.trim();
  const variants = product.variants.filter((value) => value !== defaultTitle).join('·');

  return (
    <Flex width={'100%'}>
      <Box borderRadius={RadiusVars['1']} overflow='hidden'>
        <ImageWithFallback
          usingShopifyPreview
          alt='product image'
          width={80}
          height={80}
          src={product.image_url}
        />
      </Box>
      <Flex direction='column' flex={1} marginStart={16}>
        <EllipsisText text={name} maxLine={1} variant='subtitle1' />
        <EllipsisText
          text={variants}
          maxLine={1}
          variant='body2'
          color={ColorVars['Grey']['Grey 1100']}
        />
        <Text variant='body2' color={ColorVars['Grey']['Grey1200']} paddingTop={SpacingVars['1']}>
          {toCurrency(product.price, product.currency)} x {product.number}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProductItem;
