// @ts-nocheck
import {
  Resolution,
  ReturnCostOption,
  ReturnItemType,
  ReturnMethodSlug,
  ReturnStatus,
  ReturnType,
  SummaryTotalType,
} from '../../../core';
import { mockCurrency } from '../global/global.ts';

export const defaultFallbackImage = '';
export const MOCK_SHIPPING_ADDRESS = '{Synced from the original order}';

/* eslint-disable max-lines */
export const defaultReturns: ReturnItemType[] = [
  {
    id: '7965c85168724d59bfc65f38a0e70b15',
    rma_id: 'KNXTK1A3',
    created_at: '2024-07-16T07:13:20Z',
    updated_at: '2024-07-16T07:13:20Z',
    status: ReturnStatus.SUBMITTED,
    approval_status: 'submitted',
    reject_reason: null,
    shop_id: 'cbb5fc63-6cec-4738-9840-f43bbc1c154a',
    organization_id: 'f64e2c3030584d84ba88fea2b1151c3b',
    customer_email: 'lj.chen@aftership.com',
    customer_name: 'Dubai rikki',
    order_number: '#1141',
    order_name: '#1141',
    source: 'connectors_platform-shopify',
    source_order_id: 'a2ba0c87416f4fdc9efbba9e38369f59',
    source_order_url: 'https://test0722-10000.myshopify.com/admin/orders/5677381714174',
    country_order: 'ARE',
    state: 'Dubai',
    store: {
      key: 'test0722-10000',
      platform: 'shopify',
      name: '',
    },
    order_taxes_included: false,
    return_method_slug: ReturnMethodSlug.RetailerLabel,
    return_method_id: '5b07eeb0-c658-4e92-9dee-619976542035',
    return_method_name: 'Ship with a return label',
    zone_id: '99d80590-f333-4acc-9e9b-945a3ce61dc5',
    zone_name: 'Dubai',
    rule_id: 'b472cd96-c795-485e-ba9e-a9c84f9b45f4',
    rule_name: 'all',
    resolution: Resolution.ReplaceTheSameItem,
    return_cost_option: ReturnCostOption.CUSTOM,
    is_gift_return: false,
    recipient_name: null,
    recipient_email: null,
    recipient_first_name: '',
    recipient_last_name: '',
    has_exchange_variant: true,
    exchange_items: [
      {
        return_item_id: '13988733812990',
        external_product_id: '7781956452606',
        variant_id: '43159344578814',
        external_variant_id: '43159344578814',
        sku: '999008',
        title: '',
        product_title: 'Product title',
        variant_title: '',
        quantity: 1,
        price_set: {
          shop_money: {
            amount: '200.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '200.00',
            currency: mockCurrency,
          },
        },
        weight: {
          unit: 'lb',
          value: 1,
        },
        image_url: defaultFallbackImage,
      },
    ],
    exchange_order_shipping_address: {
      first_name: 'Dubai Rikki',
      last_name: 'Chen',
      email: 'lj.chen@aftership.com',
      address_line_1: 'Dubai',
      address_line_2: '1',
      address_line_3: '',
      city: 'Dubai',
      state: 'Dubai',
      country: 'ARE',
      phone: {
        country_code: '971',
        number: '123',
      },
    },
    exchange_order_failed_detail: null,
    is_instant_exchange: false,
    instant_exchange_charge_status: null,
    instant_exchange_hold_payment: null,
    instant_exchange_charge_payment: null,
    instant_exchange_hold_payment_detail_url: null,
    instant_exchange_charge_payment_detail_url: null,
    filed_by: null,
    shipping_status: null,
    dropoff_status: null,
    retailer_label_source: null,
    refunded: false,
    refund_destination: 'exchange',
    shipping_documents_components: [],
    matched_conditional_shipping_documents: null,
    return_preview_summary: {
      credit_total_set: {
        shop_money: {
          amount: '210.00',
          currency: mockCurrency,
        },
        presentment_money: {
          amount: '210.00',
          currency: mockCurrency,
        },
      },
      refund_credit_set: {
        shop_money: {
          amount: '210.00',
          currency: mockCurrency,
        },
        presentment_money: {
          amount: '210.00',
          currency: mockCurrency,
        },
      },
      return_item_subtotal_set: {
        shop_money: {
          amount: '210.00',
          currency: mockCurrency,
        },
        presentment_money: {
          amount: '200.00',
          currency: mockCurrency,
        },
      },
      return_item_tax_total_set: {
        shop_money: {
          amount: '10.00',
          currency: mockCurrency,
        },
        presentment_money: {
          amount: '10.00',
          currency: mockCurrency,
        },
      },
      return_item_taxes_included: false,
      extra_credit_value_option: null,
      extra_credit_value: null,
      extra_credit_set: {
        shop_money: null,
        presentment_money: null,
      },
      extra_credit_used_set: {
        shop_money: null,
        presentment_money: null,
      },
      pre_discount_credit_set: {
        shop_money: null,
        presentment_money: null,
      },
      pre_discount_credit_used_set: {
        shop_money: null,
        presentment_money: null,
      },
      credit_remain_set: {
        shop_money: null,
        presentment_money: null,
      },
      exchange_total_set: {
        shop_money: null,
        presentment_money: {
          amount: '200.00',
          currency: mockCurrency,
        },
      },
      exchange_item_subtotal_excl_tax_set: {
        shop_money: null,
        presentment_money: null,
      },
      exchange_item_subtotal_set: {
        shop_money: null,
        presentment_money: {
          amount: '200.00',
          currency: mockCurrency,
        },
      },
      exchange_item_tax_total_set: {
        shop_money: null,
        presentment_money: null,
      },
      exchange_item_taxes_included: false,
      return_cost_option: ReturnCostOption.CUSTOM,
      cost_of_return_set: {
        shop_money: null,
        presentment_money: null,
      },
      exchange_shipping_fee_option: '',
      exchange_shipping_fee_set: {
        shop_money: null,
        presentment_money: null,
      },
      checkout_total_rounding_set: {
        shop_money: null,
        presentment_money: null,
      },
      financial_outcome: SummaryTotalType.Unchanged,
      checkout_total_set: {
        shop_money: null,
        presentment_money: null,
      },
      refund_total_set: {
        shop_money: null,
        presentment_money: null,
      },
      checkout_custom_shipping_total_set: {
        shop_money: null,
        presentment_money: null,
      },
      checkout_discount_total_set: {
        shop_money: null,
        presentment_money: null,
      },
    },
    automation_context: {
      auto_label_generation: {
        enabled: false,
      },
      auto_happy_returns_dropoff_generation: {
        enabled: false,
      },
      auto_retail_reworks_dropoff_generation: {
        enabled: false,
      },
    },
    items: [
      {
        item_id: '13988733812990',
        return_quantity: 1,
        reason: 'Doesn’t suit me',
        subreason: '',
        notes_to_merchant: '',
        return_images: [],
        price_set: {
          shop_money: {
            amount: '210.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '210.00',
            currency: mockCurrency,
          },
        },
        unit_price_set: {
          shop_money: {
            amount: '200.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '200.00',
            currency: mockCurrency,
          },
        },

        discount_set: {
          shop_money: null,
          presentment_money: null,
        },
        tax_set: {
          shop_money: {
            amount: '20.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '20.00',
            currency: mockCurrency,
          },
        },
        discounted_price_incl_tax_set: {
          shop_money: {
            amount: '210.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '210.00',
            currency: mockCurrency,
          },
        },
        discounted_base_price_set: {
          presentment_money: {
            amount: '200.00',
            currency: mockCurrency,
          },
        },
        image: {
          height: null,
          width: null,
          src: defaultFallbackImage,
        },
        title: 'iphone11手机壳',
        product_id: '7781956452606',
        variant_id: '43159344578814',
        sku: '999008',
        product_title: 'iphone11手机壳',
        variant_title: '',
        total_quantity: 2,
        properties: [],
        returnable_quantity: 2,
        categories: ['手机壳'],
        tags: ['手机壳tag'],
        replacement: {
          variant_id: '43159344578814',
          product_id: '7781956452606',
          product_url: 'https://test0722-10000.myshopify.com/products/iphone11手机壳',
          title: '',
          price_set: {
            shop_money: {
              amount: '200.00',
              currency: mockCurrency,
            },
            presentment_money: {
              amount: '200.00',
              currency: mockCurrency,
            },
          },
          sku: '999008',
          product_title: 'iphone11手机壳',
          variant_title: '',
          image_url: defaultFallbackImage,
        },
        parent_item: null,
        bundled_items: [],
        type: ReturnType.NORMAL,
        bundle_return_type: null,
        allowed_by_rules: null,
        return_window_context: null,
      },
    ],
    refunds: [],
    exchange_order: null,
  },
];
