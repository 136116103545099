import type { MatchingOrderFormType } from 'returns-logics';

import { useMemoGiftReturn } from './useMemoGiftReturn';

export const useSubmitGiftReturnFormMatch = () => {
  const giftReturnSubFlow = useMemoGiftReturn();

  const submit = (data: MatchingOrderFormType) => {
    giftReturnSubFlow?.dispatch?.({
      type: 'submitFormWithOrderInfo',
      data,
    });
  };

  const isLoading = giftReturnSubFlow?.matches({
    formWithOrderInfo: { submitFormWithOrderInfo: 'loading' },
  });

  return {
    submit,
    isLoading,
  };
};
