import { IconSource } from './type';

const Position: IconSource = () => (
  <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.125 6.688c0 1.86-1.056 3.644-2.429 5.157-1.357 1.496-2.916 2.608-3.684 3.113l-.009.005h-.006l-.01-.005c-.767-.505-2.326-1.617-3.683-3.113C2.93 10.332 1.875 8.55 1.875 6.688 1.875 3.515 4.579.875 8 .875s6.125 2.64 6.125 5.813'
      stroke='currentColor'
      strokeWidth={1.75}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle
      cx={8}
      cy={7}
      r={2}
      stroke='currentColor'
      strokeWidth={1.75}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default Position;
