import { Box, Flex, Text } from '@aftership/design-system';
import { RadiusVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import { ImageWithFallback } from '../ImageWithFallback';

const IMAGE_SIZE_DESKTOP = 60;
const IMAGE_SIZE_MOBILE = 64;

interface ImageListProps {
  list?: string[];
  max?: number;
}

const ImageList = ({ list = [], max = 8 }: ImageListProps) => {
  const isMobile = useDevice().mobile;
  const imageSize = isMobile ? IMAGE_SIZE_MOBILE : IMAGE_SIZE_DESKTOP;
  return (
    <Flex gap={SpacingVars[2]}>
      {list.slice(0, max).map((item) => (
        <Box key={item} overflow='hidden' borderRadius={RadiusVars['1']}>
          <ImageWithFallback
            usingShopifyPreview
            width={imageSize}
            height={imageSize}
            src={item}
            alt={item}
          />
        </Box>
      ))}
      {list.length > max && (
        <Box backgroundColor='#F6F6F7' borderRadius={RadiusVars['1']}>
          <Flex width={imageSize} height={imageSize} alignItems='center' justifyContent='center'>
            <Text variant='subtitle1'>+{list.length - max}</Text>
          </Flex>
        </Box>
      )}
    </Flex>
  );
};

export default ImageList;
