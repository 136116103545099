'use client';

import { PropsWithChildren } from 'react';

import { drawerBodyClassname, drawerHeaderRecipe } from './Drawer.css';
import { DrawerProps } from './type';

import { CloseButton, HeaderSlot } from '../../primitive';
import { popWrapperRecipe } from '../../style/animation.css';
import { popWrapperThemeClass } from '../../style/animationToken.css';
import { clsx } from '../../utils/clsx';
import { formatPopWrapperDimensions, useStyleProps } from '../../utils/styleProps';
import { Overlay } from '../Overlay';

const Drawer = (props: PropsWithChildren<DrawerProps>) => {
  const { children, className, placement = 'right', title, header, onClose, ...rest } = props;
  const { styleProps } = useStyleProps(rest);
  const style = formatPopWrapperDimensions(placement, styleProps);
  const defaultHeader = (
    <HeaderSlot
      className={drawerHeaderRecipe()}
      title={title}
      divider
      onClose={onClose}
    ></HeaderSlot>
  );

  return (
    <Overlay
      maskClassName={clsx(popWrapperThemeClass)}
      className={clsx(popWrapperRecipe({ placement }), className)}
      bodyClassName={drawerBodyClassname}
      isDismissable
      {...rest}
      style={style}
    >
      {header || defaultHeader}
      {children}
    </Overlay>
  );
};

Drawer.Header = HeaderSlot;
Drawer.CloseButton = CloseButton;

export default Drawer;
