import { ErrorActorEvent, assertEvent, emit } from 'xstate';

import { ErrorResponse } from '../../core';
import { orderLookupSubFlow } from '../../core/machine/orderLookupSubFlow/orderLookupSubFlow';
import { createRequestEmittedError } from '../../core/utils/eventUtils';

const mockOrderLookupSubFlow = orderLookupSubFlow.provide({
  actions: {
    showOrderErrorMessageInPreview: emit(({ event, self }) => {
      assertEvent(event, 'SHOW_ORDER_ERROR_MESSAGE');

      const mockError: ErrorActorEvent<ErrorResponse> = {
        type: 'error' as any,
        error: { code: 0, message: '', errors: [] },
      };
      return createRequestEmittedError(self, mockError);
    }),
  },
});

export default mockOrderLookupSubFlow;
