// Function to check and convert Resolution to RefundDestination
import { RefundDestination, Resolution } from '../constant';

export function convertToRefundDestination(resolution?: Resolution): RefundDestination | null {
  if (Object.values(RefundDestination).includes(resolution as unknown as RefundDestination)) {
    return resolution as unknown as RefundDestination;
  }
  return null;
}
export function isRefundDestination(resolution?: Resolution): boolean {
  return resolution
    ? Object.values(RefundDestination).includes(resolution as unknown as RefundDestination)
    : false;
}
