import { useEffect } from 'react';

import { ColorVars } from '@aftership/design-tokens';

import { toastContentRecipe, toastRecipe } from './Toast.css';
import { ToastProps } from './type';

import { TOAST_DEFAULT_DURATION } from '../../constant';
import { clsx } from '../../utils/clsx';
import { Button } from '../Button';
import { Icon, IconAttention, IconCross } from '../Icon';

const Toast = ({ toast, state }: ToastProps) => {
  const { content, placement, type, key, timer, duration } = toast;

  useEffect(() => {
    if (!timer) return;
    timer.reset(duration ?? TOAST_DEFAULT_DURATION);
    return () => {
      timer.pause();
    };
  }, [timer, duration]);

  return (
    <div className={clsx(toastRecipe({ type, placement }))}>
      <Icon source={IconAttention} color={ColorVars['B&W'].Background} size='24px' />
      <span className={toastContentRecipe()}>{content}</span>
      <Button variant='plain' onPress={() => state.close(key)}>
        <Icon source={IconCross} size='24px' color={ColorVars.Transparency['Transparency 1000']} />
      </Button>
    </div>
  );
};

export default Toast;
