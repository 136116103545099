import { fromPromise } from 'xstate';

import { getClickWrapConfig } from './getShopConfig';

import { IClickwrap } from '../types';

export type GetClickwrapConfigInput = {
  orgId: string;
};

export const getClickwrap = fromPromise<IClickwrap, GetClickwrapConfigInput>(
  async ({ input }: { input: GetClickwrapConfigInput }) => {
    return (await getClickWrapConfig(input.orgId)) as IClickwrap;
  },
);
