import Image from 'next/image';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import { messageText, redirectButton, titleText } from './ErrorPage.css';

export interface ErrorPageProps {
  title: string;
  message?: string;
  redirectUrl?: string;
  redirectUrlText?: string;
}

export default function ErrorPage({
  title,
  message,
  redirectUrl,
  redirectUrlText,
}: ErrorPageProps) {
  const { t } = useTranslation();
  // const timerId = useRef<NodeJS.Timeout | null>(null);
  const httpsRedirectUrl = useMemo(() => {
    if (redirectUrl) {
      return redirectUrl.includes('http') ? redirectUrl : `https://${redirectUrl}`;
    }
    return '';
  }, [redirectUrl]);

  // useEffect(() => {
  //   if (redirectUrl && process.env.NODE_ENV === 'production') {
  //     timerId.current = setTimeout(() => {
  //       location.href = httpsRedirectUrl;
  //     }, 5000);
  //   }
  //   return () => {
  //     timerId.current && clearTimeout(timerId.current);
  //     timerId.current = null;
  //   };
  // }, [httpsRedirectUrl, redirectUrl]);

  // 不要应用主题
  return (
    <Box backgroundColor='#F5F6F7'>
      <Flex direction='column' height='100dvh'>
        <Box backgroundColor={ColorVars['B&W'].Background}>
          <Flex alignItems='center' justifyContent='center' height={80}>
            <Image
              width={260}
              height={32}
              src={require('@/assets/returns-center-logo.svg').default?.src}
              alt='Returns center logo'
            />
          </Flex>
        </Box>

        <Flex flex={1} gap={16} direction='column' alignItems='center' justifyContent='center'>
          <Image
            width={360}
            height={229}
            src={require('@/assets/404.svg').default?.src}
            alt={title}
          />
          <p className={titleText}>{title}</p>

          {redirectUrl ? (
            <Flex justifyContent='center'>
              <button className={redirectButton} onClick={() => (location.href = httpsRedirectUrl)}>
                {redirectUrlText || t('page.error.backToHomePage')}
              </button>
            </Flex>
          ) : (
            <p className={messageText}>{message}</p>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
