import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Flex } from '@aftership/design-system';

import getTrackerInstance from '@/utils/tracker';
import { EventName } from '@/utils/tracker/consts';

import { continueShoppingFontSize } from './ContinueShopping.css';

import { useReturnPreviewFlow } from '../../hooks/useReturnPreviewFlow';

const ContinueShopping = memo(
  ({
    type = 'text',
    buttonVariant = 'secondary',
  }: {
    type?: 'button' | 'text';
    buttonVariant?: 'secondary' | 'primary';
  }) => {
    const { t } = useTranslation();
    const { isLoading, onStoreUrl, mainDispatch, currentStep } = useReturnPreviewFlow();
    const isButtonLoading = currentStep === 'exchangeOnStore';

    return (
      onStoreUrl && (
        <Flex direction='row' justifyContent='center'>
          {type === 'button' ? (
            <Button
              size='large'
              variant={buttonVariant}
              isDisabled={isLoading || isButtonLoading}
              className={continueShoppingFontSize}
              onPress={() => {
                window.location.href = onStoreUrl;
                mainDispatch?.({
                  type: 'GO_TO_EXCHANGE_ON_STORE',
                });
                getTrackerInstance().reportClickEvent({ eventName: EventName.continueShopping });
              }}
            >
              {t('page.action.continueShopping')}
            </Button>
          ) : (
            <Button
              variant='link'
              isDisabled={isLoading || isButtonLoading}
              className={continueShoppingFontSize}
              onPress={() => {
                window.location.href = onStoreUrl;
                mainDispatch?.({
                  type: 'GO_TO_EXCHANGE_ON_STORE',
                });
                getTrackerInstance().reportClickEvent({ eventName: EventName.continueShopping });
              }}
            >
              {t('page.action.continueShopping')}
            </Button>
          )}
        </Flex>
      )
    );
  },
);

ContinueShopping.displayName = 'ContinueShopping';

export default ContinueShopping;
