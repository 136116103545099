import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@aftership/design-system';
import { IDraftReturn, StripePaymentStatus } from 'returns-logics';

import ExpiredModal from '@/features/review/components/ExpiredModal';
import {
  StripeContext,
  useCreateStripeContextValue,
} from '@/features/review/components/PaymentModal/components/StripeModal/StripeProvider';

import StripePayNowModal from './StripePayNowModal';

export interface PayNowProps {
  draftInfo: IDraftReturn;
  onExpiredConfirmCallback?: VoidFunction;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const PayNow = ({ isDisabled, isLoading, draftInfo, onExpiredConfirmCallback }: PayNowProps) => {
  const { t } = useTranslation();

  const [payModalVisible, setPayModalVisible] = useState(false);
  const [expiredModalVisible, setExpiredModalVisible] = useState(false);
  const isStripePay = !draftInfo.checkout_url;
  const isShopifyPay = !!draftInfo.checkout_url;
  const stripeContextValue = useCreateStripeContextValue();

  const handleClosePayModal = useCallback(() => {
    setPayModalVisible(false);
  }, []);

  if (!isShopifyPay && !isStripePay) {
    return null;
  }

  const handlePayNow = async () => {
    if (isShopifyPay) {
      window.open(draftInfo.checkout_url, '_blank');
    } else if (isStripePay) {
      setPayModalVisible(true);
    }
  };

  return (
    <>
      <ExpiredModal
        isOpen={expiredModalVisible}
        onClose={() => {
          setExpiredModalVisible(false);
          onExpiredConfirmCallback?.();
        }}
      />
      {isShopifyPay && (
        <Button onPress={handlePayNow} isDisabled={isDisabled} isLoading={isLoading}>
          {t('page.request.payNow')}
        </Button>
      )}

      {isStripePay && (
        <>
          <Button onPress={handlePayNow} isDisabled={isDisabled} isLoading={isLoading}>
            {draftInfo.payment_status === StripePaymentStatus.FAILED
              ? t('popup.request.tryAgain')
              : t('page.request.payNow')}
          </Button>
          <StripeContext.Provider value={stripeContextValue}>
            <StripePayNowModal
              isOpen={payModalVisible}
              onClose={handleClosePayModal}
              draftReturnItem={draftInfo}
            />
          </StripeContext.Provider>
        </>
      )}
    </>
  );
};

export default PayNow;
