import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

export const GiftReturnTitle = () => {
  const { t } = useTranslation();
  return (
    <Flex direction='column' justifyContent='center'>
      <Text
        elementType='p'
        variant='title2'
        textAlign='center'
        color={ColorVars['Grey']['Grey1200']}
      >
        {t('page.gift.description.title')}
      </Text>
    </Flex>
  );
};
