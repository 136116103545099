import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';

import FormRadio, { Option } from '@/components/Form/FormRadio';

interface Props {
  resolutions: Option[];
  control: Control<any, any>;
}
export const HowResolve = ({ resolutions, control }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={8}>
      <Text variant={'subtitle1'}>{t('page.gift.description.howResolve')}</Text>
      <FormRadio control={control} name='resolution' radioItems={resolutions} />
    </Flex>
  );
};
