// ======== block rules ====== //
export enum BlockRulesCondition {
  CustomerEmail = 'customer_email',
  ProductTag = 'product_tag',
  ProductType = 'product_type',
}

// ======== allow rules ====== //
export enum AllowRulesCondition {
  OrderName = 'order_name',
  CustomerEmail = 'customer_email',
  ProductTag = 'product_tag',
  ProductType = 'product_type',
}

// ======== common ====== //
export type RulesCondition = BlockRulesCondition | AllowRulesCondition;

export interface IRuleItem {
  condition: RulesCondition;
  value: string;
  id: string;
  [x: string]: any;
}
