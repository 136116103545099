import { assertEvent, assign } from 'xstate';

import { returnListSubFlow } from '../../core/machine/returnListSubFlow';
import { getDraftReturnsActor, getReturns } from '../promise';
import { defaultOrder } from '../requestData/order/defaultOrder';
import { defaultReturns } from '../requestData/returns/defaultReturns';

export const mockReturnListSubFlow = returnListSubFlow.provide({
  actions: {
    updateContextInPreview: assign({
      returns: ({ event }) => {
        assertEvent(event, 'UPDATE_RETURN_LIST_IN_PREVIEW');
        return defaultReturns.map((item) => ({
          ...item,
          ...event.data,
        }));
      },
    }),
    updateOrdersInPreviewMode: assign({
      orderName: defaultOrder.order.order_name || '',
      orderNumber: defaultOrder.order.order_number || '',
      createTime: defaultOrder.order.created_at || '',
      orderId: defaultOrder.order.id || '',
    }),
  },
  actors: {
    getReturns,
    getDraftReturnsActor,
  },
});

export default mockReturnListSubFlow;
