import { IconSource } from './type.ts';

const CollapseArrow: IconSource = () => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='m8.25 5.42 4.33 4.5H3.92z' fill='#232933' />
    </svg>
  );
};

export default CollapseArrow;
