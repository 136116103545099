/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  emit,
  enqueueActions,
  not,
  sendParent,
  setup,
} from 'xstate';

import { EVENT, IContext, IEmitted, IInput } from './types.ts';
import { createIEventSelectItemDone } from './utils.ts';

import { ConditionType, blockItemByQuestion } from '../../promise';
import { ErrorResponse } from '../../request/ErrorResponse.ts';
import { BundleEligibilityType } from '../../types/shopEligibilityRules';
import { createRequestEmittedError, isJWTError, takeMainActorRef } from '../../utils/eventUtils.ts';
import type { IEventReenter, IEventSelectItemDone } from '../ItemSelectionSubFlow';

export const selectSingleItemSubFlow = setup({
  types: {
    input: {} as IInput,
    events: {} as EVENT,
    context: {} as IContext,
    emitted: {} as IEmitted,
  },
  guards: {
    isSkipQuantity: ({ context }) => {
      return context.activeOrderItem.returnableQuantity === 1;
    },
    /**
     * 没有没有 ask a question 的情况,则跳过 ask a question 步骤
     * @param context
     */
    isSkipAskQuestion: ({ context }) => {
      // 如果是 bundle 的 line item,并且是 sameAsParent 模式,不弹 ask a question
      // 如果是编辑模式也不需要弹
      return (
        (Boolean(context.activeOrderItem.external_parent_id) &&
          context.shopEligibilityRules.bundle_line_item_eligibility ===
            BundleEligibilityType.sameAsParent) ||
        !context.questionWorkFlowItem ||
        !!context.quantity
      );
    },
    /**
     * 如果 ask a question ,confirm 为 false,则允许进入到选择数量的步骤
     */
    isAnswerCorrect: ({ event }) => {
      assertEvent(event, 'SELECT_QUESTION_ANSWER');
      return !event.data.confirm;
    },
    hasSubReason: ({ event, context }) => {
      assertEvent(event, 'SELECT_REASON');
      const selectedReasonId = event.data.selectedReasonId;
      const selectedReason = context.reasonGroup?.return_reasons?.find(
        (reason) => reason.id === selectedReasonId,
      );
      return !!selectedReason?.subreasons?.length;
    },
    hasFillMoreDetail: ({ context, event }) => {
      let selectedReasonId: string | undefined;
      if (event.type === 'SELECT_REASON') {
        // warning: 从 event 里面取 selectedReasonId, guard 在 action 之前, context 中还没数据
        selectedReasonId = event.data.selectedReasonId;
      } else if (event.type === 'SELECT_SUB_REASON') {
        // warning: 从 context 里面取 selectedReasonId, 在 sub reason 已经确定了 reasonId,并且 event 没有这个数据
        selectedReasonId = context.selectedReasonId;
      }
      const selectedReason = context.reasonGroup?.return_reasons?.find(
        (reason) => reason.id === selectedReasonId,
      );
      return !!selectedReason?.comments_enabled || !!selectedReason?.images_enabled;
    },
    canFinishSelectSingleItem: ({ context, event }) => {
      assertEvent(event, 'FILL_MORE_DETAIL');
      // 判断 fill more detail 内容是否合法
      const selectedReasonId = context.selectedReasonId;
      const selectedReason = context.reasonGroup?.return_reasons?.find(
        (reason) => reason.id === selectedReasonId,
      );
      const commentsValid =
        !selectedReason?.comments_enabled ||
        !selectedReason?.comments_required ||
        Boolean(event.data.comment?.length);
      const imagesValid =
        !selectedReason?.images_enabled ||
        !selectedReason?.images_required ||
        Boolean(event.data.returnImages);
      return commentsValid && imagesValid;
    },
  },
  actions: {
    updateReason: assign(({ event, context }) => {
      assertEvent(event, 'SELECT_REASON');
      if (event.data.selectedReasonId !== context.selectedReasonId) {
        return {
          selectedReasonId: event.data.selectedReasonId,
          selectedSubReasonId: undefined,
          comment: undefined,
          returnImages: undefined,
        };
      }
      return {
        selectedReasonId: event.data.selectedReasonId,
      };
    }),
    updateSubReason: assign(({ event, context }) => {
      assertEvent(event, 'SELECT_SUB_REASON');
      if (event.data.selectedSubReasonId !== context.selectedSubReasonId) {
        return {
          ...context,
          selectedSubReasonId: event.data.selectedSubReasonId,
          comment: undefined,
          returnImages: undefined,
        };
      }
      return {
        selectedSubReasonId: event.data.selectedSubReasonId,
      };
    }),
    updateMoreDetail: assign(({ event }) => {
      assertEvent(event, 'FILL_MORE_DETAIL');
      return {
        comment: event.data.comment,
        returnImages: event.data.returnImages,
      };
    }),
  },
  actors: { blockItemByQuestion },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgGEBBAOTIFEAZAbQAYBdRUABwHtZcAXXN3wcQAD0QBWAJwB2AHQBGABwBmAGzNZAJmarJ21aoA0IAJ6JF2gL7XTaLHkJF5sMABswmfgEUAruj4gvxmJADKDLRkACoA+j4AqtTRAJLRAJos7EggPHyCwqISCNrq2vLakszMisxyspKq2qYWCOqqyvKyzJKyiooALLoDysq29hg4BMSuHl78AEpg6LDC4ZExsQu0FGEA8lRZonkCQiI5xaqKqvLS0tqKZX3q6orS6pItiAPSil0Gmi0fUMsnU4xADimzlmnm8SxWawi9CicW2uwOjEU2S4vFOhQuiGUPXkrz6smUViJ6mUXwQPwGJOYlTUknaZTKA3BkKcMzcsMWy1W+BIACEKGQANJHHInArnUDFRqdO4PPrMdSyOQDEzmKSjCo9dQjaQycmyTl2CGTHkuPnzMJ+ABG8KF62RmzCCRFWx2+0ObGOuLlRUQpXKlWqtXqjWaupKI3kqgG7RqD2uIw+XOt01tc28DudgsRGzinu9aL9mOxuSDZxDJTKFSqNTqsgaTVpSYZmukA21D2T2iGYMt3JzMPtTpdazFkulOPydYJDfDzajbZjtL7f0qzIMinJd2kWcc44AZrh3O4ALLcABOYAAImB+OhLyQAGIpej0WLXvbbLEj60NEFDfvONaLviCqEsSpIHhS2hUjScYqOUJqqMwFKIdomo6CeUIzBeV63g+z6vu+s5SgGMq1tB4hSB0tz3AetQalqOqtG8CjKIOyispIRJRrYlr4NwEBwKIY7OIGUHygxCAALSKLSJryAMjR1FUJq-LhBE2hO3j+IEwStAueLycULzqbxGjKNSyg6GoKGtDIDJIcoKrdE0qgNPp452nCRbybKS4wW0jTyJIiisphGq1AY0i0tFnRaNIdTSKMVhNDYo7ZtCgX8AW04hXRlnfLItIPMwiaDplLx6FUGr+dCxE3veT4vm+7iyRZ9YfDc0WxeqaqJVV6rqdo7wPIJ+garlEyntCEDCGAvXBsu-RYUofSebUVjSBoAydtqUWlDUHzbuakgidYQA */
  initial: 'selectQuestionAnswer',
  context: ({ input }) => {
    return {
      ...input,
    };
  },
  states: {
    selectQuestionAnswer: {
      initial: 'decision',
      states: {
        decision: {
          always: [
            { guard: 'isSkipAskQuestion', target: '#selectQuantity' },
            {
              target: 'waitingForSelectQuestion',
            },
          ],
        },
        waitingForSelectQuestion: {
          id: 'waitingForSelectQuestion',
          on: {
            SELECT_QUESTION_ANSWER: [
              {
                guard: 'isAnswerCorrect',
                target: '#selectQuantity',
              },
              {
                target: 'blockItem',
              },
            ],
          },
        },
        blockItem: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'blockItemByQuestion',
                input: ({ context }) => {
                  return {
                    token: context.token ?? '',
                    payload: {
                      condition: ConditionType.OrderItemId,
                      orderId: context.orderId,
                      itemId: context.activeOrderItem.external_id,
                      questionId: context.questionWorkFlowItem?.id ?? '',
                    },
                  };
                },
                onDone: {
                  target: 'success',
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('blockItemByQuestion error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    actions: emit(({ event, self }): IEmitted => {
                      return createRequestEmittedError(self, event);
                    }),
                    target: '#waitingForSelectQuestion',
                  },
                ],
              },
            },
            success: {
              type: 'final',
              entry: [
                emit(({ context }) => ({
                  type: 'blockItemSuccess',
                  data: {
                    productTitle: context.activeOrderItem.product_title,
                  },
                })),
                sendParent(({ context }): IEventReenter => {
                  return {
                    type: 'REENTER',
                    data: {
                      itemId: context.activeOrderItem.external_id,
                    },
                  };
                }),
              ],
            },
          },
        },
      },
    },
    selectQuantity: {
      id: 'selectQuantity',

      always: {
        guard: 'isSkipQuantity',
        target: 'selectReason',
        actions: assign({
          quantity: 1,
        }),
      },
      on: {
        SELECT_QUANTITY: {
          target: 'selectReason',
          actions: assign({
            quantity: ({ event }) => event.data.quantity,
          }),
        },
        BACK: 'selectQuestionAnswer',
      },
    },
    selectReason: {
      on: {
        SELECT_REASON: [
          {
            guard: 'hasSubReason',
            target: 'selectSubReason',
            actions: ['updateReason'],
          },
          {
            guard: 'hasFillMoreDetail',
            target: 'fillMoreDetail',
            actions: ['updateReason'],
          },
          {
            target: 'done',
            actions: ['updateReason'],
          },
        ],
        BACK: {
          guard: not('isSkipQuantity'),
          target: 'selectQuantity',
        },
      },
    },
    selectSubReason: {
      on: {
        SELECT_SUB_REASON: [
          {
            target: 'fillMoreDetail',
            guard: 'hasFillMoreDetail',
            actions: ['updateSubReason'],
          },
          {
            target: 'done',
            actions: ['updateSubReason'],
          },
        ],
        BACK: 'selectReason',
      },
    },
    fillMoreDetail: {
      on: {
        FILL_MORE_DETAIL: {
          guard: 'canFinishSelectSingleItem',
          target: 'done',
          actions: ['updateMoreDetail'],
        },
        BACK: [
          {
            guard: ({ context }) => !!context.selectedSubReasonId,
            target: 'selectSubReason',
          },
          {
            target: 'selectReason',
          },
        ],
      },
    },
    done: {
      type: 'final',
      entry: sendParent(({ context }): IEventSelectItemDone => createIEventSelectItemDone(context)),
    },
  },
  on: {
    CANCEL: {
      actions: sendParent(({ context }) => ({
        type: 'CANCEL_ITEM',
        data: {
          itemId: context.activeOrderItem.external_id,
        },
      })),
    },
    GO_TO_SELECT_ANSWER_PREVIEW: {
      target: '.selectQuestionAnswer.waitingForSelectQuestion',
    },
    GO_TO_SELECT_QUANTITY_PREVIEW: {
      target: '.selectQuantity',
      actions: assign({ selectedReasonId: '' }),
    },
    GO_TO_SELECT_REASON_PREVIEW: {
      target: '.selectReason',
      actions: assign({ selectedReasonId: '' }),
    },
    GO_TO_FILL_MORE_DETAIL_PREVIEW: {
      target: '.fillMoreDetail',
      actions: assign({ selectedReasonId: ({ event }) => event.data.selectedReasonId }),
    },
  },
});

export type SelectSingleItemSubFlowActorRef = ActorRefFrom<typeof selectSingleItemSubFlow>;

export type SelectSingleItemSubFlowSnapshot = SnapshotFrom<typeof selectSingleItemSubFlow>;
