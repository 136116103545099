import { PropsWithChildren } from 'react';

import { modalBodyRecipe } from './Modal.css';

import { ClassNameType } from '../../types/shared';
import { clsx } from '../../utils/clsx';

const ModalBody = ({ children, className }: PropsWithChildren<ClassNameType>) => {
  return <div className={clsx(modalBodyRecipe(), className)}>{children}</div>;
};

export default ModalBody;
