'use client';

import { PropsWithChildren } from 'react';
import { RadioGroup as AriaRadioGroup } from 'react-aria-components';

import { radioGroupRecipe } from './Radio.css';
import { RadioGroupProps } from './type';

import { clsx } from '../../utils/clsx';

const RadioGroup: React.FC<PropsWithChildren<RadioGroupProps>> = (props) => {
  const { children, ...rest } = props;
  return (
    <AriaRadioGroup className={clsx(radioGroupRecipe())} {...rest}>
      {children}
    </AriaRadioGroup>
  );
};

export default RadioGroup;
