import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import FormInput from '@/components/Form/FormInput';
import FormSelect, { Option } from '@/components/Form/FormSelect';

export type Props = {
  countryOptions: Array<Option>;
  stateOptions: Array<Option>;
  hasStateField: boolean;
  control: Control<any, any>;
};

const FormItemForShippingAddress = ({
  control,
  countryOptions,
  stateOptions,
  hasStateField,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={SpacingVars['6']}>
      <Flex direction='column' gap={SpacingVars['2']}>
        <Text variant={'subtitle1'}>{t('page.gift.description.address')}</Text>
        <Flex gap={SpacingVars['2']} justifyContent='space-around'>
          <FormInput
            control={control}
            name='address.firstName'
            placeholder={t('page.gift.placeholder.firstName')}
            fullWidth={true}
          />
          <FormInput
            control={control}
            name='address.lastName'
            placeholder={t('page.gift.placeholder.lastName')}
            fullWidth={true}
          />
        </Flex>
        <FormInput
          control={control}
          name='address.line1'
          placeholder={t('page.gift.placeholder.addressLine1')}
          fullWidth={true}
        />
        <FormInput
          control={control}
          name='address.line2'
          placeholder={t('page.gift.placeholder.addressLine2')}
          fullWidth={true}
        />
        <FormSelect
          control={control}
          name='address.country'
          placeholder={t('page.gift.placeholder.country')}
          options={countryOptions}
        />
        {hasStateField ? (
          <Flex gap={SpacingVars['2']}>
            <FormInput
              control={control}
              name='address.city'
              placeholder={t('page.gift.placeholder.city')}
              fullWidth={true}
            />
            <Box flex>
              <FormSelect
                control={control}
                name='address.state'
                placeholder={t('page.gift.placeholder.state')}
                options={stateOptions}
              />
            </Box>
          </Flex>
        ) : (
          <FormInput
            control={control}
            name='address.city'
            placeholder={t('page.gift.placeholder.city')}
            fullWidth={true}
          />
        )}

        <FormInput
          control={control}
          name='address.postalCode'
          placeholder={t('page.gift.placeholder.postalCode')}
          fullWidth={true}
        />
        <FormInput
          control={control}
          name='address.phoneNumber'
          placeholder={t('page.gift.placeholder.phoneNumber')}
          fullWidth={true}
        />
      </Flex>
    </Flex>
  );
};

export default FormItemForShippingAddress;
