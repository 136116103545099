import React from 'react';

import {
  Box,
  Flex,
  FlexProps,
  Icon,
  LeftChevronThin,
  Pressable,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

export interface HeaderProps {
  onBack?: VoidFunction;
  title: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  alignItems?: FlexProps['alignItems'];
  backgroundColor?: string;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const Header = ({
  onBack,
  title,
  backgroundColor,
  leftSlot,
  rightSlot,
  alignItems = 'start',
  containerRef,
}: HeaderProps) => {
  const EmptySlot = () => {
    return <Box />;
  };
  const LeftSlot = () => {
    return (
      <Pressable onPress={onBack}>
        <Flex
          alignItems='center'
          justifyContent='center'
          width={SpacingVars['9']}
          height={SpacingVars['9']}
        >
          <Icon
            source={LeftChevronThin}
            color={ColorVars['Grey']['Grey1200']}
            size={SpacingVars['6']}
          />
        </Flex>
      </Pressable>
    );
  };

  return (
    <div
      ref={containerRef}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <Flex gap={SpacingVars['4']} alignItems={alignItems}>
        <Flex direction='column' alignItems='start'>
          {leftSlot ? leftSlot : <LeftSlot />}
          <Box height={0} overflow='hidden'>
            {rightSlot ? rightSlot : <EmptySlot />}
          </Box>
        </Flex>
        <Box flex={1}>
          {React.isValidElement(title) ? (
            title
          ) : (
            <Text
              elementType='p'
              variant='title2'
              textAlign='center'
              color={ColorVars['Grey']['Grey1200']}
            >
              {title}
            </Text>
          )}
        </Box>
        <Flex direction='column' alignItems='end'>
          {rightSlot ? rightSlot : <EmptySlot />}
          <Box height={0} overflow='hidden'>
            {leftSlot ? leftSlot : <LeftSlot />}
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default Header;
