import { PresentmentMoney } from 'returns-logics';

export const toCurrency = (amount: number | string, currency = 'USD', withCurrency = false) => {
  let ret = '';
  try {
    const newCurrency = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'narrowSymbol',
    }).format(Number(amount));
    ret = `${newCurrency}${withCurrency ? ' ' + currency : ''}`;
  } catch (e) {
    ret = `${currency} ${Number(amount).toFixed(2)}`;
  }
  return ret;
};

/**
 * 传入 price 和 quantity 生成类似 $100 x 100 的字符串
 * @param price
 * @param quantity
 */
export const generatePriceWithQuantityString = (price?: PresentmentMoney, quantity?: number) => {
  if (price && quantity) {
    const priceString = toCurrency(price.amount, price.currency);
    return `${priceString} x ${quantity}`;
  } else if (price && !quantity) {
    return toCurrency(price.amount, price.currency);
  } else if (!price && quantity) {
    return `x ${quantity}`;
  } else {
    return '';
  }
};
