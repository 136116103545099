import { IconSource } from './type';

const ChevronTop: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13 10 8.177 5.177a.25.25 0 0 0-.354 0L3 10'
        stroke='#232933'
        strokeWidth={1.75}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default ChevronTop;
