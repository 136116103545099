import { MetaErrorItem, ResponseMetaError } from './response';

export class ErrorResponse {
  code: number;
  message: string;
  errors: (ResponseMetaError | MetaErrorItem)[];

  constructor(code: number, message?: string, errors?: (ResponseMetaError | MetaErrorItem)[]) {
    this.code = code;
    this.message = message || 'Something went wrong, please try again.';
    this.errors = errors || [];
  }
}
