import { Resolution, ResolutionMatchingData } from '../../../core';

const MOCK_RESOLUTION_NAME = '{Resolution name}';
const MOCK_RESOLUTION_DESCRIPTION = '{Resolution description}';

export const defaultResolution: ResolutionMatchingData = {
  show_instant_exchange_toggle: false,
  applied_resolutions: [
    Resolution.ReplaceTheSameItem,
    Resolution.OriginalPayment,
    Resolution.StoreCredit,
  ],
  resolutions: [
    {
      type: Resolution.ReplaceTheSameItem,
      name: MOCK_RESOLUTION_NAME,
      description: MOCK_RESOLUTION_DESCRIPTION,
    },
    {
      type: Resolution.OriginalPayment,
      name: MOCK_RESOLUTION_NAME,
      description: MOCK_RESOLUTION_DESCRIPTION,
    },
    {
      type: Resolution.StoreCredit,
      name: MOCK_RESOLUTION_NAME,
      description: MOCK_RESOLUTION_DESCRIPTION,
    },
  ],
};
