import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, FlexProps, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

interface SomethingWentWrongProps extends FlexProps {
  type?: 'resolution' | 'return-method';
}

const SomethingWentWrong = ({ type, ...props }: SomethingWentWrongProps) => {
  const { t } = useTranslation();
  const { title, description } = useMemo(() => {
    switch (type) {
      case 'resolution':
        return {
          title: t('page.description.somethingWentWrong'),
          description: `${t('page.description.noAvailableResolution')} ${t(
            'page.description.contactTheStore',
          )}`,
        };
      case 'return-method':
        return {
          title: t('page.description.somethingWentWrong'),
          description: `${t('page.description.noAvailableReturnMethod')} ${t(
            'page.description.contactTheStore',
          )}`,
        };
      default:
        return {
          title: t('page.description.somethingWentWrong'),
          description: t('page.description.contactTheStore'),
        };
    }
  }, [t, type]);

  return (
    <Flex
      flex={1}
      alignSelf='center'
      direction='column'
      alignItems='center'
      justifyContent='center'
      gap={SpacingVars['2']}
      {...props}
    >
      <Text variant='title2' color={ColorVars.Grey['Grey1200']}>
        {title}
      </Text>
      <Text variant='caption1' color={ColorVars.Grey['Grey 1100']}>
        {description}
      </Text>
    </Flex>
  );
};

export default SomethingWentWrong;
