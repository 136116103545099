export const LAYOUT_HEADER_HEIGHT = 64;
export const LAYOUT_FOOTER_HEIGHT = 80;

export const MOBILE_LAYOUT_HEADER_HEIGHT = 68;

export const LAYOUT_TOP_PADDING_ULTRA = 80;
export const LAYOUT_BOTTOM_PADDING_ULTRA = 80;
export const LAYOUT_TOP_PADDING_DESKTOP = 48;
export const LAYOUT_BOTTOM_PADDING_DESKTOP = 48;
export const LAYOUT_TOP_PADDING_TABLET = 24;
export const LAYOUT_BOTTOM_PADDING_TABLET = 24;
