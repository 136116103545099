import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

export interface AlreadyPaidModalProps {
  isOpen: boolean;
  createLoading: boolean;
  onCreate: () => void;
  onClose: () => void;
  onAlreadyPaid: () => void;
}

const AlreadyPaidModal = ({
  isOpen,
  createLoading,
  onAlreadyPaid,
  onCreate,
  onClose,
}: AlreadyPaidModalProps) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();

  const Container = () => {
    return (
      <Flex justifyContent='center'>
        <Text variant='body1'>
          {t('popup.description.submittedBefore')}
          {t('popup.description.wantToKeepIt')}
        </Text>
      </Flex>
    );
  };

  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button size='large' onPress={onAlreadyPaid}>
          {t('popup.request.yes')}
        </Button>
        <Button size='large' onPress={onCreate} variant='secondary'>
          {createLoading ? 'loading' : t('popup.request.createNewRequest')}
        </Button>
      </ModalButtonGroup>
    );
  };

  if (mobile) {
    return (
      <MobilePopup
        isOpen={isOpen}
        title={t('popup.description.requestSubmitted')}
        onClose={onClose}
        footer={<Footer />}
      >
        <Container />
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('popup.description.requestSubmitted')} onClose={onClose} />

      <ModalBody>
        <Container />
      </ModalBody>
      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};

export default AlreadyPaidModal;
