import { useCallback, useMemo } from 'react';

import { useFlow } from 'returns-logics/react';

const useCountry = () => {
  const flow = useFlow();

  const countriesMap = flow?.children?.giftReturnSubFlow?.context?.countryMap;

  const options = useMemo(() => {
    if (!countriesMap) return [];

    return Object.keys(countriesMap).map((key) => {
      const countryItem = countriesMap[key];
      return {
        value: countryItem.code,
        label: countryItem.name,
        key: countryItem.code,
      };
    });
  }, [countriesMap]);

  const findHasState = useCallback(
    (country: string) => {
      return !!(countriesMap?.[country] && countriesMap?.[country].sublist.length);
    },
    [countriesMap],
  );

  const getStateOption = useCallback(
    (countryCode: string) => {
      if (!countryCode) return [];
      return (
        countriesMap?.[countryCode]?.sublist?.map((item) => {
          return {
            value: item.name,
            label: item.name,
            key: item.name,
          };
        }) ?? []
      );
    },
    [countriesMap],
  );

  return useMemo(() => {
    return {
      countriesMap,
      options,
      findHasState,
      getStateOption,
    };
  }, [options, getStateOption, findHasState, countriesMap]);
};

export default useCountry;
