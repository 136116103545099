'use client';

import { useCallback, useEffect, useState } from 'react';
import { TextArea as AreaTextArea, TextField } from 'react-aria-components';

import { textFieldCounterRecipe, textFieldRecipe, textFieldTextAreaRecipe } from './TextArea.css';
import { TextAreaProps } from './type';

import { clsx } from '../../utils/clsx';
import { useStyleProps } from '../../utils/styleProps';

const TextArea = (props: TextAreaProps) => {
  const { maxLength, showCounter, rows, value, onChange, defaultValue, ...rest } = props;
  const { styleProps } = useStyleProps(rest);

  const showLimit = typeof maxLength === 'number';

  const [textLength, setTextLength] = useState<number>(
    value?.length ?? (defaultValue?.length || 0),
  );

  const handleChange = useCallback(
    (val: string) => {
      onChange?.(val);
      setTextLength(value?.length ?? val.length);
    },
    [onChange, value],
  );

  useEffect(() => {
    value !== undefined && setTextLength(value.length);
  }, [value]);

  return (
    <TextField
      {...props}
      style={styleProps.style}
      className={clsx(textFieldRecipe(), props.className)}
      onChange={handleChange}
    >
      <AreaTextArea className={textFieldTextAreaRecipe()} rows={rows} />
      {!!showCounter && (
        <div className={textFieldCounterRecipe()}>
          <span>{textLength}</span>
          {showLimit && <span>/</span>}
          {showLimit && <span>{maxLength}</span>}
        </div>
      )}
    </TextField>
  );
};

export default TextArea;
