import { t } from 'i18next';
import React from 'react';

import { Box, Button, Flex, Icon, Shop, Text } from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';

import { iconContainer } from '@/features/request-returns/components/SelectedReturnInfoBanner/styles.css.ts';
import useDevice from '@/hooks/useDevice';

export interface Props {
  selectedCount: number;
  onEdit?: () => void;
}
const SelectedReturnInfoBanner = ({ selectedCount, onEdit }: Props) => {
  const isMobile = useDevice().mobile;
  return (
    <Box
      backgroundColor={ColorVars['Transparency Reverse']['Transparency 300']}
      borderRadius={RadiusVars['2']}
      paddingX={SpacingVars['4']}
      paddingY={SpacingVars['3']}
    >
      <Flex gap={RadiusVars['4']} alignItems={'center'}>
        <Flex alignItems={'center'} className={iconContainer}>
          <Icon source={Shop} />
          <Box flex={1} flexBasis={0}>
            <Text variant={'subtitle2'}>
              {t('v2.page.description.selectedCount', {
                selectedCount: `x ${selectedCount}`,
              })}
            </Text>
          </Box>
        </Flex>
        {isMobile && (
          <Button variant={'plain'} onPress={() => onEdit?.()}>
            {t('page.request.edit')}
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default SelectedReturnInfoBanner;
