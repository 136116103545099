// @ts-nocheck
import {
  BundleEligibilityType,
  ShopEligibilityRulesData,
} from '../../../core/types/shopEligibilityRules';

export const defaultShopEligibilityRules: ShopEligibilityRulesData = {
  accept_discounted: true,
  accept_multiple_returns: true,
  ineligible_tags: [],
  ineligible_categories: [],
  return_window: null,
  return_window_base_on: 'delivery_date',
  return_window_base_on_fulfillment_days: null,
  return_window_base_on_delivery_days: null,
  product_limit: {
    enabled: false,
    feature_applied: false,
    limit_single_item_per_return: {
      enabled: false,
      quantity_exactly_one: false,
    },
    limit_items_with_common_routing_rule: {
      enabled: false,
    },
  },
  id: '700cca85-b94d-4199-ba3d-ac6a526710b7',
  shop_id: 'cbb5fc63-6cec-4738-9840-f43bbc1c154a',
  created_at: '2022-07-07T02:23:12.976000000Z',
  updated_at: '2024-07-15T08:11:25.874119330Z',

  bundle_line_item_eligibility: BundleEligibilityType.sameAsParent,
};
