import { Orders } from 'returns-logics';

import { ITracker } from './ITracker';
import {
  BasicInfo,
  ClickEvent,
  DeviceType,
  EventName,
  ExtraData,
  PageEnterPayload,
  PageLeavePayload,
  PageType,
} from './consts';

type Status = 'idle' | 'preparing' | 'initialized' | 'failed';

export enum EventType {
  click = 'click',
  pageEnter = 'pageEnter',
  pageLeave = 'pageLeave',
}

type BreadcrumbData = {
  type: string;
  reportTime: string;
  data:
    | {
        path: string[];
        text: string;
      }
    | {};
  extraData: ExtraData;
};

const MAX_RETRY_COUNT = 10;
class TrackerHelper implements ITracker {
  private reporter: any;
  private breadcrumbCollector: any;
  private initStatus: Status = 'idle';
  private retryCount = 0;
  private basicExtraData: BasicInfo = {
    trace_id: '',
    org_id: '',
    platform: '',
    store: '',
    order_id: '',
  };
  private pendingQueue: BreadcrumbData[] = [];

  constructor(sid: string) {
    this.basicExtraData.trace_id = sid;
  }

  async init() {
    if (this.initStatus === 'initialized' || this.initStatus == 'preparing') {
      return;
    }
    try {
      // @ts-ignore
      const { BreadcrumbCollector } = await import('@aftership/storefront-kit/breadcrumb');
      // @ts-ignore
      const { Reporter } = await import('@aftership/storefront-kit/reporter');
      this.reporter = new Reporter({
        pageData: {
          appName: 'returns',
          pageId: '',
        },
      });
      this.breadcrumbCollector = new BreadcrumbCollector({
        reporter: this.reporter,
        networkSources: ['automizely'],
        hooks: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onCollectBreadcrumb: (data: BreadcrumbData) => {
            if (data.extraData && data.extraData.eventName) {
              return data;
            } else {
              return false;
            }
          },
          onCollectNetwork: () => {
            return false;
          },
        },
      });
      this.initStatus = 'initialized';
      // try send queue data
      this.tryReportPendingQueue();
    } catch (e) {
      this.initStatus = 'failed';
    }
  }

  /**
   * 创建一个待上传的事件对象
   * @param clickName
   * @param event
   */
  private prepareClickData(clickData: ClickEvent, event?: Event): BreadcrumbData {
    const extraData: ExtraData = {
      ...this.basicExtraData,
      ...clickData,
    };
    return {
      type: EventType.click,
      reportTime: this.getISODateWithTimeZone(),
      data: this.formatClickData(event),
      extraData: extraData,
    };
  }

  private prepareEnterData(payload: PageEnterPayload): BreadcrumbData {
    const extraData: ExtraData = {
      ...this.basicExtraData,
      eventName: EventName.pageEnter,
      payload,
    };
    return {
      type: EventType.pageEnter,
      reportTime: this.getISODateWithTimeZone(),
      data: {},
      extraData: extraData,
    };
  }

  private preparePageLeave(payload: PageLeavePayload): BreadcrumbData {
    const extraData: ExtraData = {
      ...this.basicExtraData,
      eventName: EventName.pageLeave,
      payload,
    };
    return {
      type: EventType.pageLeave,
      reportTime: this.getISODateWithTimeZone(),
      data: {},
      extraData: extraData,
    };
  }

  /**
   * init 成功后 尝试上传等待队列
   */
  private tryReportPendingQueue() {
    if (this.initStatus === 'initialized' && this.pendingQueue.length > 0) {
      this.pendingQueue.forEach((item) =>
        this.breadcrumbCollector.collect({
          ...item,
          extraData: {
            ...this.basicExtraData,
            eventName: item.extraData.eventName,
            ...(item.extraData.payload && { payload: item.extraData.payload }),
          },
        }),
      );
      this.pendingQueue = [];
    }
  }
  /**
   * 上报页面进入事件
   * @param pageName
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  async reportPageEnterEvent(payload: PageEnterPayload) {
    this.collect(this.prepareEnterData(payload));
  }

  async reportPageLeaveEvent(payload: PageLeavePayload) {
    this.collect(this.preparePageLeave(payload));
  }

  /**
   * 上报点击事件
   * @param clickName
   * @param event
   */
  async reportClickEvent(clickData: ClickEvent, event?: Event) {
    this.collect(this.prepareClickData(clickData, event));
  }

  private async collect(breadcrumb: BreadcrumbData) {
    if (this.initStatus === 'idle') {
      this.pendingQueue.push(breadcrumb);
      // await this.init();
    } else if (this.initStatus === 'failed') {
      if (this.retryCount <= MAX_RETRY_COUNT) {
        this.retryCount += 1;
        this.pendingQueue.push(breadcrumb);
        await this.init();
      }
    } else if (this.initStatus === 'preparing') {
      this.pendingQueue.push(breadcrumb);
    } else if (this.initStatus === 'initialized') {
      this.breadcrumbCollector.collect(breadcrumb);
    }
  }

  /**
   * 从 orderInfo 中提取关键字段,更新 extraData
   * @param orderInfo
   */
  updateExtraDataByOrderInfo(orderInfo?: Orders): void {
    this.basicExtraData = {
      ...this.basicExtraData,
      order_id: orderInfo?.id ?? '',
      store: orderInfo?.app?.key,
      platform: orderInfo?.app?.platform,
    };
  }

  /**
   * 设置 orgId
   * @param orgId
   */
  updateExtraDataByOrgId(orgId: string): void {
    this.basicExtraData = {
      ...this.basicExtraData,
      org_id: orgId,
    };
  }

  /**
   * 页面类型，app proxy 或正常页面
   * @param type
   */
  updateExtraDataByPagetType(type: PageType): void {
    this.basicExtraData = {
      ...this.basicExtraData,
      page_type: type,
    };
  }

  /**
   * 设备类型
   * @param device
   */
  updateExtraDataByDeviceMode(device: DeviceType): void {
    this.basicExtraData = {
      ...this.basicExtraData,
      device,
    };
  }

  private formatClickData(event?: any): { path: string[]; text: string } {
    return {
      path: this.formatPath(event),
      text: this.formatText(event),
    };
  }

  private formatText(event?: any): string {
    return event?.target?.innerText ?? '';
  }

  private formatPath(event?: any): string[] {
    const path: HTMLElement[] = event?.nativeEvent?.path ?? [];
    return path
      .map(
        (el) =>
          `${el.tagName}${el.id ? `#${el.id}` : ''}${
            el.className ? `.${el.className.split(' ').join('.')}` : ''
          }`,
      )
      .filter((text) => text !== 'undefined');
  }

  private getISODateWithTimeZone(): string {
    const date = new Date();
    const isoString = date.toISOString();
    const totalOffset = date.getTimezoneOffset();
    if (totalOffset === 0) return isoString;

    const offsetHrs = Math.round(Math.abs(totalOffset / 60));
    const offsetMin = Math.round(Math.abs(totalOffset % 60));

    const hrs = offsetHrs < 10 ? '0' + offsetHrs : String(offsetHrs);
    const min = offsetMin < 10 ? '0' + offsetMin : String(offsetMin);

    // Add an opposite sign to the offset
    const timezoneStandard = `${totalOffset < 0 ? '+' : '-'}${hrs}:${min}`;
    return `${isoString.replace('Z', '')}${timezoneStandard}`;
  }
}

export default TrackerHelper;
