import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Modal, ModalBody, ModalHeader, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import { wordBreakMetaStyle } from '../../styles/text.css';
import type { ReturnItem } from '../ReturnItems/ReturnItems';

interface ReasonDetailModalProps {
  returnItemId: string;
  returnItems: ReturnItem[];
  isOpen: boolean;
  onClose: () => void;
}

const ReasonDetailModal = (props: ReasonDetailModalProps) => {
  const { t } = useTranslation();
  const { isOpen, onClose, returnItemId, returnItems } = props;
  const isMobile = useDevice().mobile;

  const {
    returnImages,
    notesToMerchant: comments,
    returnReason: reason,
    returnSubReason: subReason,
  } = returnItems.find((item) => item.itemId === returnItemId) ?? {};

  const Content = () => {
    return (
      <Flex direction='column' gap={SpacingVars['6']}>
        {reason && (
          <Box>
            <Flex direction='column'>
              <Text variant='subtitle2' color={ColorVars.Grey['Grey 1100']}>
                {t('v2.return.reason.detail.title')}
              </Text>
              <Text variant='body1'>{`${reason} ${subReason ? `/ ${subReason}` : ''}`}</Text>
            </Flex>
          </Box>
        )}

        {comments && (
          <Box>
            <Flex direction='column'>
              <Text variant='subtitle2' color={ColorVars.Grey['Grey 1100']}>
                {t('page.description.comment')}
              </Text>
              <Text variant='body1' className={wordBreakMetaStyle}>
                {comments}
              </Text>
            </Flex>
          </Box>
        )}

        {!!returnImages?.length && (
          <Box>
            <Flex direction='column'>
              <Text variant='subtitle2' color={ColorVars.Grey['Grey 1100']}>
                {t('page.description.images')}
              </Text>
              <Box>
                <Flex
                  gridColumn='4'
                  gap={isMobile ? SpacingVars['3'] : SpacingVars['2']}
                  gridArea=''
                  wrap={true}
                >
                  {returnImages.map((image) => (
                    <Image
                      key={image.id}
                      width={100}
                      height={100}
                      src={image.src}
                      style={{
                        borderRadius: SpacingVars['1'],
                      }}
                      alt=''
                    />
                  ))}
                </Flex>
              </Box>
            </Flex>
          </Box>
        )}
      </Flex>
    );
  };

  return isMobile ? (
    <MobilePopup isOpen={isOpen} onClose={onClose} title={t('v2.return.reason.detail.header')}>
      <Content />
    </MobilePopup>
  ) : (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('v2.return.reason.detail.header')} onClose={onClose} />
      <ModalBody>
        <Content />
      </ModalBody>
    </Modal>
  );
};

export default ReasonDetailModal;
