import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import SectionLayout, { SummarySectionName } from './SectionLayout';

import { useSummaryContext } from '../SummaryProvider';

const ShippingFeeSection = ({ shippingFeeValue }: { shippingFeeValue: string }) => {
  const { t } = useTranslation();
  const { isFold, isMobile } = useSummaryContext();

  return (
    <Flex
      direction='column'
      gap={SpacingVars['1']}
      paddingBottom={!isFold && !isMobile ? SpacingVars['3'] : 0}
    >
      <SectionLayout name={SummarySectionName.ShippingFee}>
        <Text variant='body1'>{t('page.description.exchangeShipping')}</Text>
        <Text variant='body1'>{shippingFeeValue}</Text>
      </SectionLayout>
    </Flex>
  );
};

export default ShippingFeeSection;
