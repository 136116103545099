'use client';

import {
  bannerButtonRecipe,
  bannerButtonStyle,
  bannerCloseRecipe,
  bannerIconWrapperRecipe,
  bannerInfoWrapperRecipe,
  bannerRecipe,
  bannerTextWrapperRecipe,
} from './Banner.css';
import { bannerThemeVar } from './BannerToken.css';
import { BannerProps } from './type';

import { clsx } from '../../utils/clsx';
import { Button } from '../Button';
import { Exclamation, Icon, IconCross } from '../Icon';

const bannerTypeColor = {
  warning: bannerThemeVar.Color.Banner.Warning,
  info: bannerThemeVar.Color.Banner.Info,
  critical: bannerThemeVar.Color.Banner.Critical,
};

const Banner = (props: BannerProps) => {
  const { variant, info, onAction, onClose, actionText, className } = props;

  return (
    <div className={clsx(bannerRecipe({ variant }), className)}>
      <div className={bannerIconWrapperRecipe()}>
        <Icon source={Exclamation} color={bannerTypeColor[variant].IconColor}></Icon>
      </div>
      <div className={bannerInfoWrapperRecipe()}>
        <span className={bannerTextWrapperRecipe()}>{info}</span>
        {actionText && (
          <div className={bannerButtonRecipe()}>
            <Button onPress={onAction} className={bannerButtonStyle}>
              {actionText}
            </Button>
          </div>
        )}
      </div>
      {onClose && (
        <Button onPress={onClose} className={bannerCloseRecipe()} variant='plain'>
          <Icon source={IconCross} size={'30px'} />
        </Button>
      )}
    </div>
  );
};

export default Banner;
