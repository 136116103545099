import { UnexpectedHostnameException } from './errorHandler';
import { logger } from './logger';

interface RequestOption extends RequestInit {
  auth?: boolean;
}

export async function request<T = any>(url: string, options: RequestOption = { auth: true }) {
  const res = await fetch(url, options);
  const body = await (res.json() as Promise<{ data: T }>);
  if (res.status >= 300 || res.status < 200) {
    logger.info({
      res: {
        data: body,
        status: res?.status,
        headers: res?.headers,
      },
      message: `[HTTP] Response: ${res?.url}`,
    });
    throw new UnexpectedHostnameException(res.statusText, { cause: res });
  }
  return body;
}

export async function requestInternal<T = any>(
  url: string,
  options: RequestOption = { auth: true },
) {
  const res = await fetch(url, options);
  return await (res.json() as Promise<{ data: T }>);
}
