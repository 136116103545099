import { useTranslation } from 'react-i18next';

import { Card, CardHeader, CardSection, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { ShippingAddress as IShippingAddress } from 'returns-logics';

import { Divider } from '@/components/Divider';

import useCartStyle from '../../hooks/useCartStyle';
import useHideSummaryWidth from '../../hooks/useHideSummaryWidth';
import { getFullShippingAddress } from '../../utils/shipment';

const ShippingAddress = ({ address }: { address: IShippingAddress }) => {
  const { isMobile, normalStyle, mobileNoOutlineClassname } = useCartStyle();
  const hideSummaryWidth = useHideSummaryWidth();
  const { t } = useTranslation();

  return (
    <>
      {isMobile && <Divider spacing={SpacingVars['1']} />}
      <Card
        {...normalStyle}
        width={hideSummaryWidth || normalStyle.width}
        className={mobileNoOutlineClassname}
      >
        <CardHeader>
          <Text variant='title3'>{t('v2.shipping.address.title')}</Text>
        </CardHeader>

        <CardSection>
          <Text variant='body2'>{getFullShippingAddress(address)}</Text>
        </CardSection>
      </Card>
    </>
  );
};

export default ShippingAddress;
