import { IconSource } from './type.ts';

const ShoppingCart: IconSource = () => {
  return (
    <svg viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2 4h3.726l1.966 10.372A2 2 0 0 0 9.657 16H18.5m-11-3.5h11.134L21 6.5H6.5'
        stroke='currentColor'
        fill='transparent'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle cx={8.5} cy={20} r={1.5} fill='currentColor' />
      <circle cx={17.5} cy={20} r={1.5} fill='currentColor' />
    </svg>
  );
};

export default ShoppingCart;
