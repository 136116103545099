import { useCallback, useSyncExternalStore } from 'react';

import {
  OutOfQueueStrategy,
  QueuedToastProps,
  ToastOptions,
  ToastQueueProps,
  ToastState,
} from './type';

import { Timer } from '../../utils/Timer';

export class ToastQueue {
  #maxVisibleToast: number;
  #defaultDuration: number;
  #outOfQueueStrategy: OutOfQueueStrategy;
  #queue: QueuedToastProps[] = [];
  visibleToast: QueuedToastProps[] = [];
  #subscriptions: Set<() => void> = new Set();

  constructor({ maxVisibleToast, outOfQueueStrategy, defaultDuration }: ToastQueueProps) {
    this.#maxVisibleToast = maxVisibleToast;
    this.#outOfQueueStrategy = outOfQueueStrategy;
    this.#defaultDuration = defaultDuration;
  }

  subscribe(fn: () => void) {
    this.#subscriptions.add(fn);

    return () => this.#subscriptions.delete(fn);
  }

  add(content: string, options?: ToastOptions) {
    const toastKey = Math.random().toString(36).slice(2);
    const timeout = options?.duration ?? this.#defaultDuration;
    const toast: QueuedToastProps = {
      ...options,
      content,
      key: toastKey,
      timer: timeout > 0 ? new Timer(() => this.close(toastKey), timeout) : null,
    };

    if (this.visibleToast.length >= this.#maxVisibleToast) {
      switch (this.#outOfQueueStrategy) {
        case 'replace':
          this.#queue.shift()?.onClose?.();
          this.#queue.push(toast);
          break;
        case 'ignore':
          break;
        case 'queue':
          this.#queue.push(toast);
          break;
      }
    } else {
      this.#queue.push(toast);
    }

    this.#updateVisibleToast();

    return toastKey;
  }

  close(key: string) {
    const index = this.#queue.findIndex((toast) => toast.key === key);
    if (index !== -1) {
      this.#queue[index].onClose?.();
      this.#queue.splice(index, 1);
    }
    this.#updateVisibleToast();
  }

  #updateVisibleToast() {
    const toasts = this.#queue.slice(0, this.#maxVisibleToast);
    this.visibleToast = toasts;
    for (let fn of this.#subscriptions) {
      fn();
    }
  }
}

export function useToastQueue(queue: ToastQueue): ToastState['visibleToasts'] {
  const subscribe = useCallback(
    (fn: VoidFunction) => {
      return queue.subscribe(fn);
    },
    [queue],
  );

  const getSnapshot = useCallback(() => {
    return queue.visibleToast;
  }, [queue]);

  const visibleToasts = useSyncExternalStore(subscribe, getSnapshot, getSnapshot);

  return visibleToasts;
}
