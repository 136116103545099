import { fromPromise } from 'xstate';

import { giftReturnSubFlow } from '../../core/machine/giftReturnSubFlow/giftReturnSubFlow';
import { CountryCodeMap } from '../../core/types';

export const mockGiftReturnSubFlow = giftReturnSubFlow.provide({
  actors: {
    getCountries: fromPromise<CountryCodeMap>(() => Promise.resolve({})),
  },
});
