'use client';

import { Tag as AriaTag } from 'react-aria-components';

import { tagInnerClassName, tagRecipe } from './TagGroup.css';
import { TagProps } from './type';

const Tag = ({ children, onPress, ...rest }: TagProps) => {
  return (
    <AriaTag {...rest} className={tagRecipe()}>
      {({ isDisabled }) => {
        return (
          <div
            className={tagInnerClassName}
            onClick={() => {
              !isDisabled && onPress?.();
            }}
          >
            {children}
          </div>
        );
      }}
    </AriaTag>
  );
};

export default Tag;
