import { ExternalLink, Flex, Icon, Link, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

interface LinkWithIconProps {
  link?: string | null;
  children?: React.ReactNode;
}

const LinkWithIcon = ({ link, children }: LinkWithIconProps) => {
  return (
    <Link target='_blank' href={link || ''}>
      <Flex alignItems='center' gap={SpacingVars['1']}>
        {typeof children === 'string' ? (
          <Text variant='body2' color={ColorVars['Grey']['Grey1200']}>
            {children}
          </Text>
        ) : (
          children
        )}
        <Icon source={ExternalLink} color={ColorVars['Grey']['Grey1200']} />
      </Flex>
    </Link>
  );
};

export default LinkWithIcon;
