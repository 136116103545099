import { useMemo } from 'react';

import useDevice from '@/hooks/useDevice';

import useSpecialPoint from './useSpecialPoint';
import { useUniversalRouting } from './useUniversalRouting';

enum Layout {
  desktop = 'desktop',
  tablet = 'tablet',
  mobile = 'mobile',
}

const hiddenHeaderRegexMap = {
  [Layout.desktop]: ['^/exchange/preview$', '^/exchange/products/[^/]+$'],
  [Layout.tablet]: ['^/exchange/preview$', '^/exchange/products/[^/]+$'],
  [Layout.mobile]: [
    '^/return-method$',
    /^\/return-detail.*/,
    /^\/review.*/,
    '^/gift-return$',
    '^/request-returns$',
    '^/return-resolution$',
    '^/return-replacement$',
    '^/exchange/cart$',
    '^/exchange/products$',
    '^/exchange/products/[^/]+$',
  ],
};
const hiddenFooterRegexMap = {
  [Layout.desktop]: [
    '^/exchange/preview$',
    '^/exchange/cart$',
    '^/exchange/products$',
    '^/exchange/products/[^/]+$',
  ],
  [Layout.tablet]: [
    '^/exchange/preview$',
    '^/exchange/cart$',
    '^/exchange/products$',
    '^/exchange/products/[^/]+$',
  ],
  [Layout.mobile]: [],
};
const fullScreenRegexMap = {
  [Layout.desktop]: [
    '^/exchange/preview$',
    '^/exchange/cart$',
    '^/exchange/products$',
    '^/exchange/products/[^/]+$',
    // '^/return-policy$',
  ],
  [Layout.tablet]: [
    '^/exchange/preview$',
    '^/exchange/cart$',
    '^/exchange/products$',
    '^/exchange/products/[^/]+$',
    // '^/return-policy$',
  ],
  [Layout.mobile]: [],
};

const matchPath = (pathname: string, regex: RegExp) => regex.test(pathname);

export const useLayoutHidden = () => {
  const { mobile: isMobile, tablet: isTablet } = useDevice();
  const { specialDesktop } = useSpecialPoint();
  // ultra 和 desktop 共用一个 layout
  const layout = isMobile ? Layout.mobile : isTablet ? Layout.tablet : Layout.desktop;
  const { pathname } = useUniversalRouting()!;

  const hiddenHeader = hiddenHeaderRegexMap[layout].some((path) =>
    matchPath(pathname, new RegExp(path)),
  );
  const hiddenFooter = hiddenFooterRegexMap[layout].some((path) =>
    matchPath(pathname, new RegExp(path)),
  );
  const fullScreen = fullScreenRegexMap[layout].some((path) =>
    matchPath(pathname, new RegExp(path)),
  );

  const allowFullScreen = useMemo(() => {
    if (isMobile) return true;
    else if (specialDesktop?.review || specialDesktop?.returnDetail) {
      return true;
    } else return fullScreen;
  }, [fullScreen, isMobile, specialDesktop?.returnDetail, specialDesktop?.review]);

  return {
    hiddenHeader,
    hiddenFooter: isMobile ? true : hiddenFooter,
    fullScreen: allowFullScreen,
  };
};
