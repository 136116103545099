import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { IDraftReturn } from 'returns-logics';

import MobilePopup from '@/components/MobilePopup';
import { SummaryProvider } from '@/features/return-detail/components/Summary/SummaryProvider';
import MaximumReturnableQuantityExceededErrorModal from '@/features/review/components/ErrorModals/MaximumReturnableQuantityExceededErrorModal';
import StripeErrorModal from '@/features/review/components/ErrorModals/StripeErrorModal';
import { StripeInProgressModal } from '@/features/review/components/InProgressModals';
import StripeElement from '@/features/review/components/PaymentModal/components/StripeModal/StripeElement';
import { stripeDesktopStyles } from '@/features/review/components/PaymentModal/components/StripeModal/StripeModal.css';
import { useStripeContext } from '@/features/review/components/PaymentModal/components/StripeModal/StripeProvider';
import { modalScrollStyles } from '@/features/review/components/PaymentModal/styles/modalStyle.css';
import { PayStatus } from '@/features/review/type';
import useDevice from '@/hooks/useDevice';

import PayNowSummary from './PayNowSummary';

import usePayNow from '../../hooks/usePayNow';

interface StripePayNowModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  draftReturnItem: IDraftReturn;
}
const StripePayNowModal = ({ isOpen, draftReturnItem, onClose }: StripePayNowModalProps) => {
  const isMobile = useDevice().mobile;
  const { isStripePaymentLoading, isStripeReady, stripeSubmit } = useStripeContext();
  const { t } = useTranslation();

  const [inProgress, setProgress] = useState(false);
  const [noReturnableQuantityModalVisible, setNoReturnableQuantityModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { onPayContinue, status, pollingCancel, reload } = usePayNow({
    draftId: draftReturnItem.id,
  });

  const onPayNow = () => {
    stripeSubmit?.()
      .then(() => {
        setProgress(true);
        // 开始轮询 draft return
        onPayContinue();
      })
      .catch((error: string) => {
        if (error === 'validation_error') {
          //do nothing，比如校验错误，仍停留在本页面
        } else {
          setErrorModalVisible(true);
          setErrorMsg(error);
        }
      });
  };

  useEffect(() => {
    switch (status) {
      // TODO 以前的 shopper 已经不设置 repeatedPay， 需要确认场景
      // case PayStatus.repeatedPay:
      //   setNoReturnableQuantityModalVisible(true);
      //   break;
      case PayStatus.paid:
        pollingCancel();
        onClose();
        reload();
        break;
      default:
        // 若订单本身为 PayStatus.error 状态不用处理，继续展示付款表单即可
        break;
    }
  }, [onClose, pollingCancel, reload, status]);

  if (inProgress) {
    return (
      <StripeInProgressModal
        isOpen={inProgress}
        onCancel={onClose}
        title={t('page.error.paymentInProgress')}
        message={t('popup.description.processingStripePayment')}
      />
    );
  }

  if (errorModalVisible) {
    return (
      <StripeErrorModal
        isOpen={errorModalVisible}
        errorMsg={errorMsg}
        onClose={() => {
          onClose();
          setErrorModalVisible(false);
        }}
      />
    );
  }

  if (noReturnableQuantityModalVisible) {
    return (
      <MaximumReturnableQuantityExceededErrorModal
        isOpen={noReturnableQuantityModalVisible}
        onClose={() => setNoReturnableQuantityModalVisible(false)}
        onConfirm={() => {
          reload();
          onClose();
        }}
      />
    );
  }

  if (isMobile) {
    return (
      <MobilePopup
        isOpen={isOpen}
        onClose={onClose}
        title={t('ENTER_AC0975')}
        layout='top'
        footer={
          <Flex
            direction='column'
            padding={SpacingVars['4']}
            paddingBottom={SpacingVars['4']}
            gap={SpacingVars['4']}
          >
            <Button
              onPress={onPayNow}
              isLoading={isStripePaymentLoading}
              isDisabled={!isStripeReady}
              size='large'
            >
              {t('page.request.payNow')}
            </Button>
          </Flex>
        }
      >
        <Flex direction='column' gap={SpacingVars['1']}>
          <SummaryProvider isFold={false} previewSummary={draftReturnItem?.return_preview_summary}>
            <PayNowSummary />
          </SummaryProvider>

          <Box className={stripeDesktopStyles}>
            <StripeElement
              stripeKey={draftReturnItem.payment_app_key}
              stripeToken={draftReturnItem.payment_client_token}
            />
          </Box>
        </Flex>
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen} className={modalScrollStyles}>
      <ModalHeader title={t('popup.description.payForReturnRequest')} onClose={onClose} />

      <ModalBody>
        <Flex direction='column' gap={SpacingVars['1']}>
          <SummaryProvider isFold={false} previewSummary={draftReturnItem?.return_preview_summary}>
            <PayNowSummary />
          </SummaryProvider>

          <Box className={stripeDesktopStyles}>
            <StripeElement
              stripeKey={draftReturnItem.payment_app_key}
              stripeToken={draftReturnItem.payment_client_token}
            />
          </Box>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <Flex direction='row' justifyContent='end'>
          <Button onPress={onPayNow} isLoading={isStripePaymentLoading} isDisabled={!isStripeReady}>
            {t('page.request.payNow')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default StripePayNowModal;
