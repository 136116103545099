import { t } from 'i18next';
import React from 'react';

import { Flex, Text, TextArea } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { ReturnReasonDetail, genReturnReasonCode } from '@/i18n/dynamic';

export interface CommentsAreaProps {
  reasonId: string;
  commentsDescription: Maybe<string>;
  required: boolean;
  comments?: string;
  onCommentsChange: (comments: string) => void;
}
const CommentsArea = ({
  reasonId,
  commentsDescription,
  comments,
  required,
  onCommentsChange,
}: CommentsAreaProps) => {
  const placeholder = t(
    genReturnReasonCode({
      reasonId: reasonId,
      detail: ReturnReasonDetail.Comment,
    }),
    {
      rawValue: commentsDescription,
      defaultValue: commentsDescription,
    },
  );
  return (
    <Flex gap={SpacingVars['1']} direction={'column'}>
      <Text variant={'body1'}>
        {t('page.description.comment')}
        {required ? '*' : ''}
      </Text>
      <TextArea
        height={'120px'}
        placeholder={placeholder}
        showCounter
        maxLength={500}
        value={comments}
        onChange={onCommentsChange}
      />
    </Flex>
  );
};

export default CommentsArea;
