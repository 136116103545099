import Image, { ImageProps } from 'next/image';
import React, { memo, useEffect, useState } from 'react';

import thumb from '@/assets/thumbnail.svg';
import { getShopifyPreviewImage } from '@/utils/images.ts';

export type IProps = Record<string, any>;

export interface Props extends ImageProps {
  src: string;
  autoFitWidth?: boolean;
  isBundle?: boolean;
  usingShopifyPreview?: boolean;
}

// const LayerItemImage = (props: Props) => {};

const ImageWithFallback = memo((props: Props) => {
  const { usingShopifyPreview = false, autoFitWidth, width, height, src, alt, ...rest } = props;
  const previewSrc = usingShopifyPreview ? getShopifyPreviewImage(src) : src;
  const [imgSrc, setImgSrc] = useState(previewSrc || thumb.src);

  useEffect(() => {
    setImgSrc(previewSrc || thumb.src);
  }, [previewSrc]);
  if (autoFitWidth) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '0',
          paddingTop: '100%' /* 高度与宽度相等 */,
        }}
      >
        <Image
          {...rest}
          alt={alt}
          src={imgSrc}
          onError={() => {
            setImgSrc(thumb.src);
          }}
          fill={true}
          layout='fill'
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
    );
  }

  return (
    <Image
      {...rest}
      alt={alt}
      src={imgSrc}
      onError={() => {
        setImgSrc(thumb.src);
      }}
      style={{
        objectFit: 'cover',
      }}
      width={width}
      height={height}
    />
  );
});

ImageWithFallback.displayName = 'ImageWithFallback';

export default ImageWithFallback;
