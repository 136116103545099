import { ActorRefFrom, SnapshotFrom, setup } from 'xstate';

import { ExchangeOnStoreContext, ExchangeOnStoreInput } from './types';

export const exchangeOnStoreSubFlow = setup({
  types: {
    context: {} as ExchangeOnStoreContext,
    input: {} as ExchangeOnStoreInput,
  },
  actors: {},
}).createMachine({
  context: ({ input }) => input,
  initial: 'hold',
  states: {
    hold: { tags: 'loading' },
  },
});

export type ExchangeOnStoreSubFlowActorRef = ActorRefFrom<typeof exchangeOnStoreSubFlow>;
export type ExchangeOnStoreSubFlowSnapshot = SnapshotFrom<typeof exchangeOnStoreSubFlow>;
