import { ReturnReasonData } from '../../../core';

export const mockSelectedReasonName = '{Return reason}';
export const mockSelectedReasonId = '045c026f42004a12a22488a2927ea9f6';

export const defaultReasons: ReturnReasonData = {
  items_return_reason_groups_mapping: {
    '13988733812990': 'd96b0843522241e9b782e97a660fde54',
  },
  reason_groups: [
    {
      id: 'd96b0843522241e9b782e97a660fde54',
      name: 'Default group',
      product_types: [],
      randomize_reasons: false,
      default_group: true,
      return_reasons: [
        {
          id: mockSelectedReasonId,
          name: mockSelectedReasonName,
          subreasons: [],
          comments_enabled: true,
          comments_required: false,
          images_enabled: true,
          images_required: false,
          created_at: '2022-06-07T09:14:16.775Z',
          updated_at: '2022-06-07T09:14:16.775Z',
          comments_description: '{Comment instructions}',
          images_description: '{Image upload instructions}',
        },
        {
          id: '8173b9dfb4134cd5a5a71f59254d388e',
          name: mockSelectedReasonName,
          comments_enabled: false,
          comments_required: false,
          images_enabled: false,
          images_required: false,
          comments_description: '{Comment instructions}',
          images_description: '{Image upload instructions}',
          subreasons: [],
          created_at: '2022-07-07T02:23:07.988Z',
          updated_at: '2023-12-01T03:22:47.972Z',
        },
        {
          id: 'c7f3eecc431b426cbac5ea2076e77cbc',
          name: mockSelectedReasonName,
          comments_enabled: true,
          comments_required: false,
          images_enabled: false,
          images_required: false,
          comments_description: '{Comment instructions}',
          images_description: '{Image upload instructions}',
          subreasons: [],
          created_at: '2022-07-07T02:23:07.988Z',
          updated_at: '2024-05-14T05:53:28.246Z',
        },
        {
          id: 'cdc9880aa68448808c0913a3c04100b5',
          name: mockSelectedReasonName,
          comments_enabled: false,
          comments_required: false,
          images_enabled: false,
          images_required: false,
          comments_description: '{Comment instructions}',
          images_description: '{Image upload instructions}',
          subreasons: [],
          created_at: '2022-07-07T02:23:07.988Z',
          updated_at: '2022-07-07T02:23:07.988Z',
        },
        {
          id: '086df4ffd0424b47a4d40c0e5885049d',
          name: mockSelectedReasonName,
          comments_enabled: true,
          comments_required: true,
          images_enabled: true,
          images_required: false,
          comments_description: '{Comment instructions}',
          images_description: '{Image upload instructions}',
          subreasons: [],
          created_at: '2022-07-07T02:23:07.988Z',
          updated_at: '2024-02-27T09:37:27.284Z',
        },
      ],
      created_at: '2022-07-07T02:23:08.048Z',
      updated_at: '2022-07-07T02:23:08.048Z',
    },
  ],
};
