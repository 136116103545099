import { createContext, useContext } from 'react';

import { useIsSSR } from '../../hooks/useIsSSR';

export interface ThemeContext {
  portalContainer?: Element | null;
}

export const ThemeContainerContext = createContext<ThemeContext | null>(null);

/**
 * Why need to store portalContainer in context instead of using document.body or document.querySelector('#root')?
 * In order to deal with the problem that in the App Proxy scenario, document cannot obtain the app root inside the shadow dom, and we need to hang the styles and tokens to the app root.
 */
export const usePortalContainer = () => {
  const context = useContext(ThemeContainerContext);
  const isSSR = useIsSSR();

  if (!context) {
    throw new Error('usePortalContainer must be used within a ThemeProvider');
  }

  if (!context.portalContainer) {
    return !isSSR ? document.body : undefined;
  }

  return context.portalContainer;
};
