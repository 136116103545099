import { t } from 'i18next';
import React from 'react';

import { Box, Flex, Icon, Link, RightChevron, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { Divider } from '@/components/Divider';
import { NonReturnableTitleText } from '@/features/preview/components/WithPreviewSection';
import { NonReturnableItem } from '@/features/request-returns/components/NonReturnableItem';
import { NonReturnableItemProps } from '@/features/request-returns/components/NonReturnableItem/NonReturnableItem';
import { NonReturnableContainerStyle } from '@/features/request-returns/components/NonReturnableList/styles.css.ts';
import { useGetPrefiedLink } from '@/features/returns/hooks/useUniversalRouting';

export interface NonReturnableListProps {
  policy: {
    policyEnable: boolean;
    policyUrl?: string;
  };
  nonReturnableItems: Array<NonReturnableItemProps>;
}
const NonReturnableList = ({
  policy: { policyEnable, policyUrl },
  nonReturnableItems,
}: NonReturnableListProps) => {
  const policyInternalLink = useGetPrefiedLink('/return-policy');

  let url;
  if (policyEnable && !!policyUrl) {
    url = policyUrl;
  } else if (!policyEnable) {
    url = policyInternalLink;
  }

  return (
    <Flex direction={'column'} gap={SpacingVars['4']} marginTop={SpacingVars['2']}>
      <Flex direction={'column'}>
        <NonReturnableTitleText variant={'title3'}>
          {t('page.request.nonReturnableTitle')}
        </NonReturnableTitleText>
        {url && (
          <Flex direction={'row'} alignItems={'center'}>
            <Link href={url} target={'_blank'}>
              <Text variant={'subtitle2'} color={ColorVars.Grey.Grey1200}>
                {t('page.landing.viewFullPolicy')}
              </Text>
            </Link>
            <Icon source={RightChevron} color={ColorVars.Grey.Grey1200} />
          </Flex>
        )}
      </Flex>
      <Box className={NonReturnableContainerStyle} padding={SpacingVars['4']}>
        {nonReturnableItems.map((item, index, array) => (
          <React.Fragment key={item.itemId}>
            <NonReturnableItem {...item} />
            {index !== array.length - 1 && <Divider spacing={SpacingVars['4']} />}
          </React.Fragment>
        ))}
      </Box>
    </Flex>
  );
};

export default NonReturnableList;
