import { IconSource } from './type';

const Cross: IconSource = () => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.913 11.477a0.583 0.583 0 1 1 -0.825 -0.825l2.87 -2.869L4.087 4.913a0.583 0.583 0 1 1 0.827 -0.827l2.869 2.87 2.869 -2.87a0.583 0.583 0 0 1 0.825 0.825l-2.869 2.869 2.869 2.869a0.583 0.583 0 0 1 -0.825 0.825l-2.869 -2.869z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Cross;
