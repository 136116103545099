import type { NotMatchingOrderFormType } from 'returns-logics';

import { useMemoGiftReturn } from './useMemoGiftReturn';

export const useSubmitGiftReturnFormNotMatch = () => {
  const giftReturnSubFlow = useMemoGiftReturn();

  const submit = (data: NotMatchingOrderFormType) => {
    giftReturnSubFlow?.dispatch?.({
      type: 'submitFormWithoutOrderInfo',
      data,
    });
  };

  const isLoading = giftReturnSubFlow?.matches({
    formWithoutOrderInfo: { submitFormWithoutOrderInfo: 'loading' },
  });

  return {
    submit,
    isLoading,
  };
};
