import {
  Box,
  Card,
  CardSection,
  Flex,
  Icon,
  Pressable,
  ProgressBar,
  RightChevron,
  Text,
} from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars, StrokeWidthVars } from '@aftership/design-tokens';
import { usePreviewContext } from '@aftership/preview-kit/client';
import { ReturnItemType } from 'returns-logics';
import { useMainFlowContext } from 'returns-logics/react';

import ImageList from '@/components/ImageList';
import { ReturnItemTitleText } from '@/features/preview/components/WithPreviewSection';
import { useRefundStatus } from '@/features/returns-list/hooks/useRefundStatus';
import { useReturnTitle } from '@/features/returns-list/hooks/useReturnTitle';
import { getCurrentProgress, getTotalProgress } from '@/features/returns-list/utils/getProgress';
import useDevice from '@/hooks/useDevice';

interface ReturnItemProps {
  item: ReturnItemType;
}

const ReturnItem = ({ item }: ReturnItemProps) => {
  const isMobile = useDevice().mobile;
  const mainFlowRef = useMainFlowContext();
  const covers = item.items.map((item) => item?.image?.src);

  const title = useReturnTitle({
    returnStatus: item.status,
    shippingStatus: item.shipping_status || undefined,
    returnMethodSlug: item.return_method_slug,
    dropoffStatus: item.dropoff_status,
  });
  const totalProgress = getTotalProgress(item.return_method_slug);
  const currentProgress = getCurrentProgress({
    returnStatus: item.status,
    shippingStatus: item.shipping_status || undefined,
    returnMethodSlug: item.return_method_slug,
    dropoffStatus: item.dropoff_status,
  });
  const { label: refundLabel, hidden: hiddenRefundLabel } = useRefundStatus({
    resolution: item.refunds?.[0]?.destination,
    amount: item.refunds?.[0]?.refunded_total?.amount,
    currency: item.refunds?.[0]?.refunded_total?.currency,
    isRefunded: item.refunded || false,
  });

  const handleDetail = () => {
    mainFlowRef.send({ type: 'GO_TO_REDETAIL', data: { rmaId: item.rma_id } });
  };

  const { isPreview } = usePreviewContext();

  return (
    <Pressable onPress={handleDetail} isDisabled={isPreview}>
      <Card width='100%' padding={SpacingVars['4']}>
        <CardSection>
          <Flex direction='column'>
            <Flex paddingBottom={SpacingVars['3']}>
              <ImageList list={covers} />
              <Box flex={1} />
              <Icon
                source={RightChevron}
                color={ColorVars['Grey']['Grey 1100']}
                size={SpacingVars['4']}
              />
            </Flex>
            <Text
              variant='caption1'
              color={ColorVars['Grey']['Grey 1100']}
              paddingBottom={SpacingVars['1']}
            >
              RMA #{item.rma_id}
            </Text>
            {!hiddenRefundLabel && (
              <Box
                alignSelf='start'
                padding={
                  isMobile
                    ? `${SpacingVars['1']} ${SpacingVars['3']}`
                    : `${StrokeWidthVars['2']} ${SpacingVars['4']}`
                }
                marginBottom={SpacingVars['2']}
                backgroundColor={ColorVars['Grey']['Grey 200']}
                borderRadius={isMobile ? RadiusVars['2'] : RadiusVars['4']}
              >
                <Text variant='body2' color={ColorVars['Grey']['Grey1200']}>
                  {refundLabel}
                </Text>
              </Box>
            )}
            <Box marginBottom={SpacingVars['2']}>
              <ReturnItemTitleText
                variant={isMobile ? 'subtitle1' : 'title3'}
                color={ColorVars['Grey']['Grey1200']}
              >
                {title}
              </ReturnItemTitleText>
            </Box>
            <ProgressBar
              fullWidth
              showIndeterminate
              value={currentProgress}
              maxValue={totalProgress}
            />
          </Flex>
        </CardSection>
      </Card>
    </Pressable>
  );
};

export default ReturnItem;
