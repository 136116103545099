import i18next, { Resource } from 'i18next';
import { AppContext } from 'next/app';
import { initReactI18next } from 'react-i18next/initReactI18next';

import { Shops } from 'returns-logics';

import getInitialProps from '@/features/returns/resources/getInitialProps';
import { resourceNotAvailableError } from '@/i18n/utils';
import { isIndexJsonUpdate } from '@/utils/common';

import { fetchLocalLanguageResource, loadI18NextResource } from './dynamic';
import { skipTranslatePlugin } from './plugins';
import { I18NEXT_NAMESPACE } from './utils';

/**
 * @description 初始化 SSR i18next
 * @info 服务端每次请求的客户不一样，需要使用不同的 i18next 实例
 */
const initI18NextForSSR = async (lng: string, resources: Resource, fallbackLng?: string) => {
  const i18nInstance = i18next.createInstance();
  await i18nInstance
    .use(skipTranslatePlugin({ skipTranslateLang: fallbackLng }))
    .use(initReactI18next)
    // @ts-ignore
    // meerkat 的类型定义影响到了 i18next
    .init({
      postProcess: ['skipTranslatePlugin'],
      lng: lng,
      ns: [I18NEXT_NAMESPACE],
      defaultNS: I18NEXT_NAMESPACE,
      resources,
      fallbackLng,
      interpolation: {
        escapeValue: false,
        prefix: '${',
        suffix: '}',
      },
    });
  return i18nInstance;
};

/**
 * @description 初始化<<服务端组件>> i18next
 * @info Server Component 只会运行在服务端（SSR）
 */
export async function init18NextForServerComponent(
  orgId: string,
  lng: string,
  defaultLang: string,
  appContext: AppContext,
  initProps: Awaited<ReturnType<typeof getInitialProps>>['initialProps'],
) {
  let resources: Resource = {};
  try {
    // 如果是 index.json 更新，则不需要再次获取 translation，因为客户端不会重复初始化 i18n
    if (!isIndexJsonUpdate(appContext.ctx.req)) {
      resources = await loadI18NextResource(orgId, lng);
    }
  } catch (error) {
    // 加载本地多语言资源，不然 error page 会显示 key
    resources = {
      [lng]: { shopper: await fetchLocalLanguageResource(lng) },
    };
    resourceNotAvailableError(
      appContext,
      error instanceof Error ? error : new Error('Fail to load i18Next resource'),
      (returnsPageAccess) => (initProps.returnsPageAccess = returnsPageAccess),
    );
  }
  const i18n = await initI18NextForSSR(lng, resources, defaultLang);
  return { i18n, resources };
}

export const getServerSideTranslation = async (
  shopInfo: Shops,
  initialLang: string,
  appContext: AppContext,
  initProps: Awaited<ReturnType<typeof getInitialProps>>['initialProps'],
) => {
  const { i18n, resources } = await init18NextForServerComponent(
    shopInfo.organization?.id,
    initialLang,
    shopInfo.default_language,
    appContext,
    initProps,
  );

  return {
    t: i18n.getFixedT(initialLang),
    i18n,
    resources,
  };
};
