import { ClientRenderer, RuntimeProvider } from '@aftership/atta-engine/renderer';

import { AttaPreviewer } from './AttaPreviewer';
import { loader } from './loader';
import { AttaProps, PathName } from './type';

import { useSyncXStateAndRoute } from '../returns/hooks/useSyncXStateAndRoute';

const emptyLoader = async () => () => null;

export const AttaRenderer: React.FC<{ attaProps: AttaProps; name: PathName; pageProps: any }> = ({
  attaProps,
  name,
  pageProps,
}) => {
  useSyncXStateAndRoute();

  if (attaProps.preview) {
    return <AttaPreviewer schema={attaProps.schema[name]} renderProps={pageProps}></AttaPreviewer>;
  }

  return (
    <RuntimeProvider
      code={attaProps.code[name]}
      deps={attaProps.deps[name]}
      loader={emptyLoader}
      syncLoader={loader}
    >
      <ClientRenderer lazy={false} renderProps={pageProps}></ClientRenderer>
    </RuntimeProvider>
  );
};
