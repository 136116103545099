import { IconSource } from './type';

const Instagram: IconSource = () => (
  <svg width={36} height={36} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)'>
      <path
        d='M22 10h-8c-2.2 0-4 1.8-4 4v8c0 2.2 1.8 4 4 4h8c2.2 0 4-1.8 4-4v-8c0-2.2-1.8-4-4-4m-4 11.333a3.333 3.333 0 1 1 0-6.667 3.333 3.333 0 0 1 0 6.667m4.333-6.666a1 1 0 1 1 0-2 1 1 0 0 1 0 2'
        fill='currentColor'
        fillOpacity={0.95}
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='currentColor' d='M10 10h16v16H10z' />
      </clipPath>
    </defs>
  </svg>
);

export default Instagram;
