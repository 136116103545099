import { IconSource } from '../../Icon/type';

const ErrorIcon: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 18' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.69378 7.39083L2 14.2594V2.04844C2.04688 1.72969 2.29531 1.5 2.61406 1.5H11.6746L13.0592 0H1.8125C1.08125 0 0.5 0.595313 0.5 1.32656V16.6734C0.5 17.4047 1.08125 18 1.8125 18H6.30294L12.3029 12L7.75147 7.44853L7.69378 7.39083ZM13.4967 9.79967L14.8485 11.1515L15.6971 12L14.8485 12.8485L9.69706 18H20.1875C20.9188 18 21.5 17.4047 21.5 16.6734V1.32656C21.5 0.595313 20.9188 0 20.1875 0H16.3254L14.9408 1.5H19.3812C19.7047 1.5 19.9672 1.73906 19.9859 2.0625L20 14.2687L15.3547 9.26719C15.2141 9.10313 14.9984 8.97656 14.7547 8.97656C14.5156 8.97656 14.3469 9.08906 14.1547 9.24375L13.4967 9.79967ZM16.25 7.5C17.4922 7.5 18.5 6.49219 18.5 5.25C18.5 4.00781 17.4922 3 16.25 3C15.0078 3 14 4.00781 14 5.25C14 6.49219 15.0078 7.5 16.25 7.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ErrorIcon;
