import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';

import MobilePopup from '@/components/MobilePopup';
import { wordBreakMetaStyle } from '@/features/return-detail/styles/text.css';
import useDevice from '@/hooks/useDevice';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

export interface RemoveItemModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onRemove: VoidFunction;
}

// TODO 多语言
const RemoveItemModal = ({ isOpen, onClose, onRemove }: RemoveItemModalProps) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();

  const Container = () => {
    return (
      <Flex justifyContent='center' alignItems='center'>
        <Text variant='body1' className={wordBreakMetaStyle}>
          {t('v2.remove.item.desc')}
        </Text>
      </Flex>
    );
  };

  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button size='large' onPress={onRemove}>
          {t('page.request.remove')}
        </Button>
        <Button size='large' variant='secondary' onPress={onClose}>
          {t('popup.request.cancel')}
        </Button>
      </ModalButtonGroup>
    );
  };
  if (mobile) {
    return (
      <MobilePopup
        title={t('v2.remove.item.title')}
        isOpen={isOpen}
        onClose={onClose}
        footer={<Footer />}
      >
        <Container />
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('v2.remove.item.title')} onClose={onClose} />

      <ModalBody>
        <Container />
      </ModalBody>

      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};

export default RemoveItemModal;
