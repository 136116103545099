'use client';

import { skeletonItemsRecipe } from './Skeleton.css';
import { SkeletonItemProps } from './type';

import { clsx } from '../../utils/clsx';
import { useStyleProps, viewStyleProps } from '../../utils/styleProps';

const SkeletonItem = ({ animated, className, children, ...rest }: SkeletonItemProps) => {
  const { styleProps } = useStyleProps(rest, viewStyleProps);
  return (
    <div className={clsx(skeletonItemsRecipe({ animated }), className)} {...styleProps}>
      {children}
    </div>
  );
};

export default SkeletonItem;
