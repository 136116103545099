import React from 'react';

import { Box, DimensionValue, Flex, Icon, Text, Warning } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

export interface Props {
  tips: string;
  color?: string;
  alignItems?: 'center' | 'start';
  marginTop?: DimensionValue;
}
const WarningTips = ({
  tips,
  color = ColorVars.Grey.Grey1200,
  alignItems = 'center',
  marginTop = 0,
}: Props) => {
  return (
    <Flex gap={SpacingVars['2']} alignItems={alignItems} marginTop={marginTop}>
      <Box paddingY={'2px'}>
        <Icon source={Warning} color={color} />
      </Box>
      <Text variant={'body2'} color={ColorVars.Grey.Grey1200}>
        {tips}
      </Text>
    </Flex>
  );
};

export default WarningTips;
