/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  setup,
} from 'xstate';

import {
  ExchangeInAppChildren,
  ExchangeInAppContext,
  ExchangeInAppEvent,
  ExchangeInAppInput,
  ExchangeInAppOutput,
} from './types';

import { CollectionValue, MAX_ADD_QUANTITY } from '../../constant/exchangeInApp';
import { Resolution } from '../../constant/refund';
import {
  exchangeCalculation,
  getAllProducts,
  getCollectionListings,
  getCollectionProducts,
  getProductDetails,
  getRecommendedProducts,
} from '../../promise/exchangeInApp';
import { ErrorResponse } from '../../request/ErrorResponse';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils';

export const exchangeInAppSubFlow = setup({
  types: {
    context: {} as ExchangeInAppContext,
    input: {} as ExchangeInAppInput,
    events: {} as ExchangeInAppEvent,
    output: {} as ExchangeInAppOutput,
    children: {} as ExchangeInAppChildren,
  },
  actors: {
    exchangeCalculation,
    getAllProducts,
    getCollectionProducts,
    getRecommendedProducts,
    getProductDetails,
    getCollectionListings,
  },
}).createMachine({
  initial: 'beforeInit',
  context: ({ input }) => ({
    ...input,
    page: 1,
    keyword: '',
    products: [],
    productMap: {},
    collectionId: input.shouldDisplayRecommendedProduct
      ? CollectionValue.Recommend
      : CollectionValue.ALL,
    collectionOptions: [],
    exchangeItems: [],
    cartItems: [],
    haveNextPage: false,
  }),
  output: ({ context }) => ({
    exchangeItems: context.exchangeItems.map((item) => ({
      productId: item.external_product_id,
      variantId: item.external_variant_id,
      quantity: item.quantity,
      productTitle: item.detail.title,
      variantTitle: item.variant.title,
      productCoverUrl: item.detail.image_urls ? item.detail.image_urls[0] : '',
      resolution: Resolution.ExchangeForAnything,
      price: item.variant.price,
      originPrice: item.variant.compare_at_price,
      maxQuantity: item.variant.allow_backorder
        ? MAX_ADD_QUANTITY
        : item.variant.available_quantity,
    })),
  }),
  states: {
    beforeInit: { initial: 'loading', states: { loading: {} } },
    init: {
      type: 'parallel',
      states: {
        loadProducts: {
          initial: 'decision',
          states: {
            decision: {
              always: [
                {
                  guard: ({ context }) => context.collectionId === CollectionValue.ALL,
                  target: 'getAllProducts',
                },
                {
                  guard: ({ context }) => context.collectionId === CollectionValue.Recommend,
                  target: 'getRecommendedProducts',
                },
                { target: 'getCollectionProducts' },
              ],
            },
            getAllProducts: {
              description: '获取全部商品',
              initial: 'loading',
              states: {
                loading: {
                  tags: 'loading',
                  invoke: {
                    src: 'getAllProducts',
                    input: ({ context }) => ({
                      token: context.token,
                      q: context.keyword,
                      page: context.page,
                    }),
                    onDone: {
                      target: 'success',
                      actions: assign({
                        products: ({ context, event }) => [
                          ...context.products,
                          ...(event.output?.products || []),
                        ],
                        haveNextPage: ({ event }) =>
                          event.output?.pagination.has_next_page || false,
                      }),
                    },
                    onError: [
                      {
                        guard: ({ event }) =>
                          isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                        actions: enqueueActions(({ enqueue }) => {
                          enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                            type: 'HANDLE_JWT_ERROR',
                          });
                        }),
                      },
                      { target: 'error' },
                    ],
                  },
                },
                success: { type: 'final' },
                error: { tags: 'error' },
              },
            },
            getCollectionProducts: {
              description: '获取 collectionId 下的商品',
              initial: 'loading',
              states: {
                loading: {
                  tags: 'loading',
                  invoke: {
                    src: 'getCollectionProducts',
                    input: ({ context }) => ({
                      token: context.token,
                      collectionId: context.collectionId,
                      page: context.page,
                    }),
                    onDone: {
                      target: 'success',
                      actions: assign({
                        products: ({ context, event }) => [
                          ...context.products,
                          ...(event.output?.products || []),
                        ],
                        haveNextPage: ({ event }) =>
                          event.output?.data?.pagination.has_next_page || false,
                      }),
                    },
                    onError: [
                      {
                        guard: ({ event }) =>
                          isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                        actions: enqueueActions(({ enqueue }) => {
                          enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                            type: 'HANDLE_JWT_ERROR',
                          });
                        }),
                      },
                      { target: 'error' },
                    ],
                  },
                },
                success: { type: 'final' },
                error: { tags: 'error' },
              },
            },
            getRecommendedProducts: {
              description: '获取推荐商品',
              initial: 'loading',
              states: {
                loading: {
                  tags: 'loading',
                  invoke: {
                    src: 'getRecommendedProducts',
                    input: ({ context }) => ({
                      token: context.token,
                      returnItemIds: context.returnItems.map((item) => item.itemId),
                    }),
                    onDone: {
                      target: 'success',
                      actions: assign({
                        products: ({ event }) => event.output?.products || [],
                        haveNextPage: false,
                      }),
                    },
                    onError: [
                      {
                        guard: ({ event }) =>
                          isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                        actions: enqueueActions(({ enqueue }) => {
                          enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                            type: 'HANDLE_JWT_ERROR',
                          });
                        }),
                      },
                      { target: 'error' },
                    ],
                  },
                },
                success: { type: 'final' },
                error: { tags: 'error' },
              },
            },
          },
        },
        getCollectionListings: {
          description: '获取 collection 列表',
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getCollectionListings',
                input: ({ context }) => ({ token: context.token }),
                onDone: {
                  target: 'success',
                  actions: assign({
                    collectionOptions: ({ event }) =>
                      (event.output?.collections || []).map((item) => ({
                        label: item.title,
                        value: item.id,
                      })),
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  { target: 'error' },
                ],
              },
            },
            success: { type: 'final' },
            error: { tags: 'error' },
          },
        },
        exchangeCalculation: {
          description: '计算 exchange 金额',
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'exchangeCalculation',
                input: ({ context }) => ({
                  token: context.token,
                  storePlatform: context.storePlatform,
                  storeKey: context.storeKey,
                  orgId: context.orgId,
                  returnItems: context.returnItems.map((item) => ({
                    external_item_id: item.itemId,
                    quantity: item.quantity,
                  })),
                  exchangeItems: context.exchangeItems,
                }),
                onDone: {
                  target: 'success',
                  actions: assign({
                    refundInfo: ({ event }) => event.output?.refund,
                    exchangeInfo: ({ event }) => event.output?.exchange,
                    totalCredit: ({ event }) => event.output?.exchange.credit_total,
                    extraCredit: ({ event }) => event.output?.exchange.extra_credit,
                    preDiscountCredit: ({ event }) => event.output?.exchange.pre_discount_credit,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  { target: 'error' },
                ],
              },
            },
            success: { type: 'final' },
            error: { tags: 'error' },
          },
        },
      },
    },
    loadProducts: {
      description: '根据 collectionId 来选择加载的数据',
      always: [
        {
          guard: ({ context }) => context.collectionId === CollectionValue.ALL,
          target: 'getAllProducts',
        },
        {
          guard: ({ context }) => context.collectionId === CollectionValue.Recommend,
          target: 'getRecommendedProducts',
        },
        { target: 'getCollectionProducts' },
      ],
    },
    loadMore: {
      always: [
        {
          guard: ({ context }) =>
            context.collectionId === CollectionValue.ALL && context.haveNextPage,
          target: 'getAllProducts',
          actions: assign({ page: ({ context }) => context.page + 1 }),
        },
        {
          guard: ({ context }) =>
            context.collectionId !== CollectionValue.Recommend && context.haveNextPage,
          target: 'getCollectionProducts',
          actions: assign({ page: ({ context }) => context.page + 1 }),
        },
      ],
    },
    getAllProducts: {
      description: '获取全部商品',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getAllProducts',
            input: ({ context }) => ({
              token: context.token,
              q: context.keyword,
              page: context.page,
            }),
            onDone: {
              target: 'success',
              actions: assign({
                products: ({ context, event }) => [
                  ...context.products,
                  ...(event.output?.products || []),
                ],
                haveNextPage: ({ event }) => event.output?.pagination.has_next_page || false,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    getCollectionProducts: {
      description: '获取 collectionId 下的商品',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getCollectionProducts',
            input: ({ context }) => ({
              token: context.token,
              collectionId: context.collectionId,
              page: context.page,
            }),
            onDone: {
              target: 'success',
              actions: assign({
                products: ({ context, event }) => [
                  ...context.products,
                  ...(event.output?.products || []),
                ],
                haveNextPage: ({ event }) => event.output?.data?.pagination.has_next_page || false,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    getRecommendedProducts: {
      description: '获取推荐商品',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getRecommendedProducts',
            input: ({ context }) => ({
              token: context.token,
              returnItemIds: context.returnItems.map((item) => item.itemId),
            }),
            onDone: {
              target: 'success',
              actions: assign({
                products: ({ event }) => event.output?.products || [],
                haveNextPage: false,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    getCollectionListings: {
      description: '获取 collection 列表',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getCollectionListings',
            input: ({ context }) => ({ token: context.token }),
            onDone: {
              target: 'success',
              actions: assign({
                collectionOptions: ({ event }) =>
                  (event.output?.collections || []).map((item) => ({
                    label: item.title,
                    value: item.id,
                  })),
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    getProductDetails: {
      description: '获取商品详情, externalIds 可传入多个商品 id',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'getProductDetails',
            input: ({ event, context }) => {
              assertEvent(event, 'LOAD_PRODUCT_DETAILS');
              return { token: context.token, externalIds: event.data.externalIds };
            },
            onDone: {
              target: 'success',
              actions: assign({
                productMap: ({ event }) =>
                  (event.output?.products || []).reduce(
                    (acc, item) => ({ ...acc, [item.external_id]: item }),
                    {},
                  ),
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    exchangeCalculation: {
      description: '计算 exchange 金额',
      initial: 'loading',
      states: {
        loading: {
          tags: 'loading',
          invoke: {
            src: 'exchangeCalculation',
            input: ({ context }) => ({
              token: context.token,
              storePlatform: context.storePlatform,
              storeKey: context.storeKey,
              orgId: context.orgId,
              returnItems: context.returnItems.map((item) => ({
                external_item_id: item.itemId,
                quantity: item.quantity,
              })),
              exchangeItems: context.exchangeItems,
            }),
            onDone: {
              target: 'success',
              actions: assign({
                refundInfo: ({ event }) => event.output?.refund,
                exchangeInfo: ({ event }) => event.output?.exchange,
                totalCredit: ({ event }) => event.output?.exchange.credit_total,
                extraCredit: ({ event }) => event.output?.exchange.extra_credit,
                preDiscountCredit: ({ event }) => event.output?.exchange.pre_discount_credit,
              }),
            },
            onError: [
              {
                guard: ({ event }) =>
                  isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                actions: enqueueActions(({ enqueue }) => {
                  enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                    type: 'HANDLE_JWT_ERROR',
                  });
                }),
              },
              { target: 'error' },
            ],
          },
        },
        success: { type: 'final' },
        error: { tags: 'error' },
      },
    },
    done: {
      type: 'final',
    },
  },
  on: {
    INIT: { target: '.init' },
    SET_COLLECTION_ID: {
      actions: assign({
        collectionId: ({ event }) => event.data.collectionId,
        products: [],
        page: 1,
      }),
      target: '.loadProducts',
    },
    SET_KEYWORD: {
      actions: assign({
        collectionId: CollectionValue.ALL,
        keyword: ({ event }) => event.data.keyword,
        products: [],
      }),
      target: '.loadProducts',
    },
    SET_EXCHANGE_ITEMS: {
      actions: assign({
        exchangeItems: ({ event }) =>
          event.data.exchangeItems.map((item) => {
            const maxQuantity = item.variant.allow_backorder
              ? MAX_ADD_QUANTITY
              : item.variant.available_quantity;
            return {
              ...item,
              maxQuantity,
              quantity: Math.min(item.quantity, maxQuantity),
            };
          }),
      }),
      target: '.exchangeCalculation',
    },
    LOAD_PRODUCT_DETAILS: { target: '.getProductDetails' },
    LOAD_MORE: { target: '.loadMore' },
    GO_NEXT: { target: '.done' },
  },
});

export type ExchangeInAppSubFlowActorRef = ActorRefFrom<typeof exchangeInAppSubFlow>;
export type ExchangeInAppSubFlowSnapshot = SnapshotFrom<typeof exchangeInAppSubFlow>;
