import { useRouter } from 'next/router';

import ReturnsPage from '@/features/returns/components/ReturnsPage';

import { AttaRenderer } from './AttaRenderer';
import { PathName } from './type';

import { useReturns } from '../returns/hooks/useReturns';

export { getServerSideAttaProps } from './getServerSideAttaProps';
export * from './type';

/**
 * 页面路径与页面组件的映射
 * 在非 Atta 渲染时，会直接根据当前 router.pathname 直接渲染页面组件
 */
const routerPathMap = new Map<PathName, React.FC<any>>();

const AttaWrapper = (props: any) => {
  const shopInfo = useReturns();
  const attaProps = shopInfo?.attaProps;

  const router = useRouter();
  const pathName = router.pathname as PathName;

  const Page = routerPathMap.get(pathName);

  if (!attaProps) {
    return <ReturnsPage>{Page && <Page {...props} />}</ReturnsPage>;
  } else {
    return <AttaRenderer attaProps={attaProps} name={pathName} pageProps={props} />;
  }
};

AttaWrapper.getLayout = (page: React.ReactElement) => <>{page}</>;

export function supportAtta(page: React.FC<any>, name: PathName) {
  routerPathMap.set(name, page);
  return AttaWrapper;
}
