import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Banner, Button } from '@aftership/design-system';
import { ErrorCode } from 'returns-logics';

import { useReturnPreviewFlow } from '../../hooks/useReturnPreviewFlow';

const LabelRateUnavailableBanner = () => {
  const { t } = useTranslation();
  const { reviewError, returnPreviewFlowMatches, returnPreviewFlowDispatch, mainDispatch } =
    useReturnPreviewFlow();
  const isPostReviewError = !!returnPreviewFlowMatches?.({
    fetchReviewDatas: {
      postReturnPreviewActor: 'error',
    },
  });

  const costOfReturnText = t('page.description.costOfReturns');
  const refreshPage = t('page.action.refreshPage');
  const changeMethod = t('page.action.changeReturnMethod');

  const showLabelCostError = useMemo(
    () => isPostReviewError && reviewError?.code === ErrorCode.ReturnLabelRateUnavailable,
    [isPostReviewError, reviewError?.code],
  );

  return (
    showLabelCostError && (
      <Banner
        info={
          <Trans
            i18nKey='page.description.getCostOfReturnsFailure'
            values={{
              costOfReturn: costOfReturnText,
              refreshPage,
              changeMethod,
            }}
            components={[
              <Button
                key='page.action.refreshPage'
                variant='link'
                onPress={() => {
                  returnPreviewFlowDispatch?.({ type: 'REFETCH_PREVIEW' });
                }}
              >
                {refreshPage}
              </Button>,
              <Button
                key='page.action.changeReturnMethod'
                variant='link'
                onPress={() => {
                  mainDispatch?.({ type: 'GO_TO_RETURN_METHOD' });
                }}
              >
                {changeMethod}
              </Button>,
            ]}
          />
        }
        variant='critical'
      />
    )
  );
};

export default LabelRateUnavailableBanner;
