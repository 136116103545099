import { useFlow } from 'returns-logics/react';

import { useReturnMethodSubFlow } from './useReturnMethodSubFlow';

export const useReturnMethodPage = () => {
  const mainFlow = useFlow();
  const returnMethodFlow = useReturnMethodSubFlow();
  const { context, currentStep } = returnMethodFlow || {};

  return {
    isLoading: currentStep?.isLoading,
    returnMethods: context?.returnMethods ?? [],
    dropoffLocations: context?.dropoffLocations ?? [],
    showMerchantModeOverrideBanner: !!mainFlow.context?.orderLookup?.isMerchantMode,

    handleGoBack: () => {
      mainFlow.dispatch({ type: 'GO_TO_RESOLUTION' });
    },
  };
};
