import { useMemo, useRef } from 'react';

export function useFieldAWithMemo<T>(fieldA: T): T | undefined {
  const previousFieldA = useRef<T>();
  return useMemo(() => {
    if (fieldA !== null && fieldA !== undefined) {
      previousFieldA.current = fieldA;
    }
    return previousFieldA.current;
  }, [fieldA]);
}
