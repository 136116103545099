import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import SectionLayout, { SummarySectionName } from './SectionLayout';

const CostOfReturnSection = ({ costOfReturnValue }: { costOfReturnValue: string }) => {
  const { t } = useTranslation();

  return (
    costOfReturnValue && (
      <Flex direction='column' gap={SpacingVars['1']}>
        <SectionLayout name={SummarySectionName.CostOfReturn}>
          <Text variant='body1'>{t('page.description.costOfReturns')}</Text>
          <Text variant='body1'>{costOfReturnValue}</Text>
        </SectionLayout>
      </Flex>
    )
  );
};

export default CostOfReturnSection;
