'use client';

import { DialogTrigger as ModalTrigger } from 'react-aria-components';

import { default as ModalCloseButton } from './CloseButton';
import { default as ModalBody } from './ModalBody';
import { default as ModalFooter } from './ModalFooter';
import { default as ModalHeader } from './ModalHeader';

export { default as Modal } from './Modal';
export { ModalTrigger, ModalHeader, ModalBody, ModalFooter, ModalCloseButton };
export * from './ModalTokens.css';
