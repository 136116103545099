import { QuestionWorkFlowItem, ReturnImage, getNextSnapshot } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

export const extractQuestionInfo = (questionWorkFlowItem?: QuestionWorkFlowItem) => {
  if (questionWorkFlowItem) {
    const { id, question, answer_for_terminate_flow, answer_for_continue_flow } =
      questionWorkFlowItem;
    const questionId = id ?? '';
    const questionTitle = question ?? '';
    const primaryBtnText =
      answer_for_terminate_flow && answer_for_terminate_flow.length
        ? answer_for_terminate_flow
        : 'Yes';
    const secondaryBtnText =
      answer_for_continue_flow && answer_for_continue_flow.length ? answer_for_continue_flow : 'No';
    return { questionId, questionTitle, primaryBtnText, secondaryBtnText };
  }
};

export const useReturnInfoModal = (itemId: string) => {
  const { children } = useFlow();

  const { dispatch, context, currentStep, snapshot, matches, on } =
    children?.itemSelectionSubFlow?.children?.[itemId] || {};

  // 是否限制每次退货只能退一种商品
  const isLimitSingleItemPerReturn =
    Boolean(context?.shopEligibilityRules?.product_limit?.feature_applied) &&
    Boolean(context?.shopEligibilityRules?.product_limit?.limit_single_item_per_return?.enabled);
  // 是否限制每次退货数量为 1
  const isLimitQuantityExactlyOne =
    isLimitSingleItemPerReturn &&
    Boolean(
      context?.shopEligibilityRules?.product_limit?.limit_single_item_per_return
        ?.quantity_exactly_one,
    );

  const questionInfo = extractQuestionInfo(context?.questionWorkFlowItem);
  const canBack =
    !!snapshot &&
    getNextSnapshot(snapshot?.machine, snapshot, {
      type: 'BACK',
    }).value !== currentStep?.name;
  return {
    questionInfo,
    isLimitQuantityExactlyOne,
    context,
    currentStep,
    snapshot,
    matches,
    selectQuantity: (quantity: number) => {
      dispatch?.({
        type: 'SELECT_QUANTITY',
        data: {
          quantity: quantity,
        },
      });
    },
    selectReason: (reasonId: string) => {
      dispatch?.({
        type: 'SELECT_REASON',
        data: {
          selectedReasonId: reasonId,
        },
      });
    },
    selectSubReason: (subReasonId: string) => {
      dispatch?.({
        type: 'SELECT_SUB_REASON',
        data: {
          selectedSubReasonId: subReasonId,
        },
      });
    },

    addToBlockList: (addToBlockList: boolean) => {
      dispatch?.({
        type: 'SELECT_QUESTION_ANSWER',
        data: {
          confirm: addToBlockList,
        },
      });
    },
    fillMoreDetail: (comment?: string, images?: Array<ReturnImage>) => {
      dispatch?.({
        type: 'FILL_MORE_DETAIL',
        data: {
          comment: comment,
          returnImages: images,
        },
      });
    },
    backPreState: () => {
      dispatch?.({
        type: 'BACK',
      });
    },
    cancelFillReturnInfo: () => {
      dispatch?.({
        type: 'CANCEL',
        data: {
          itemId: itemId,
        },
      });
    },
    on,
    canBack,
  };
};
