import React from 'react';

import { Box, Card, Flex } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { HomeCarousel } from '@/features/order-lookup/components/HomeCarousel';
import useDevice from '@/hooks/useDevice';

import { desktopContainerClassName } from './MainPageLayout.css';

interface MainPageLayoutProps {
  children: React.ReactNode;
}

const MainPageLayout = ({ children }: MainPageLayoutProps) => {
  const isMobile = useDevice().mobile;

  if (isMobile) {
    return (
      <Flex flex={1} direction='column'>
        <Box flex={1} />
        <Box
          backgroundColor={ColorVars['B&W'].Background}
          borderTopStartRadius={SpacingVars['6']}
          borderTopEndRadius={SpacingVars['6']}
        >
          <Flex
            minHeight={460}
            paddingTop={SpacingVars['8']}
            paddingBottom={SpacingVars['6']}
            paddingX={SpacingVars['4']}
          >
            {children}
          </Flex>
        </Box>
      </Flex>
    );
  }

  return (
    <Card padding={SpacingVars['8']} className={desktopContainerClassName}>
      <Flex direction='row' gap={SpacingVars['8']} alignItems='center'>
        <Box width={336}>{children}</Box>
        <HomeCarousel />
      </Flex>
    </Card>
  );
};

export default MainPageLayout;
