import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';

import FormInput from '@/components/Form/FormInput';

export type Props = {
  control: Control<any, any>;
};

export const HowContact = ({ control }: Props) => {
  const { t } = useTranslation();
  return (
    <Flex direction='column' gap={8}>
      <Text variant={'subtitle1'}>{t('page.gift.description.howContact')}</Text>
      <Flex gap={8} justifyContent='space-around'>
        <FormInput
          control={control}
          name='recipient.firstName'
          placeholder={t('page.gift.placeholder.firstName')}
          fullWidth={true}
        />
        <FormInput
          control={control}
          name='recipient.lastName'
          placeholder={t('page.gift.placeholder.lastName')}
          fullWidth={true}
        />
      </Flex>
      <FormInput
        control={control}
        name='recipient.email'
        placeholder={t('page.gift.placeholder.email')}
        fullWidth={true}
      />
    </Flex>
  );
};
