'use client';

import React from 'react';

import { Button, Flex, Icon, LeftChevronThick, RightChevronThick } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

export interface PagingStepperProps {
  isPreviousDisabled: boolean;
  isNextDisabled: boolean;
  onPrevious: () => void;
  onNext: () => void;
}

const PagingStepper = (props: PagingStepperProps) => {
  const { isPreviousDisabled, isNextDisabled, onPrevious, onNext } = props;

  return (
    <Flex alignSelf='center' gap={SpacingVars['12']} paddingTop={SpacingVars['10']}>
      <Button isDisabled={isPreviousDisabled} onPress={onPrevious} variant='plain'>
        <Icon
          source={LeftChevronThick}
          color={isPreviousDisabled ? ColorVars['Grey']['Grey 700'] : ColorVars['Grey']['Grey1200']}
        />
      </Button>

      <Button isDisabled={isNextDisabled} onPress={onNext} variant='plain'>
        <Icon
          source={RightChevronThick}
          color={isNextDisabled ? ColorVars['Grey']['Grey 700'] : ColorVars['Grey']['Grey1200']}
        />
      </Button>
    </Flex>
  );
};

export default PagingStepper;
