import { IconSource } from './type';

const Attention: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 15.333A7.333 7.333 0 1 0 8 .667a7.333 7.333 0 0 0 0 14.666m0-12A.667.667 0 0 1 8.667 4v4.667a.667.667 0 1 1-1.333 0V4a.667.667 0 0 1 .667-.667m-1 8.333a1 1 0 1 0 2 0 1 1 0 0 0-2 0'
        fill='currentColor'
      />
    </svg>
  );
};

export default Attention;
