import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request.ts';
import { ResponseBody } from '../request/response';
import { ProductEnhanceQuantity, ProductItem } from '../types/products';

export interface GetAllProductsInput {
  input: {
    token: string;
    returnItems: {
      itemId: string;
      quantity: number;
      productId: string;
      variantId: string;
    }[];
    needComparePrice: boolean;
  };
}

/**
 * 获取所有待 replace item 的 Options 和 variant
 * 对于 KURU 存在 productLinks 的情况,该方法内部处理好
 * 返回外部直接能用的数据结构
 */
export const getAllProducts = fromPromise(
  async ({ input }: GetAllProductsInput): Promise<Record<string, ProductEnhanceQuantity>> => {
    const { returnItems, token, needComparePrice } = input;

    const queryProductById = async (
      productId: string,
      variantId: string,
      needComparePrice: boolean,
    ): Promise<{ parameter_string: string; products: ProductItem[] }> => {
      const uri = needComparePrice
        ? `${v4hostName()}/products?external_ids=${productId}&calculate_online_inventory=true&only_active_products=true&compare_replace_price=true&external_variant_id=${variantId}`
        : `${v4hostName()}/products?external_ids=${productId}&calculate_online_inventory=true&only_active_products=true`;
      return request<ResponseBody<{ parameter_string: string; products: ProductItem[] }>>(uri, {
        method: 'GET',
        headers: {
          'returns-authorization': token,
        },
      }).then((response) => response.data || Promise.reject(response));
    };

    const promises = returnItems?.map(async (item) => {
      const ret = await queryProductById(item.productId, item.variantId, needComparePrice);
      return { itemId: item.itemId, quantity: item.quantity, product: ret.products[0] };
    });
    const results = await Promise.all(promises);

    // 数组 -> Record
    return results.reduce<Record<string, ProductEnhanceQuantity>>(
      (previousValue, currentValue) => {
        previousValue[currentValue.itemId] = {
          ...currentValue.product,
          selectedQuantity: currentValue.quantity,
        };
        return previousValue;
      },
      {} as Record<string, ProductEnhanceQuantity>,
    );
  },
);

export interface GetRecommendProductsInput {
  input: {
    token: string;
    returnItemProductIds: Array<string>;
  };
}
export const getRecommendProducts = fromPromise(
  async ({ input: { token, returnItemProductIds } }: GetRecommendProductsInput) => {
    const uri = `${v4hostName()}/product-recommendations?limit=6&target_product_ids=${returnItemProductIds.join()}`;

    const res = await request<ResponseBody<{ parameter_string: string; products: ProductItem[] }>>(
      uri,
      {
        method: 'GET',
        headers: {
          'returns-authorization': token,
        },
      },
    );
    return res?.data?.products;
  },
);
