import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useStripeKey from '@/features/review/hooks/useStripeKey';
import { stripeLanguagesMap } from '@/i18n/utils';

import CheckoutForm from './CheckoutForm';

interface IStripeElementProps {
  stripeKey: string;
  stripeToken: string;
}

const StripeElement = ({ stripeKey, stripeToken }: IStripeElementProps) => {
  const STRIPE_KEY = useStripeKey();
  const stripePromise = loadStripe(STRIPE_KEY, {
    stripeAccount: stripeKey,
  });

  const { i18n } = useTranslation();

  const options: StripeElementsOptions = useMemo(
    () => ({
      clientSecret: stripeToken,
      appearance: {
        theme: 'stripe',
      },
      locale: stripeLanguagesMap[i18n.language],
    }),
    [stripeToken, i18n],
  );

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  );
};

export default StripeElement;
