'use client';

import { Box, Modal, ModalBody, ModalHeader, Popup } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { useShopInfo } from '@/features/returns/hooks/useShopInfo';
import useDevice from '@/hooks/useDevice';

import RichTextRender from '../../../components/RichTextRender/RichTextRender';
import { clickwrapMobile } from '../css/style.css';

interface IClickWrapModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
  title?: string;
  content?: string;
  isPreview: boolean;
}

const ClickWrapModal = ({
  isOpen,
  onClose,
  title,
  content = '',
  isPreview,
}: IClickWrapModalProps) => {
  const isMobile = useDevice().mobile;

  const shop = useShopInfo();

  const clickWrapContent = (
    <>
      <RichTextRender componentType='style' content={`#clickwrapBody * { margin: revert }`} />
      <RichTextRender
        id='clickwrapBody'
        componentType='div'
        content={content}
        style={{ fontFamily: shop?.returns_page_body_font }}
      />
    </>
  );

  if (isMobile) {
    return (
      <Popup
        width={'100%'}
        isOpen={isOpen}
        title={title}
        disableFocusManagement={isPreview}
        onClose={onClose}
      >
        <Box className={clickwrapMobile}>{clickWrapContent}</Box>
      </Popup>
    );
  }

  return (
    <Modal isOpen={isOpen} disableFocusManagement={isPreview}>
      <ModalHeader onClose={onClose} title={title} />
      <ModalBody>
        <Box maxHeight={'70vh'} paddingX={SpacingVars['6']} overflow='auto'>
          {clickWrapContent}
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default ClickWrapModal;
