import { ISelectedItem, NonReturnableOrderItem, ReturnableOrderItem } from 'returns-logics';

import { NonReturnableItemProps } from '@/features/request-returns/components/NonReturnableItem/NonReturnableItem';
import { SelectedItem } from '@/features/request-returns/types';
import {
  getItemNonRefundableDescription,
  getMerchantModeOverrideReason,
} from '@/features/request-returns/utils/notAllowReturnReasonUtils';

export const convertOderItem2ReturnableItem = (
  item: ReturnableOrderItem,
): RequestReturn.ReturnableItemProps => {
  return {
    itemId: item.external_id,
    returnable: item.returnable,
    overridingReason: getMerchantModeOverrideReason(item.overridingReasons),
    parentId: item.external_parent_id,
    productTitle: item.product_title ?? '',
    productCoverUrl: item.image_urls?.[0],
    variantTitle: item.variant_title,
    price: item.frontEndPrice,
    originPrice: item.base_price_set.presentment_money,
    productTags: item.tags,
    returnableQuantity: item.returnableQuantity,
    type: item.type,
    returnRule: item.return_rule,
    bundles: item.bundledItems?.map((lineItem) => convertOderItem2ReturnableItem(lineItem)),
  };
};
export const convertOderItem2NonReturnableItem = (
  item: NonReturnableOrderItem,
): NonReturnableItemProps => {
  return {
    itemId: item.external_id,
    productTitle: item.product_title,
    productCoverUrl: item.image_urls?.[0],
    variantTitle: item.variant_title,
    reason: getItemNonRefundableDescription(item.reasons),
    price: item.frontEndPrice,
    originPrice: item.base_price_set.presentment_money,
    productTags: item.tags,
    nonReturnableQuantity: item.nonReturnableQuantity,
  };
};
export const convertISelectedItem2SelectedItem = (item: ISelectedItem): SelectedItem => ({
  itemId: item.itemId,
  parentId: item.parentId,
  returnedQuantity: item.quantity,
});
