import React from 'react';

import { Flex } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { PresentmentMoney } from 'returns-logics';

import { ItemCardContent } from '@/components/ItemCardContent';
import useDevice from '@/hooks/useDevice';

import { WarningTips } from '../WarningTips';

export interface NonReturnableItemProps {
  /**
   * 商品id
   */
  itemId: string;
  /**
   * 商品标题
   */
  productTitle: string;
  /**
   * 商品封面链接
   */
  productCoverUrl?: string;
  /**
   * 商品variant标题
   */
  variantTitle?: string;
  /**
   * 商品价格信息
   */
  price?: PresentmentMoney;
  /**
   * 商品的原始价格
   */
  originPrice?: PresentmentMoney;
  /**
   * 商品标签列表
   */
  productTags?: Array<string>;
  reason: string;
  /**
   * 不可退数量
   */
  nonReturnableQuantity: number;
}

const NonReturnableItem = ({
  reason,
  productTags,
  nonReturnableQuantity,
  productCoverUrl,
  productTitle,
  variantTitle,
  price,
  originPrice,
}: NonReturnableItemProps) => {
  const isMobile = useDevice().mobile;
  return (
    <Flex direction={'column'} gap={SpacingVars[isMobile ? '3' : '4']}>
      <div
        style={{
          opacity: '0.5',
        }}
      >
        <ItemCardContent
          productInfo={{
            productTitle: productTitle,
            variantTitle,
            productTags,
            price,
            originPrice,
            productCoverUrl,
            quantity: nonReturnableQuantity,
          }}
        />
      </div>
      <WarningTips tips={reason} color={ColorVars.Grey['Grey 900']} alignItems={'start'} />
    </Flex>
  );
};

export default NonReturnableItem;
