'use client';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import { PropsWithChildren } from 'react';
import { Dialog, Modal, ModalOverlay } from 'react-aria-components';

import { overlayDialogRecipe, overlayMaskRecipe } from './Overlay.css';
import { overlayThemeVars } from './OverlayToken.css';
import { OverlayProps } from './type';

import { clsx } from '../../utils/clsx';
import { usePortalContainer } from '../ThemeProvider/AppContainerContext';

const Overlay = (props: PropsWithChildren<OverlayProps>) => {
  const {
    className,
    maskClassName,
    maskOpacity,
    mask,
    children,
    bodyClassName,
    disableFocusManagement,
    style,
    ...rest
  } = props;

  const DialogElement = disableFocusManagement !== true ? Dialog : 'div';

  const container = usePortalContainer();

  return (
    <ModalOverlay
      style={assignInlineVars({
        [overlayThemeVars.Color.Overlay.Normal_Background]: maskOpacity,
      })}
      className={clsx(overlayMaskRecipe({ mask }), maskClassName)}
      UNSTABLE_portalContainer={container}
      {...rest}
    >
      <Modal className={className} style={style}>
        <DialogElement className={clsx(overlayDialogRecipe(), bodyClassName)}>
          {children}
        </DialogElement>
      </Modal>
    </ModalOverlay>
  );
};

export default Overlay;
