import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import {
  CalculationExchangeInfo,
  CalculationRefundInfo,
  CollectionListingItem,
  ExchangeCalculationInput,
  GetCollectionListingsInput,
  GetCollectionProductsInput,
  GetProductDetailsInput,
  GetRecommendedProductsInput,
  Product,
  ProductDetail,
  ProductListingItem,
  SearchProductsInput,
} from '../types/exchangeInApp';

export const getAllProducts = fromPromise(async ({ input }: { input: SearchProductsInput }) => {
  const { token, q = '', page = 1, limit = 50, sort = '-sales_volume_day30' } = input;
  return await request<ResponseBody<Product[], 'products', true>>(
    `${v4hostName()}/products-search?q=${q}&page=${page}&limit=${limit}&sort=${sort}`,
    { headers: { 'returns-authorization': token } },
  ).then((res) => res?.data);
});

export const getProductDetails = fromPromise(
  async ({ input }: { input: GetProductDetailsInput }) => {
    const { token, externalIds } = input;
    return await request<ResponseBody<{ parameter_string: string; products: ProductDetail[] }>>(
      `${v4hostName()}/products?external_ids=${externalIds}`,
      { headers: { 'returns-authorization': token } },
    ).then((res) => res?.data);
  },
);

export const getCollectionProducts = fromPromise(
  async ({ input }: { input: GetCollectionProductsInput }) => {
    const { token, page, collectionId } = input;
    return await request<ResponseBody<ProductListingItem[], 'products', true>>(
      `${v4hostName()}/products?only_variant_available=true&page=${page}&collection_id=${collectionId}&limit=50`,
      { headers: { 'returns-authorization': token } },
    ).then((res) => ({
      ...res,
      products: res.data?.products ?? [],
    }));
  },
);

export const getRecommendedProducts = fromPromise(
  async ({ input }: { input: GetRecommendedProductsInput }) => {
    const { token, returnItemIds } = input;
    return await request<ResponseBody<Product[], 'products', true>>(
      `${v4hostName()}/product-recommendations?target_product_ids=${returnItemIds.join()}&limit=50`,
      { headers: { 'returns-authorization': token } },
    ).then((res) => res?.data);
  },
);

export const getCollectionListings = fromPromise(
  async ({ input }: { input: GetCollectionListingsInput }) => {
    const { token, page = 1 } = input;
    return await request<ResponseBody<CollectionListingItem[], 'collections', true>>(
      `${v4hostName()}/collections?page=${page}&limit=50`,
      { headers: { 'returns-authorization': token } },
    ).then((res) => res.data);
  },
);

export const exchangeCalculation = fromPromise(
  async ({ input }: { input: ExchangeCalculationInput }) => {
    const {
      token,
      storePlatform,
      storeKey,
      orgId,
      returnItems,
      exchangeItems,
      disabled_cache = true,
    } = input;
    return await request<
      ResponseBody<{ refund: CalculationRefundInfo; exchange: CalculationExchangeInfo }>
    >(`${v4hostName()}/exchange-calculations`, {
      method: 'POST',
      headers: { 'returns-authorization': token },
      payload: {
        app: { platform: storePlatform, key: storeKey },
        organization: { id: orgId },
        items: returnItems,
        exchange: { items: exchangeItems },
        disabled_cache,
      },
    }).then((res) => res.data);
  },
);
