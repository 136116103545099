import { useReturns } from './useReturns';

const defaultAppProxy: Returns.AppProxyInfo = {
  pathPrefix: '',
  shop: '',
};

export function useCachedAppProxy() {
  const sharedData = useReturns();
  return sharedData?.appProxy || defaultAppProxy;
}
