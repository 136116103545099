import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request.ts';

export enum ConditionType {
  OrderItemId = 'order_item_id',
}

export interface AdditionalLabelPayload {
  returnId: string;
  quantity: number;
}

export interface AdditionalLabelInput {
  input: {
    token: string;
    payload: AdditionalLabelPayload;
  };
}
export const addAdditionalLabel = fromPromise(
  async ({ input: { token, payload } }: AdditionalLabelInput) => {
    const uri = `${v4hostName()}/returns/${payload.returnId}/additional-labels`;
    const res = await request(uri, {
      method: 'POST',
      headers: { 'returns-authorization': token },
      payload: { quantity: payload.quantity },
    });
    return res.data;
  },
);
