import React from 'react';
import { useTranslation } from 'react-i18next';

import { Flex } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';
import { useFlow } from 'returns-logics/react';

import { StepCard } from '@/components/StepCard';
import { RequestReturnsCardTitleText } from '@/features/preview/components/WithPreviewSection';
import RequestReturns from '@/features/request-returns';
import { useStepCardMinHeight } from '@/hooks/useStepCardMinHeight';
import { PageId } from '@/utils/tracker/consts';
import { useReportPageViewEvent } from '@/utils/tracker/useReportPageViewEvent';

export function RequestReturnsPage() {
  const { dispatch, children } = useFlow();
  const minHeight = useStepCardMinHeight();
  const { t } = useTranslation();

  useReportPageViewEvent(PageId.itemSelection);

  return (
    <StepCard
      width={'800px'}
      title={
        <Flex direction={'column'} flex={1} justifyContent={'center'} alignItems={'center'}>
          <RequestReturnsCardTitleText
            variant={'title2'}
            textAlign={'center'}
            color={ColorVars.Grey.Grey1200}
          >
            {t('page.request.whatItems')}
          </RequestReturnsCardTitleText>
        </Flex>
      }
      onBack={() => {
        if (children.itemSelectionSubFlow?.context?.returns?.length) {
          dispatch({
            type: 'BACK_TO_RETURN_LIST',
          });
        } else {
          dispatch({
            type: 'BACK_TO_ORDER_LOOKUP',
          });
        }
      }}
      height={minHeight}
    >
      <RequestReturns />
    </StepCard>
  );
}
