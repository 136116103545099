import { IconSource } from './type';

const Warning: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16M8 3.125c.483 0 .875.392.875.875v5a.875.875 0 1 1-1.75 0V4c0-.483.392-.875.875-.875M7 12a1 1 0 1 0 2 0 1 1 0 0 0-2 0'
        fill='currentColor'
      />
    </svg>
  );
};

export default Warning;
