import { useFlow } from 'returns-logics/react';

import { useFieldAWithMemo } from '@/hooks/useFieldAWithMemo';

export const useReturnMethodSubFlow = () => {
  const flow = useFlow();
  return useFieldAWithMemo(flow.children.returnMethodSubFlow) as NonNullable<
    typeof flow.children.returnMethodSubFlow
  >;
};
