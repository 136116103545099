import { assign } from 'xstate';

import { Resolution, SummaryTotalType } from '../../core';
import { reviewSubFlow } from '../../core/machine/reviewSubFlow';
import { mockGetMatchingResolutions, mockPostReturnPreview } from '../promise/review';
import { defaultSelectedItems } from '../requestData/order/defaultOrder';
import { defaultReturnMethod } from '../requestData/returnMethod/defaultReturnMethod';
import { defaultPreview } from '../requestData/review/defaultReview';

export const mockReviewSubFlow = reviewSubFlow.provide({
  actors: {
    postReturnPreviewActor: mockPostReturnPreview,
    getMatchingResolutions: mockGetMatchingResolutions,
  },
  actions: {
    updateReviewContextInPreviewMode: assign({
      selectedItems: defaultSelectedItems,
      resolution: Resolution.ReplaceTheSameItem,
      returnMethod: defaultReturnMethod[0],
      exchangeItems: [],
    }),
    updateContextForOriginalPaymentInPreview: assign({
      selectedItems: defaultSelectedItems?.map((item) => ({
        ...item,
        exchangeVariant: void 0,
      })),
      resolution: Resolution.OriginalPayment,
      preview: {
        ...defaultPreview,
        summary: {
          ...defaultPreview.summary,
          financial_outcome: SummaryTotalType.Refund,
          cost_of_return_included_in_calculation: true,
        },
      },
      exchangeItems: [],
      shippingAddress: null,
    }),
    updateContextForExchangeInPreview: assign({
      selectedItems: defaultSelectedItems,
      resolution: Resolution.ReplaceTheSameItem,
      preview: defaultPreview,
      exchangeItems: [],
      shippingAddress: defaultPreview?.exchange_shipping_address,
    }),
  },
});
