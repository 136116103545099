'use client';

import { Link as AriaLink } from 'react-aria-components';

import { linkRecipe } from './Link.css';
import { LinkProps } from './type';

import { clsx } from '../../utils/clsx';

const Link = <T extends React.ElementType = typeof AriaLink>({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  component: Component = AriaLink,
  className,
  variant = 'subtitle1',
  underline = 'none',
  ...rest
}: LinkProps<T>) => {
  return <Component {...rest} className={clsx(linkRecipe({ variant, underline }), className)} />;
};

export default Link;
