import { useTranslation } from 'react-i18next';

import { RefundDestination } from 'returns-logics';

import { toCurrency } from '@/utils/price';

export function useRefundStatus({
  resolution,
  amount = 0,
  currency = 'USD',
  isRefunded = true,
}: {
  resolution?: RefundDestination;
  amount?: number | string;
  currency?: string;
  isRefunded?: boolean;
}) {
  const { t } = useTranslation();
  const price = toCurrency(amount, currency);
  switch (resolution) {
    case RefundDestination.Refundid: {
      if (isRefunded) {
        return {
          label: t('v2.refundid.refunded', { price }),
        };
      }
      return {
        label: t('v2.refundid.refund_ready', { price }),
      };
    }
    case RefundDestination.StoreCredit: {
      if (isRefunded) {
        return {
          label: t('v2.store_credit.refunded', { price }),
        };
      }
      return { hidden: true };
    }
    case RefundDestination.OriginalPayment: {
      if (isRefunded) {
        return {
          label: t('v2.original_payment.refunded', { price }),
        };
      }
      return { hidden: true };
    }
    default:
      return { hidden: true };
  }
}
