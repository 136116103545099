import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import { OrderLookupType } from '../machine/orderLookupSubFlow/types';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { Session } from '../types/SessionInfo';

export type Payload = {
  orderNumber: string;
  email?: string;
  zipCode?: string;
  gReCaptchaToken?: string;
  orderLookupType: OrderLookupType;
  phoneNumber?: string;
  shopId: string;
  traceId?: string;
};

export const getSession = fromPromise(async ({ input }: { input: Payload }) => {
  const { orderNumber, shopId, traceId } = input;

  const getAdditionalPayload = (input: Payload) => {
    const { email, zipCode, gReCaptchaToken, orderLookupType, phoneNumber } = input;

    if (orderLookupType === OrderLookupType.EMAIL) return { customer_email: email };
    if (orderLookupType === OrderLookupType.ZIPCODE)
      return {
        postal_code: zipCode,
        recaptcha_response_token: gReCaptchaToken,
      };
    if (orderLookupType === OrderLookupType.PHONE) {
      return {
        phone_number: phoneNumber,
      };
    }
    return {};
  };

  return request<ResponseBody<Session>>(`${v4hostName()}/sessions`, {
    method: 'POST',
    body: JSON.stringify({
      ...getAdditionalPayload(input),
      order_number: orderNumber,
      shop: {
        id: shopId,
      },
      trace_id: traceId,
    }),
  }).then((response) => response.data);
});
