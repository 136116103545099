import React from 'react';
import { HTMLAttributes } from 'react';
import { SpacingMode1ClassName } from './Mode1.css';

export * from './contract.css';

export const SpacingThemeModeClassNames: Record<SpacingThemeMode, string> = {
  Mode1: SpacingMode1ClassName,
};

export type SpacingThemeMode = 'Mode1';

export interface SpacingThemeProviderProps
  extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  mode?: SpacingThemeMode;
}

export const SpacingThemeProvider = ({
  children,
  mode = 'Mode1',
  ...props
}: SpacingThemeProviderProps) => {
  return (
    <div
      {...props}
      className={`${props.className} ${mode ? SpacingThemeModeClassNames[mode] : ''}`}
    >
      {children}
    </div>
  );
};
