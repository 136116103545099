'use client';

import { useCallback, useEffect, useState } from 'react';

import { StripePaymentStatus } from 'returns-logics';

import getTrackerInstance from '@/utils/tracker';
import { EventName } from '@/utils/tracker/consts';

import useCheckoutError from './useCheckoutError';
import { useReturnPreviewFlow } from './useReturnPreviewFlow';
import useSummaryTotalUtil from './useSummaryTotalUtil';

import { PayStatus } from '../type';

export const useSubmitReturns = () => {
  const { reviewSubFlow, returnPreviewFlowDispatch, returnPreviewFlowContext } =
    useReturnPreviewFlow();

  // post returns 接口返回的数据
  const returnsData = reviewSubFlow?.context?.returns;

  // 是否已经创建了 rma 的标识
  const rmaId = reviewSubFlow?.context?.rmaId;
  const { isNeedPay } = useSummaryTotalUtil();

  /*
   *通用处理
   * */
  const [status, setPayStatus] = useState<PayStatus | null>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);

  // 错误处理
  const { handleCheckoutError } = useCheckoutError({
    exchangeItems: returnPreviewFlowContext?.exchangeItems,
    setPayStatus,
  });

  const isPostReturnsLoading = !!reviewSubFlow?.matches({ postReturnsActor: 'loading' });
  const isPostReturnsSuccess = !!reviewSubFlow?.matches({ postReturnsActor: 'success' });
  const isPostReturnsError = !!reviewSubFlow?.matches({ postReturnsActor: 'error' });
  const isPayTimeout = !rmaId && !!reviewSubFlow?.matches({ pollingGetDraftReturn: 'success' });

  // 在 提交 returns 接口的时候是否存在 draft return
  const isExistDraftReturnAtReturn = returnsData?.draft_return;
  // 是否需要进行 draft return 的支付
  const isNeedPayDraftReturn = !rmaId && isNeedPay && isExistDraftReturnAtReturn;

  const draftReturn = reviewSubFlow?.context?.draftReturn;

  const isStripePay = !!draftReturn && !draftReturn?.checkout_url;
  const isShopifyPay = !!draftReturn && !!draftReturn?.checkout_url;

  const returnIdFromDraftReturn =
    isShopifyPay ||
    draftReturn?.payment_status === StripePaymentStatus.CAPTURED ||
    draftReturn?.payment_status === StripePaymentStatus.AUTHORIZED
      ? draftReturn?.return_id
      : '';

  const stopPollingDraftReturn = useCallback(() => {
    returnPreviewFlowDispatch?.({ type: 'POLLING_CANCEL_DRAFT_RETURN' });
  }, [returnPreviewFlowDispatch]);

  useEffect(() => {
    if (isNeedPayDraftReturn) {
      // 如果需要付款，且 draftReturn 存在，且 draftReturn 有 return_id，则表示已经支付成功，获取 rmaId
      if (returnIdFromDraftReturn) {
        returnPreviewFlowDispatch?.({
          type: 'GET_RETURN_BY_ID',
          data: { returnId: returnIdFromDraftReturn },
        });
      }
    }
  }, [returnIdFromDraftReturn, returnPreviewFlowDispatch, isNeedPayDraftReturn]);

  // TODO 优化
  useEffect(() => {
    // 如果有 rmaId 且需要支付，且是 stripe 支付或 shopify 支付,则表示支付成功，直接跳转到 rma detail 页面
    if (isNeedPay && (isStripePay || isShopifyPay)) {
      if (rmaId) {
        // 由于外部会判断 paid 状态以跳转到 rma detail 页面，所以需要获取到 rmaId
        setPayStatus(PayStatus.paid);
      } else if (
        draftReturn?.payment_status === StripePaymentStatus.FAILED ||
        draftReturn?.payment_status === StripePaymentStatus.CANCELED
      ) {
        setPayStatus(PayStatus.error);
      } else if (isPayTimeout) {
        setPayStatus(PayStatus.timeout);
      } else if (draftReturn?.checkout_status === 'canceled') {
        setPayStatus(PayStatus.checkoutCanceled);
      }
    }
  }, [
    isPayTimeout,
    draftReturn?.checkout_status,
    draftReturn?.payment_status,
    isNeedPay,
    isShopifyPay,
    isStripePay,
    rmaId,
    returnsData,
  ]);

  useEffect(() => {
    if (isPostReturnsSuccess) {
      // 如果是需要支付的 draft return
      if (isNeedPayDraftReturn) {
        setPayStatus(PayStatus.pay);
      } else {
        // 创建 returns 成功
        setSubmitting(false);
      }
    }
    if (isPostReturnsError) {
      handleCheckoutError(reviewSubFlow?.context?.returnError);
    }
  }, [
    rmaId,
    isNeedPay,
    isPostReturnsError,
    isPostReturnsSuccess,
    reviewSubFlow?.context?.returnError,
    isNeedPayDraftReturn,
    handleCheckoutError,
  ]);

  // TODO 埋点上报
  const submit = useCallback(() => {
    returnPreviewFlowDispatch?.({
      type: 'SUBMIT_GENARATE_RETURN',
    });
    getTrackerInstance().reportClickEvent({ eventName: EventName.submitReturn });
  }, [returnPreviewFlowDispatch]);

  return {
    data: returnsData,
    rmaId,
    status,
    submitting,
    returnId: returnsData?.id || returnIdFromDraftReturn,
    isSubmitting: isPostReturnsLoading || submitting,
    setSubmitting,
    setPayStatus,
    submit,
    stopPollingDraftReturn,
  };
};
