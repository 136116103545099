import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import ErrorIcon from './ErrorIcon';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

interface StripeHoldPaymentErrorModalProps {
  isOpen: boolean;
  errorDescription?: string;
  hasChargePayment: boolean;
  onClose: VoidFunction;
  onTryAgain: VoidFunction;
}

const StripeHoldPaymentErrorModal = ({
  isOpen,
  errorDescription,
  hasChargePayment,
  onClose,
  onTryAgain,
}: StripeHoldPaymentErrorModalProps) => {
  const { t } = useTranslation();
  const modalTitle = hasChargePayment ? t('popup.description.paymentFailed') : t('AUTHOR_D588FC');
  const errorMsg = hasChargePayment
    ? t('popup.description.unsuccessfulPayment')
    : t('UNABLE_2CC6C4');

  const { mobile } = useDevice();

  if (mobile) {
    return (
      <MobilePopup
        isOpen={isOpen}
        title={modalTitle}
        onClose={onClose}
        footer={
          <ModalButtonGroup>
            <Button size='large' onPress={onTryAgain}>
              {t('popup.request.tryAgain')}
            </Button>
          </ModalButtonGroup>
        }
      >
        <Flex direction='column' justifyContent='center' alignItems='center' gap={SpacingVars['6']}>
          <ErrorIcon />
          <Flex direction='column' justifyContent='center'>
            {errorMsg && <Text variant='body1'>{errorMsg}</Text>}

            {errorDescription && (
              <Text variant='body1' textAlign='center'>
                {errorDescription}
              </Text>
            )}
          </Flex>
        </Flex>
      </MobilePopup>
    );
  }

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={modalTitle} onClose={onClose} />

      <ModalBody>
        <Flex direction='column' justifyContent='center' alignItems='center' gap={SpacingVars['6']}>
          <ErrorIcon />
          <Flex direction='column' justifyContent='center'>
            {errorMsg && <Text variant='body1'>{errorMsg}</Text>}

            {errorDescription && (
              <Text variant='body1' textAlign='center'>
                {errorDescription}
              </Text>
            )}
          </Flex>
        </Flex>
      </ModalBody>

      <ModalFooter>
        <ModalButtonGroup>
          <Button size='large' onPress={onTryAgain}>
            {t('popup.request.tryAgain')}
          </Button>
        </ModalButtonGroup>
      </ModalFooter>
    </Modal>
  );
};

export default StripeHoldPaymentErrorModal;
