import { useEffect } from 'react';

import { Font } from 'returns-logics';

const loadedFonts = new Set();

const loadCustomFont = (fonts: Font[]) => {
  if (typeof window === 'undefined') {
    return;
  }

  fonts.forEach((font) => {
    if (loadedFonts.has(font.name)) {
      return;
    } else {
      loadedFonts.add(font.name);
    }

    new FontFace(font.name, `url(${font.url})`).load().then((loadedFont) => {
      document.fonts.add(loadedFont);
    });
  });
};

export const useCustomFont = (fonts: Font[], option?: { useJS: boolean }) => {
  const { useJS = false } = option || {};
  const fontStyle = fonts
    .map(
      (font) => `@font-face {
        font-family: "${font.name}";
        src: url(${font.url});
      }`,
    )
    .join('\n');

  useEffect(() => {
    if (useJS) {
      loadCustomFont(fonts);
    }
  }, [fonts, useJS]);

  return useJS ? '' : fontStyle;
};
