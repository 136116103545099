import { useEffect, useState } from 'react';

/**
 * @description Hook to check if the component is mounted, use this hook to add client-side only logic or render components conditionally, difference from isSSR utils, this hook won't cause hydration mismatch error
 * @returns {boolean} isMounted - Returns true if the component is mounted
 */
export function useIsMounted() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted;
}
