import { t } from 'i18next';

import { AmountPrice, ReturnCostOption } from 'returns-logics';

import { toCurrency } from '@/utils/price';

export const getCostOfReturnValue = (
  price?: AmountPrice | null,
  costOfReturnOption?: ReturnCostOption,
) => {
  if (costOfReturnOption === ReturnCostOption.FREE) {
    return t('page.description.free');
  }
  if (
    price !== null &&
    price?.amount !== null &&
    price?.amount !== undefined &&
    !!price?.currency
  ) {
    return toCurrency(Number(price.amount), price.currency, true);
  }
  return '';
};

export const formatCostOfReturn = (
  price?: AmountPrice | null,
  costOfReturnOption?: ReturnCostOption,
) => {
  const costOfReturnValue = getCostOfReturnValue(price, costOfReturnOption);
  return costOfReturnValue ? ` (${costOfReturnValue})` : '';
};
