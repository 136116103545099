import { assertEvent, assign } from 'xstate';

import { clickwrapSubFlow } from '../../core/machine/clickwrapSubFlow';

export const mockClickwrapSubFlow = clickwrapSubFlow.provide({
  actions: {
    updateContextInPreview: assign({
      clickwrapData: ({ event }) => {
        assertEvent(event, 'UPDATE_MOCK_CLICKWRAP');
        return event.data.clickwrapData;
      },
    }),
  },
});
