import { IconSource } from './type';

const Minus: IconSource = () => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.875 8C13.875 7.51675 13.4832 7.125 13 7.125H3C2.51675 7.125 2.125 7.51675 2.125 8C2.125 8.48325 2.51675 8.875 3 8.875H13C13.4832 8.875 13.875 8.48325 13.875 8Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Minus;
