import { t } from 'i18next';
import React, { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  Icon,
  IconCrossFill,
  Modal,
  Popup,
  Spinner,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { usePreviewContext } from '@aftership/preview-kit/client';
import { PresentmentMoney } from 'returns-logics';

import { RecommendProducts } from '@/features/resolution/components/RecommendProducts';
import { useMemoExchangeOrRefundContext } from '@/features/resolution/hooks/resolutions.ts';
import useDevice from '@/hooks/useDevice';
import { toCurrency } from '@/utils/price';

import { bodyMobileClassName, containerMobileClassName } from './styles.css.ts';

const Loading = () => {
  return (
    <Flex
      alignSelf={'center'}
      justifySelf={'center'}
      alignItems={'center'}
      justifyContent={'center'}
      flex={1}
    >
      <Spinner />
    </Flex>
  );
};
export interface RefundOrEfaModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onClickRefund: VoidFunction;
  onClickShopNow: VoidFunction;
  onClickProduct: (productId: string, productUrl: string) => void;
}

interface HeaderProps {
  onClose: VoidFunction;
}
const Header = ({ onClose }: HeaderProps) => {
  const isMobile = useDevice().mobile;
  return (
    <Box
      paddingX={SpacingVars[isMobile ? '6' : '8']}
      paddingBottom={SpacingVars[isMobile ? '6' : '4']}
      paddingTop={SpacingVars[isMobile ? '6' : '8']}
    >
      <Flex justifyContent={'space-between'}>
        <Box />
        <Button
          variant={'plain'}
          onPress={() => {
            setTimeout(() => {
              onClose();
            }, 20);
          }}
        >
          <Icon source={IconCrossFill} size={SpacingVars['9']} />
        </Button>
      </Flex>
    </Box>
  );
};

interface EfaTitleProps {
  selectedItemsWithCreditAmountString: string;
  extraCreditAmount?: PresentmentMoney;
  preDiscountCreditAmount?: PresentmentMoney;
  showEmptySpacing?: boolean;
}
const EfaTitle = ({
  selectedItemsWithCreditAmountString,
  extraCreditAmount,
  preDiscountCreditAmount,
  showEmptySpacing,
}: EfaTitleProps) => {
  const isMobile = useDevice().mobile;

  const extraCreditTotalAmount: PresentmentMoney = {
    amount: `${
      Number(extraCreditAmount?.amount ?? 0) + Number(preDiscountCreditAmount?.amount ?? 0)
    }`,
    currency: extraCreditAmount?.currency || preDiscountCreditAmount?.currency || 'USD',
  };
  return (
    <Flex direction={'column'} gap={SpacingVars[isMobile ? '4' : '2']} paddingX={SpacingVars['4']}>
      <Text variant={'title2'} textAlign={'center'}>
        {t('resolution.description.exchangeCredit', {
          creditTotal: selectedItemsWithCreditAmountString,
        })}
      </Text>
      {extraCreditTotalAmount && !!Number(extraCreditTotalAmount.amount) ? (
        <Text
          variant='title4'
          textAlign={'center'}
          color={ColorVars.Grey['Grey 1000']}
          width={'100%'}
          elementType={'i'}
        >
          <Trans
            i18nKey='v2.resolution.description.exchangeCredit'
            values={{
              extraCredit: toCurrency(
                extraCreditTotalAmount.amount,
                extraCreditTotalAmount.currency,
              ),
            }}
            components={[
              <Text key={'amount'} variant='title4' color={ColorVars.Grey['Grey1200']}>
                {toCurrency(extraCreditTotalAmount.amount, extraCreditTotalAmount.currency)}
              </Text>,
            ]}
          >
            {'Extra <0>${extraCredit}</0> included'}
          </Trans>
        </Text>
      ) : null}
      {showEmptySpacing && <Box height={SpacingVars['25']} />}
    </Flex>
  );
};
interface ButtonsProps {
  selectedItemsWithCreditAmountString: string;
  selectedItemsAmount: PresentmentMoney;
  onClickRefund: VoidFunction;
  onClickShopNow: VoidFunction;
  isLoading: boolean;
}
const Buttons = ({
  selectedItemsWithCreditAmountString,
  selectedItemsAmount,
  onClickRefund,
  onClickShopNow,
  isLoading,
}: ButtonsProps) => {
  const isMobile = useDevice().mobile;

  return (
    <Box paddingX={isMobile ? SpacingVars['4'] : '100px'} paddingY={SpacingVars[4]}>
      <Flex
        direction={isMobile ? 'column' : 'row-reverse'}
        gap={SpacingVars[isMobile ? '4' : '10']}
      >
        <Button
          size={'large'}
          variant={'primary'}
          onPress={onClickShopNow}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          {t('page.request.shopNow', {
            creditTotal: selectedItemsWithCreditAmountString,
          })}
        </Button>
        <Button size={'large'} variant={'secondary'} onPress={onClickRefund} isDisabled={isLoading}>
          {t('page.request.refundMeDirectlyWithAmount', {
            refund: `${toCurrency(selectedItemsAmount.amount, selectedItemsAmount.currency)}`,
          })}
        </Button>
      </Flex>
    </Box>
  );
};

interface ContainerCompProps {
  isLoading: boolean;
  isOpen: boolean;
  usingMinHeight?: boolean;
  onClose: VoidFunction;
  footer: React.ReactNode;
}
const ContainerComp = ({
  isLoading,
  children,
  isOpen,
  onClose,
  footer,
  usingMinHeight,
}: PropsWithChildren<ContainerCompProps>) => {
  const isMobile = useDevice().mobile;
  const { isPreview } = usePreviewContext();
  const popupStyle = usingMinHeight
    ? {
        minHeight: '444px',
        maxHeight: '95%',
        height: '0px',
      }
    : {
        maxHeight: '95%',
        height: '95%',
      };
  return isMobile ? (
    <Popup
      isOpen={isOpen}
      header={<Header onClose={onClose} />}
      disableFocusManagement={isPreview}
      {...popupStyle}
    >
      <Box className={containerMobileClassName}>
        {isLoading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Box className={bodyMobileClassName}>{children}</Box>
            {footer}
          </React.Fragment>
        )}
      </Box>
    </Popup>
  ) : (
    <Modal isOpen={isOpen} size={'large'} disableFocusManagement={isPreview}>
      {isLoading ? (
        <Flex height={'500px'}>
          <Loading />
        </Flex>
      ) : (
        <Flex direction={'column'} flex={1} justifyContent={'center'}>
          {/* header*/}
          <Header onClose={onClose} />
          <Box paddingX={SpacingVars['8']} flexGrow={1} flexBasis={0} paddingBottom={'52px'}>
            <Flex direction={'column'} gap={SpacingVars['10']}>
              {children}
              {footer}
            </Flex>
          </Box>
        </Flex>
      )}
    </Modal>
  );
};

const RefundOrEfaOverlay = ({
  isOpen,
  isLoading,
  onClose,
  onClickRefund,
  onClickShopNow,
  onClickProduct,
}: RefundOrEfaModalProps) => {
  const {
    isJumpingEfaOnStore,
    selectedItemsAmount,
    selectedItemsWithCreditAmountString,
    extraCreditAmount,
    preDiscountCreditAmount,
    products,
  } = useMemoExchangeOrRefundContext();
  const isMobile = useDevice().mobile;
  const showProducts = !isMobile || Boolean(products?.length);
  const usingMinHeight = isMobile && !products?.length;

  return (
    <ContainerComp
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      footer={
        <Buttons
          selectedItemsWithCreditAmountString={selectedItemsWithCreditAmountString}
          selectedItemsAmount={selectedItemsAmount}
          onClickRefund={onClickRefund}
          isLoading={isJumpingEfaOnStore}
          onClickShopNow={onClickShopNow}
        />
      }
      usingMinHeight={usingMinHeight}
    >
      <EfaTitle
        extraCreditAmount={extraCreditAmount}
        selectedItemsWithCreditAmountString={selectedItemsWithCreditAmountString}
        preDiscountCreditAmount={preDiscountCreditAmount}
        showEmptySpacing={usingMinHeight}
      />
      {/* recommend products */}
      {showProducts && (
        <RecommendProducts
          items={products}
          onItemClick={(productId, productUrl) => {
            onClickProduct(productId, productUrl);
          }}
        />
      )}
    </ContainerComp>
  );
};
export default RefundOrEfaOverlay;
