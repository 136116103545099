import { Resource } from 'i18next';

import { genCustomCode, getTranslations } from '@aftership/meerkat-sdk/business/server';
import { Resolution } from 'returns-logics';

// ========== 生成动态文案 resource code ========== //

/** 退货原因详情类型 */
export enum ReturnReasonDetail {
  Comment = 'comment_instructions',
  Image = 'image_upload_instructions',
}

/** 退款方式文案的后缀描述 */
export enum ResolutionSuffix {
  Name = 'name',
  Description = 'description',
}

/** 退货方法文案的后缀描述 */
export enum ReturnMethodSuffix {
  Name = 'name',
  Description = 'description',
  Instructions = 'instructions',
}

/** Ask Question Workflow 文案类型*/
export enum AskQuestionWorkflow {
  Text = 'text',
  PrimaryButton = 'primary_button',
  SecondaryButton = 'secondary_button',
  TerminateFlowMessage = 'terminate_flow_message',
}

interface ReturnReasonConfig {
  reasonId: string;
  subReasonId?: string;
  detail?: ReturnReasonDetail;
}

interface RefundExchangeConfig {
  type: Resolution;
  suffix: ResolutionSuffix;
}

interface ReturnMethodConfig {
  methodId: string;
  suffix: ReturnMethodSuffix;
}

interface AskQuestionWorkflowConfig {
  flowId: string;
  type: AskQuestionWorkflow;
}

/** 生成 Return Reasons 动态文案 resource code */
export const genReturnReasonCode = (config: ReturnReasonConfig) => {
  const { reasonId, subReasonId, detail } = config;
  if (subReasonId) {
    return genCustomCode('return_reason', reasonId, 'subreason', subReasonId);
  }
  if (detail) {
    return genCustomCode('return_reason', reasonId, 'detail', detail);
  }
  return genCustomCode('return_reason', reasonId);
};

/** 生成 Refund Exchange 动态文案 resource code */
export const genRefundExchangeCode = (config: RefundExchangeConfig) => {
  const { type, suffix } = config;
  return genCustomCode(type, suffix);
};

/** 生成 Return Routing Rule 动态文案 resource code */
export const genReturnRoutingRuleCode = (config: ReturnMethodConfig) => {
  const { methodId, suffix } = config;
  return genCustomCode('return_routing_rule', 'return_method', methodId, suffix);
};

/** 生成 Ask a question workflow 动态文案 resource code */
export const genAskQuestionWorkflowCode = (config: AskQuestionWorkflowConfig) => {
  const { flowId, type } = config;
  return genCustomCode('ask_a_question_workflow', flowId, type);
};

export const fetchLocalLanguageResource = async (lng = 'en-US') => {
  return (await import(`./languages/${lng}.json`)).default;
};

export const fetchLanguageResource = async (orgId: string, lng: string, useIntranet = false) => {
  let resource;

  if (orgId) {
    resource = await getTranslations({
      languageCode: lng,
      organizationId: orgId,
      projectCode: 'returns-center-returns-page',
      // getTranslations 内部会根据是否为服务端环境以及是否存在 AM_API_KEY 来判断是否使用 intranet 接口
      useIntranet,
    });
  } else {
    resource = await fetchLocalLanguageResource(lng);
  }

  return resource;
};

export const loadI18NextResource = async (orgId: string, lng: string) => {
  const resource: Resource | undefined = {
    [lng]: {
      shopper: await fetchLanguageResource(orgId, lng, true),
    },
  };
  return resource;
};
