import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { Divider } from '@/components/Divider';

export interface PaymentDetailItemProps {
  name: string;
  value: string;
  description?: string;
}

export interface PaymentDetailProps {
  paymentDetail: PaymentDetailItemProps[];
}

const PaymentDetail = ({ paymentDetail }: PaymentDetailProps) => {
  return (
    <Flex direction='column'>
      {paymentDetail.map(({ name, value, description }, index) => {
        return (
          <Flex direction='column' key={name + value}>
            <Divider />

            <Flex
              direction='column'
              key={name + value}
              paddingY='4'
              paddingBottom={index === paymentDetail.length - 1 ? '1' : '4'}
              gap={SpacingVars['2']}
            >
              <Flex justifyContent='space-between'>
                <Text variant='body1'>{name}</Text>
                <Text variant='body1'>{value}</Text>
              </Flex>
              {description && <Text variant='body2'>{description}</Text>}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
};

export default PaymentDetail;
