import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Popup,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

export interface ConfirmCompProps {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
}
export interface Props extends ConfirmCompProps {
  title: string;
  description: string;
  primaryBtnText: string;
  secondaryBtnText: string;
}
const ConfirmPopup = ({
  isOpen,
  onClose,
  onConfirm,
  primaryBtnText,
  secondaryBtnText,
  description,
  title,
}: Props) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <Flex
        direction={'column'}
        padding={SpacingVars['4']}
        justifyContent={'center'}
        gap={SpacingVars['7']}
      >
        <Flex direction={'column'} gap={SpacingVars['2']}>
          <Text variant={'title3'} textAlign={'center'}>
            {title}
          </Text>
          <Text variant={'body1'} textAlign={'center'}>
            {description}
          </Text>
        </Flex>
        <Flex direction={'column'} gap={SpacingVars['4']}>
          <Button size={'large'} variant='primary' onPress={onConfirm}>
            {primaryBtnText}
          </Button>
          <Button size={'large'} variant={'secondary'} onPress={onClose}>
            {secondaryBtnText}
          </Button>
        </Flex>
      </Flex>
    </Popup>
  );
};
const ConfirmModal = ({
  isOpen,
  onClose,
  onConfirm,
  primaryBtnText,
  secondaryBtnText,
  title,
  description,
}: Props) => {
  return (
    <Modal isOpen={isOpen} size={'small'}>
      <ModalHeader divider={true} title={title} onClose={onClose}></ModalHeader>
      <ModalBody>
        <Text variant={'body1'}>{description}</Text>
      </ModalBody>
      <ModalFooter>
        <Flex gap={SpacingVars['6']} justifyContent={'space-between'}>
          <Button size={'large'} variant='primary' onPress={onConfirm}>
            {primaryBtnText}
          </Button>
          <Button size={'large'} variant={'secondary'} onPress={onClose}>
            {secondaryBtnText}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};
// 可以做成通用组件,传入 title 之类的
const ConfirmComp = (compProps: ConfirmCompProps) => {
  const isMobile = useDevice().mobile;
  const { t } = useTranslation();
  const primaryBtnText = t('v2.page.action.confirm.remove');
  const secondaryBtnText = t('v2.page.action.confirm.cancel');
  const title = t('v2.page.description.selectItem.removeTitle');
  const description = t('v2.page.description.selectItem.removeDescription');
  const props = { ...compProps, primaryBtnText, secondaryBtnText, title, description };
  return isMobile ? <ConfirmPopup {...props} /> : <ConfirmModal {...props} />;
};

export default ConfirmComp;
