import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListBox, ListBoxItem } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { SelectableItem } from '@/features/request-returns/components/SelectableItem';
import { genReturnReasonCode } from '@/i18n/dynamic.ts';

export interface ChooseSubReasonProps {
  reasonId: string;
  subReasons: { name: string; id: string }[];
  onSelectSubReason: (subReasonId: string) => void;
  selectedSubReasonId?: string;
}
const ChooseSubReason = ({
  reasonId,
  subReasons,
  onSelectSubReason,
  selectedSubReasonId,
}: ChooseSubReasonProps) => {
  const selectedKeys = selectedSubReasonId ? [selectedSubReasonId] : [];
  const { t } = useTranslation();
  return (
    <ListBox
      selectionMode='single'
      rowGap={SpacingVars['3']}
      items={subReasons}
      selectedKeys={selectedKeys}
    >
      {(item) => {
        return (
          <ListBoxItem>
            <SelectableItem
              key={item.id}
              content={t(
                genReturnReasonCode({
                  reasonId: reasonId,
                  subReasonId: item.id,
                }),
                {
                  rawValue: item.name,
                  defaultValue: item.name,
                },
              )}
              onClick={() => onSelectSubReason(item.id)}
            />
          </ListBoxItem>
        );
      }}
    </ListBox>
  );
};

export default ChooseSubReason;
