import dynamic from 'next/dynamic';
import React from 'react';

import { getReCaptchaKey } from '@/config/reCaptcha';

const GoogleReCaptchaProvider = dynamic(() =>
  import('react-google-recaptcha-v3').then((mod) => mod.GoogleReCaptchaProvider),
);
interface Props {
  enableReCaptcha?: boolean;
  children: React.ReactNode;
  APP_ENV: string;
}
const GoogleReCaptchaProviderWrapper = ({ enableReCaptcha, children, APP_ENV }: Props) => {
  const reCaptchaKey = getReCaptchaKey(APP_ENV);

  if (enableReCaptcha) {
    return (
      <GoogleReCaptchaProvider
        reCaptchaKey={reCaptchaKey}
        scriptProps={{
          async: false,
          defer: false,
          appendTo: 'head',
          nonce: undefined,
        }}
      >
        {children}
      </GoogleReCaptchaProvider>
    );
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default GoogleReCaptchaProviderWrapper;
