import React from 'react';

import { Flex, FlexProps } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import { ScrollBox } from '../ScrollBox';

interface ScrollFlexProps extends FlexProps {
  children: React.ReactNode;
}

const ScrollFlex = ({ children, className, ...props }: ScrollFlexProps) => {
  const isMobile = useDevice().mobile;

  return (
    <ScrollBox
      className={className}
      paddingX={isMobile ? SpacingVars['4'] : SpacingVars['10']}
      paddingTop={isMobile ? 0 : SpacingVars['3']}
      offsetX={isMobile ? SpacingVars['1'] : SpacingVars['4']}
    >
      <Flex direction='column' gap={SpacingVars['6']} {...props}>
        {children}
      </Flex>
    </ScrollBox>
  );
};

export default ScrollFlex;
