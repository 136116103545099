import React from 'react';

import { Box, Button, Flex, Icon, IconCross, LeftChevronThin } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice.ts';

export interface Props {
  canBack: boolean;
  backPreState: () => void;
  cancelFillReturnInfo: () => void;
}
const Header = ({ canBack, backPreState, cancelFillReturnInfo }: Props) => {
  const isMobile = useDevice().mobile;
  return (
    <Box padding={isMobile ? SpacingVars['4'] : SpacingVars['6']}>
      <Flex justifyContent={'space-between'}>
        {canBack ? (
          <Button
            variant={'plain'}
            onPress={() => {
              setTimeout(() => {
                backPreState();
              }, 20);
            }}
          >
            <Icon source={LeftChevronThin} size={SpacingVars['6']} />
          </Button>
        ) : (
          <Box />
        )}

        <Button
          variant={'plain'}
          onPress={() => {
            setTimeout(() => {
              cancelFillReturnInfo();
            }, 20);
          }}
        >
          <Icon source={IconCross} size={SpacingVars['9']} />
        </Button>
      </Flex>
    </Box>
  );
};

export default Header;
