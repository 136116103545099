export class Timer {
  #timerId: NodeJS.Timeout | number | null = null;
  #startTime: number = Date.now();
  private remaining: number;
  private callback: () => void;

  constructor(callback: () => void, delay: number) {
    this.remaining = delay;
    this.callback = callback;
  }

  reset(delay: number) {
    this.remaining = delay;
    this.resume();
  }

  pause() {
    if (this.#timerId === null) {
      return;
    }

    clearTimeout(this.#timerId);
    this.#timerId = null;
    this.remaining -= Date.now() - this.#startTime;
  }

  resume() {
    if (this.remaining <= 0) {
      return;
    }

    this.#startTime = Date.now();

    this.#timerId = setTimeout(() => {
      this.#timerId = null;
      this.remaining = 0;
      this.callback();
    }, this.remaining);
  }
}
