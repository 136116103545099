/* eslint-disable @typescript-eslint/naming-convention */
import React, { HTMLAttributes } from 'react';

import { TypographyDesktopClassName } from './Desktop.css';
import { TypographyMobileClassName } from './Mobile.css';

export * from './contract.css';

export const TypographyThemeModeClassNames: Record<TypographyThemeMode, string> = {
  Desktop: TypographyDesktopClassName,
  Mobile: TypographyMobileClassName,
};

export type TypographyThemeMode = 'Desktop' | 'Mobile';

export interface TypographyThemeProviderProps extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  mode?: TypographyThemeMode;
}

export const TypographyThemeProvider = ({
  children,
  mode = 'Desktop',
  ...props
}: TypographyThemeProviderProps) => {
  return (
    <div
      {...props}
      className={`${props.className} ${mode ? TypographyThemeModeClassNames[mode] : ''}`}
    >
      {children}
    </div>
  );
};
