import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { ReturnItemType, Returns } from '../types/returnList';

export type GetReturnsPayload = { order_id?: string; token: string };
// 获取 returns 列表
export const getReturns = fromPromise<Returns['returns'], GetReturnsPayload>(
  async ({ input }: { input: GetReturnsPayload }) => {
    const { order_id, token } = input;
    const res = await request<ResponseBody<Returns>>(
      `${v4hostName()}/returns?order_id=${order_id}`,
      {
        method: 'GET',
        headers: { 'returns-authorization': token },
      },
    );

    return res?.data?.returns ?? [];
  },
);

// 获取单个 return 详情
export const getReturnById = fromPromise(
  async ({ input }: { input: { returnId: string; token: string } }) => {
    const { returnId, token } = input;
    const res = await request<ResponseBody<ReturnItemType>>(`${v4hostName()}/returns/${returnId}`, {
      method: 'GET',
      headers: { 'returns-authorization': token },
    });

    return res?.data;
  },
);
