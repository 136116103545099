import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname.ts';
import { request } from '../request/request.ts';
import { ResponseBody } from '../request/response';
import { QuestionWorkFlowData } from '../types/question';

export interface GetQuestionsInput {
  input: { token: string };
}
export const getQuestions = fromPromise(async ({ input }: GetQuestionsInput) => {
  const res = await request<ResponseBody<QuestionWorkFlowData>>(
    `${v4hostName()}/workflow-event/v1/item-selected/questions`,
    {
      method: 'GET',
      headers: {
        'returns-authorization': input.token,
      },
    },
  );
  return res?.data?.questions ?? [];
});
