import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';

import FormInput from '@/components/Form/FormInput';

export type Props = {
  control: Control<any, any>;
};

export const WhichProduct = ({ control }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={8}>
      <Text variant={'subtitle1'}>{t('page.gift.description.whichProduct')}</Text>
      <FormInput
        control={control}
        name='productName'
        placeholder={t('page.gift.placeholder.productName')}
        fullWidth={true}
      />
    </Flex>
  );
};
