import { useContext, useMemo } from 'react';

import { BreakPointContext } from '@/features/returns/components/BreakPointProvider';
import useSpecialPoint from '@/features/returns/hooks/useSpecialPoint';
import {
  LAYOUT_BOTTOM_PADDING_DESKTOP,
  LAYOUT_BOTTOM_PADDING_TABLET,
  LAYOUT_BOTTOM_PADDING_ULTRA,
  LAYOUT_TOP_PADDING_DESKTOP,
  LAYOUT_TOP_PADDING_TABLET,
  LAYOUT_TOP_PADDING_ULTRA,
} from '@/utils/constants';

const useDevice = () => {
  const { device } = useContext(BreakPointContext);
  const { mobile, tablet, desktop, ultra } = device;
  const { specialDesktop } = useSpecialPoint();
  const paddingTop = useMemo(() => {
    if (specialDesktop?.review || specialDesktop?.returnDetail) {
      return 0;
    } else if (ultra) {
      return LAYOUT_TOP_PADDING_ULTRA;
    } else if (desktop) {
      return LAYOUT_TOP_PADDING_DESKTOP;
    } else if (tablet) {
      return LAYOUT_TOP_PADDING_TABLET;
    }
    return 0;
  }, [desktop, tablet, ultra, specialDesktop]);
  const paddingBottom = useMemo(() => {
    if (specialDesktop?.review || specialDesktop?.returnDetail) {
      return 0;
    } else if (ultra) {
      return LAYOUT_BOTTOM_PADDING_ULTRA;
    } else if (desktop) {
      return LAYOUT_BOTTOM_PADDING_DESKTOP;
    } else if (tablet) {
      return LAYOUT_BOTTOM_PADDING_TABLET;
    }
    return 0;
  }, [desktop, tablet, ultra, specialDesktop]);

  return useMemo(
    () => ({
      mobile,
      tablet,
      desktop,
      ultra,
      paddingTop,
      paddingBottom,
    }),
    [desktop, mobile, tablet, ultra, paddingTop, paddingBottom],
  );
};

export default useDevice;
