import { fromPromise } from 'xstate';

import { GetMatchingResolutionsInput, ResolutionMatchingData } from '../../core';
import { defaultResolution } from '../requestData/resolution/defaultResolution';

export const getMatchingResolutions = fromPromise<
  ResolutionMatchingData,
  GetMatchingResolutionsInput['input']
>(async () => {
  return defaultResolution;
});
