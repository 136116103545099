'use client';

import React, { useRef } from 'react';
import { Button as AriaButton, PressEvent } from 'react-aria-components';

import { buttonCircleLoaderRecipe, buttonRecipe, hideButtonChildClassName } from './Button.css';
// import { testButtonRecipe } from './TestButton.css';
import { ButtonProps } from './type';

import { clsx } from '../../utils/clsx';
import { Spinner } from '../Spinner';

// import { useHasChild } from '../../hooks/useHasChild';
// import { iconRecipe } from '../Icon/Icon.css';

const isOnlyChildIcon = (children: React.ReactNode): boolean => {
  const childrenArray = React.Children.toArray(children);
  if (childrenArray.length !== 1) {
    return false;
  }

  const child = childrenArray[0];
  return typeof child === 'object' && 'type' in child && (child.type as any).displayName === 'Icon';
};

const Button = ({
  children,
  variant,
  size,
  className,
  isLoading,
  onPress,
  ...rest
}: ButtonProps) => {
  const domRef = useRef<HTMLButtonElement>(null);
  const hasIcon = isOnlyChildIcon(children);

  const handlePress = (e: PressEvent) => {
    if (isLoading) {
      return;
    }
    onPress?.(e);
  };

  return (
    <AriaButton
      ref={domRef}
      onPress={handlePress}
      {...rest}
      className={clsx(buttonRecipe({ variant, size, iconOnly: hasIcon }), className)}
    >
      <div
        className={clsx({
          [hideButtonChildClassName]: isLoading,
        })}
      >
        {children}
      </div>
      {isLoading && (
        <div className={buttonCircleLoaderRecipe()}>
          <Spinner color='currentColor' />
        </div>
      )}
    </AriaButton>
  );
};

// export const TestButton = () => {
//   const variants = testButtonRecipe.variantValues() as any;

//   let allVariantCombinations = [];

//   for (const [name, values] of Object.entries(variants) as any) {
//     if (!allVariantCombinations.length) {
//       allVariantCombinations = values.map((value: any) => ({ [name]: value }));
//       continue;
//     }

//     allVariantCombinations = allVariantCombinations.flatMap((combination: any) =>
//       (values as any).map((value: any) => ({ ...combination, [name]: value })),
//     );
//   }

//   return allVariantCombinations.map((combination: any) => {
//     const key = new URLSearchParams(combination).toString();
//     return (
//       <div style={{ display: 'flex', gap: 20 }} key={key}>
//         {key}

//         <AriaButton key={key} className={testButtonRecipe(combination)}>
//           Button
//         </AriaButton>
//       </div>
//     );
//   });
// };

export default Button;
