import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { IReturnIntentions } from '../types/intention';

export type CreateIntentionDataInput = {
  input: {
    token: string;
    payload: Omit<IReturnIntentions, 'id'>;
  };
};

export type UpdateIntentionDataInput = {
  input: {
    token: string;
    payload: IReturnIntentions;
  };
};

export const getIntentionData = fromPromise(
  async ({ input }: { input: { intentionId: string; token: string } }) => {
    return request<ResponseBody<IReturnIntentions>>(
      `${v4hostName()}/return-intentions/${input.intentionId}`,
      { headers: { 'returns-authorization': input.token } },
    ).then((response) => response.data);
  },
);

export const createIntentionData = fromPromise(async ({ input }: CreateIntentionDataInput) => {
  const res = await request<ResponseBody<IReturnIntentions>>(`${v4hostName()}/return-intentions`, {
    method: 'POST',
    headers: { 'returns-authorization': input.token },
    payload: input.payload,
  });
  return res.data;
});

export const updateIntentionData = fromPromise(async ({ input }: UpdateIntentionDataInput) => {
  const { id, ...payload } = input.payload;
  const res = await request<ResponseBody<IReturnIntentions>>(
    `${v4hostName()}/return-intentions/${id}`,
    {
      method: 'PUT',
      headers: { 'returns-authorization': input.token },
      payload,
    },
  );
  return res.data;
});
