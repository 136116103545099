import { Flex, Skeleton } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { Divider } from '@/components/Divider';

import { fieldNormalStyle, productItemCardImageLoadingStyle } from './CardLoading.css';

import useCartStyle from '../../hooks/useCartStyle';

export const ReturnItemCardLoading = () => {
  return (
    <Skeleton width={'100%'}>
      <Flex direction='row' gap={SpacingVars['4']}>
        <Skeleton.Item className={productItemCardImageLoadingStyle} />
        <Flex direction='column' gap={SpacingVars['1']}>
          <Skeleton.Item className={fieldNormalStyle} width={234} />
          <Skeleton.Item className={fieldNormalStyle} width={137} />
        </Flex>
      </Flex>
      <Divider spacing={SpacingVars['5']} />
      <Flex direction='column' gap={SpacingVars['1']}>
        <Skeleton.Item className={fieldNormalStyle} width={137} />
        <Skeleton.Item className={fieldNormalStyle} width={326} />
      </Flex>
    </Skeleton>
  );
};

export const ReturnStatusCardLoading = () => {
  const { isMobile } = useCartStyle();
  return (
    <Skeleton width={'100%'}>
      <Flex direction='column' gap={SpacingVars['4']}>
        <Skeleton.Item className={fieldNormalStyle} width={isMobile ? '70%' : 350} />
        <Skeleton.Item className={fieldNormalStyle} width={isMobile ? '100%' : 512} />
      </Flex>
    </Skeleton>
  );
};
