import { IconSource } from './type.ts';

/**
 * @description 厚一点的左箭头，用于 app proxy
 * @size 16 x 16
 */
const LeftChevronThick: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.333 3.334 5.785 7.882a.167.167 0 0 0 0 .236l4.548 4.549'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default LeftChevronThick;
