import { assertEvent, assign } from 'xstate';

import { returnPolicySubFlow } from '../../core/machine/returnPolicySubFlow';

export const mockReturrnPolicySubFlow = returnPolicySubFlow.provide({
  actions: {
    updateContextInPreview: assign({
      returnPolicyData: ({ event }) => {
        assertEvent(event, 'UPDATE_RETURN_POLICY_IN_PREVIEW');
        return {
          content: event.data.content,
        };
      },
    }),
  },
});
