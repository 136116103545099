import { IconSource } from './type';

const Global: IconSource = () => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M23 12c0 6.075-4.924 11-10.999 11h-.003C5.924 22.999 1 18.075 1 12 1 5.926 5.924 1.001 11.998 1h.007C18.078 1.003 23 5.927 23 12M7.032 13a15.46 15.46 0 0 0 2.65 7.699A9.01 9.01 0 0 1 3.056 13zm2.005 0c.207 2.795 1.276 5.367 2.963 7.474 1.687-2.107 2.756-4.679 2.963-7.474zm5.926-2H9.037C9.244 8.205 10.313 5.633 12 3.526c1.687 2.107 2.756 4.679 2.963 7.474m2.005 2a15.46 15.46 0 0 1-2.65 7.699A9.01 9.01 0 0 0 20.944 13zm3.977-2h-3.977a15.46 15.46 0 0 0-2.65-7.699A9.01 9.01 0 0 1 20.944 11m-17.89 0a9.01 9.01 0 0 1 6.628-7.699A15.46 15.46 0 0 0 7.033 11z'
      fill='currentColor'
    />
  </svg>
);

export default Global;
