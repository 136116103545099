import { StripeElementLocale } from '@stripe/stripe-js/types/stripe-js';
import { AppContext } from 'next/app';

import { ReturnsPageAccessCode, ReturnsPageAccessStatus } from '@/features/returns/utils/constants';
import { SSRException } from '@/utils/errorHandler';
import { logger } from '@/utils/logger';

export const I18NEXT_NAMESPACE = 'shopper';
export interface IPreviewI18nextResource {
  previewI18nextResource?: Record<string, string>;
}

const allNamesForLanguages: Record<string, string> = {
  'da-DK': 'Dansk',
  de: 'Deutsch',
  'en-US': 'English',
  es: 'Español',
  fr: 'Français',
  'fr-CA': 'Français (Canada)',
  'it-IT': 'Italiano',
  'ja-JP': '日本語',
  'nb-NO': 'Norsk',
  'nl-NL': 'Nederlands',
  'pl-PL': 'Polski',
  'pt-BR': 'Português(Brasil)',
  'pt-PT': 'Português',
  'sv-SE': 'Svenska',
  'tr-TR': 'Türkçe',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁體中文',
  'cs-CZ': 'Czech',
  'sk-SK': 'Slovak',
  ar: 'العربية',
  ko: '한국어',
};

export const stripeLanguagesMap: Record<string, StripeElementLocale> = {
  'da-DK': 'da',
  de: 'de',
  'en-US': 'en',
  es: 'es',
  fr: 'fr',
  'fr-CA': 'fr-CA',
  'it-IT': 'it-IT',
  'ja-JP': 'ja',
  'nb-NO': 'nb',
  'nl-NL': 'nl',
  'pl-PL': 'pl',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt',
  'sv-SE': 'sv',
  'tr-TR': 'tr',
  'zh-Hans': 'zh',
  'zh-Hant': 'zh-TW',
  ar: 'ar',
  ko: 'ko',
};

export function getNameForLanguage(language: string): string {
  return allNamesForLanguages[language] ?? language;
}

export interface IAcceptLanguageParseResult {
  code: string;
  region?: string | null;
  script?: string | null;
  quality?: number;
}

const regex = /((([a-zA-Z]+(-[a-zA-Z0-9]+){0,2})|\*)(;q=[0-1](\.[0-9]+)?)?)*/g;
export const parse = (acceptLanguage: string): IAcceptLanguageParseResult[] => {
  const lngStrs = acceptLanguage.match(regex);

  const ret = lngStrs!
    .map((item) => {
      if (!item) return null;

      const bits = item.split(';');
      const lngTags = bits[0].split('-');

      const hasScript = lngTags.length === 3;

      return {
        code: lngTags[0],
        script: hasScript ? lngTags[1] : null,
        region: hasScript ? lngTags[2] : lngTags[1],
        quality: bits[1] ? parseFloat(bits[1].split('=')[1]) : 1.0,
      };
    })
    .filter(Boolean)
    .sort((a, b) => b!.quality - a!.quality);

  return ret as IAcceptLanguageParseResult[];
};

export const pick = (
  supportedLanguages: string[],
  acceptLanguage: string | string[],
  options: { loose?: boolean } = {},
): string | null => {
  if (!supportedLanguages || !supportedLanguages.length || !acceptLanguage) {
    return null;
  }

  let parsedAcceptLanguage: IAcceptLanguageParseResult[] = [];

  if (typeof acceptLanguage === 'string') {
    parsedAcceptLanguage = parse(acceptLanguage);
  }

  const supported = supportedLanguages.map((lng) => {
    const lngTags = lng.split('-');
    const hasScript = lngTags.length === 3;

    return {
      code: lngTags[0],
      script: hasScript ? lngTags[1] : null,
      region: hasScript ? lngTags[2] : lngTags[1],
    };
  });

  for (let i = 0; i < parsedAcceptLanguage.length; i++) {
    const lang = parsedAcceptLanguage[i];
    const langCode = lang.code.toLowerCase();
    const langRegion = lang.region ? lang.region.toLowerCase() : lang.region;
    const langScript = lang.script ? lang.script.toLowerCase() : lang.script;

    for (let j = 0; j < supported.length; j++) {
      const supportedCode = supported[j].code.toLowerCase();
      const supportedScript = supported[j].script
        ? supported[j].script?.toLowerCase()
        : supported[j].script;
      const supportedRegion = supported[j].region
        ? supported[j].region?.toLowerCase()
        : supported[j].region;
      if (
        langCode === supportedCode &&
        (options.loose || !langScript || langScript === supportedScript) &&
        (options.loose || !langRegion || langRegion === supportedRegion)
      ) {
        return supportedLanguages[j];
      }
    }
  }

  return null;
};

export const resourceNotAvailableError = (
  appContext: AppContext,
  error: Error,
  setReturnsPageAccess?: (returnsPageAccess: Returns.ReturnsPageAccessResult) => void,
) => {
  const req = appContext.ctx.req as NonNullable<typeof appContext.ctx.req>;
  const res = appContext.ctx.res as NonNullable<typeof appContext.ctx.res>;

  res.statusCode = 500;
  logger.error({
    error: new SSRException(error.message, { cause: error }),
    headers: { path: req.url || '', ...req.headers },
  });
  setReturnsPageAccess?.({
    code: ReturnsPageAccessCode.I18NextResourceNotAvailable,
    status: ReturnsPageAccessStatus.Denied,
  });
};
