import { EfaMethod, PayMethod } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

export interface IShopsSettingsProps {
  isCostOfReturnShouldCharge: boolean;
}
export const useShopsAndEFASettings = () => {
  const { context } = useFlow();
  const shops = context?.storeConfig?.shopInfo;

  const isEfaActive = shops?.exchange_for_anything_active ?? false;
  const efaMethod = shops?.exchange_for_anything_shopping_channel ?? EfaMethod.inApp;
  const isEfaInStore = isEfaActive && efaMethod === EfaMethod.inStore;
  const isEfaInApp = isEfaActive && efaMethod === EfaMethod.inApp;

  const isStripePay = shops?.checkout_gateway_active === PayMethod.stripe;
  const isShopifyPay = shops?.checkout_gateway_active === PayMethod.shopify;

  return {
    data: shops,
    efaMethod,
    payMethod: shops?.checkout_gateway_active,
    isEfaInStore,
    isEfaInApp,
    isStripePay,
    isShopifyPay,
    shouldDisplayRecommendedProduct: shops?.exchange_for_anything_recommendation_active ?? false,
  };
};
