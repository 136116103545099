import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname.ts';
import { request } from '../request/request.ts';
import { ResponseBody } from '../request/response';
import { CountryCodeMap, CountryResponse } from '../types';

export const getCountries = fromPromise<CountryCodeMap>(async () => {
  const res = await request<ResponseBody<CountryResponse>>(`${v4hostName()}/countries`, {
    method: 'GET',
  });
  return res?.data?.countries?.reduce<CountryCodeMap>(
    (sum, current) => ({ ...sum, [current.code]: current }),
    {} as CountryCodeMap,
  ) as CountryCodeMap;
});
