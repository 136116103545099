import { PostProcessorModule } from 'i18next';

/**
 * 对于一些用户自定义的文案，比如 shopper 当前默认语言设置为 en-US，用户更新一条 return reason 后，我们认为这条 reason 对应的时候 en-US 的文案，但是这些文案直接保存在业务后台，文案中心不会及时同步
 * - 用户设置的默认语言一致时，无法展示用户最新保存的文案的，这种情况下，可以通过 skipTranslatePlugin 跳过翻译，直接展示用户保存的文案
 * - 用户设置的默认语言不一致时，是需要用户进入文案管理界面进行翻译和同步的，直接翻译即可
 * @param param skipTranslateLang: 跳过翻译的语言
 * @returns
 */
export const skipTranslatePlugin = ({
  skipTranslateLang,
}: {
  skipTranslateLang?: string;
}): PostProcessorModule => ({
  type: 'postProcessor',
  name: 'skipTranslatePlugin',
  process: (value: string, key: string, options: any, translator: any) => {
    if (skipTranslateLang && skipTranslateLang === translator.language) {
      return options.rawValue ?? value;
    }
    return value;
  },
});
