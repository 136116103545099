import React from 'react';

import { Box, Flex, Icon, Pressable, RightChevron, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

export interface SelectableItemProps {
  content: string;
  onClick: VoidFunction;
}
const SelectableItem = ({ content, onClick }: SelectableItemProps) => {
  const isMobile = useDevice().mobile;
  return (
    <Pressable onPress={onClick} width={'100%'}>
      <Box padding={isMobile ? SpacingVars['4'] : SpacingVars['5']} width={'100%'}>
        <Flex justifyContent={'space-between'} gap={SpacingVars['3']} alignItems={'center'}>
          <Text wordBreak={'break-word'}>{content}</Text>
          <Icon source={RightChevron} />
        </Flex>
      </Box>
    </Pressable>
  );
};

export default SelectableItem;
