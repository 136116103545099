import React from 'react';

import { Box, Flex, Icon, Text, Warning } from '@aftership/design-system';
import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

export interface Props {
  tips: string;
}
const OrderWarningTips = ({ tips }: Props) => {
  const isMobile = useDevice().mobile;
  return (
    <Box
      backgroundColor={ColorVars.Cyan['Cyan 1']}
      padding={SpacingVars['4']}
      borderRadius={RadiusVars['4']}
    >
      <Flex gap={SpacingVars[isMobile ? '2' : '4']} alignItems={'first baseline'}>
        <Box paddingY={SpacingVars['1']}>
          <Icon source={Warning} color={ColorVars.Cyan['Cyan 11']} />
        </Box>
        <Text variant={'body1'}>{tips}</Text>
      </Flex>
    </Box>
  );
};

export default OrderWarningTips;
