import { useMemo } from 'react';

import { messageChannel } from '@aftership/atta-engine/renderer';
import { messageBridge } from '@aftership/preview-kit/client';

import { useIsAttaEditor } from '@/features/returns/hooks/useIsAttaEditor';

type MessageBridge = {
  sendMessage: (typeof messageBridge)['sendMessage'];
  onMessage: (typeof messageBridge)['onMessage'];
};

/**
 * 兼容 preview-kit 的 messageBridge 和 atta-editor 的 messageChannel
 */
export const useMessageBridge = (): MessageBridge => {
  const isAttaEditor = useIsAttaEditor();
  const res = useMemo<MessageBridge>(() => {
    return {
      sendMessage: ({ type, data }) => {
        if (!isAttaEditor) {
          messageBridge.sendMessage({ type, data });
        } else if (type) {
          messageChannel.send(type, data);
        }
      },
      onMessage: ({ type, callback }) => {
        if (!isAttaEditor) {
          return messageBridge.onMessage({ type, callback });
        } else if (type) {
          return messageChannel.on(type, callback);
        }
        return () => {};
      },
    };
  }, [isAttaEditor]);
  return res;
};
