import React, { useRef } from 'react';

import {
  Box,
  Flex,
  FlexProps,
  Icon,
  LeftChevronThin,
  Pressable,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { navbarStyles, stickyStyles } from './StickyHeader.css';

export interface HeaderProps {
  onBack?: VoidFunction;
  title: React.ReactNode;
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  alignItems?: FlexProps['alignItems'];
  height?: string | number;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  offset?: number;
  backgroundColor?: string;
}

const StickyHeader = ({
  onBack,
  title,
  leftSlot,
  rightSlot,
  height,
  backgroundColor,
  alignItems = 'start',
  // scrollContainerRef,
  // offset = 0,
}: HeaderProps) => {
  const headerRef = useRef<HTMLDivElement>(null);
  // const [sticky, setSticky] = useState({
  //   isSticky: false,
  //   offset: 0,
  // });

  // const [_, setTransition] = useTransition();

  const EmptySlot = () => {
    return <Box width={SpacingVars['6']} height={SpacingVars['6']} />;
  };
  const LeftSlot = () => {
    return (
      <Pressable onPress={onBack}>
        <Flex
          alignItems='center'
          justifyContent='center'
          width={SpacingVars['9']}
          height={SpacingVars['9']}
        >
          <Icon
            source={LeftChevronThin}
            color={ColorVars['Grey']['Grey1200']}
            size={SpacingVars['6']}
          />
        </Flex>
      </Pressable>
    );
  };

  // const handleScroll = useCallback(
  //   (elTopOffset: number, elHeight: number) => {
  //     setTransition(() => {
  //       if ((scrollContainerRef?.current?.scrollTop ?? 0) > elTopOffset + elHeight) {
  //         setSticky({
  //           isSticky: true,
  //           offset: elTopOffset,
  //         });
  //       } else {
  //         setSticky({
  //           isSticky: false,
  //           offset: 0,
  //         });
  //       }
  //     });
  //   },
  //   [scrollContainerRef],
  // );

  // console.log('sticky', sticky);

  // useEffect(() => {
  //   const options = { passive: true };
  //   const header = headerRef.current?.getBoundingClientRect();
  //   const handleScrollEvent = () => {
  //     if (header?.top !== void 0 && header?.height !== void 0)
  //       handleScroll(header?.top + offset, header?.height);
  //   };
  //   const currentRef = scrollContainerRef?.current;
  //   currentRef?.addEventListener('scroll', handleScrollEvent, options);

  //   return () => {
  //     currentRef?.addEventListener('scroll', handleScrollEvent);
  //   };
  // }, [handleScroll, scrollContainerRef, offset]);

  return (
    <div
      ref={headerRef}
      className={`${navbarStyles} ${stickyStyles}`}
      style={{
        height,
        backgroundColor,
      }}
    >
      <Flex direction='row' gap={SpacingVars['4']} alignItems={alignItems} height={height}>
        <Flex direction='column' height={height} justifyContent='center'>
          {leftSlot ? leftSlot : <LeftSlot />}
        </Flex>

        <Box flex={1}>
          {React.isValidElement(title) ? (
            title
          ) : (
            <Flex direction='column' justifyContent='center' height={height}>
              <Text
                elementType='p'
                variant='title2'
                textAlign='center'
                color={ColorVars['Grey']['Grey1200']}
              >
                {title}
              </Text>
            </Flex>
          )}
        </Box>
        <Flex direction='column' alignItems='end'>
          {rightSlot ? rightSlot : <EmptySlot />}
          <Box height={0} overflow='hidden'>
            {leftSlot ? leftSlot : <LeftSlot />}
          </Box>
        </Flex>
      </Flex>
    </div>
  );
};

export default StickyHeader;
