import { ReactNode } from 'react';

import { Flex, Text, clsx } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { fullWidthFlex } from './index.css';

type SectionWrapperProps = {
  title: ReactNode;
  children: React.ReactNode;
  className?: string;
};

export const SectionWrapper = ({ title, children, className }: SectionWrapperProps) => {
  return (
    <Flex className={clsx(className)}>
      <Flex direction='column' gap={SpacingVars['2']} className={fullWidthFlex}>
        {typeof title === 'string' ? <Text variant='subtitle1'>{title}</Text> : title}
        {children}
      </Flex>
    </Flex>
  );
};
