'use client';

import { PropsWithChildren } from 'react';

import { BoxProps } from './type';

import { useStyleProps, viewStyleProps } from '../../utils/styleProps';

const Box = (props: PropsWithChildren<BoxProps>) => {
  const { children, className, ...rest } = props;

  const { styleProps } = useStyleProps(rest, viewStyleProps);

  return (
    <div {...styleProps} className={className}>
      {children}
    </div>
  );
};

export default Box;
