import { t } from 'i18next';

import { Box, Button, Text, clsx } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';
import { OrderLookupType } from 'returns-logics';

import useDevice from '@/hooks/useDevice';

type ChangeVerifyMethodActionProps = {
  currentVerifyMethod: OrderLookupType | null;
  allActiveVerifyMethods: OrderLookupType[];
  onUpdateVarifyMethod: (method: OrderLookupType) => void;
  className?: string;
};

export const getVerifyMethodConfig = () => ({
  [OrderLookupType.EMAIL]: {
    actionLabel: t('verifyMethod.email'),
    errorLabel: t('verifyMethod.emailAddress'),
  },
  [OrderLookupType.PHONE]: {
    actionLabel: t('verifyMethod.phoneNumber'),
    errorLabel: t('verifyMethod.phoneNumber'),
  },
  [OrderLookupType.ZIPCODE]: {
    actionLabel: t('verifyMethod.zipcode'),
    errorLabel: t('verifyMethod.zipcode'),
  },
});

export const ChangeVerifyMethodAction = ({
  currentVerifyMethod,
  allActiveVerifyMethods,
  onUpdateVarifyMethod,
  className,
}: ChangeVerifyMethodActionProps) => {
  const isMobile = useDevice().mobile;
  const remainingMethods = allActiveVerifyMethods.filter(
    (method) => method !== currentVerifyMethod,
  );

  const verifyMethodConfig = getVerifyMethodConfig();

  if (remainingMethods.length > 0) {
    return (
      <Box className={clsx(className)} margin={'auto'}>
        <Text
          variant={isMobile ? 'subtitle2' : 'caption1'}
          color={ColorVars['Grey']['Grey 900']}
          textAlign='center'
        >
          {t('verifyMethod.verifyBy')}{' '}
          {remainingMethods.map((method, index) => (
            <span key={method}>
              <Button
                variant='plain'
                onPress={() => {
                  onUpdateVarifyMethod(method);
                }}
              >
                <Text
                  variant={isMobile ? 'subtitle2' : 'caption1'}
                  color={ColorVars['Grey']['Grey1200']}
                >
                  {verifyMethodConfig[method].actionLabel}
                </Text>
              </Button>
              {index < remainingMethods.length - 1 && ` ${t('common.or')} `}
            </span>
          ))}
        </Text>
      </Box>
    );
  }

  return null;
};
