const regex =
  // eslint-disable-next-line no-useless-escape
  /(https:\/\/cdn.shopify.com\/s\/files\/[^\/]*\/[^\/]*\/([0-9]+)\/([0-9]+)\/.+)\.((?:jpg|webp|jpeg|png).*)/gm;

export const getShopifyPreviewImage = (source?: string) => {
  if (!source) {
    return source;
  }
  const replacer = (_: string, path: string, height: string, width: string, suffix: string) => {
    let result = source;

    if (path && height && width && suffix) {
      const previewStandardValue = 334;

      const resolution = `${previewStandardValue}x${previewStandardValue}`;
      result = `${path}_${resolution}.${suffix}`;
    }

    return result;
  };

  return source.replace(regex, replacer);
};
