import { assertEvent, assign, enqueueActions } from 'xstate';

import { mockGiftReturnSubFlow } from './giftReturnSubFlow';
import mockItemSelectionSubFlow from './itemSelectionSubFlow';
import { mockReviewSubFlow } from './mockReviewSubFlow';
import mockOrderLookupSubFlow from './orderLookupSubFlow';
import { mockReplaceTheSameItemSubFlow } from './replaceTheSameItemSubFlow.ts';
import mockResolutionSubFlow from './resolutionSubFlow.ts';
import mockReturnListSubFlow from './returnListSubFlow.ts';
import mockReturnMethodSubFlow from './returnMethodSubFlow.ts';

import { mainFlowEngine } from '../../core/machine/mainFlow';
import { defaultOrder, defaultSelectedItems } from '../requestData/order/defaultOrder.ts';
import { MOCK_RESOLUTION_DESCRIPTION, MOCK_RESOLUTION_NAME } from '../requestData/shop/index';

const mockMainFlow = mainFlowEngine.provide({
  actors: {
    orderLookupSubFlow: mockOrderLookupSubFlow,
    itemSelectionSubFlow: mockItemSelectionSubFlow,
    resolutionSubFlow: mockResolutionSubFlow,
    returnMethodSubFlow: mockReturnMethodSubFlow,
    returnListSubFlow: mockReturnListSubFlow,
    replaceTheSameItemSubFlow: mockReplaceTheSameItemSubFlow,
    giftReturnSubFlow: mockGiftReturnSubFlow,
    reviewSubFlow: mockReviewSubFlow,
  },
  actions: {
    updateContextInPreviewMode: enqueueActions(({ context, event, enqueue }) => {
      assertEvent(event, 'PREVIEW_UPDATE_CONTEXT');
      switch (event.data.type) {
        case 'update_shop_theme':
        case 'update_shop_info':
          enqueue.assign({
            storeConfig: {
              ...context.storeConfig,
              shopInfo: {
                ...context.storeConfig?.shopInfo,
                ...event.data.value,
              },
            },
          });
          break;
        case 'set_order_ineligibilities':
          enqueue.assign({
            request: {
              ...context.request,
              orders: {
                ...context.request?.orders!,
                order_ineligibilities: [{ reason: event.data.value }],
              },
            },
          });
          break;
        default:
          break;
      }
    }),
    updateSelectItemsInPreviewMode: assign({
      selectedItems: defaultSelectedItems,
    }),
    clearSelectItemsInPreviewMode: assign({
      selectedItems: [],
    }),
    updateInitialInputInPreviewMode: assign({
      // @ts-ignore
      storeConfig: ({ context }) => {
        const result = {
          ...context.storeConfig,
          shopInfo: {
            ...context.storeConfig?.shopInfo,
            exchange_description: MOCK_RESOLUTION_DESCRIPTION,
            exchange_name: MOCK_RESOLUTION_NAME,
            exchange_for_anything_name: MOCK_RESOLUTION_NAME,
            exchange_for_anything_description: MOCK_RESOLUTION_DESCRIPTION,
            refund_to_original_payment_name: MOCK_RESOLUTION_NAME,
            refund_to_original_payment_description: MOCK_RESOLUTION_DESCRIPTION,
            refund_to_store_credit_name: MOCK_RESOLUTION_NAME,
            refund_to_store_credit_description: MOCK_RESOLUTION_DESCRIPTION,
            instant_refund_with_refundid_name: MOCK_RESOLUTION_NAME,
            instant_refund_with_refundid_description: MOCK_RESOLUTION_DESCRIPTION,
          },
        };

        return result;
      },
      request: {
        orders: defaultOrder,
      },
    }),
  },
});

export default mockMainFlow;
