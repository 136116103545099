'use client';

import CloseButton from './CloseButton';
import {
  headerSlotButtonClassname,
  headerSlotIconClassname,
  headerSlotRecipe,
  headerSlotTextClassname,
  headerSlotTitleClassname,
} from './HeaderSlot.css';
import { HeaderSlotProps } from './type';

import { Text } from '../../components/Text';
import { sprinkles } from '../../sprinkles/index.css';
import { clsx } from '../../utils/clsx';
import { isTrueAndPass } from '../../utils/isTrueAndPass';
import { useStyleProps } from '../../utils/styleProps';

const HeaderSlot = (props: HeaderSlotProps) => {
  const {
    children,
    className,
    divider,
    onClose,
    title,
    prefixIcon,
    suffixIcon = <CloseButton className={headerSlotButtonClassname} onClose={onClose} />,
    textAlign = 'left',
    ...rest
  } = props;
  const { styleProps } = useStyleProps(rest);

  const defaultRender = (
    <>
      {isTrueAndPass(!!prefixIcon, <div className={headerSlotIconClassname}>{prefixIcon}</div>)}
      <div className={clsx(headerSlotTextClassname, sprinkles({ textAlign }))}>
        <Text variant='title3' className={clsx(headerSlotTitleClassname)}>
          {title}
        </Text>
      </div>
      {isTrueAndPass(!!suffixIcon, <div className={headerSlotIconClassname}>{suffixIcon}</div>)}
    </>
  );
  return (
    <div className={clsx(headerSlotRecipe({ divider }), className)} {...styleProps}>
      {children || defaultRender}
    </div>
  );
};

export default HeaderSlot;
