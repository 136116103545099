import React from 'react';
import { HTMLAttributes } from 'react';
import { RadiusMode1ClassName } from './Mode1.css';

export * from './contract.css';

export const RadiusThemeModeClassNames: Record<RadiusThemeMode, string> = {
  Mode1: RadiusMode1ClassName,
};

export type RadiusThemeMode = 'Mode1';

export interface RadiusThemeProviderProps
  extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  mode?: RadiusThemeMode;
}

export const RadiusThemeProvider = ({
  children,
  mode = 'Mode1',
  ...props
}: RadiusThemeProviderProps) => {
  return (
    <div
      {...props}
      className={`${props.className} ${mode ? RadiusThemeModeClassNames[mode] : ''}`}
    >
      {children}
    </div>
  );
};
