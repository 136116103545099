import { PropsWithChildren } from 'react';

import CloseButton from './CloseButton';
import { closeButtonBoxClassName, modalHeaderRecipe, modalHeaderTextRecipe } from './Modal.css';
import { ModalHeaderProps } from './types';

import { sprinkles } from '../../sprinkles/index.css';
import { clsx } from '../../utils/clsx';
import { Box } from '../Box';
import { Text } from '../Text';

const ModalHeader = (props: PropsWithChildren<ModalHeaderProps>) => {
  const {
    children,
    title,
    divider = true,
    textAlign = 'center',
    prefixIcon,
    suffixIcon,
    onClose,
  } = props;
  const closeButton = (
    <Box className={closeButtonBoxClassName}>
      <CloseButton onClose={onClose} />
    </Box>
  );

  const defaultRender = (
    <>
      <Box>{prefixIcon}</Box>
      <Text variant='title3' className={clsx(modalHeaderTextRecipe(), sprinkles({ textAlign }))}>
        {title}
      </Text>
      <Box>{suffixIcon || closeButton}</Box>
    </>
  );
  return <div className={modalHeaderRecipe({ divider })}>{children || defaultRender}</div>;
};

export default ModalHeader;
