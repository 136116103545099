import { ActorRef, AnyMachineSnapshot, StateValue } from 'xstate';
import * as yup from 'yup';

import { InputFieldErrorType, RequestErrorType, YupIssueCode } from '../types';

export enum ExceptionCode {
  // 非预期的 hostname
  UnexpectedHostnameError = 13001,
  UnexpectedSSRError = 13002,
}

export enum ExceptionName {
  UnexpectedHostnameError = 'UnexpectedHostnameError',
  UnexpectedSSRError = 'UnexpectedSSRError',
}

export class CustomException extends Error {
  code;

  constructor(
    name: ExceptionName,
    code: ExceptionCode,
    message = 'Unknown error message',
    options?: ErrorOptions,
  ) {
    super(message);
    this.name = name;
    this.code = code;
    this.cause = options?.cause;
  }
}

export class UnexpectedHostnameException extends CustomException {
  constructor(message: string, options?: ErrorOptions) {
    super(
      ExceptionName.UnexpectedHostnameError,
      ExceptionCode.UnexpectedHostnameError,
      message,
      options,
    );
  }
}

export class RequestError implements RequestErrorType {
  type: 'request' = 'request';
  stepName: StateValue;
  code: number;
  message: string;

  constructor(stepName: StateValue, code: number, message: string = 'Unknown error message') {
    this.stepName = stepName;
    this.code = code;
    this.message = message;
  }
}

export class InputFieldError implements InputFieldErrorType {
  type: 'input' = 'input';
  stepName: StateValue;
  fieldName: string;
  code: YupIssueCode;
  message: string;

  constructor(
    stepName: StateValue,
    fieldName: string,
    code: YupIssueCode,
    message: string = 'Unknown error message',
  ) {
    this.stepName = stepName;
    this.fieldName = fieldName;
    this.code = code;
    this.message = message;
  }
}

export const transformYupErrorsIntoObject = (
  errors: yup.ValidationError,
  self: ActorRef<AnyMachineSnapshot, any, any>,
): Record<string, InputFieldErrorType> => {
  const validationErrors: Record<string, InputFieldErrorType> = {};

  errors.inner.forEach((error: any) => {
    if (error.path !== undefined) {
      validationErrors[error.path] = new InputFieldError(
        self.getSnapshot().value,
        error.path,
        error.type,
        error.message,
      );
    }
  });

  return validationErrors;
};
