import { IconSource } from './type.ts';

const ExpandArrow: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='m7.75 10.58-4.33-4.5h8.66z' fill='#232933' />
    </svg>
  );
};

export default ExpandArrow;
