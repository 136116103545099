import { t } from 'i18next';
import React from 'react';

import { Button, Flex, Icon, IconCross, RightChevron, TopChevron } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import { Divider } from '../../../../components/Divider';

export interface ReturnItemRightContentProps {
  isSelected: boolean;
  expanded: boolean;
  expandability: boolean;
  onEdit: () => void;
  onRemove: () => void;
}
const ReturnItemRightContent = ({
  isSelected,
  expanded,
  expandability,
  onRemove,
  onEdit,
}: ReturnItemRightContentProps) => {
  const isMobile = useDevice().mobile;
  if (isSelected) {
    return isMobile ? (
      <Button variant={'plain'} onPress={onRemove}>
        <Icon source={IconCross} size={SpacingVars['6']} color={ColorVars.Grey['Grey 1100']} />
      </Button>
    ) : (
      <Flex alignItems={'center'} gap={SpacingVars['3']}>
        <Button variant={'plain'} onPress={onRemove}>
          {t('page.request.remove')}
        </Button>
        <Divider backgroundColor={ColorVars.Grey['Grey 1000']} direction={'horizontal'} />
        <Button variant={'plain'} onPress={onEdit}>
          {t('page.request.edit')}
        </Button>
      </Flex>
    );
  } else if (expanded && !expandability) {
    return null;
  } else if (expanded && expandability) {
    return (
      <Flex direction={'column'} justifyContent={'center'} alignSelf={'center'}>
        <Icon source={TopChevron} />
      </Flex>
    );
  } else {
    return (
      <Flex direction={'column'} justifyContent={'center'} alignSelf={'center'}>
        <Icon source={expanded ? TopChevron : RightChevron} />
      </Flex>
    );
  }
};

export default ReturnItemRightContent;
