import {
  AnyActorLogic,
  EventFromLogic,
  getNextSnapshot as getNextSnapshotXstate,
  SnapshotFrom,
} from 'xstate';

export const getNextSnapshot = <T extends AnyActorLogic>(
  actorLogic: T,
  snapshot: SnapshotFrom<T>,
  event: EventFromLogic<T>,
): SnapshotFrom<T> => {
  return getNextSnapshotXstate(actorLogic, snapshot, event);
};

// <T extends AnyActorLogic>(actorLogic: T, snapshot: SnapshotFrom<T>, event: EventFromLogic<T>): SnapshotFrom<T>;
// <TSnapshot extends Snapshot<unknown>, TEvent extends EventObject, TEmitted extends EventObject = EventObject>
//  ActorRef<MachineSnapshot<TContext, TEvent, Record<string, AnyActorRef | undefined>
