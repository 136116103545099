import { t } from 'i18next';
import React from 'react';

import { Box, Button } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

export interface NextButtonProps {
  isLoading: boolean;
  isDisabled: boolean;
  onPress: VoidFunction;
}
const NextButton = ({ isLoading, isDisabled, onPress }: NextButtonProps) => {
  const isMobile = useDevice().mobile;
  return (
    <Box paddingX={isMobile ? SpacingVars['4'] : '160px'} paddingY={SpacingVars['4']}>
      <Button isLoading={isLoading} isDisabled={isDisabled} size={'large'} onPress={onPress}>
        {t('page.request.nextStep')}
      </Button>
    </Box>
  );
};

export default NextButton;
