import { ActorRefFrom, SnapshotFrom, assign, sendParent, setup } from 'xstate';

import {
  SelectSingleReplaceItemContext,
  SelectSingleReplaceItemEvent,
  SelectSingleReplaceItemInput,
} from './types.ts';

import { findVariantByOption, getNewAvailableOptionsWhenSelected } from '../../utils/convertor.ts';

export const selectSingleReplaceItemSubFlow = setup({
  types: {
    input: {} as SelectSingleReplaceItemInput,
    context: {} as SelectSingleReplaceItemContext,
    events: {} as SelectSingleReplaceItemEvent,
  },
  actions: {},
}).createMachine({
  initial: 'waitingSelectVariant',

  context: ({ input }) => ({
    initialOptions: input.productInfo.options,
    availableOptions:
      input.hideUnselectableVariant && !!input.activeSelectedItem.exchangeVariant
        ? getNewAvailableOptionsWhenSelected(
            input.activeSelectedItem.exchangeVariant.options,
            input.productInfo.options,
            input.productInfo.variants,
          )
        : input.productInfo.options,
    variants: input.productInfo.variants,
    activeSelectedItem: input.activeSelectedItem,
    hideUnselectableVariant: input.hideUnselectableVariant,
    selectedOptions: input.activeSelectedItem?.exchangeVariant?.options ?? [],
    selectedVariant: input.activeSelectedItem?.exchangeVariant,
    lastSelectedVariant: input.activeSelectedItem?.exchangeVariant,
  }),
  states: {
    waitingSelectVariant: {
      on: {
        SELECT_ITEM_DONE: {
          target: 'done',
          actions: assign({
            activeSelectedItem: ({ context, event }) => {
              return {
                ...context.activeSelectedItem!,
                exchangeVariant: event.data.variant,
              };
            },
          }),
        },
      },
    },
    done: {
      type: 'final',
      entry: sendParent(({ context }) => {
        console.log('done', context.activeSelectedItem);
        return {
          type: 'SELECT_ITEM_DONE',
          data: {
            selectedItem: context.activeSelectedItem,
          },
        };
      }),
    },
  },
  on: {
    SELECT_OPTION_ITEM: {
      actions: assign(({ event, context }) => {
        // 更新选中的 option,反选 or 选中
        const option = event.data;
        const shouldUnSelect = context.selectedOptions.some(
          (item) => item.name === option.name && item.value === option.value,
        );
        const options = context.selectedOptions.filter((item) => item.name !== option.name);
        const newSelectedOptions = shouldUnSelect ? [...options] : [...options, option];

        const newAvailableOptions = context.hideUnselectableVariant
          ? getNewAvailableOptionsWhenSelected(
              newSelectedOptions,
              context.initialOptions,
              context.variants,
            )
          : context.availableOptions;
        const newSelectedVariant =
          newSelectedOptions.length === context.initialOptions.length
            ? findVariantByOption(context.variants, newSelectedOptions, context.initialOptions)
            : undefined;
        const newLastSelectedVariant = newSelectedVariant
          ? newSelectedVariant
          : context.lastSelectedVariant;
        console.log(newSelectedOptions, newSelectedVariant);
        return {
          ...context,
          selectedOptions: newSelectedOptions,
          availableOptions: newAvailableOptions,
          selectedVariant: newSelectedVariant,
          lastSelectedVariant: newLastSelectedVariant,
        };
      }),
    },

    SELECT_ITEM_CANCEL: {
      actions: sendParent(({ event }) => {
        return {
          type: 'SELECT_ITEM_CANCEL',
          data: {
            selectedItem: event.data.cancelSelectedItem,
          },
        };
      }),
    },
  },
});
export type SelectSingleReplaceItemSubFlowActorRef = ActorRefFrom<
  typeof selectSingleReplaceItemSubFlow
>;

export type SelectSingleReplaceItemSubFlowSnapshot = SnapshotFrom<
  typeof selectSingleReplaceItemSubFlow
>;
