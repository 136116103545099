import React, { useEffect, useState } from 'react';

import {
  AttaRenderer,
  AttaRendererProvider,
  Renderer,
  messageChannel,
} from '@aftership/atta-engine/renderer';

import { loader } from './loader';

export const AttaPreviewer: React.FC<{ schema: string; renderProps: any }> = ({
  schema,
  renderProps,
}) => {
  const [renderer, setRenderer] = useState<AttaRenderer | null>(null);

  useEffect(() => {
    const componentLoader = (name: string) => loader('component', name);
    const renderer = new AttaRenderer({
      componentLoader,
      errorRender: ({ error }) => {
        return <div>{error}</div>;
      },
    });
    setRenderer(renderer);

    return () => {
      renderer.dispose();
    };
  }, []);

  useEffect(() => {
    return messageChannel.send<string>('page-schema', schema);
  }, [schema]);

  return (
    <>
      {renderer ? (
        <AttaRendererProvider renderer={renderer}>
          <Renderer renderProps={renderProps}></Renderer>
        </AttaRendererProvider>
      ) : null}
    </>
  );
};
