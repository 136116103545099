import { useActor } from '@xstate/react';

import { getCountries } from '../../core/promise/countries';

export const useCountries = () => {
  const [state] = useActor(getCountries);

  return {
    data: {
      countries: state.output,
    },
    status: state.status,
  };
};
