import { useTranslation } from 'react-i18next';

import { Box, Card, Checkbox, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

export interface InstantExchangeProps {
  checked: boolean;
  onChange?: (checked: boolean) => void;
  triggerStatusName?: string | null;
  triggerDays?: number;
}

const InstantExchange = ({
  checked,
  triggerStatusName,
  triggerDays,
  onChange,
}: InstantExchangeProps) => {
  const { t } = useTranslation();

  const { mobile } = useDevice();
  return (
    <Box paddingX={mobile ? SpacingVars['6'] : 0}>
      <Card padding={0} overflow='hidden'>
        <Box backgroundColor={ColorVars['Grey']['Grey 400']} padding={SpacingVars['5']}>
          <Checkbox onChange={onChange} isSelected={checked}>
            <Box>
              <Text variant='subtitle1' color={ColorVars['Grey']['Grey1200']}>
                {t('page.instantExchange.title')}
              </Text>
            </Box>
          </Checkbox>
        </Box>

        <Box padding={SpacingVars['5']}>
          <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
            {t('GET_YO_AE7FBA', {
              triggerStatusName,
              triggerDays,
            })}
          </Text>
        </Box>
      </Card>
    </Box>
  );
};

export default InstantExchange;
