import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request.ts';

export const ERROR_DUPLICATED = 40905;

export enum ConditionType {
  OrderItemId = 'order_item_id',
}

interface BlockRulesPayload {
  condition: ConditionType;
  orderId: string;
  itemId: string;
  questionId: string;
}

export interface BlockItemByQuestionInput {
  input: {
    token: string;
    payload: BlockRulesPayload;
  };
}
export const blockItemByQuestion = fromPromise(
  async ({ input: { token, payload } }: BlockItemByQuestionInput) => {
    const uri = `${v4hostName()}/block-rules`;
    const res = await request(uri, {
      method: 'POST',
      headers: { 'returns-authorization': token },
      payload: {
        condition: payload.condition,
        value: `${payload.orderId}.${payload.itemId}`,
        question: {
          id: payload.questionId,
        },
      },
    });
    return res.data;
  },
);
