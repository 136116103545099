'use client';

import { skeletonRecipe } from './Skeleton.css';
import SkeletonItem from './SkeletonItem';
import { SkeletonProps } from './type';

import { clsx } from '../../utils/clsx';
import { createArray } from '../../utils/createArray';
import { useStyleProps } from '../../utils/styleProps';

const Skeleton = (props: SkeletonProps) => {
  const { lineCount = 1, children, animated, className, ...rest } = props;
  const { styleProps } = useStyleProps(rest);
  return (
    <div className={clsx(skeletonRecipe(), className)} {...styleProps}>
      {children ||
        createArray(lineCount).map((item) => <SkeletonItem key={item} animated={animated} />)}
    </div>
  );
};

Skeleton.Item = SkeletonItem;

export default Skeleton;
