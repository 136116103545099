import { Font, FontType } from '../types/shopInfo/ShopInfo';

export const RESOLUTIONT_MAP = {
  STORE_CREDIT: 'store_credit',
  ORIGINAL_PAYMENT: 'original_payment',
  EXCHANGE: 'exchange',
  EXCHANGE_FOR_ANYTHING: 'exchange_for_anything',
  REFUNDID: 'refundid',
};

export enum SummaryTotalType {
  Unchanged = 'unchanged',
  Upsell = 'upsell',
  Refund = 'refund',
}

export enum AutoLabelStatus {
  Crasting = 'creating',
  Created = 'created',
  Failed = 'failed',
}

export const defaultFont: Font = {
  type: FontType.Default,
  name: 'Lato',
  url: 'https://fonts.googleapis.com/css2?family=Lato&display=swap',
  in_use: true,
};
