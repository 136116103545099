import { useActorRef, useSelector } from '@xstate/react';
import { useEffect } from 'react';

import { ClickwrapSubFlowActorRef, clickwrapSubFlow } from '../../core/machine/clickwrapSubFlow';
import { mockClickwrapSubFlow } from '../../mock/machine/clickwrapSubFlow';
import { RecursiveResult } from '../types';
import useFlow from '../useFlow';

export const useClickwrap = (isPreview?: boolean): RecursiveResult<ClickwrapSubFlowActorRef> => {
  const orgId = useFlow().context?.storeConfig?.shopInfo?.organization?.id;
  const actorRef = useActorRef(isPreview ? mockClickwrapSubFlow : clickwrapSubFlow);

  const { value, isLoading, context, matches, snapshot } = useSelector(actorRef, (state) => {
    return {
      value: state.value,
      children: state.children,
      context: state.context,
      matches: state.matches,
      isLoading: state.hasTag('loading'),
      snapshot: state,
    };
  });

  useEffect(() => {
    if (orgId) {
      actorRef.send({ type: 'GET_CLICKWRAP', data: { orgId } });
    }
  }, [orgId, actorRef]);

  return {
    id: actorRef.id,
    currentStep: {
      name: value,
      isLoading,
    },
    context,
    matches,
    ref: actorRef,
    dispatch: actorRef['send'],
    on: actorRef['on'],
    snapshot,
    children: {},
  };
};
