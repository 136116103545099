import React from 'react';

import { RefundDestination, Resolution, SummaryTotalType } from 'returns-logics';
import { ReturnPreviewSummary } from 'returns-logics';

export interface SummaryContextProps {
  isFold: boolean;
  setIsFold?: (isFold: boolean) => void;
  resolution?: Resolution;
  previewSummary?: ReturnPreviewSummary;
  isLoading?: boolean;
  exchangeItemsLength?: number;
  returnItemsLength?: number;
  financialOutcome?: SummaryTotalType;
  isMobile?: boolean;
  isPreview?: boolean;
  isReturnDetail?: boolean;
  refundDestination?: RefundDestination;
  refundName?: string | null;
}

interface SummaryProviderProps extends SummaryContextProps {
  children: React.ReactNode;
}
export const SummaryContext = React.createContext<SummaryContextProps>({
  isFold: true,
});

export const SummaryProvider = (props: SummaryProviderProps) => {
  const { children, ...rest } = props;
  return <SummaryContext.Provider value={rest}>{children}</SummaryContext.Provider>;
};

export const useSummaryContext = () => {
  return React.useContext(SummaryContext);
};
