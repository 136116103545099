import { assign } from 'xstate';

import { Resolution } from '../../core';
import { returnMethodSubFlow } from '../../core/machine/returnMethodSubFlow';
import { batchGetDropoffLocations, getDropoffLocations, getMatchingMethod } from '../promise';
import { defaultSelectedItems } from '../requestData/order/defaultOrder.ts';
import { defaultReturnMethod } from '../requestData/returnMethod/defaultReturnMethod';

const mockReturnMethodSubFlow = returnMethodSubFlow.provide({
  actors: {
    getMatchingMethod,
    getDropoffLocations,
    batchGetDropoffLocations,
  },
  actions: {
    updateSelectedMethodInPreviewMode: assign({
      selectedMethodId: defaultReturnMethod[0].id,
      selectedItems: defaultSelectedItems,
      resolution: Resolution.ReplaceTheSameItem,
    }),
  },
});

export default mockReturnMethodSubFlow;
