import { useTranslation } from 'react-i18next';

import { OrderWarningTips } from '@/features/request-returns/components/OrderWarningTips';
import { useRequestReturnsInfo } from '@/features/request-returns/hooks';

const OverrideRuleBanner = () => {
  const { t } = useTranslation();
  const { isMerchantMode } = useRequestReturnsInfo();
  return isMerchantMode && <OrderWarningTips tips={t('page.banner.overrideRule')} />;
};

export default OverrideRuleBanner;
