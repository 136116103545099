import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { fetchLanguageResource } from '@/i18n/dynamic';
import { I18NEXT_NAMESPACE } from '@/i18n/utils';

export const useChangeLanguage = (uuid: string) => {
  const [loading, setLoading] = useState(false);
  const { i18n, t } = useTranslation();

  const handleChangeLanguage = async (selectedLang: string, onError?: (error: Error) => void) => {
    try {
      setLoading(true);
      if (!i18n.hasResourceBundle(selectedLang, I18NEXT_NAMESPACE)) {
        const resource = await fetchLanguageResource(uuid, selectedLang);
        i18n.addResourceBundle(selectedLang, I18NEXT_NAMESPACE, resource, false, true);
      }
      await i18n.changeLanguage(selectedLang);
      document.documentElement.lang = selectedLang;
    } catch (e) {
      //   Toast.show({
      //     title: t('page.common.tryAgain'),
      //     accessibilityRole: 'alert',
      //     status: 'error',
      //     placement: 'top',
      //   });
      // todo: toast 报错
      onError?.(new Error(t('page.common.tryAgain')));
    } finally {
      setLoading(false);
    }
  };

  return {
    changeLanguage: handleChangeLanguage,
    loading,
  };
};
