import Image from 'next/image';

import { Carousel, Link } from '@aftership/design-system';

import { HomeCarouselWrapperWithPreviewSection } from '@/features/preview/components/WithPreviewSection';

import { carouselImage } from '../css/style.css';
import { useCarouselInfo } from '../hooks/useCarouselInfo';

export const HomeCarousel = () => {
  const marketingAssets = useCarouselInfo();
  if (!marketingAssets?.length) return null;

  return (
    <HomeCarouselWrapperWithPreviewSection width={360}>
      <Carousel
        items={marketingAssets}
        renderItem={(item) => {
          return item?.destination_url ? (
            <Link href={item?.destination_url}>
              <Image
                src={item.image_url}
                width={360}
                height={360}
                alt='market image'
                className={carouselImage}
              />
            </Link>
          ) : (
            <Image
              src={item.image_url}
              width={360}
              height={360}
              alt='market image'
              className={carouselImage}
            />
          );
        }}
      />
    </HomeCarouselWrapperWithPreviewSection>
  );
};
