import { DropoffStatus, ReturnMethodSlug, ReturnStatus, ShippingStatus } from 'returns-logics';

import { TotalProgress } from './constants';

// 兼容旧数据
enum DeprecatedReturnMethodSlug {
  Dropoff = 'carrier_dropoff',
  Pickup = 'carrier_pickup',
}

export function getTotalProgress(returnMethodSlug?: ReturnMethodSlug): number {
  switch (returnMethodSlug) {
    case ReturnMethodSlug.CustomerCourier:
      return TotalProgress.CustomerCourier;
    case ReturnMethodSlug.InStore:
      return TotalProgress.InStore;
    case ReturnMethodSlug.RetailerLabel:
    case DeprecatedReturnMethodSlug.Dropoff as unknown as ReturnMethodSlug:
    case DeprecatedReturnMethodSlug.Pickup as unknown as ReturnMethodSlug:
      return TotalProgress.RetailerLabel;
    case ReturnMethodSlug.GreenReturn:
      return TotalProgress.GreenReturn;
    case ReturnMethodSlug.HappyReturns:
      return TotalProgress.HappyReturns;
    case ReturnMethodSlug.RetailRework:
      return TotalProgress.RetailRework;
    default:
      return 0;
  }
}

function calculateCustomerCourierProgress(returnStatus?: ReturnStatus): number {
  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return 1;
    case ReturnStatus.APPROVED:
      return 2;
    case ReturnStatus.SHIPPED:
      return 3;
    case ReturnStatus.RECEIVED:
      return 4;
    case ReturnStatus.DONE:
    case ReturnStatus.REJECTED:
    case ReturnStatus.EXPIRED:
      return TotalProgress.CustomerCourier;
    default:
      return 0;
  }
}
function calculateInStoreProgress(returnStatus?: ReturnStatus): number {
  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return 1;
    case ReturnStatus.APPROVED:
      return 2;
    case ReturnStatus.RECEIVED:
      return 3;
    case ReturnStatus.DONE:
    case ReturnStatus.SHIPPED:
    case ReturnStatus.REJECTED:
    case ReturnStatus.EXPIRED:
      return TotalProgress.InStore;
    default:
      return 0;
  }
}
function calculateRetailerLabelProgress(
  returnStatus?: ReturnStatus,
  shippingStatus?: ShippingStatus,
): number {
  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return 1;
    case ReturnStatus.APPROVED: {
      switch (shippingStatus) {
        case ShippingStatus.NoLabel:
          return 2;
        case ShippingStatus.Pending:
          return 3;
        case ShippingStatus.InTransit:
        case ShippingStatus.OutForDelivery:
        case ShippingStatus.AttemptFail:
        case ShippingStatus.Delivered:
        case ShippingStatus.AvailableForPickup:
        case ShippingStatus.Exception:
        case ShippingStatus.Expired:
          return 4;
        case ShippingStatus.Received:
          return 5;
        default:
          return 3;
      }
    }
    case ReturnStatus.RECEIVED:
      return 5;
    case ReturnStatus.SHIPPED:
    case ReturnStatus.DONE:
    case ReturnStatus.REJECTED:
    case ReturnStatus.EXPIRED:
      return TotalProgress.RetailerLabel;
    default:
      return 0;
  }
}
function calculateGreenReturnProgress(returnStatus?: ReturnStatus): number {
  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return 1;
    case ReturnStatus.APPROVED:
      return 2;
    case ReturnStatus.SHIPPED:
    case ReturnStatus.RECEIVED:
    case ReturnStatus.DONE:
    case ReturnStatus.REJECTED:
    case ReturnStatus.EXPIRED:
      return TotalProgress.GreenReturn;
    default:
      return 0;
  }
}
function calculateHappyReturnsProgress(
  returnStatus?: ReturnStatus,
  dropoffStatus?: DropoffStatus,
): number {
  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return 1;
    case ReturnStatus.APPROVED: {
      switch (dropoffStatus) {
        case DropoffStatus.Created:
        case DropoffStatus.Dropped:
        case DropoffStatus.PartiallyDropped:
          return 3;
        // 不存在 dropoff status 的情况
        case DropoffStatus.Creating:
        case DropoffStatus.Failed:
        default:
          return 2;
      }
    }
    case ReturnStatus.RECEIVED:
      return 4;
    case ReturnStatus.DONE:
    case ReturnStatus.SHIPPED:
    case ReturnStatus.REJECTED:
    case ReturnStatus.EXPIRED:
      return TotalProgress.HappyReturns;
    default:
      return 0;
  }
}
function calculateRetailReworkProgress(
  returnStatus?: ReturnStatus,
  dropoffStatus?: DropoffStatus,
): number {
  switch (returnStatus) {
    case ReturnStatus.SUBMITTED:
      return 1;
    case ReturnStatus.APPROVED: {
      switch (dropoffStatus) {
        case DropoffStatus.Created:
        case DropoffStatus.Dropped:
        case DropoffStatus.PartiallyDropped:
          return 3;
        // 不存在 dropoff status 的情况
        case DropoffStatus.Creating:
        case DropoffStatus.Failed:
        default:
          return 2;
      }
    }
    case ReturnStatus.RECEIVED:
      return 4;
    case ReturnStatus.DONE:
    case ReturnStatus.SHIPPED:
    case ReturnStatus.REJECTED:
    case ReturnStatus.EXPIRED:
      return TotalProgress.RetailRework;
    default:
      return 0;
  }
}
export function getCurrentProgress({
  returnStatus,
  shippingStatus,
  returnMethodSlug,
  dropoffStatus,
}: {
  returnStatus?: ReturnStatus;
  shippingStatus?: ShippingStatus;
  returnMethodSlug?: ReturnMethodSlug;
  dropoffStatus?: DropoffStatus;
}): number {
  switch (returnMethodSlug) {
    case ReturnMethodSlug.CustomerCourier: {
      return calculateCustomerCourierProgress(returnStatus);
    }
    case ReturnMethodSlug.InStore: {
      return calculateInStoreProgress(returnStatus);
    }
    case DeprecatedReturnMethodSlug.Dropoff as unknown as ReturnMethodSlug:
    case DeprecatedReturnMethodSlug.Pickup as unknown as ReturnMethodSlug:
    case ReturnMethodSlug.RetailerLabel: {
      return calculateRetailerLabelProgress(returnStatus, shippingStatus);
    }
    case ReturnMethodSlug.GreenReturn: {
      return calculateGreenReturnProgress(returnStatus);
    }
    case ReturnMethodSlug.HappyReturns: {
      return calculateHappyReturnsProgress(returnStatus, dropoffStatus);
    }
    case ReturnMethodSlug.RetailRework: {
      return calculateRetailReworkProgress(returnStatus, dropoffStatus);
    }
    default:
      return 0;
  }
}
