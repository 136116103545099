import { useMemo } from 'react';

import { OrderLookupType } from 'returns-logics';

import { useMainFlow } from '@/hooks/useMainFlow';

export const useOrderLookupSetting = () => {
  const { context } = useMainFlow();

  const orderLookupSettings = context?.storeConfig?.shopInfo?.order_lookup;

  const enablePostalCode = context?.storeConfig?.shopInfo?.order_lookup?.by_postal_code_enabled;
  const enableEmail = context?.storeConfig?.shopInfo?.order_lookup?.by_email_enabled;
  const onlyEmail = enableEmail && !enablePostalCode;

  const orderLookupVarifyOptions = useMemo(() => {
    return [
      {
        key: OrderLookupType.EMAIL,
        enabled: orderLookupSettings?.by_email_enabled,
      },
      {
        key: OrderLookupType.ZIPCODE,
        enabled: orderLookupSettings?.by_postal_code_enabled,
      },
      {
        key: OrderLookupType.PHONE,
        enabled: orderLookupSettings?.by_phone_number_enabled,
      },
    ]
      .filter((item) => Boolean(item.enabled))
      .map((item) => item.key);
  }, [orderLookupSettings]);

  return {
    enablePostalCode,
    enableEmail,
    onlyEmail,
    orderLookupVarifyOptions,
  };
};
