'use client';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, Link, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import ScrollView from '@/components/ScrollView';
import { useGetPrefiedLink } from '@/features/returns/hooks/useUniversalRouting';

interface Props {
  policyTextHeight: number;
  externalReturnPolicyPage?: boolean;
  policyUrl?: string;
  disabled?: boolean;
}

const Policy: FC<Props> = ({
  policyTextHeight,
  externalReturnPolicyPage,
  policyUrl,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const privatePolicyLink = useGetPrefiedLink('/return-policy'); // 兼顾 app proxy 模式
  return (
    <Box>
      <ScrollView style={{ maxHeight: policyTextHeight }}>
        <Text
          textAlign='center'
          elementType='p'
          variant='caption1'
          color={ColorVars['Grey']['Grey 1100']}
        >
          {t('page.description.acceptReturnsPolicy')}
        </Text>
      </ScrollView>
      <Flex justifyContent='center'>
        {externalReturnPolicyPage ? (
          policyUrl && (
            <Link isDisabled={disabled} href={policyUrl} target='_blank'>
              <Text variant='caption1' color={ColorVars['B&W']['Foreground']}>
                {t('page.landing.viewFullPolicy')}
              </Text>
            </Link>
          )
        ) : (
          <Link isDisabled={disabled} href={privatePolicyLink} target='_blank'>
            <Text variant='caption1' color={ColorVars['B&W']['Foreground']}>
              {t('page.landing.viewFullPolicy')}
            </Text>
          </Link>
        )}
      </Flex>
    </Box>
  );
};

export default Policy;
