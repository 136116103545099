import { useCallback } from 'react';
import { Button } from 'react-aria-components';

import { carouselActionButtonRecipe } from './Carousel.css';
import { CarouselElementProps } from './type';

import { useTimeout } from '../../hooks/useTimeout';

const CarouselActionButton = ({
  nextSilde,
  prevSilde,
  duration,
}: Pick<
  CarouselElementProps,
  'nextSilde' | 'prevSilde' | 'animated' | 'setAnimated' | 'duration'
>) => {
  const { isWaiting, start } = useTimeout({ delay: duration });
  const handlePress = useCallback(
    (direction: 'prev' | 'next') => {
      if (isWaiting) return;
      start();
      direction === 'prev' ? prevSilde() : nextSilde();
    },
    [isWaiting, start, prevSilde, nextSilde],
  );
  return (
    <>
      {/* {TODO} UI */}
      <Button className={carouselActionButtonRecipe()} onPress={() => handlePress('prev')}>
        Prev
      </Button>
      <Button
        className={carouselActionButtonRecipe()}
        style={{ insetInlineEnd: 0 }}
        onPress={() => handlePress('next')}
      >
        Next
      </Button>
    </>
  );
};

export default CarouselActionButton;
