'use client';

import { HeaderSlotProps } from './type';

import { Icon, IconCross } from '../../components/Icon';
import { AiraOverlayTriggerState, OverlayTrigger } from '../../components/Overlay';

export default function CloseButton({
  onClose,
  className,
}: Pick<HeaderSlotProps, 'onClose' | 'className'>) {
  const handleClose = (state?: AiraOverlayTriggerState) => {
    setTimeout(() => {
      onClose?.();
    }, 20);
    state?.close();
  };
  return (
    <OverlayTrigger onPress={handleClose} className={className}>
      <Icon source={IconCross} color='currentColor' size={'36px'} />
    </OverlayTrigger>
  );
}
