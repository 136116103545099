import React from 'react';

import {
  Box,
  Flex,
  FlexProps,
  Icon,
  LeftChevronThin,
  Pressable,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { wrapperClassName } from './FullScreenCard.css';

import { ScrollBox } from '../ScrollBox';

interface FullScreenCardProps extends FlexProps {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  children: React.ReactNode;
  onBack?: () => void;
}

const FullScreenCard = ({
  title = '',
  footer,
  children,
  onBack,
  ...props
}: FullScreenCardProps) => {
  return (
    <Flex
      position='relative'
      flex={1}
      height='0'
      direction='column'
      className={wrapperClassName}
      {...props}
    >
      <Box
        paddingTop={SpacingVars['7']}
        paddingBottom={SpacingVars['4']}
        paddingX={SpacingVars['4']}
        backgroundColor={ColorVars['B&W']['Background']}
      >
        <Flex
          alignItems='center'
          justifyContent='center'
          width={SpacingVars['6']}
          height={SpacingVars['6']}
        >
          <Pressable onPress={onBack}>
            <Icon
              source={LeftChevronThin}
              size={SpacingVars['6']}
              color={ColorVars['Grey']['Grey1200']}
            />
          </Pressable>
        </Flex>
      </Box>
      <ScrollBox backgroundColor={ColorVars['B&W']['Background']} offsetY={SpacingVars[10]}>
        <Flex height='100%' flex={1} direction='column'>
          {typeof title === 'string' ? (
            <Text
              variant='title1'
              textAlign='center'
              color={ColorVars['Grey']['Grey1200']}
              paddingX={SpacingVars['2']}
            >
              {title}
            </Text>
          ) : (
            title
          )}
          {children}
        </Flex>
      </ScrollBox>
      {footer}
    </Flex>
  );
};

export default FullScreenCard;
