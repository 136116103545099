import { ReactNode, createContext, useMemo, useState } from 'react';
import { UAParser } from 'ua-parser-js';

import { useIsMounted } from '@aftership/design-system';

import { useMediaQuery } from '@/hooks/useMediaQuery';

export enum PreviewLayout {
  mobile,
  desktop,
}

interface BreakPointContextProps {
  isPreview?: boolean;
  uaString?: string;
  children: ReactNode;
}
interface BreakPointContextValue {
  device: {
    mobile: boolean;
    tablet: boolean;
    desktop: boolean;
    ultra: boolean;
  };
  updatePreviewLayout?: (layout: PreviewLayout) => void;
}

export const BREAK_POINTS = { lg: 2560, md: 1920, sm: 800, xs: 0 };

export const BreakPointContext = createContext<BreakPointContextValue>({
  device: { mobile: false, tablet: false, desktop: true, ultra: false },
});

const BreakPointProvider = ({ isPreview = false, uaString, children }: BreakPointContextProps) => {
  const [previewLayout, setPreviewLayout] = useState<PreviewLayout>(PreviewLayout.desktop);
  const mobile = useMediaQuery(`only screen and (max-width : ${BREAK_POINTS.sm}px)`);
  const tablet = useMediaQuery(
    `only screen and (min-width : ${BREAK_POINTS.sm + 1}px) and (max-width : ${BREAK_POINTS.md}px)`,
  );
  const desktop = useMediaQuery(
    `only screen and (min-width : ${BREAK_POINTS.md + 1}px) and (max-width : ${BREAK_POINTS.lg}px)`,
  );

  const ultra = useMediaQuery(`only screen and (min-width : ${BREAK_POINTS.lg + 1}px)`);
  const parser = new UAParser(uaString);
  const isMobile = parser.getDevice().type === 'mobile';
  const isMounted = useIsMounted();

  const value = useMemo(() => {
    // 服务端渲染时以 uaString 为准
    if (!isMounted) {
      return {
        device: { mobile: isMobile, tablet: false, desktop: !isMobile, ultra: false },
        updatePreviewLayout: setPreviewLayout,
      };
    }

    return {
      // preview 模式以 message bridge 传过来的 layout为准
      device: isPreview
        ? {
            mobile: previewLayout === PreviewLayout.mobile,
            tablet: false,
            desktop: previewLayout === PreviewLayout.desktop,
            ultra: false,
          }
        : { mobile, tablet, desktop, ultra },
      updatePreviewLayout: setPreviewLayout,
    };
  }, [isMounted, isPreview, previewLayout, mobile, tablet, desktop, ultra, isMobile]);

  return <BreakPointContext.Provider value={value}>{children}</BreakPointContext.Provider>;
};

export default BreakPointProvider;
