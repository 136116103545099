import { IconSource } from './type';

const Trailing: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
      <path
        d='M6 13L10.8232 8.17678C10.9209 8.07915 10.9209 7.92085 10.8232 7.82322L6 3'
        stroke='currentColor'
        strokeOpacity='0.66'
        strokeWidth='1.75'
        strokeLinecap='round'
      />
    </svg>
  );
};

export default Trailing;
