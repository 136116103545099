import { GiftReturnResolution, GiftReturnSettings, Resolution } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

import { useTranslateResolutionInfo } from '@/hooks/useResolutionInfo';

const useResolutionOptions = () => {
  const giftReturnSetting =
    useFlow()?.context?.storeConfig?.giftReturnSetting ?? ({} as GiftReturnSettings);

  const translatedResolutionInfo = useTranslateResolutionInfo();

  const resolutions = [];
  if (giftReturnSetting?.resolutions?.replace_with_the_same_item_active) {
    resolutions.push({
      label: translatedResolutionInfo[Resolution.ReplaceTheSameItem].name,
      value: GiftReturnResolution.replaceWithTheSameItem,
    });
  }

  if (giftReturnSetting?.resolutions?.refund_to_store_credit_active) {
    resolutions.push({
      label: translatedResolutionInfo[Resolution.StoreCredit].name,
      value: GiftReturnResolution.storeCredit,
    });
  }

  return resolutions;
};

export default useResolutionOptions;
