'use client';

import { useTranslation } from 'react-i18next';

import { Button, Checkbox, Flex, Text } from '@aftership/design-system';
import { ColorVars, TypographyVars } from '@aftership/design-tokens';
import { usePreviewContext } from '@aftership/preview-kit/client';

import { useClickwrapContext } from '@/features/returns/components/ClickwrapProvider';

import ClickWrapModal from './ClickwrapModal';

import RichTextRender from '../../../components/RichTextRender/RichTextRender';

interface IClickWrapProps {
  isClickwrapChecked: boolean;
  clickwrapError?: boolean;
}

const ClickWrap = ({ isClickwrapChecked, clickwrapError }: IClickWrapProps) => {
  const { t } = useTranslation();

  const {
    isClickwrapPopupOpen,
    setClickwrapPopupOpen,
    clickwrapConfig,
    setClickwrapChecked,
    setWarningMsgVisible,
  } = useClickwrapContext();

  const { isPreview } = usePreviewContext();

  const handleViewDetail = () => {
    setClickwrapPopupOpen(true);
  };

  const handleCloseClickwrapModal = () => {
    setClickwrapPopupOpen(false);
  };

  const onClickwrapChange = (selected: boolean) => {
    setClickwrapChecked(selected);
    setWarningMsgVisible(!selected);
  };

  return (
    <Flex direction='column' gap={4} width={'100%'}>
      {clickwrapError && (
        <Text variant='caption1' color={ColorVars['Red']['Red 1000']}>
          {t('page.landing.CheckAgreement')}
        </Text>
      )}

      <Flex alignItems='center' gap={0}>
        <Checkbox isSelected={isClickwrapChecked} onChange={onClickwrapChange}>
          <Text variant='caption1'>
            <RichTextRender
              componentType='style'
              content={`.clickwrap-summary p {display: inline; font-size: ${TypographyVars['Caption 1'].Size}; line-height: ${TypographyVars['Caption 1']['Line Height']}; font-family: ${TypographyVars['Caption 1'].Font}; font-weight:${TypographyVars['Caption 1'].Weight}; display: inline; letter-spacing: ${TypographyVars['Caption 1']['Letter Spacing']}} `}
            />
            <RichTextRender
              componentType='span'
              className='clickwrap-summary'
              content={t('page.landing.termsConditions')}
            />
          </Text>
          &nbsp;
          {!!clickwrapConfig?.popup_enabled && (
            <Button onPress={handleViewDetail} variant='plain'>
              <Text variant='caption1'>{t('page.landing.viewDetail')}</Text>
            </Button>
          )}
        </Checkbox>
      </Flex>
      <ClickWrapModal
        isOpen={isClickwrapPopupOpen}
        onClose={handleCloseClickwrapModal}
        title={clickwrapConfig?.popup_title}
        content={clickwrapConfig?.popup_body}
        isPreview={isPreview}
      />
    </Flex>
  );
};

export default ClickWrap;
