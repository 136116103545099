import { fromPromise } from 'xstate';

import {
  GetMatchingResolutionsInput,
  PostReturnPreviewInput,
  Preview,
  ResolutionMatchingData,
} from '../../core';
import { defaultResolution } from '../requestData/resolution/defaultResolution';
import { defaultPreview } from '../requestData/review/defaultReview';

export const mockPostReturnPreview = fromPromise<Preview, PostReturnPreviewInput['input']>(
  async () => {
    return defaultPreview;
  },
);

export const mockGetMatchingResolutions = fromPromise<
  ResolutionMatchingData,
  GetMatchingResolutionsInput['input']
>(async () => {
  return defaultResolution;
});
