import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { Divider } from '@/components/Divider';
import useDevice from '@/hooks/useDevice';
import { toCurrency } from '@/utils/price';

import SectionLayout, { SectionCommonProps, SummarySectionName } from './SectionLayout';

import { useSummaryContext } from '../SummaryProvider';

const ExchangesSection = ({ isFold, showDivider }: { isFold: boolean } & SectionCommonProps) => {
  const { previewSummary: returnPreviewSummary, exchangeItemsLength } = useSummaryContext();
  const { t } = useTranslation();
  const isMobile = useDevice().mobile;

  const exchangeTaxIncluded = returnPreviewSummary?.exchange_item_taxes_included;
  const exchangeSubtotal = returnPreviewSummary?.exchange_item_subtotal_set?.presentment_money;
  const exchangeTotal = returnPreviewSummary?.exchange_total_set?.presentment_money;
  const exchangeTaxTotal = returnPreviewSummary?.exchange_item_tax_total_set?.presentment_money;

  const showTaxLabel = !!exchangeTaxIncluded && !!exchangeTotal;
  const showExchangeTax = !exchangeTaxIncluded && !!exchangeTaxTotal;

  const totalAmount = Number(exchangeTotal?.amount ?? 0);
  // section 栏的展示价格
  const sectionValue = totalAmount ? toCurrency(totalAmount, exchangeTotal?.currency) : '-';

  const exchangeAmount = showTaxLabel ? totalAmount : Number(exchangeSubtotal?.amount ?? 0);
  // exchange 的展示价格
  const exchangesValue = exchangeAmount
    ? toCurrency(exchangeAmount, exchangeSubtotal?.currency)
    : '-';
  // 展示 tax 价格
  const exchangesTaxValue =
    showExchangeTax &&
    exchangeTaxTotal &&
    toCurrency(exchangeTaxTotal.amount, exchangeTaxTotal.currency);

  const getExchangeItemQty = () => {
    if (exchangeItemsLength) return exchangeItemsLength;
    return '';
  };

  const exchangeItemQty = getExchangeItemQty();

  const exchangeItemLabel = `${Number(exchangeItemQty) > 1 ? t('v2.page.general.exchangeItems') : t('v2.page.general.exchangeItem')}${
    showTaxLabel ? ` (${t('page.general.taxIncluded')})` : ''
  }`;

  return (
    <Flex direction='column' gap={SpacingVars['1']}>
      <SectionLayout name={SummarySectionName.Exchanges}>
        <Text variant='body1'>{t('page.general.exchanges')}</Text>
        <Text variant='body1'>{sectionValue}</Text>
      </SectionLayout>

      {!isFold && (
        <>
          <SectionLayout>
            <Text
              variant='body2'
              color={ColorVars.Grey['Grey 1100']}
            >{`${exchangeItemQty} ${exchangeItemLabel}`}</Text>
            <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
              {exchangesValue}
            </Text>
          </SectionLayout>
          {showExchangeTax && (
            <SectionLayout>
              <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
                {t('v2.page.general.tax')}
              </Text>
              <Text variant='body2' color={ColorVars.Grey['Grey 1100']}>
                {exchangesTaxValue ?? '-'}
              </Text>
            </SectionLayout>
          )}
          {!isMobile && showDivider && <Divider spacing={SpacingVars['3']} />}
        </>
      )}
    </Flex>
  );
};

export default ExchangesSection;
