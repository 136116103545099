import { fromPromise } from 'xstate';

import { v4hostName } from '..//constant/hostname';
import { request } from '..//request/request';
import { ResponseBody } from '..//request/response';
import { ReturnableOrderItem } from '../types';
import { ReturnReasonData, ReturnReasonResponseData } from '../types/reason';

export type GetReasonsInput = {
  input: { returnableItems: Array<ReturnableOrderItem>; orderId: string; token?: string };
};
export const getReasons = fromPromise(async ({ input }: GetReasonsInput) => {
  const { returnableItems, orderId } = input;

  const flattenItemIds = returnableItems
    .reduce<Array<ReturnableOrderItem>>((pre, cur) => {
      pre.push(cur);
      if (cur.bundledItems) {
        pre.push(...cur.bundledItems);
      }
      return pre;
    }, [])
    .map(({ external_id }) => ({ item_id: external_id }));

  const payload = {
    order: { id: orderId ?? '' },
    return_items: flattenItemIds,
  };

  const res = await request<ResponseBody<ReturnReasonResponseData>>(
    `${v4hostName()}/return-reason-matchings`,
    {
      method: 'POST',
      payload,
      headers: {
        'returns-authorization': input.token!,
      },
    },
  );

  const reasonsData = res.data;
  if (reasonsData) {
    const { reason_groups, items_return_reason_groups_mapping } = reasonsData;
    const reasonGroups = reason_groups ?? [];
    const shuffledReturnReasonGroups = reasonGroups.map((groupValues) => {
      const { return_reasons: reasons } = groupValues;
      return {
        ...groupValues,
        return_reasons: reasons,
      };
    });
    return {
      items_return_reason_groups_mapping,
      reason_groups: shuffledReturnReasonGroups,
    } as ReturnReasonData;
  }

  return {
    items_return_reason_groups_mapping: {},
    reason_groups: [],
  } as ReturnReasonData;
});
