import {
  connectorsEndpoint,
  expShopperV4Endpoint,
  shopperEndpoint,
  shopperV2Endpoint,
  shopperV3Endpoint,
  shopperV4Endpoint,
} from '@/config/endPoints';

export const hostName = shopperEndpoint;

export const v2hostName = shopperV2Endpoint;

export const v3hostName = shopperV3Endpoint;

export const v4hostName = shopperV4Endpoint;
// 实验性 v4 base url
export const expV4hostName = expShopperV4Endpoint;

export const connectorHostName = connectorsEndpoint;

export const SHOP_HOST_NAME = 'shopHostName';
