import { omit } from 'lodash-es';
import { useMemo } from 'react';

import { ColorVars, RadiusVars, SpacingVars } from '@aftership/design-tokens';

import useSpecialPoint from '@/features/returns/hooks/useSpecialPoint';
import useDevice from '@/hooks/useDevice';

import { cardHeaderBottomStyle, noOutlineClassname } from './cartStyle.css';

const DESKTOP_CONTAINER_LAYOUT_GAP = 24;

const desktopLeftWidth = 560;
const desktopRightWidth = 400;

enum Mode {
  /**
   * Desktop mode
   */
  Desktop = 'desktop',
  /**
   * Special desktop mode, 宽度介于 mobile 和 desktop 之间
   */
  Special = 'special',
  /**
   * Mobile mode
   */
  Mobile = 'mobile',
}

/**
 * Return detail label container style
 */
class LabelContainerStyle {
  mode: Mode;
  backgroundColor: string;
  paddingY: string;
  borderRadius: string;
  paddingX: string;
  constructor(mode: Mode) {
    this.mode = mode;
    this.backgroundColor = ColorVars['Grey']['Grey 100'];
    this.paddingY = SpacingVars['6'];
    this.paddingX = this.mode === Mode.Mobile ? SpacingVars['4'] : SpacingVars['6'];
    this.borderRadius = RadiusVars['1'];
  }
}

class SummaryCartProps {
  mode: Mode;
  paddingY?: string;
  borderRadius?: string;
  backgroundColor?: string;
  className?: string;
  width: string | number;
  constructor(mode: Mode) {
    this.mode = mode;
    this.paddingY = this.mode === Mode.Mobile ? '0px' : void 0;
    this.borderRadius = this.mode === Mode.Mobile ? '0px' : void 0;
    this.backgroundColor = this.mode === Mode.Mobile ? 'transparent' : void 0;
    this.className = this.mode === Mode.Mobile ? noOutlineClassname : void 0;
    this.width = this.#calculateWidth();
  }

  #calculateWidth() {
    const mode = this.mode;
    if (mode === Mode.Special) {
      return '100%';
    } else if (mode === Mode.Mobile) {
      return 'auto';
    } else return desktopRightWidth;
  }
}

class NormalStyle {
  mode: Mode;
  width: string | number;
  boarderRadius?: string;
  constructor(mode: Mode) {
    this.mode = mode;
    this.width = this.#calculateWidth();
    this.boarderRadius = this.#calculateBorderRadius();
  }

  #calculateWidth() {
    const mode = this.mode;
    if (mode === Mode.Special) {
      return '100%';
    } else if (mode === Mode.Mobile) {
      return 'auto';
    } else return desktopLeftWidth;
  }

  #calculateBorderRadius() {
    return this.mode === Mode.Mobile ? '0px' : void 0;
  }
}

const useCartStyle = () => {
  const { mobile: isMobile } = useDevice();
  const { specialDesktop } = useSpecialPoint();
  const isSpecialDesktop = specialDesktop?.review || specialDesktop?.returnDetail;

  const mode = useMemo(() => {
    if (isSpecialDesktop) {
      return Mode.Special;
    } else if (isMobile) {
      return Mode.Mobile;
    }
    return Mode.Desktop;
  }, [isMobile, isSpecialDesktop]);

  const desktopLayoutGap = useMemo(() => {
    return `${DESKTOP_CONTAINER_LAYOUT_GAP}px`;
  }, []);

  const backgroundColor = ColorVars['Grey']['Grey 100'];
  const labelBackgroundColor = ColorVars['Grey']['Grey 100'];
  const mobileBackgroundColor = isMobile ? backgroundColor : void 0;
  const summaryBackgroundColor = isMobile ? 'transparent' : void 0;

  const mobileNoOutlineClassname = isMobile ? noOutlineClassname : void 0;

  const normalStyle = useMemo(() => omit(new NormalStyle(mode), 'mode'), [mode]);
  const labelContainerStyle = useMemo(() => omit(new LabelContainerStyle(mode), 'mode'), [mode]);
  const summaryCartProps = useMemo(() => omit(new SummaryCartProps(mode), 'mode'), [mode]);

  return {
    isMobile,
    isSpecialDesktopForReview: specialDesktop?.review,
    isSpecialDesktopForReturnDetail: specialDesktop?.returnDetail,
    normalStyle,
    labelContainerStyle,
    backgroundColor,
    labelBackgroundColor,
    desktopLayoutGap,
    mobileNoOutlineClassname,
    mobileBackgroundColor,
    summaryBackgroundColor,
    summaryCartProps,
    cardHeaderBottomStyle,
  };
};

export default useCartStyle;
