import { useEffect, useState } from 'react';

import { isRTL } from './isRTL';

import { DEFAULT_LOCALE } from '../constant/locale';
import { useIsSSR } from '../hooks/useIsSSR';
import { Direction } from '../types';

export interface Locale {
  /** The [BCP47](https://www.ietf.org/rfc/bcp/bcp47.txt) language code for the locale. */
  locale: string;
  /** The writing direction for the locale. */
  direction: Direction;
}

export function getDefaultLocale(): Locale {
  let locale =
    typeof window !== 'undefined' ||
    // @ts-ignore
    (typeof navigator !== 'undefined' && (navigator.language || navigator.userLanguage)) ||
    'en-US';

  try {
    // @ts-ignore
    Intl.DateTimeFormat.supportedLocalesOf([locale]);
  } catch (_err) {
    locale = 'en-US';
  }
  return {
    locale,
    direction: isRTL(locale) ? 'rtl' : 'ltr',
  };
}

let currentLocale = getDefaultLocale();
let listeners = new Set<(locale: Locale) => void>();

function updateLocale() {
  currentLocale = getDefaultLocale();
  for (let listener of listeners) {
    listener(currentLocale);
  }
}

/**
 * Returns the current browser/system language, and updates when it changes.
 */
export function useDefaultLocale(): Locale {
  let isSSR = useIsSSR();
  let [defaultLocale, setDefaultLocale] = useState(currentLocale);

  useEffect(() => {
    if (listeners.size === 0) {
      window.addEventListener('languagechange', updateLocale);
    }

    listeners.add(setDefaultLocale);

    return () => {
      listeners.delete(setDefaultLocale);
      if (listeners.size === 0) {
        window.removeEventListener('languagechange', updateLocale);
      }
    };
  }, []);

  if (isSSR) {
    return {
      locale: DEFAULT_LOCALE,
      direction: 'ltr',
    };
  }

  return defaultLocale;
}
