import React, { useCallback, useEffect, useRef } from 'react';

import { carouselItemBoxRecipe, carouselItemStyle, carouselWrapperRecipe } from './Carousel.css';
import CarouselActionButton from './CarouselActionButton';
import { CarouselElementProps } from './type';

const CarouselAnimateWrapper = ({
  renderItem,
  animated,
  index,
  duration = 300,
  actionArrows = false,
  prevSilde,
  nextSilde,
  setAnimated,
  moveSildeTo,
  items,
}: CarouselElementProps) => {
  /****************** State and Args  ******************/
  const position = items.length > 1 ? index * 100 : 0;
  const touchPosition = useRef({
    startX: 0,
    endX: 0,
  });

  /******************  Handle special slides ******************/
  const handleFirstAndLastSildeIndex = useCallback(() => {
    if (index === 0) {
      /** Pull back to the last slide **/
      setTimeout(() => {
        setAnimated(false);
        moveSildeTo(items.length);
      }, duration);
    } else if (index === items.length + 1) {
      /** Pull back to the first slide **/
      setTimeout(() => {
        setAnimated(false);
        moveSildeTo(1);
      }, duration);
    }
  }, [index, duration, items, moveSildeTo, setAnimated]);

  useEffect(handleFirstAndLastSildeIndex, [handleFirstAndLastSildeIndex]);

  /** handle mobile touch **/
  const touchProps = {
    onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => {
      touchPosition.current.startX = e.touches[0].clientX;
    },
    onTouchMove: (e: React.TouchEvent<HTMLDivElement>) => {
      touchPosition.current.endX = e.touches[0].clientX;
    },
    onTouchEnd: () => {
      const offset = touchPosition.current.startX - touchPosition.current.endX;
      if (offset > 50) {
        nextSilde();
      } else if (offset < -50) {
        prevSilde();
      }
    },
  };

  const itemRender = (item: any, key?: number) => (
    <div key={key} className={carouselItemStyle}>
      {renderItem(item, { active: key === index })}
    </div>
  );

  return (
    <div className={carouselWrapperRecipe()} {...touchProps}>
      <div
        className={carouselItemBoxRecipe({ animated })}
        style={{
          transform: `translateX(-${position}%)`,
          transitionDuration: `${duration / 1000}s`,
        }}
      >
        {items.length > 1
          ? [items[items.length - 1], ...items, items[0]].map(itemRender)
          : itemRender(items[0])}
      </div>
      {actionArrows === true ? (
        <CarouselActionButton {...{ nextSilde, prevSilde, animated, setAnimated, duration }} />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CarouselAnimateWrapper;
