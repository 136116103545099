import { fromPromise } from 'xstate';

export const mockAny = fromPromise(async ({ input: { token } }: { input: { token: string } }) => {
  // 模拟的URI和请求
  const uri = 'https://example.com/block-rules'; // 这是一个示例URI
  const mockRequest = async (uri: string, options: Record<string, any>) => {
    console.log('请求URI:', uri);
    console.log('请求选项:', options);
    // 模拟请求的延迟
    await new Promise((resolve) => setTimeout(resolve, 1000));
    // 返回模拟的响应
    return {
      status: 200,
      json: async () => ({
        success: true,
        message: '模拟请求成功',
      }),
    };
  };

  const res = await mockRequest(uri, {
    method: 'POST',
    headers: { 'returns-authorization': token },
    body: JSON.stringify({
      for: 'for',
      bar: ['bar', 'bar'],
    }),
  });

  if (res.status === 200) {
    const data = await res.json();
    console.log('响应数据:', data);
    return data.success;
  } else {
    console.error('请求失败:', res.status);
    return false;
  }
});
