import { createContext, useContext, useState } from 'react';

export interface StripeContextValue {
  isStripePaymentLoading?: boolean;
  isStripeReady?: boolean;
  stripeSubmit?: (() => Promise<string>) | null;
  setIsStripePaymentLoading?: (loading: boolean) => void;
  setStripeReady?: (ready: boolean) => void;
  setStripeSubmit?: React.Dispatch<React.SetStateAction<(() => Promise<string>) | null>>;
}

interface StripeContextProviderProps extends StripeContextValue {
  children: React.ReactNode;
}

export const StripeContext = createContext<StripeContextValue>({});

export const StripeContextProvider = (props: StripeContextProviderProps) => {
  const { children, ...rest } = props;
  return <StripeContext.Provider value={rest}>{children}</StripeContext.Provider>;
};

export const useCreateStripeContextValue = () => {
  const [isStripePaymentLoading, setIsStripePaymentLoading] = useState(false);
  const [isStripeReady, setStripeReady] = useState(false);
  const [stripeSubmit, setStripeSubmit] = useState<(() => Promise<string>) | null>(null);

  return {
    isStripePaymentLoading,
    isStripeReady,
    stripeSubmit,
    setIsStripePaymentLoading,
    setStripeReady,
    setStripeSubmit,
  };
};

export const useStripeContext = () => {
  const context = useContext(StripeContext) ?? {};
  return context;
};
