/* eslint-disable max-lines */
// @ts-nocheck
import { ISelectedItem, OrderResponse } from '../../../core';
import { defaulExchangeItems } from '../exchanges/defaultExchangeItems.ts';
import { mockCurrency } from '../global/global.ts';
import { defaultFallbackImage } from '../returns/defaultReturns';

// 这些得改，是从原来拷贝过来的
export const mockItemId = '13988733812990';
export const mockSelectedProductId = '7705443860697';
export const mockReplaceItemId = '42894696382681';
export const mockOrderNumber = '1005';
export const mockCustomerEmail = 'test@aftership.com';
export const mockTag = 'mockTag';

export const defaultOrder: OrderResponse = {
  order: {
    id: 'a2ba0c87416f4fdc9efbba9e38369f59',
    external_id: '5677381714174',
    order_number: '1141',
    order_name: '#1141',
    app: {
      key: 'test0722-10000',
      platform: 'shopify',
    },
    organization: {
      id: 'f64e2c3030584d84ba88fea2b1151c3b',
    },
    tags: [],
    taxes_included: false,
    subtotal_set: {
      shop_money: {
        amount: '400.00',
        currency: mockCurrency,
      },
      presentment_money: {
        amount: '400.00',
        currency: mockCurrency,
      },
    },
    discount_total_set: {
      shop_money: {
        amount: '0.00',
        currency: mockCurrency,
      },
      presentment_money: {
        amount: '0.00',
        currency: mockCurrency,
      },
    },
    tax_total_set: {
      shop_money: {
        amount: '20.00',
        currency: mockCurrency,
      },
      presentment_money: {
        amount: '20.00',
        currency: mockCurrency,
      },
    },
    // shipping_tax_set: {
    //   shop_money: null,
    //   presentment_money: null,
    // },
    // shipping_discount_set: {
    //   shop_money: null,
    //   presentment_money: null,
    // },
    shipping_total_set: {
      shop_money: {
        amount: '0.00',
        currency: mockCurrency,
      },
      presentment_money: {
        amount: '0.00',
        currency: mockCurrency,
      },
    },
    order_total_set: {
      shop_money: {
        amount: '420.00',
        currency: mockCurrency,
      },
      presentment_money: {
        amount: '420.00',
        currency: mockCurrency,
      },
    },
    items: [
      {
        external_id: mockItemId,
        sku: '999008',
        quantity: 3,
        image_urls: [defaultFallbackImage],
        categories: [],
        tags: [mockTag],
        // discount_set: {
        //   shop_money: null,
        //   presentment_money: null,
        // },
        tax_set: {
          shop_money: {
            amount: '20.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '20.00',
            currency: mockCurrency,
          },
        },
        unit_price_set: {
          shop_money: {
            amount: '100.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '100.00',
            currency: mockCurrency,
          },
        },
        unit_price_incl_tax_set: {
          shop_money: {
            amount: '210.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '210.00',
            currency: mockCurrency,
          },
        },
        base_price_set: {
          shop_money: {
            amount: '100.00',
            currency: mockCurrency,
          },
          presentment_money: {
            amount: '100.00',
            currency: mockCurrency,
          },
        },
        discounted_base_price_set: {
          shop_money: null,
          presentment_money: {
            amount: '100.00',
            currency: mockCurrency,
          },
        },
        external_product_id: '7781956452606',
        external_variant_id: '43159344578814',
        title: 'Product title',
        product_title: 'Product title',
        variant_title: '',
        properties: [],
        type: 'normal',
        external_parent_id: '',
        return_rule: '',
        bundled_items: [],
      },
    ],
    shipping_address: {
      country: 'ARE',
      state: 'Dubai',
    },
    metrics: {
      placed_at: '2024-07-15T11:53:46Z',
    },
    created_at: '2024-07-15T11:53:49Z',
  },
  store: {
    url: 'https://test0722-10000.myshopify.com',
  },
  eligibility: {
    order: {
      allow: true,
      any_of: {
        allow_list: {
          allow: false,
          context: {},
        },
        other_eligibility: {
          all_of: {
            block_list: {
              allow: true,
              context: null,
            },
            create_multiple_returns: {
              allow: true,
              context: {
                accept_multiple_returns: true,
                created_return_ids: ['7965c85168724d59bfc65f38a0e70b15'],
              },
            },
            unfulfilled_order: {
              allow: true,
              context: {
                prevent_unfulfilled_orders_enabled: false,
              },
            },
          },
          allow: true,
        },
      },
    },
    items: [
      {
        item_id: mockItemId,
        eligibility: {
          all_of: {
            sub_eligibility: {
              all_of: {
                item_eligibility: {
                  allow: true,
                  any_of: {
                    allow_list: {
                      allow: false,
                      context: {},
                    },
                    other_eligibility: {
                      all_of: {
                        allowed_to_return_discounted_items: {
                          allow: true,
                          context: {
                            accept_discounted: true,
                            item_discount_amount: 0,
                          },
                        },
                        block_list: {
                          allow: true,
                          context: null,
                        },
                        order_eligibility: {
                          all_of: {
                            block_list: {
                              allow: true,
                              context: null,
                            },
                            create_multiple_returns: {
                              allow: true,
                              context: {
                                accept_multiple_returns: true,
                                created_return_ids: ['7965c85168724d59bfc65f38a0e70b15'],
                              },
                            },
                            unfulfilled_order: {
                              allow: true,
                              context: {
                                prevent_unfulfilled_orders_enabled: false,
                              },
                            },
                          },
                          allow: true,
                        },
                        prevent_unfulfilled: {
                          allow: true,
                          context: {
                            prevent_unfulfilled_orders_enabled: false,
                          },
                        },
                        return_window: {
                          allow: true,
                          any_of: {
                            base_return_window: {
                              all_of: {
                                apply_base_return_window: {
                                  allow: true,
                                  context: {
                                    reason: 'not matched conditional return window',
                                  },
                                },
                                return_window_by_date: {
                                  allow: true,
                                  any_of: {
                                    return_window_by_delivery_date: {
                                      allow: true,
                                      context: {
                                        delivery_date: '2024-07-15T11:53:46Z',
                                        fulfillment_date: '2024-07-15T11:53:46Z',
                                        order_date: '2024-07-15T11:53:46Z',
                                        return_window: 0,
                                        return_window_based_on: 'delivery_date',
                                      },
                                    },
                                    return_window_by_fulfillment_date: {
                                      allow: true,
                                      context: {
                                        fulfillment_date: '2024-07-15T11:53:46Z',
                                        order_date: '2024-07-15T11:53:46Z',
                                        return_window: 0,
                                        return_window_based_on: 'delivery_date',
                                      },
                                    },
                                    return_window_by_order_date: {
                                      allow: true,
                                      context: {
                                        order_created_at: '2024-07-15T11:53:46Z',
                                        return_window: 0,
                                        return_window_based_on: 'delivery_date',
                                      },
                                    },
                                  },
                                },
                              },
                              allow: true,
                            },
                            return_window_by_condition: {
                              allow: false,
                              context: null,
                            },
                          },
                        },
                      },
                      allow: true,
                    },
                  },
                },
                returnable_quantity: {
                  allow: true,
                  context: {
                    returnable_quantity: 1,
                  },
                },
              },
              allow: true,
            },
            whole_bundle_remains_intact: {
              allow: true,
              context: {
                is_bundle: false,
              },
            },
          },
          allow: true,
        },
      },
    ],
  },
  returnable_items: [
    {
      external_id: mockItemId,
      quantity: 10,
      overriding_ineligibilities: [],
    },
  ],
  non_returnable_items: [
    {
      external_id: mockItemId,
      quantity: 1,
      ineligibilities: [
        {
          reason: 'unfulfilled_item',
          // reason: 'returnable_quantity',
          context: null,
        },
      ],
    },
  ],
  order_ineligibilities: [],
};

export const defaultSelectedItems: ISelectedItem[] = defaultOrder.order.items.map((item) => ({
  itemId: item.external_id,
  parentId: item.external_parent_id,
  variantId: item.external_variant_id,
  productId: item.external_product_id,
  productTitle: item.product_title,
  productTags: item.tags,
  productUrl: item.product_url,
  productCoverUrl: item.image_urls?.[0],
  variantTitle: item.variant_title,
  price: item.discounted_base_price_set.presentment_money,
  originPrice: item.base_price_set.presentment_money,
  quantity: 1,
  selectedReasonId: '',
  selectedReason: '',
  selectedSubReasonId: '',
  selectedSubReason: '',
  comment: '',
  exchangeVariant: defaulExchangeItems[0],
}));
