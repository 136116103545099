import { useReturns } from '@/features/returns/hooks/useReturns';

import useDevice from './useDevice';

import { LAYOUT_FOOTER_HEIGHT, LAYOUT_HEADER_HEIGHT } from '../utils/constants';

export const useStepCardMinHeight = () => {
  const { paddingTop, paddingBottom } = useDevice();
  const { isAppProxy } = useReturns() ?? {};

  if (isAppProxy) {
    return `calc(var(--proxy-content-height) - ${paddingTop + paddingBottom}px)`; // 在 useProxyWrapperStyle 中计算出来的值
  }

  return `calc(100vh - ${LAYOUT_HEADER_HEIGHT + LAYOUT_FOOTER_HEIGHT + paddingTop + paddingBottom}px)`;
};
