import { IconSource } from './type.ts';

/**
 * @description 厚一点的右箭头，用于 app proxy
 * @size 16 x 16
 */
const RightChevronThick: IconSource = () => {
  return (
    <svg width={17} height={16} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m6.334 12.667 4.549-4.55a.167.167 0 0 0 0-.235l-4.55-4.549'
        stroke='currentColor'
        strokeWidth={2}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default RightChevronThick;
