// 校验不为null或undefined，才渲染后面的内容
import { isNull, isUndefined } from 'lodash-es';

export function renderIfNotNullOrUndefined(
  value: React.ReactNode | undefined,
  content: React.ReactNode,
) {
  if (!isNull(value) && !isUndefined(value)) {
    return content;
  }
  return null;
}
