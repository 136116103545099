'use client';

import { Popover as AriaPopover, DialogTrigger, OverlayArrow } from 'react-aria-components';

import { popoverArrowRecipe, popoverContentWrapperStyle, popoverStyle } from './Popover.css';
import { PopoverProps, PopoverTriggerType } from './type';

import { clsx } from '../../utils/clsx';
import { isTrueAndPass } from '../../utils/isTrueAndPass';
import { usePortalContainer } from '../ThemeProvider/AppContainerContext';

const Popover = <T extends React.ElementType = PopoverTriggerType>(props: PopoverProps<T>) => {
  const {
    children,
    // {TODO} fix name
    trigger: Trigger = DialogTrigger,
    triggerProps,
    content,
    className,
    arrow = false,
    ...rest
  } = props;

  const container = usePortalContainer();
  return (
    <Trigger {...triggerProps}>
      {children}
      <AriaPopover
        {...rest}
        className={clsx(popoverStyle, className)}
        UNSTABLE_portalContainer={container}
      >
        {isTrueAndPass(
          arrow === true,
          <OverlayArrow>
            <svg className={popoverArrowRecipe()} width={12} height={12} viewBox='0 0 12 12'>
              <path d='M0 0 L6 6 L12 0' />
            </svg>
          </OverlayArrow>,
        )}
        <div className={popoverContentWrapperStyle}>{content}</div>
      </AriaPopover>
    </Trigger>
  );
};

export default Popover;
