export enum ShippingStatus {
  NoLabel = 'no_label',
  // todo: 拼写错误，要找后端确认下是不是正确的
  ParitallyReceived = 'paritally_received',
  Received = 'received',
  Shipped = 'shipped',
  PartiallyDroppedOff = 'partially_dropped',
  DroppedOff = 'dropped',
  Pending = 'pending',
  NoPrepaidLabel = 'no_label',
  InTransit = 'in_transit',
  OutForDelivery = 'out_for_delivery',
  AttemptFail = 'attempt_fail',
  Delivered = 'delivered',
  AvailableForPickup = 'available_for_pickup',
  Exception = 'exception',
  Expired = 'expired',
}

/**
 * 被退 item 的类型
 */
export enum ReturnType {
  NORMAL = 'normal',
  BUNDLE = 'bundle',
  BundledItem = 'bundled_item',
}

export enum ReturnStatus {
  SUBMITTED = 'submitted',
  APPROVED = 'approved',
  SHIPPED = 'shipped',
  RECEIVED = 'received',
  DONE = 'done',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
}

export enum DropoffStatus {
  Creating = 'creating',
  Created = 'created',
  Failed = 'failed',
  PartiallyDropped = 'partially_dropped',
  Dropped = 'dropped',
}

export enum IShippingDocumentType {
  Label = 'label',
  PackingSlip = 'packing_slip',
  ConditionalShippingDocument = 'conditional_shipping_document',
}

export enum ShippingFeeOption {
  FREE = 'free',
  CUSTOM = 'custom',
}
