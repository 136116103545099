import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import ErrorIcon from './ErrorIcon';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

interface Props {
  errorMsg: string;
  isOpen: boolean;
  onClose: VoidFunction;
}

const StripeErrorModal = ({ errorMsg, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();

  const Container = () => {
    return (
      <Flex direction='column' justifyContent='center' alignItems='center' gap={SpacingVars['6']}>
        <ErrorIcon />
        <Flex direction='column' justifyContent='center'>
          <Text variant='body1'>{t('popup.description.unsuccessfulPayment')}</Text>
          <Text variant='body1'>{errorMsg}</Text>
        </Flex>
      </Flex>
    );
  };
  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button onPress={onClose} size='large'>
          {t('popup.request.Close')}
        </Button>
      </ModalButtonGroup>
    );
  };
  if (mobile) {
    return (
      <MobilePopup isOpen={isOpen} title={t('popup.description.paymentFailed')} footer={<Footer />}>
        <Container />
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('popup.description.paymentFailed')} suffixIcon={<></>} />

      <ModalBody>
        <Container />
      </ModalBody>

      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};

export default StripeErrorModal;
