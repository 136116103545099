import { TFunction } from 'i18next';

import type { Shops } from 'returns-logics';

import ErrorPage from '@/components/ErrorPage/ErrorPage';
import { ReturnsPageAccessCode, ReturnsPageAccessStatus } from '@/features/returns/utils/constants';

export interface GetErrorPageProps {
  returnsPageAccessResult: Returns.ReturnsPageAccessResult;
  shopInfo: Shops;
  shopHostName: string;
  t: TFunction;
}

export function useGetErrorPage({
  returnsPageAccessResult,
  shopInfo,
  shopHostName,
  t,
}: GetErrorPageProps) {
  const { status, code } = returnsPageAccessResult;
  const { store_url } = shopInfo;
  const backToHomePageProps = {
    title: t('page.description.somethingWentWrong'),
    redirectUrl: shopHostName,
    redirectUrlText: t('page.error.backToHomePage'),
  };
  const backToStoreUrlProps = {
    title: t('page.error.pageNotFound'),
    message: t('page.description.pageHaveBeenRemoved'),
    redirectUrl: store_url,
    redirectUrlText: t('page.error.backToStoreUrl'),
  };

  if (status === ReturnsPageAccessStatus.Denied) {
    switch (code) {
      case ReturnsPageAccessCode.ReturnsPageNotAvailable:
        return <ErrorPage {...backToStoreUrlProps} />;
      case ReturnsPageAccessCode.ReturnsPageNotPublished:
        return <ErrorPage {...backToStoreUrlProps} />;
      case ReturnsPageAccessCode.AppProxyNotAvailable:
        return <ErrorPage {...backToHomePageProps} />;
      case ReturnsPageAccessCode.UnexpectedHostname:
        return <ErrorPage {...backToStoreUrlProps} />;
      case ReturnsPageAccessCode.ServerError:
        return <ErrorPage {...backToHomePageProps} />;
      default:
        return <ErrorPage {...backToHomePageProps} />;
    }
  }

  return null;
}
