import { MutableRefObject } from 'react';

import { Box, Flex } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import ExchangeAndEditItems from './components/ExchangeAndEditItems';
import { ExchangeAndEditItemsRef } from './components/ExchangeAndEditItems/ExchangeAndEditItems';
import { useReturnPreviewFlow } from './hooks/useReturnPreviewFlow';

import ExchangeNote from '../return-detail/components/ExchangeNote';
import ReturnItems from '../return-detail/components/ReturnItems';
import ShippingAddress from '../return-detail/components/ShippingAddress';

interface LeftColumnProps {
  exchangeAndEditItemsRef: MutableRefObject<ExchangeAndEditItemsRef | undefined>;
}
const LeftColumn = ({ exchangeAndEditItemsRef }: LeftColumnProps) => {
  const {
    returnItems = [],
    returnMethod,
    exchangeItems,
    resolution,

    comment,
    costOfReturnAmountPrice,
    costOfReturnOptions,
    shippingAddress,
  } = useReturnPreviewFlow();

  const { mobile } = useDevice();

  const showShippingAddressWhenNotUsedVariants = !exchangeItems?.length && shippingAddress;

  return (
    <Box backgroundColor={ColorVars['B&W']['Background']}>
      <Flex gap={mobile ? 0 : 24} direction='column'>
        {!!exchangeItems?.length && (
          <ExchangeAndEditItems
            exchangeItems={exchangeItems}
            resolution={resolution}
            ref={exchangeAndEditItemsRef}
          />
        )}
        <ReturnItems
          exchangeItemsExist={!!exchangeItems?.length}
          returnItems={returnItems}
          returnMethod={returnMethod}
          costOfReturAmountPrice={costOfReturnAmountPrice}
          costOfReturnOption={costOfReturnOptions}
        />
        {comment && <ExchangeNote comment={comment} />}
        {showShippingAddressWhenNotUsedVariants && <ShippingAddress address={shippingAddress} />}
      </Flex>
    </Box>
  );
};

export default LeftColumn;
