'use client';

import { ReactElement } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Radio, RadioGroup } from '@aftership/design-system';

interface BasicOption {
  label: string;
  value: string;
}

interface BasicOptionFunc {
  renderField?: (item: BasicOption, onChange: (...event: any[]) => void) => ReactElement;
}

export type Option = BasicOption & BasicOptionFunc;

export type Props = {
  control: Control<any>;
  name: string;
  defaultValue?: string;
  radioItems: Array<Option>;
};

function FormRadio({ control, name, radioItems }: Props) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <RadioGroup onChange={onChange} value={value}>
            {radioItems.map((item) => {
              return (
                <Radio value={item.value} key={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </RadioGroup>
        );
      }}
    />
  );
}

export default FormRadio;
