import { IconSource } from './type';

const Facebook: IconSource = () => (
  <svg width={36} height={36} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.357 11.989H22V9.127A21 21 0 0 0 19.606 9c-2.369 0-3.992 1.49-3.992 4.23v2.52H13v3.2h2.614V27h3.206v-8.05h2.508l.398-3.2H18.82v-2.203c0-.925.25-1.558 1.538-1.558'
      fill='currentColor'
      fillOpacity={0.95}
    />
  </svg>
);

export default Facebook;
