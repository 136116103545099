import React, { useMemo } from 'react';

const openGraphImage = require('@/assets/open-graph-image.png').default.src;
const openGraph = {
  type: 'website',
  title: 'Hassle-free returns',
  description:
    'Easily choose return resolutions and shipping methods that satisfy your needs. We prioritize your shopping experience and guarantee peace of mind when shopping with us. Powered by AfterShip Returns.',
  image: openGraphImage,
  imageType: 'image/png',
  imageAlt: 'Hassle-free returns',
  imageWidth: '1200',
  imageHeight: '630',
};

export interface OpenGraphProps {
  url: string;
}

const OpenGraph = ({ url }: OpenGraphProps) => {
  const openGraphMap = useMemo(
    () => [
      // Open Graph
      { property: 'og:type', content: openGraph.type },
      { property: 'og:title', content: openGraph.title },
      { property: 'og:description', content: openGraph.description },
      { property: 'og:image', content: openGraph.image },
      { property: 'og:image:secure_url', content: openGraph.image },
      { property: 'og:image:alt', content: openGraph.imageAlt },
      { property: 'og:image:type', content: openGraph.imageType },
      { property: 'og:image:width', content: openGraph.imageWidth },
      { property: 'og:image:height', content: openGraph.imageHeight },
      { property: 'og:url', content: url },
      // Twitter Card
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:title', content: openGraph.title },
      { property: 'twitter:description', content: openGraph.description },
      { property: 'twitter:image', content: openGraph.image },
      { property: 'twitter:image:alt', content: openGraph.imageAlt },
    ],
    [url],
  );

  return (
    <>
      {openGraphMap.map(({ property, content }) => (
        <meta key={property} property={property} content={content} />
      ))}
    </>
  );
};

export default OpenGraph;
