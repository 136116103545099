'use client';

import { popupBodyClassname, popupHeaderTitleClassname, popupRecipe } from './Popup.css';
import { PopupProps } from './type';

import { CloseButton, HeaderSlot } from '../../primitive';
import { popWrapperRecipe } from '../../style/animation.css';
import { popWrapperThemeClass } from '../../style/animationToken.css';
import { clsx } from '../../utils/clsx';
import { formatPopWrapperDimensions, useStyleProps } from '../../utils/styleProps';
import { withCls } from '../../utils/withCls';
import { Overlay } from '../Overlay';

const Popup = (props: PopupProps) => {
  const { children, className, placement = 'bottom', title, header, onClose, ...rest } = props;
  const { styleProps } = useStyleProps(rest);
  const style = formatPopWrapperDimensions(placement, styleProps);

  const defaultHeader = (
    <HeaderSlot
      prefixIcon={<div />}
      className={clsx(withCls('Popup-Header'), popupHeaderTitleClassname)}
      onClose={onClose}
      textAlign='center'
      title={title}
    ></HeaderSlot>
  );

  return (
    <Overlay
      maskClassName={clsx(withCls('Popup-Overlay'), popWrapperThemeClass)}
      className={clsx(withCls('Popup'), popWrapperRecipe({ placement }), popupRecipe(), className)}
      bodyClassName={popupBodyClassname}
      isDismissable
      {...rest}
      style={style}
    >
      {header || defaultHeader}
      {children}
    </Overlay>
  );
};

Popup.Header = HeaderSlot;
Popup.CloseButton = CloseButton;

export default Popup;
