import { IconSource } from './type.ts';

const LeftChevronCircle: IconSource = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12Z'
        fill='white'
      />
      <path
        d='M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12Z'
        stroke='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.4004 16.2C13.2212 16.2 13.042 16.1314 12.9055 15.9949L9.40547 12.4949C9.13177 12.2212 9.13177 11.7788 9.40547 11.5051L12.9055 8.00508C13.1792 7.73138 13.6216 7.73138 13.8953 8.00508C14.169 8.27878 14.169 8.72118 13.8953 8.99488L10.8902 12L13.8953 15.0051C14.169 15.2788 14.169 15.7212 13.8953 15.9949C13.7588 16.1314 13.5796 16.2 13.4004 16.2Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default LeftChevronCircle;
