'use client';

import { useTranslation } from 'react-i18next';

import { Box, Button, Flex, Icon, Text, Tick } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { useFlow } from 'returns-logics/react';

import { StepCard } from '@/components/StepCard';
import useDevice from '@/hooks/useDevice';

const Submmited = () => {
  const { t } = useTranslation();

  const { dispatch } = useFlow();

  const { mobile } = useDevice();

  const content = (
    <Box margin={'auto'}>
      <Flex direction='column' gap={SpacingVars['6']}>
        <Flex justifyContent='center'>
          <Icon size='80px' source={Tick} />
        </Flex>

        <Flex direction='column' gap={SpacingVars['6']} alignItems='center'>
          <Flex direction='column' gap={SpacingVars['2']} alignItems='center'>
            <Text variant='title2'>{t('page.gift.description.submitSuccessTitle')}</Text>
            <Text variant='caption1'>{t('page.gift.description.submitSuccessHint')}</Text>
          </Flex>
          <Button
            variant='link'
            onPress={() => {
              dispatch({ type: 'GO_TO_ORDER_LOOKUP' });
            }}
          >
            <Text variant='subtitle1'>{t('page.gift.action.requestAnother')}</Text>
          </Button>
        </Flex>
      </Flex>
    </Box>
  );

  if (mobile) {
    return (
      <Box backgroundColor='white' height={'100%'} paddingTop={180} paddingX={SpacingVars['4']}>
        {content}
      </Box>
    );
  }

  return (
    <StepCard minHeight={462} hiddenHeader width={792} title={<></>}>
      {content}
    </StepCard>
  );
};

export default Submmited;
