'use client';

import { NumberField as AriaNumberField, Button, Group, Input } from 'react-aria-components';

import {
  numberFieldGroupButtonRecipe,
  numberFieldGroupInputRecipe,
  numberFieldGroupRecipe,
  numberFieldRecipe,
} from './NumberField.css';
import { NumberFieldProps } from './type';

import { clsx } from '../../utils/clsx';
import { Icon, IconMinus, IconPlus } from '../Icon';

const NumberField = ({ outline, fullWidth, ...rest }: NumberFieldProps) => {
  return (
    <AriaNumberField {...rest} className={clsx(numberFieldRecipe({ fullWidth }))}>
      <Group
        className={numberFieldGroupRecipe({
          outline,
          fullWidth,
        })}
      >
        <Button slot='decrement' className={numberFieldGroupButtonRecipe({ outline })}>
          <Icon source={IconMinus} />
        </Button>
        <Input className={numberFieldGroupInputRecipe({ outline, fullWidth })} />
        <Button slot='increment' className={numberFieldGroupButtonRecipe({ outline })}>
          <Icon source={IconPlus} />
        </Button>
      </Group>
    </AriaNumberField>
  );
};

export default NumberField;
