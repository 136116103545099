/**
 * https://aftership.atlassian.net/browse/RTC-20150
 * 与客户约定,如果 product tags 是以 'storefront_desc:' 开头的,则提取出来展示
 * @param productTags
 */
export const extractStorefrontTags = (productTags: Array<string> = []) => {
  const prefix = 'storefront_desc:';
  return productTags?.reduce<string[]>((previous, tag) => {
    if (!tag.startsWith(prefix)) return previous;
    return [...previous, tag.replace(prefix, '')];
  }, []);
};
