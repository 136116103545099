import { fromPromise } from 'xstate';

import { hostName } from '../constant/hostname.ts';
import { request } from '../request/request.ts';
import { SuccessResponse } from '../request/response';
import { ShopEligibilityRulesData } from '../types/shopEligibilityRules';

export interface ShopEligibilityInput {
  input: { token: string; shopId: string };
}
export const getShopEligibilityRules = fromPromise<
  ShopEligibilityRulesData,
  ShopEligibilityInput['input']
>(async ({ input }: ShopEligibilityInput) => {
  const res = await request<SuccessResponse<ShopEligibilityRulesData>>(
    `${hostName()}/eligibility-rules?shop_id=${input.shopId}`,
    {
      method: 'GET',
      headers: {
        'returns-authorization': input.token,
      },
    },
  );
  return res.data;
});
