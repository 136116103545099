import { CSSProperties, HTMLAttributes } from 'react';

import { useDefaultLocale } from '../i18n/useDefaultLocale';
import { Direction } from '../types';
import { DimensionValue, StyleProps, ViewStyleProps } from '../types/styleProps';

type StyleName = string | string[] | ((dir: Direction) => string);
type StyleHandler = (value: any) => string | undefined;

export interface StyleHandlers {
  [key: string]: [StyleName, StyleHandler];
}

const UNIT_REG = /(%|px|em|rem|vw|vh|auto|cm|mm|in|pt|pc|ex|ch|rem|vmin|vmax|fr)$/;
const CSS_VAR_REG = /^--[\w-]+/;

function rtl(ltr: string, rtl: string) {
  return (direction: Direction) => (direction === 'rtl' ? rtl : ltr);
}

export function dimensionValue(value: DimensionValue) {
  if (typeof value === 'number' || Number.isFinite(Number(value))) {
    return value + 'px';
  }

  if (typeof value === 'undefined') {
    return undefined;
  }

  if (UNIT_REG.test(value)) {
    return value;
  }

  if (CSS_VAR_REG.test(value)) {
    return `var(${value})`;
  }

  return value;
}

export function primitiveValue(value: any) {
  if (CSS_VAR_REG.test(value)) {
    return `var(${value})`;
  }

  return value;
}

export function passthroughStyle(value: string) {
  return value;
}

function flexValue(value: boolean | number | string) {
  if (typeof value === 'boolean') {
    return value ? '1' : undefined;
  }

  return '' + value;
}

export const baseStyleProps: StyleHandlers = {
  margin: ['margin', dimensionValue],
  marginStart: ['marginInlineStart', dimensionValue],
  marginEnd: ['marginInlineEnd', dimensionValue],
  marginTop: ['marginTop', dimensionValue],
  marginBottom: ['marginBottom', dimensionValue],
  marginX: [['marginLeft', 'marginRight'], dimensionValue],
  marginY: [['marginTop', 'marginBottom'], dimensionValue],
  width: ['width', dimensionValue],
  height: ['height', dimensionValue],
  minWidth: ['minWidth', dimensionValue],
  minHeight: ['minHeight', dimensionValue],
  maxWidth: ['maxWidth', dimensionValue],
  maxHeight: ['maxHeight', dimensionValue],
  alignSelf: ['alignSelf', passthroughStyle],
  justifySelf: ['justifySelf', passthroughStyle],
  position: ['position', passthroughStyle],
  zIndex: ['zIndex', passthroughStyle],
  top: ['top', dimensionValue],
  bottom: ['bottom', dimensionValue],
  start: [rtl('left', 'right'), dimensionValue],
  end: [rtl('right', 'left'), dimensionValue],
  left: ['left', dimensionValue],
  right: ['right', dimensionValue],
  flex: ['flex', flexValue],
  flexGrow: ['flexGrow', passthroughStyle],
  flexShrink: ['flexShrink', passthroughStyle],
  flexBasis: ['flexBasis', passthroughStyle],
  gridArea: ['gridArea', passthroughStyle],
  gridColumn: ['gridColumn', passthroughStyle],
  gridColumnEnd: ['gridColumnEnd', passthroughStyle],
  gridColumnStart: ['gridColumnStart', passthroughStyle],
  gridRow: ['gridRow', passthroughStyle],
  gridRowEnd: ['gridRowEnd', passthroughStyle],
  gridRowStart: ['gridRowStart', passthroughStyle],
  padding: ['padding', dimensionValue],
  paddingStart: ['paddingInlineStart', dimensionValue],
  paddingEnd: ['paddingInlineEnd', dimensionValue],
  paddingTop: ['paddingTop', dimensionValue],
  paddingBottom: ['paddingBottom', dimensionValue],
  paddingX: [['paddingLeft', 'paddingRight'], dimensionValue],
  paddingY: [['paddingTop', 'paddingBottom'], dimensionValue],
};

export const viewStyleProps: StyleHandlers = {
  ...baseStyleProps,
  backgroundColor: ['backgroundColor', primitiveValue],
  borderWidth: ['borderWidth', dimensionValue],
  borderColor: ['borderColor', primitiveValue],
  borderRadius: ['borderRadius', dimensionValue],
  borderTopStartRadius: [rtl('borderTopLeftRadius', 'borderTopRightRadius'), dimensionValue],
  borderTopEndRadius: [rtl('borderTopRightRadius', 'borderTopLeftRadius'), dimensionValue],
  borderBottomStartRadius: [
    rtl('borderBottomLeftRadius', 'borderBottomRightRadius'),
    dimensionValue,
  ],
  borderBottomEndRadius: [rtl('borderBottomRightRadius', 'borderBottomLeftRadius'), dimensionValue],
  overflow: ['overflow', passthroughStyle],
};

export function convertStyleProps(
  props: ViewStyleProps,
  handlers: StyleHandlers,
  direction: Direction,
) {
  let style: CSSProperties = {};
  for (let key in props) {
    let styleProp = handlers[key];
    if (!styleProp || props[key as keyof ViewStyleProps] == null) {
      continue;
    }

    let [name, convert] = styleProp;

    if (typeof name === 'function') {
      name = name(direction);
    }

    let value = convert(props[key as keyof ViewStyleProps]);
    if (Array.isArray(name)) {
      for (let k of name) {
        // @ts-ignore
        style[k] = value;
      }
    } else {
      // @ts-ignore
      style[name] = value;
    }
  }
  return style;
}

export function useStyleProps<T extends StyleProps>(
  props: T,
  handlers: StyleHandlers = baseStyleProps,
) {
  let { direction } = useDefaultLocale();

  const style = convertStyleProps(props, handlers, direction);

  let styleProps: HTMLAttributes<HTMLElement> = {
    style,
  };

  return {
    styleProps,
  };
}

export const formatPopWrapperDimensions = (
  placement: string,
  { style }: React.HTMLAttributes<HTMLElement>,
) => {
  const key = placement === 'left' || placement === 'right' ? 'height' : 'width';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: _, ...rest } = style || {};
  return rest;
};
