import { IconSource } from './type.ts';

/**
 * @description 厚一点的右箭头，用于 app proxy
 * @size 16 x 16
 */
const RightChevronCircle: IconSource = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12Z'
        stroke='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M10.5996 16.2002C10.4204 16.2002 10.2412 16.1316 10.1047 15.9951C9.83099 15.7214 9.83099 15.279 10.1047 15.0053L13.1098 12.0002L10.1047 8.99512C9.83099 8.72142 9.83099 8.27902 10.1047 8.00532C10.3784 7.73162 10.8208 7.73162 11.0945 8.00532L14.5945 11.5053C14.8682 11.779 14.8682 12.2214 14.5945 12.4951L11.0945 15.9951C10.958 16.1316 10.7788 16.2002 10.5996 16.2002Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default RightChevronCircle;
