'use client';

import React, { CSSProperties, ReactNode, useCallback, useEffect, useRef } from 'react';

interface ScrollViewProps {
  children: ReactNode;
  onScrollEnd?: () => void;
  direction?: 'horizontal' | 'vertical';
  className?: string;
  style?: CSSProperties;
}

const ScrollView: React.FC<ScrollViewProps> = ({
  children,
  onScrollEnd,
  direction = 'vertical',
  className,
  style,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    if (!scrollRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth, scrollTop, scrollHeight, clientHeight } =
      scrollRef.current;

    if (direction === 'vertical' && scrollTop + clientHeight >= scrollHeight) {
      if (typeof onScrollEnd === 'function') {
        onScrollEnd();
      }
    } else if (direction === 'horizontal' && scrollLeft + clientWidth >= scrollWidth) {
      if (typeof onScrollEnd === 'function') {
        onScrollEnd();
      }
    }
  }, [direction, onScrollEnd]);

  useEffect(() => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  const overflowStyle: CSSProperties =
    direction === 'horizontal' ? { overflowX: 'auto' } : { overflowY: 'auto' };

  return (
    <div
      ref={scrollRef}
      className={`scroll-view ${className || ''}`}
      style={{ ...overflowStyle, ...style }}
    >
      {children}
    </div>
  );
};

export default ScrollView;
