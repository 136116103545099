import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { FileUpload, Flex, Text, Uploader } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { ReturnImage } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

import { v4hostName } from '@/constants/hostname.ts';
import {
  FileTransferURLResponse,
  compressFile,
  getLocalImageSize,
} from '@/features/request-returns/resource/upload';
import { ReturnReasonDetail, genReturnReasonCode } from '@/i18n/dynamic';
import { request } from '@/utils/request';

export interface UploadImagesProps {
  reasonId: string;
  images?: Array<ReturnImage>;
  imagesDescription: Maybe<string>;
  required: boolean;
  onImagesChange: (images: Array<ReturnImage>) => void;
  onUploadingStatusChange: (status: Uploader['status']) => void;
  onUploadError: (error: Uploader['uploadError']) => void;
}
const UploadImages = ({
  reasonId,
  required,
  images,
  imagesDescription,
  onImagesChange,
  onUploadingStatusChange,
  onUploadError,
}: UploadImagesProps) => {
  const { context } = useFlow();
  const { t } = useTranslation();
  return (
    <Flex gap={SpacingVars['2']} direction={'column'}>
      <Flex direction={'column'}>
        <Text variant={'body1'}>
          {t('page.description.images')}
          {required ? '*' : ''}
        </Text>
        {imagesDescription && (
          <Text variant='body2' color={ColorVars.Grey['Grey 900']}>
            {t(
              genReturnReasonCode({
                reasonId: reasonId || '',
                detail: ReturnReasonDetail.Image,
              }),
              {
                rawValue: imagesDescription,
                defaultValue: imagesDescription,
              },
            )}
          </Text>
        )}
      </Flex>
      <FileUpload<ReturnImage>
        limitSize={5}
        value={images?.map((image) => image.src)}
        action={async (put, file, signal) => {
          const uri = `${v4hostName}/files`;
          const res = await request<FileTransferURLResponse>(uri, {
            method: 'POST',
            headers: { 'returns-authorization': context.token ?? '' },
            signal,
            body: JSON.stringify({
              is_public: true,
              file_name: file.name ?? '',
              file_type: file.type ?? '',
            }),
          });
          let compressFileData = file;
          // png 已经是压缩格式，不需要继续压缩
          if (file.type !== 'image/png') {
            // step2: 压缩图片
            compressFileData = await compressFile(file);
          }

          // step3: 获取图片尺寸
          const imageDimensions = await getLocalImageSize(compressFileData);
          const width = imageDimensions.width;
          const height = imageDimensions.height;

          await put(res.data?.upload_url, {
            data: compressFileData,
            method: 'PUT',
            headers: {
              'x-goog-content-length-range': `0,${res.data?.size_limit}`,
            },
          });

          return {
            id: uuid(),
            group_id: uuid(),
            src: res?.data?.download_url,
            size: 'original',
            width,
            height,
          };
        }}
        onChange={(uploaders) => {
          // status 为 done ，但没有 response 意味着是回显的图片
          const snapshotUploaders = uploaders.filter(
            (uploader) => uploader.status === 'done' && !uploader.response,
          );
          // 在回显中，但是不在原数组，意味着被删掉了
          const excludeDeletedImaged =
            images?.filter((image) =>
              snapshotUploaders.some((uploader) => uploader.key === image.src),
            ) ?? [];
          // 再看看哪些是新增的
          const newImages = uploaders
            .filter((uploader) => uploader.status === 'done' && !!uploader.response)
            .map((uploader) => uploader.response);
          onImagesChange([...excludeDeletedImaged, ...newImages]);
          // 更新下整个上传组件的状态
          let status: Uploader['status'] = 'done';

          if (uploaders.some((uploader) => uploader.status === 'uploading')) {
            status = 'uploading';
          } else if (uploaders.some((uploader) => uploader.status === 'error')) {
            status = 'error';
          }

          onUploadingStatusChange(status);
        }}
        allowsMultiple={true}
        allowSameFile={true}
        maxCount={6}
        acceptedFileTypes={['image/*']}
        onError={onUploadError}
        tips={
          <Text variant={'caption2'} color={ColorVars.Grey['Grey 900']}>
            {t('page.description.uploadMax5MB')}
          </Text>
        }
      />
    </Flex>
  );
};

export default UploadImages;
