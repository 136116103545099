import { assign } from 'xstate';

import { ExchangeMode } from '../../core';
import { replaceTheSameItemSubFlow } from '../../core/machine/ReplaceTheSameItemSubFlow';
import { getAllProducts } from '../promise';
import { defaultSelectedItems } from '../requestData/order/defaultOrder.ts';

export const mockReplaceTheSameItemSubFlow = replaceTheSameItemSubFlow.provide({
  actors: {
    getAllProducts: getAllProducts,
  },
  actions: {
    updatePreviewData: assign({
      // 不要 exchangeVariant 字段,否则会影响 modal 中的 variant 展示
      selectedItems: defaultSelectedItems.map((item) => {
        const ret = { ...item };
        delete ret.exchangeVariant;
        return ret;
      }),
      replaceMode: ExchangeMode.DifferentPrice,
    }),
  },
});
