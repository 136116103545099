import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Button, Flex, Modal, ModalHeader, Popup } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { PickupInfomationData } from 'returns-logics';
import { useCountries } from 'returns-logics/react';

import { ScrollBox } from '@/components/ScrollBox';
import useDevice from '@/hooks/useDevice';
import { useMainFlow } from '@/hooks/useMainFlow';

import { PickupContactSection } from './PickupContactSection';
import { PickupDateSection } from './PickupDateSection';
import { PickupShippingAddress } from './PickupShippingAddress';
import { SpecialInstruction } from './SpecialInstruction';
import { modalWrapper } from './index.css';
import { pickupInfomationSchema } from './schema';

import { useGetPickupDefaultValues } from '../../hooks/useGetPickupDefaultValues';
import { getIsShowContectSection } from '../../hooks/usePickupInfomation';

interface PickupInfomationFormProps {
  open: boolean;
  onClose: VoidFunction;
  handleComfirmPickupInfo: (data: PickupInfomationData) => void;
}

export const PickupInfomationForm = ({
  open,
  onClose,
  handleComfirmPickupInfo,
}: PickupInfomationFormProps) => {
  const mainFlow = useMainFlow();

  const defaultValues = useGetPickupDefaultValues();

  const {
    data: { countries },
  } = useCountries();

  const form = useForm<PickupInfomationData>({
    mode: 'all',
    defaultValues,
    resolver: yupResolver(pickupInfomationSchema()) as any,
    context: {
      countries,
      isMissingPhoneNumber: mainFlow?.context?.request?.orders?.missing_phone_number,
    },
  });

  const ref = useRef<HTMLDivElement | null>(null);

  const isMobile = useDevice().mobile;

  const { isShowOuterContactSection, isShowInnerContactSection } = getIsShowContectSection(
    form.watch('pickupAddressSource'),
    !!mainFlow?.context?.request?.orders?.missing_phone_number,
  );

  const handleSubmit = form.handleSubmit(
    (data) => {
      handleComfirmPickupInfo({
        ...data,
        pickupContact:
          isShowInnerContactSection || isShowOuterContactSection ? data.pickupContact : null,
      });
    },
    () => {
      if (ref.current) {
        const scrollElement = ref.current.parentElement;
        if (scrollElement) {
          scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    },
  );

  const content = (
    <FormProvider {...form}>
      <Flex direction='column' maxHeight={isMobile ? '100vh' : '70vh'} className={modalWrapper}>
        <ScrollBox padding={SpacingVars['6']}>
          <div ref={ref} />
          <Flex direction='column' gap={SpacingVars['6']} flex={1}>
            <PickupDateSection />

            {isShowOuterContactSection ? <PickupContactSection /> : null}
            <PickupShippingAddress countries={countries} />
            <SpecialInstruction />
          </Flex>
        </ScrollBox>
        <Box width={'100%'} margin={'auto'} padding={SpacingVars['4']}>
          <Button size='large' onPress={() => handleSubmit()}>
            {t('page.request.nextStep')}
          </Button>
        </Box>
      </Flex>
    </FormProvider>
  );

  if (isMobile) {
    return (
      <Popup isOpen={open} title={t('schedule_pickup.title')} onClose={onClose}>
        {content}
      </Popup>
    );
  }

  return (
    <Modal isOpen={open}>
      <ModalHeader title={t('schedule_pickup.title')} onClose={onClose} />
      {content}
    </Modal>
  );
};
