import { fromPromise } from 'xstate';

import { hostName } from '../constant';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { Policy } from '../types/returnPolicy';

export const getReturnPolicy = fromPromise(
  async ({ input }: { input: { shopHostName: string } }) => {
    const uri = `${hostName()}/shops/return-policy?hostname=${input.shopHostName}`;

    return request<ResponseBody<Policy>>(uri).then(
      (response) => response.data || Promise.reject(response),
    );
  },
);
