'use client';

import { ComboBox, Input, Key, MenuItemRenderProps } from 'react-aria-components';

import { searchFieldInputRecipe } from './SearchField.css';
import { SearchFieldProps } from './type';

import DropdownBase from '../Dropdown/DropdownBase';

const SearchField = (props: SearchFieldProps) => {
  const { items, placeholder, ...rest } = props;
  const handleAction = (
    key: Key,
    state?: MenuItemRenderProps & { setSelectedKey?: (key: Key) => void },
  ) => {
    state?.setSelectedKey?.(key);
  };
  return (
    <DropdownBase
      trigger={ComboBox}
      items={items}
      onAction={handleAction}
      triggerProps={{
        menuTrigger: 'focus',
        allowsCustomValue: true,
        ...rest,
      }}
      renderChildren={<Input className={searchFieldInputRecipe()} placeholder={placeholder} />}
    />
  );
};

export default SearchField;
