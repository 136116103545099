import { t } from 'i18next';

import { Ineligibility, NonReturnableReason } from 'returns-logics';

import { AskQuestionWorkflow, genAskQuestionWorkflowCode } from '@/i18n/dynamic.ts';

export const notAllowToReturnReasonMap: Record<NonReturnableReason, string> = {
  return_window_by_order_date: 'page.details.outOfReturnWindow',
  item_categories: 'page.description.nonReturnableReasonPolicy',
  item_tags: 'page.description.nonReturnableReasonPolicy',
  item_sku: 'page.description.nonReturnableReasonPolicy',
  discount_items: 'page.description.nonReturnableReasonPolicy',
  multiple_returns: 'page.description.nonReturnableReasonAlreadyARequested',
  return_window_by_fulfill_date: 'page.details.outOfReturnWindow',
  return_window_by_delivery_date: 'page.details.outOfReturnWindow',
  return_window_by_condition: 'page.details.outOfReturnWindow',
  base_return_window: 'page.details.outOfReturnWindow',
  order_item_id: 'page.description.nonReturnableReasonQuestion',
  unfulfilled_item: 'page.description.nonReturnableUnfulfilled',
  unfulfilled_order: 'page.description.nonReturnableUnfulfilled',
  block_by_item_discount_percentage: 'page.description.nonReturnableReasonPolicy',
  discount_code: 'page.description.nonReturnableReasonPolicy',
  discount_value: 'page.description.nonReturnableReasonPolicy',
  discount_percentage: 'page.description.nonReturnableReasonPolicy',
  returns_center_exchange_order: '', // only on order level
  returnable_quantity: 'page.description.nonReturnableReasonAlreadyRequested', // returned all quantity
};

export const getItemNonRefundableDescription = (reasons: Ineligibility[]) => {
  if (!reasons.length) {
    return t('page.description.nonReturnableReasonPolicy');
  }
  const priorityReason = reasons[0];

  if (priorityReason.reason === 'order_item_id') {
    const context = priorityReason.context?.context || {};
    const { item_selected_question_id, terminate_flow_message } = context;

    const defaultValue =
      terminate_flow_message || t('page.description.nonReturnableReasonQuestion');

    if (item_selected_question_id) {
      return t(
        genAskQuestionWorkflowCode({
          flowId: item_selected_question_id,
          type: AskQuestionWorkflow.TerminateFlowMessage,
        }),
        {
          defaultValue,
          rawValue: defaultValue,
        },
      );
    }
  }

  // show the first reason when more than one reasons exist
  if (notAllowToReturnReasonMap[priorityReason.reason]) {
    return t(notAllowToReturnReasonMap[priorityReason.reason]);
  }
  return t('page.description.nonReturnableReasonPolicy');
};

export const getMerchantModeOverrideReason = (reasons: Ineligibility[]) => {
  if (!reasons.length) {
    return;
  }
  const priorityReason = reasons[0];
  if (notAllowToReturnReasonMap[priorityReason.reason]) {
    return t(notAllowToReturnReasonMap[priorityReason.reason]);
  }

  return t('page.description.nonReturnableReasonPolicy');
};
