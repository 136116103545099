import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Flex, NumberField } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import useDevice from '@/hooks/useDevice';

import WarningTips from '../WarningTips/WarningTips';

export interface ChooseQuantityProps {
  isLimitQuantityExactlyOne: boolean;
  returnableQuantity: number;
  selectedQuantity: number;
  onSelectedQuantity: (quantity: number) => void;
  onQuantityChange: (quantity: number) => void;
}
const ChooseQuantity = ({
  isLimitQuantityExactlyOne,
  returnableQuantity,
  selectedQuantity,
  onQuantityChange,
}: ChooseQuantityProps) => {
  const { t } = useTranslation();
  const isMobile = useDevice().mobile;
  return (
    <Flex
      flex={1}
      paddingBottom={isMobile ? '220px' : 0}
      gap={SpacingVars['6']}
      direction={'column'}
      alignItems={isMobile ? 'center' : 'start'}
    >
      <Box width={isMobile ? '250px' : '100%'}>
        <NumberField
          fullWidth={isMobile}
          outline
          defaultValue={selectedQuantity}
          value={selectedQuantity}
          onChange={(value) => {
            onQuantityChange(value);
          }}
          minValue={1}
          maxValue={isLimitQuantityExactlyOne ? 1 : returnableQuantity}
        />
      </Box>
      {isLimitQuantityExactlyOne && <WarningTips tips={t('page.description.limitPlusQuantity')} />}
    </Flex>
  );
};

export default ChooseQuantity;
