import React from 'react';

import { Text, TextProps } from '@aftership/design-system';

import {
  ellipsisClassName,
  maxLine1ClassName,
  maxLine2ClassName,
  maxLine3ClassName,
} from './EllipsisText.css';

interface EllipsisTextProps extends TextProps {
  text: string;
  maxLine?: 1 | 2 | 3;
}

const EllipsisText = ({
  text,
  maxLine = 1,
  className,
  ...props
}: Omit<EllipsisTextProps, 'children'>) => {
  return (
    <Text
      className={`${ellipsisClassName} ${maxLine === 1 && maxLine1ClassName} ${maxLine === 2 && maxLine2ClassName} ${maxLine === 3 && maxLine3ClassName} ${className}`}
      {...props}
    >
      {text}
    </Text>
  );
};

export default EllipsisText;
