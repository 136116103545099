import { IconSource } from './type';

const Plus: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.875 13C8.875 13.4832 8.48325 13.875 8 13.875C7.51675 13.875 7.125 13.4832 7.125 13V8.875H3C2.51675 8.875 2.125 8.48325 2.125 8C2.125 7.51675 2.51675 7.125 3 7.125H7.125V3C7.125 2.51675 7.51675 2.125 8 2.125C8.48325 2.125 8.875 2.51675 8.875 3V7.125H13C13.4832 7.125 13.875 7.51675 13.875 8C13.875 8.48325 13.4832 8.875 13 8.875H8.875V13Z'
        fill='currentColor'
        // style={{fill: 'var(--icon-main-color)'}}
      />
    </svg>
  );
};

export default Plus;
