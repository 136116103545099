import { IconSource } from '../../Icon/type';

const Plus: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 26 26' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.875 24.25C13.875 24.7332 13.4832 25.125 13 25.125C12.5168 25.125 12.125 24.7332 12.125 24.25V13.875H1.75C1.26675 13.875 0.875 13.4832 0.875 13C0.875 12.5168 1.26675 12.125 1.75 12.125H12.125V1.75C12.125 1.26675 12.5168 0.875 13 0.875C13.4832 0.875 13.875 1.26675 13.875 1.75V12.125H24.25C24.7332 12.125 25.125 12.5168 25.125 13C25.125 13.4832 24.7332 13.875 24.25 13.875H13.875V24.25Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Plus;
