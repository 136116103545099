import { useCallback, useEffect, useState } from 'react';

import { PayStatus } from '@/features/review/type';

import { useReturnsListSubFlow } from './useReturnsListSubFlow';

export interface PayNowProps {
  draftId: string;
}

const usePayNow = ({ draftId }: PayNowProps) => {
  const { dispatch, context } = useReturnsListSubFlow() ?? {};
  const [status, setPayStatus] = useState<PayStatus | null>(null);

  const draftReturn = context?.draftReturn;

  useEffect(() => {
    if (draftReturn?.return_id) {
      setPayStatus(PayStatus.paid);
    }
  }, [draftReturn]);

  const onPayContinue = useCallback(() => {
    dispatch?.({ type: 'POLLING_GET_DRAFT_RETURN', data: { draftId } });
  }, [dispatch, draftId]);
  const pollingCancel = useCallback(() => {
    dispatch?.({ type: 'POLLING_CANCEL_DRAFT_RETURN' });
  }, [dispatch]);
  const reload = useCallback(() => {
    dispatch?.({ type: 'RELOAD_RETURNS' });
  }, [dispatch]);

  return {
    status,
    onPayContinue,
    pollingCancel,
    reload,
  };
};

export default usePayNow;
