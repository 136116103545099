import { IconSource } from './type';

// TODO props 需要
const Tick: IconSource = () => (
  <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='m6 8.754 1.374 1.379 3.16-4'
      stroke='#4EC75F'
      strokeWidth={0.8}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.867 8A5.867 5.867 0 0 1 8 13.867 5.867 5.867 0 0 1 2.133 8a5.867 5.867 0 0 1 11.734 0z'
      stroke='#4EC75F'
      strokeWidth={0.8}
    />
  </svg>
);

export default Tick;
