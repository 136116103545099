import dynamic from 'next/dynamic';
import { PropsWithChildren, useMemo } from 'react';

import { PreviewProvider } from '@aftership/preview-kit/client';
import { defaultFont } from 'returns-logics';

import { useFonts } from '@/hooks/useFonts';
import { useMainFlow } from '@/hooks/useMainFlow';

export interface PreviewEntryProps {
  isPreview: boolean;
}

const DynamicPreviewContainer = dynamic(
  () => import('@/features/preview/components/PreviewContainer'),
  {
    ssr: true,
    loading: () => <div>Loading...</div>,
  },
);

const PreviewEntry = ({ children, isPreview }: PropsWithChildren<PreviewEntryProps>) => {
  const mainFlow = useMainFlow();
  const {
    returns_page_primary_font,
    returns_page_body_font,
    fonts = [],
  } = mainFlow.context.storeConfig?.shopInfo || {};
  const fontFamilies = useMemo(
    () => [
      returns_page_primary_font || defaultFont.name,
      returns_page_body_font || defaultFont.name,
      defaultFont.name,
    ],
    [returns_page_body_font, returns_page_primary_font],
  );
  const fontStyle = useFonts(fontFamilies, fonts);

  if (!isPreview) {
    return (
      <PreviewProvider isPreview={isPreview}>
        {fontStyle}
        {children}
      </PreviewProvider>
    );
  }

  return (
    <PreviewProvider isPreview={isPreview}>
      {fontStyle}
      <DynamicPreviewContainer>{children}</DynamicPreviewContainer>
    </PreviewProvider>
  );
};

export default PreviewEntry;
