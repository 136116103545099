import { useTranslation } from 'react-i18next';

import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import ErrorIcon from './ErrorIcon';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

export interface MaximumReturnableQuantityExceededErrorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const MaximumReturnableQuantityExceededErrorModal = ({
  isOpen,
  onClose,
  onConfirm,
}: MaximumReturnableQuantityExceededErrorModalProps) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();
  const Container = () => {
    return (
      <Flex direction='column' justifyContent='center' alignItems='center' gap={SpacingVars['6']}>
        <ErrorIcon />
        <Flex direction='column' justifyContent='center'>
          <Text variant='body1'>{t('popup.description.noReturnableQuantity')}</Text>
        </Flex>
      </Flex>
    );
  };

  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button onPress={onConfirm} size='large'>
          {t('page.request.viewReturnRequests')}
        </Button>
      </ModalButtonGroup>
    );
  };
  if (mobile) {
    return (
      <MobilePopup
        isOpen={isOpen}
        title={t('popup.description.submitFailed')}
        onClose={onClose}
        footer={<Footer />}
      >
        <Container />
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('popup.description.submitFailed')} onClose={onClose} />

      <ModalBody>
        <Container />
      </ModalBody>

      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};

export default MaximumReturnableQuantityExceededErrorModal;
