'use client';

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, Link, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

import { useGetPrefiedLink } from '@/features/returns/hooks/useUniversalRouting';

interface Props {
  policyUrl?: string;
  policyText?: string;
  externalReturnPolicyPage?: boolean;
}

const Policy: FC<Props> = ({ policyUrl, policyText, externalReturnPolicyPage }) => {
  const { t } = useTranslation();
  const privatePolicyLink = useGetPrefiedLink('/return-policy'); // 兼顾 app proxy 模式

  return (
    <Flex direction='column'>
      <Text variant='title3' color={ColorVars['Grey']['Grey1200']}>
        {t('page.description.returnPolicy')}
      </Text>
      <Text variant='body2' elementType='p' color={ColorVars['Grey']['Grey1200']}>
        {t('page.description.acceptReturnsPolicy', {
          rawValue: policyText,
          defaultValue: policyText,
        })}{' '}
        {externalReturnPolicyPage ? (
          policyUrl && (
            <Link href={policyUrl} target='_blank'>
              <Text textDecoration='underline' variant='body2'>
                {t('page.landing.viewFullPolicy')}
              </Text>
            </Link>
          )
        ) : (
          <Link href={privatePolicyLink} target='_blank'>
            <Text textDecoration='underline' variant='body2'>
              {t('page.landing.viewFullPolicy')}
            </Text>
          </Link>
        )}
      </Text>
    </Flex>
  );
};

export default Policy;
