import { IconSource } from './type';

const Subtract: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16 8.00098C16 12.4193 12.4183 16.001 8 16.001C3.58172 16.001 0 12.4193 0 8.00098C0 3.5827 3.58172 0.000976562 8 0.000976562C12.4183 0.000976562 16 3.5827 16 8.00098ZM4.23412 4.23607C4.54654 3.92365 5.05307 3.92365 5.36549 4.23607L8 6.87058L10.6345 4.23607C10.9469 3.92365 11.4535 3.92365 11.7659 4.23607C12.0783 4.54849 12.0783 5.05502 11.7659 5.36744L9.13137 8.00195L11.7656 10.6362C12.078 10.9486 12.078 11.4551 11.7656 11.7675C11.4532 12.08 10.9466 12.08 10.6342 11.7675L8 9.13332L5.36578 11.7675C5.05336 12.08 4.54683 12.08 4.23441 11.7675C3.92199 11.4551 3.92199 10.9486 4.23441 10.6362L6.86863 8.00195L4.23412 5.36744C3.9217 5.05502 3.9217 4.54849 4.23412 4.23607Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Subtract;
