import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import { request } from '../request/request';
import { SuccessResponse } from '../request/response';
import { OrderResponse } from '../types/order';

export type GetOrderInput = {
  order_id: string;
  token?: string;
};

export const getOrder = fromPromise<OrderResponse, GetOrderInput>(
  async ({ input }: { input: GetOrderInput }) => {
    const { token, order_id } = input;

    const res = await request<SuccessResponse<OrderResponse>>(
      `${v4hostName()}/orders/${order_id}`,
      {
        method: 'GET',
        headers: {
          'returns-authorization': token!,
        },
      },
    );

    return res.data;
  },
);
