import { Button } from 'react-aria-components';

import { carouselIndicatorButtonRecipe, carouselIndicatorRecipe } from './Carousel.css';
import { CarouselElementProps } from './type';

import { createArray } from '../../utils/createArray';

const getIndicatorIndex = (index: number, total: number) => {
  if (index === 0) {
    return total - 1;
  } else if (index === total + 1) {
    return 0;
  } else {
    return index - 1;
  }
};

type CarouselIndicatorProps = Pick<
  CarouselElementProps,
  'index' | 'setAnimated' | 'moveSildeTo' | 'items'
>;

const CarouselIndicator = ({
  setAnimated,
  moveSildeTo,
  index: currentIndex,
  items,
}: CarouselIndicatorProps) => {
  const handleIndicatorMove = (index: number) => {
    setAnimated(true);
    moveSildeTo(index);
  };

  return (
    <div className={carouselIndicatorRecipe()}>
      {createArray(items.length).map((_, index) => (
        <Button
          key={index}
          className={carouselIndicatorButtonRecipe({
            active: getIndicatorIndex(currentIndex, items.length) === index,
          })}
          onPress={() => handleIndicatorMove(index + 1)}
        />
      ))}
    </div>
  );
};

export default CarouselIndicator;
