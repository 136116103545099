import { parse } from 'next-useragent';

export function isMobile(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const regExp = /iPhone|iPad|iPod|Android|Mobi/i;
  return regExp.test(window.navigator.userAgent);
}

export function isIOS(uaString: string) {
  const useragent = parse(uaString);
  return useragent.isIphone || useragent.isIpad;
}

export const isSSR = () => typeof window === 'undefined';
