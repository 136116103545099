import { ComponentType } from 'react';

import { ItemWrapper } from '@aftership/atta-engine/renderer';
import { withPreviewSection as withPreviewSectionPreviewKit } from '@aftership/preview-kit/client';

import { useIsAttaEditor } from '@/features/returns/hooks/useIsAttaEditor';

export const withPreviewSection = <Props extends object>(
  // eslint-disable-next-line @typescript-eslint/naming-convention
  Comp: ComponentType<Props>,
  name: string,
): React.FC<Props> => {
  const Com = withPreviewSectionPreviewKit(Comp, name);
  // eslint-disable-next-line react/display-name
  return (prop: Props) => {
    const isAttaEditor = useIsAttaEditor();
    if (!isAttaEditor) {
      return <Com {...prop} />;
    }
    return (
      <ItemWrapper
        id={name}
        mask
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Comp {...prop} />
      </ItemWrapper>
    );
  };
};
