import { t } from 'i18next';
import React, { useEffect, useState } from 'react';

import { Flex, Spinner, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { Resolution } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

import { NextButton } from '@/components/NextButton';
import { ScrollFlex } from '@/components/ScrollFlex';
import { SomethingWentWrong } from '@/components/SomethingWentWrong';
import { OrderWarningTips } from '@/features/request-returns/components/OrderWarningTips';
import { MissAddressModal } from '@/features/resolution/components/MissAddressModal';
import ResolutionList, {
  ResolutionMode,
} from '@/features/resolution/components/ResolutionList/ResolutionList';
import { useGeneratePlatformEfaUrl } from '@/features/resolution/utils/url';
import useDevice from '@/hooks/useDevice';
import { useFieldAWithMemo } from '@/hooks/useFieldAWithMemo';
import getTrackerInstance from '@/utils/tracker';
import { EventName, PageId } from '@/utils/tracker/consts';
import { useReportPageViewEvent } from '@/utils/tracker/useReportPageViewEvent';

import { RefundOrEfaOverlay } from './components/RefundOrEfaOverlay';

import { xStateMetaData } from '../returns/hooks/useSyncXStateAndRoute';

const ResolutionPage = () => {
  const isMobile = useDevice().mobile;
  const flow = useFlow();
  const { context: mainContext, children: mainChildren, currentStep: mainCurrentStep } = flow;
  const { dispatch: resolutionDispatch, children: resolutionChildren } =
    mainChildren?.resolutionSubFlow ?? {};
  const isMerchantMode = !!mainContext.orderLookup?.isMerchantMode;
  const { generatePlatformEfaUrl } = useGeneratePlatformEfaUrl(mainContext);
  const [missAddressModalShow, setMissAddressModalShow] = useState(false);

  const contextMemory = useFieldAWithMemo(mainChildren.resolutionSubFlow?.snapshot?.context);
  const subFlowCurrentStateMemory = useFieldAWithMemo(
    mainChildren.resolutionSubFlow?.snapshot?.value,
  );

  const [selectedResolution, setSelectedResolution] = useState<Resolution | undefined>(
    contextMemory?.selectedResolution,
  );

  const [mode, setMode] = useState(ResolutionMode.hideRefundWhenHaveEfa);

  useReportPageViewEvent(
    mode === ResolutionMode.hideRefundWhenHaveEfa
      ? PageId.resolutionSelection
      : PageId.refundResolutionSelection,
  );

  // 匹配到的 resolutions
  const resolutions = contextMemory?.matchingResolutions ?? [];
  const exchangeOrRefundSubFlowDispatch = resolutionChildren?.exchangeOrRefundSubFlow?.dispatch;
  const isWrongInput = resolutions.length <= 0;

  const missingAddress = (): boolean => {
    if (!mainContext.request?.orders?.order?.shipping_address) {
      setMissAddressModalShow(true);
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (mainCurrentStep.name === 'exchangeOnStore') {
      const url = generatePlatformEfaUrl({
        productUrl: contextMemory?.productUrl,
        redirectUrl: `${location.origin}${
          mainContext?.skipReturnMethodAfterEFA ? '/review' : '/return-method'
        }`,
      });

      getTrackerInstance().reportPageEnterEvent({ pageId: PageId.EFAOnStore });

      location.href = url;
    }
  }, [
    contextMemory?.productUrl,
    generatePlatformEfaUrl,
    mainContext?.skipReturnMethodAfterEFA,
    mainCurrentStep.name,
  ]);

  const onSelectedResolution = (resolution?: Resolution) => {
    setSelectedResolution(resolution);
    getTrackerInstance().reportClickEvent({
      eventName: EventName.selectResolution,
      payload: {
        resolution,
      },
    });
  };

  if (subFlowCurrentStateMemory === 'fetchMatchingResolutions') {
    return (
      <Flex flex={1} alignItems={'center'} justifyContent={'center'}>
        <Spinner />
      </Flex>
    );
  }
  const isButtonDisable =
    !selectedResolution ||
    (mode === ResolutionMode.onlyRefund &&
      ![Resolution.Refundid, Resolution.StoreCredit, Resolution.OriginalPayment].includes(
        selectedResolution,
      ));
  return (
    <>
      <Flex height={0} flex={1} direction={'column'}>
        <ScrollFlex height={isWrongInput ? '100%' : undefined}>
          {isMobile && (
            <Text variant={'title2'} textAlign={'center'} color={ColorVars.Grey.Grey1200}>
              {t('page.request.howToResolve')}
            </Text>
          )}
          {isMerchantMode && <OrderWarningTips tips={t('page.banner.overrideRule')} />}
          {isWrongInput ? (
            <SomethingWentWrong
              type='resolution'
              paddingBottom={isMobile ? SpacingVars['25'] : SpacingVars['22']}
            />
          ) : (
            <ResolutionList
              items={resolutions}
              mode={mode}
              onSelectedResolution={onSelectedResolution}
              selectedResolution={selectedResolution}
            />
          )}
        </ScrollFlex>
        {!isWrongInput && (
          <NextButton
            isLoading={subFlowCurrentStateMemory === 'beforeConfirmResolution'}
            isDisabled={isButtonDisable}
            onPress={() => {
              getTrackerInstance().reportClickEvent({
                eventName:
                  mode === ResolutionMode.hideRefundWhenHaveEfa
                    ? EventName.clickResolutionPageNext
                    : EventName.clickRefundResolutionNext,
              });

              if (selectedResolution === Resolution.ReplaceTheSameItem && missingAddress()) {
                return;
              }
              if (selectedResolution) {
                if (selectedResolution === Resolution.ExchangeForAnything) {
                  resolutionDispatch?.({
                    type: 'CHOOSE_EFA_OR_REFUND',
                  });
                } else {
                  resolutionDispatch?.({
                    type: 'CONFIRM_RESOLUTION',
                    data: { selectedResolution: selectedResolution },
                  });
                }
              }
            }}
          />
        )}
      </Flex>
      {contextMemory?.isWaitingSelectEfaOrRefund && (
        <RefundOrEfaOverlay
          isOpen={contextMemory?.isWaitingSelectEfaOrRefund}
          isLoading={!!resolutionChildren?.exchangeOrRefundSubFlow?.currentStep?.isLoading}
          onClose={() => {
            exchangeOrRefundSubFlowDispatch?.({
              type: 'SELECT_CANCEL',
            });
          }}
          onClickShopNow={() => {
            if (missingAddress()) {
              return;
            }
            getTrackerInstance().reportClickEvent({
              eventName: EventName.clickEFAOrRefund,
              payload: {
                choice: 'shop_now',
              },
            });
            exchangeOrRefundSubFlowDispatch?.({ type: 'SELECT_SHOP_NOW' });
          }}
          onClickRefund={() => {
            setMode(ResolutionMode.onlyRefund);
            getTrackerInstance().reportClickEvent({
              eventName: EventName.clickEFAOrRefund,
              payload: {
                choice: 'refund',
              },
            });
            exchangeOrRefundSubFlowDispatch?.({ type: 'SELECT_REFUND_ME' });
          }}
          onClickProduct={(productId, productUrl) => {
            if (missingAddress()) {
              return;
            }

            getTrackerInstance().reportClickEvent({
              eventName: EventName.clickEFAOrRefund,
              payload: {
                choice: 'EFA_product_image',
              },
            });

            xStateMetaData.set('exchangeInApp', { productId });
            exchangeOrRefundSubFlowDispatch?.({
              type: 'SELECT_PRODUCT',
              data: {
                productId,
                productUrl,
              },
            });
          }}
        />
      )}
      <MissAddressModal
        isOpen={missAddressModalShow}
        onClose={() => {
          setMissAddressModalShow(false);
        }}
      />
    </>
  );
};

export default ResolutionPage;
