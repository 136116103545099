import { IconSource } from './type';

const ExternalLink: IconSource = () => (
  <svg width={17} height={16} viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.5 3h-3a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V9m-6 0 5-5'
      stroke='currentColor'
      strokeWidth={1.75}
      strokeLinecap='round'
    />
    <path
      d='M10.927 2.427A.25.25 0 0 1 11.104 2h3.146a.25.25 0 0 1 .25.25v3.146a.25.25 0 0 1-.427.177z'
      fill='currentColor'
    />
  </svg>
);

export default ExternalLink;
