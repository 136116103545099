type WhiteList = { [key: string]: string[] | undefined };

export const rcAdminOrigin: string[] = [
  'https://*.returnscenter.io',
  'https://*.returnscenter.com',
];

const whiteList: WhiteList = {
  'bdaa5bff-1a02-4b5c-8302-36c02ca6c6e8': [
    'https://test10.returnscenter.io',
    'http://localhost:8000',
  ],
  'b990ddfa-0b43-47d7-9212-42b6c930c68f': [
    'https://krakenautodea54fe07.returnscenter.com',
    'https://staging-shopper.returnscenter.com',
    'http://localhost:8000',
  ],
  '0adbc6d9-048b-4c58-9bf8-4bf25c561993': [
    'https://mcmsandbox.returnscenter.com',
    'https://de-deveu02.mcmworldwide.com',
    'https://ch-deveu02.mcmworldwide.com',
    'https://development-eu02-mcm.demandware.net',
    'https://uk-deveu02.mcmworldwide.com',
    'https://de-stg.mcmworldwide.com',
    'https://ch-stg.mcmworldwide.com',
    'https://uk-stg.mcmworldwide.com',
    'https://us-devncsa.mcmworldwide.com',
    'https://dev05-eu02-mcm.demandware.net',
    'https://mcmreturnsuk.returnscenter.com',
    'https://mcmreturnsde.returnscenter.com',
    'https://mcmreturnsch.returnscenter.com',
    'https://ch-stg.mcmworldwide.com',
    'https://de-stg.mcmworldwide.com',
    'https://staging-eu02-mcm.demandware.net',
    'https://ch.mcmworldwide.com',
    'https://de.mcmworldwide.com',
    'https://us.mcmworldwide.com',
  ],
  '2c2fcd97-4a5d-48c4-afac-e159eec24796': [
    'https://ch.mcmworldwide.com',
    'https://staging-eu02-mcm.demandware.net',
    'https://ch-stg.mcmworldwide.com',
  ],
  '5796e679-88bc-4a5e-b6d2-1e4605bededa': [
    'https://de.mcmworldwide.com',
    'https://staging-eu02-mcm.demandware.net',
    'https://de-stg.mcmworldwide.com',
  ],
  'bff8942b-29ca-4d24-98e2-e2497a9df36b': [
    'https://staging-ncsastore-mcmworldwide.demandware.net',
    'https://us-stg.mcmworldwide.com',
    'https://us.mcmworldwide.com',
  ],
};

export const inWhiteList = (shopId: string, parentWindowOrigin: string) => {
  return whiteList[shopId]?.includes(parentWindowOrigin);
};

export const getWhiteListForShop = (shopId: string) => {
  return whiteList[shopId];
};
