import { fromPromise } from 'xstate';

import {
  BatchGetDropoffLocationsInput,
  DropoffLocation,
  DropoffLocations,
  GetDropoffLocationsInput,
  GetMatchingMethodInput,
  ReturnMethod,
} from '../../core';
import {
  defaultDropoffLocation,
  defaultDropoffLocations,
  defaultReturnMethod,
} from '../requestData/returnMethod/defaultReturnMethod';

export const getMatchingMethod = fromPromise<ReturnMethod[], GetMatchingMethodInput>(async () => {
  return defaultReturnMethod;
});

export const getDropoffLocations = fromPromise<
  DropoffLocation | undefined,
  GetDropoffLocationsInput
>(async () => {
  return defaultDropoffLocation;
});

export const batchGetDropoffLocations = fromPromise<
  DropoffLocations,
  BatchGetDropoffLocationsInput
>(async () => {
  return defaultDropoffLocations;
});
