import GiftReturn from './pages/GiftReturn.json';
import OrderLookup from './pages/OrderLookup.json';
import RequestReturns from './pages/RequestReturns.json';
import ReturnMethod from './pages/ReturnMethod.json';
import ReturnPolicy from './pages/ReturnPolicy.json';
import ReturnResolution from './pages/ReturnResolution.json';
import ReturnsList from './pages/ReturnsList.json';
import Review from './pages/Review.json';

import { PathName } from '../type';

const schemaList: {
  name: PathName;
  schema: any;
}[] = [
  {
    name: PathName.OrderLookup,
    schema: OrderLookup,
  },
  {
    name: PathName.RequestReturns,
    schema: RequestReturns,
  },
  {
    name: PathName.ReturnResolution,
    schema: ReturnResolution,
  },
  {
    name: PathName.ReturnMethod,
    schema: ReturnMethod,
  },
  {
    name: PathName.Review,
    schema: Review,
  },
  {
    name: PathName.ReturnsList,
    schema: ReturnsList,
  },
  {
    name: PathName.GiftReturn,
    schema: GiftReturn,
  },
  {
    name: PathName.ReturnPolicy,
    schema: ReturnPolicy,
  },
];

export function getSchema() {
  return schemaList.map((i) => ({
    name: i.name,
    schema: typeof i.schema === 'string' ? i.schema : JSON.stringify(i.schema),
  }));
}
