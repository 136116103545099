'use client';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import type { ProgressBarProps as AriaProgressBarProps } from 'react-aria-components';
import { ProgressBar as AriaProgressBar, Label } from 'react-aria-components';

import * as classnames from './ProgressBar.css';
import { progressBarThemeVars } from './ProgressBarTokens.css';

import { clsx } from '../../utils/clsx';

interface ProgressBarProps extends AriaProgressBarProps {
  /**
   * The label for the progress bar.
   */
  label?: string;
  /**
   * Whether to show the percentage value.
   * @default false
   */
  showPercentage?: boolean;
  /**
   * The size of the progress bar.
   * @default 'medium'
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Whether the progress bar should be full width.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * The width of the progress bar.
   * @default '250px'
   */
  width?: string;
  /**
   * Whether to show the indeterminate state
   * (A circle append at the end of the bar).
   */
  showIndeterminate?: boolean;
  className?: string;
}

export default function ProgressBar({
  label,
  showPercentage,
  size,
  width,
  showIndeterminate,
  fullWidth,
  className,
  ...props
}: ProgressBarProps) {
  return (
    <AriaProgressBar
      className={clsx(classnames.container({ size, fullWidth }), className)}
      {...props}
      style={assignInlineVars({
        [progressBarThemeVars.ItemSizing.Height]: width,
      })}
    >
      {({ percentage, valueText }) => (
        <>
          {label ? <Label className={classnames.label}>{label}</Label> : null}
          {showPercentage ? <span className={classnames.value}>{valueText}</span> : null}
          <div className={classnames.bar({ size })}>
            <div className={classnames.fill} style={{ width: percentage + '%' }}>
              {showIndeterminate ? <div className={classnames.indeterminate} /> : null}
            </div>
          </div>
        </>
      )}
    </AriaProgressBar>
  );
}
