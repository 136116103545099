import { t } from 'i18next';
import * as yup from 'yup';

import { OrderLookupType, isEmail } from 'returns-logics';

export type OrderLookupFormValues = {
  orderNumber?: string;
  email?: string;
  postalCode?: string;
  callingCodeData?: {
    callingCode: string;
    countryCode: string;
  };
  phoneNumber?: string;
  verifyMethod: OrderLookupType | null;
};

export const getSchema = () =>
  yup.object().shape({
    orderNumber: yup.string().test({
      test: (value) => !!value?.trim(),
      message: '',
    }),
    email: yup.string().when(['verifyMethod'], ([orderLookupType]) => {
      if (orderLookupType === OrderLookupType.EMAIL) {
        return yup
          .string()
          .transform((value) => value.trim())
          .test({
            test: (value) => !!value,
            message: '',
          })
          .test({
            name: 'email',
            test: isEmail,
            message: t('page.gift.form.emailValid'),
          });
      }
      return yup.string().nullable().notRequired();
    }),
    postalCode: yup.string().when(['verifyMethod'], ([orderLookupType]) => {
      if (orderLookupType === OrderLookupType.ZIPCODE) {
        return yup
          .string()
          .transform((value) => value.trim())
          .test({
            test: (value) => !!value,
            message: '',
          })
          .transform((value) => value.toUpperCase());
      }
      return yup.string().nullable().notRequired();
    }),
    phoneNumber: yup.string().when(['verifyMethod'], ([orderLookupType]) => {
      if (orderLookupType === OrderLookupType.PHONE) {
        return yup
          .string()
          .transform((value) => value.trim())
          .test({
            test: (value) => !!value,
            message: '',
          })
          .matches(/^\d+$/, t('phoneNumber.valid.error'));
      }
      return yup.string().nullable().notRequired();
    }),
    callingCodeData: yup.object().when(['verifyMethod'], ([orderLookupType]) => {
      if (orderLookupType === OrderLookupType.PHONE) {
        return yup.object().shape({
          callingCode: yup.string().test({
            test: (value) => !!value?.trim(),
            message: '',
          }),
        });
      }
      return yup.object().nullable().notRequired();
    }),
  });
