import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import {
  MatchingOrderFormType,
  NotMatchingOrderFormType,
} from '../machine/giftReturnSubFlow/types';
import { OrderLookupType } from '../machine/orderLookupSubFlow/types';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { CountryCodeMap } from '../types';
import {
  FormType,
  GiftReturnFormPayload,
  GiftReturnFormResponse,
  GiftReturnMatchingPayload,
  GiftReturnMatchingResponse,
} from '../types/giftReturn';

export const getGiftReturnOrderMatching = fromPromise(
  async ({ input }: { input: GiftReturnMatchingPayload }) => {
    return request<ResponseBody<GiftReturnMatchingResponse>>(
      `${v4hostName()}/gift-return-order-matchings`,
      {
        method: 'POST',
        payload: input,
      },
    ).then((response) => response.data);
  },
);

// 公共 payload
const convertCommonPayload = ({
  field,
  orgId,
  formType,
  countryMap,
}: {
  orgId: string;
  field: MatchingOrderFormType | NotMatchingOrderFormType;
  formType: FormType;
  countryMap?: CountryCodeMap;
}) => {
  const RecipientShippingAddress = {
    recipient_shipping_address: {
      first_name: field.address?.firstName,
      last_name: field.address?.lastName,
      address_line_1: field.address?.line1,
      ...(field.address?.line2 && { address_line_2: field.address.line2 }),
      country: field.address?.country,
      ...(field.address?.country &&
        countryMap?.[field.address?.country]?.sublist?.length && {
          state: field.address?.state,
        }),
      city: field.address?.city,
      postal_code: field.address?.postalCode,
      phone_number: field.address?.phoneNumber,
    },
    replace_option: field.option,
  };

  return {
    organization: {
      id: orgId,
    },
    form_type: formType,

    recipient: {
      first_name: field.recipient?.firstName ?? '',
      last_name: field.recipient?.lastName ?? '',
      email: field.recipient?.email ?? '',
    },
    product_name: field.productName,
    resolution: field.resolution,
    ...(field.langCode && { customer_lang: field.langCode }),
    ...RecipientShippingAddress,
    ...(field.notes && { notes: field.notes }),
  };
};

// 没匹配到 order 的 payload
export const convertField2GiftReturnWithoutOrderPayload = ({
  field,
  orgId,
  countryMap,
}: {
  orgId: string;
  field: NotMatchingOrderFormType;
  countryMap?: CountryCodeMap;
}) => {
  return {
    ...convertCommonPayload({
      orgId,
      field,
      formType: FormType.orderUnknown,
      countryMap,
    }),
    customer: {
      first_name: field.customer?.firstName ?? '',
      last_name: field.customer?.lastName ?? '',
      email: field.customer?.email ?? '',
    },
  };
};

// 匹配到 order 的 payload
export const convertField2GiftReturnWithOrderPayload = ({
  field,
  orgId,
  orderNumber,
  zipCode,
  countryMap,
  phoneNumber,
  customerEmail,
  orderLookupType,
}: {
  orgId: string;
  field: MatchingOrderFormType;
  orderNumber: string;
  zipCode?: string;
  countryMap?: CountryCodeMap;
  phoneNumber?: string;
  customerEmail?: string;
  orderLookupType: OrderLookupType;
}) => {
  return {
    ...convertCommonPayload({
      orgId,
      field,
      formType: FormType.orderMatched,
      countryMap,
    }),
    order_number: orderNumber,
    form_type: FormType.orderMatched,
    ...(orderLookupType === OrderLookupType.ZIPCODE && { postal_code: zipCode }),
    ...(orderLookupType === OrderLookupType.EMAIL && { customer_email: customerEmail }),
    ...(orderLookupType === OrderLookupType.PHONE && { phone_number: phoneNumber }),
  };
};

export const submitGiftReturnOrderForm = fromPromise(
  async ({ input }: { input: Partial<GiftReturnFormPayload> }) => {
    return request<ResponseBody<GiftReturnFormResponse>>(`${v4hostName()}/gift-return-intentions`, {
      method: 'POST',
      payload: input,
    }).then((response) => response.data);
  },
);
