import { PropsWithChildren } from 'react';

import { cardHeaderRecipe } from './Card.css';
import { CardHeaderProps } from './type';

import { clsx } from '../../utils/clsx';

const CardHeader = ({ children, className }: PropsWithChildren<CardHeaderProps>) => {
  return <div className={clsx(cardHeaderRecipe(), className)}>{children}</div>;
};

export default CardHeader;
