import { spinnerIconWrapperRecipe, spinnerRecipe, spinnerTipRecipe } from './Spinner.css';
import { spinnerThemeClass, spinnerThemeVars } from './SpinnerTokens.css';
import { SpinnerProps } from './type';

import type { DimensionMode } from '../../types/shared';
import { clsx } from '../../utils/clsx';
import { useStyleProps } from '../../utils/styleProps';
import { Icon } from '../Icon';
import Loading from '../Icon/Loading';

const SpinnerSize = {
  default: spinnerThemeVars.ItemSizing.Spinner.Default,
  small: spinnerThemeVars.ItemSizing.Spinner.Small,
  large: spinnerThemeVars.ItemSizing.Spinner.Large,
};

const Spinner = (props: SpinnerProps) => {
  const {
    spinning = true,
    children,
    tip,
    color = spinnerThemeVars.Color.Spinner,
    size = 'default',
    className,
    ...rest
  } = props;
  const spinnerIconSize = size in SpinnerSize ? SpinnerSize[size as DimensionMode] : size;
  const iconSizeStyle = size in SpinnerSize ? {} : { width: size, height: size };
  const { styleProps } = useStyleProps(rest);
  return (
    <div
      style={{ ...iconSizeStyle, ...styleProps.style }}
      className={clsx(spinnerThemeClass, spinnerRecipe({ size: size as DimensionMode }), className)}
    >
      {spinning ? (
        <div className={spinnerIconWrapperRecipe()}>
          <Icon source={Loading} size={spinnerIconSize} color={color}></Icon>
          <div className={spinnerTipRecipe()}>{tip}</div>
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Spinner;
