/* eslint-disable max-lines */
import {
  type ActorRefFrom,
  ErrorActorEvent,
  type SnapshotFrom,
  assertEvent,
  assign,
  enqueueActions,
  sendParent,
  setup,
  spawnChild,
  stopChild,
} from 'xstate';

import {
  Children,
  IEvent,
  IOutput,
  ItemSelectionSubFlowContext,
  ItemSelectionSubFlowInput,
} from './types.ts';

import { getItemSelectionGrouping, getOrder, getReasons, getReturns } from '../../promise';
import { getQuestions } from '../../promise/questions';
import { getShopEligibilityRules } from '../../promise/shopEligibilityRules';
import { ErrorResponse } from '../../request/ErrorResponse.ts';
import { PrivateSelectionItemDoneEvent, ReasonGroup } from '../../types';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils.ts';
import { getReturnableListAndNonReturnableList } from '../../utils/formatReturnableList.ts';
import { parseAskQuestion } from '../../utils/parseEngine.ts';
import type { IInput as SelectSingleItemSubFlowInput } from '../SelectSingleItemSubFlow';
import { selectSingleItemSubFlow } from '../SelectSingleItemSubFlow';

export const itemSelectionSubFlow = setup({
  types: {
    input: {} as ItemSelectionSubFlowInput,
    context: {} as ItemSelectionSubFlowContext,
    events: {} as IEvent,
    output: {} as IOutput,
    children: {} as Children,
  },
  actors: {
    getReturns,
    getOrder,
    getReasons,
    getQuestions,
    getItemSelectionGrouping,
    getShopEligibilityRules,
    selectSingleItemSubFlow,
  },
  actions: {
    updateNonReturnReason: () => {},
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAoC2BDAxgCwJYDswBKAYgGUBRAGQoGEAVAfQEl6KBZAbQAYBdRUAAcA9rFwAXXMPwCQAD0QAWAEwAaEAE9EARkXaAdAE4AzADZTAdmWGArMtPazAXyfq0WPISL6AZmHE4APIAThBgwQCC+BAASv4ArsH4sCQQ0mD6sOLo4hnuOATEvv5BoeFRsQlJsDz8SCAiYpLSsgoIpgAcHfoW2tyGvabcysoWForqWgiKNgZ6xsod-Z3GXdoubhgFXsUB2CFhkdFx4onJ+jDiB+H6ADbC6BAEUKnp+gQAbsIA1nlbnkU-HtrkdKqdqhd-CC7g8nvgoAhPsJMDkpPharVZI0JGjWohFt0OjYTONFMYLNxTMpjJNEMY9PoyZY7DYOrYbA5FBsQPkAd4gaVDhUTmdYJCrmVgjDHs8SOFgsIpYJbjkfIrUPpeYV+SV9pLhVVzpdofcZfDEfgvijmui+Jj6tibXiEAT9ESSYoyRSqTTNEp6W62WzrMZuNxWcZuVqdgK9ULjoaxZcRRDTXCXmlCO9LT8-h5tbtBeUE+Cjf4U+c088LVbUdIMXwsaIcS16m1QwZjLMbBZbNpezYFhZaQhiQYuoYuopxoYHD2o-8C7GQQbS0ny4npem5cEFUqVeI1cENdHAbqVyXReKK2Kq+akda0Q26kJm0623TuJ3uwP+7YhyOnQ2Po9KhsoNj9F+-YWAu+YxrqcToLA0i3rCsqZhkSK-Jqi7wXsiHIZWaH3jmj71najYOm+uIfggfTkoyHSel2E4sR0I4qMYRhmBSFiDtoRLgaYsHbGe+FgEhKFbrK8qKvoyqquqOFwWJOAEVJd4Ig+da2rw9qvk0NGgG09EWIxzGsmybEjrMZmrJO04dFYXbTiJfKZGAtxgJg4jMLkqCvFmWQ5HmoneLAnneb5-n6Q01GtsZiDjN0XY2BB2iGIspieqYI4dAYdhhmYTlWEMhiGC4rggPgwhhPA9SnkQTaGQl8iIAAtCoI7tcBk5hv1A39VyVWNYWcbFmCorNS2Mi0blfp0eYjJmJYXRdtw9K6G5S7nvql4QsakrTe+iWjhMC3gVxJjmM5wyKKYszbXhRagje4omsRUDHUZbV0cMI4ufo2imMYCwdKGvZ6K5I24ap42vZuh2HJk8SYJgcD1QZM3OjMXGmP+xh9OV+P3cOC1mMoQMgwss6Tn0nFPXDF6TQdUKSvosnBN9rVtLj+j44OhP9LTpMcaDgaTkSxjlRY2XKMNmwqTqwJ7SzZbiDe3OzadNjnVMEGKCBQaGNoizKAJ042IzysvauV7Jpumla86fRqAtQyGw42Ue50-UVTDStjczb0O2uKNoxjzu0Y5-O9GyYazPlFhEoBpv88D90CaywOrNbQeqyHG5h5zUenTHlgCYYCcCf2Kfu0xIFkl+fHEh01IwQHYVjepySl79yfAeSBujBtTmegDZjLRXiiUp65vCZ37mxj3qFml9VEtdrv3g1xQ-cCoFKrKSYumPzIMjws2XOIvO3iZJ5ywKj6OwJjcWby75uGPoHLgZDsszGTKYnpPbnweuBe6AxZZ52XhJQiYoS4b2xrRU2GVv5Uh7IYac2Uew2WliBcwehZhfmlrrPOEUvI+T8mAVAfc2hty-sDVkvRbAbWUBtEcugzIWGltwZhvCqSVScEAA */
  context: ({ input }) => {
    return {
      ...input,
    };
  },
  initial: 'validateData',
  output: ({ context }) => {
    return {
      selectedItems: context.selectedItems,
    };
  },
  states: {
    validateData: {
      always: [
        {
          guard: ({ context }) => Boolean(context.orderId),
          target: 'fetchOrderAndReturns',
        },
        {
          actions: sendParent(() => ({
            type: 'GO_TO_ORDER_LOOKUP',
          })),
        },
      ],
    },
    fetchOrderAndReturns: {
      id: 'fetchOrderAndReturns',
      type: 'parallel',
      states: {
        getOrder: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getOrder',
                input: ({ context }) => {
                  return {
                    order_id: context.orderId,
                    token: context.token!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    orderInfo: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getOrder error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
            },
            error: {},
          },
        },

        getReturns: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getReturns',
                input: ({ context }) => {
                  return {
                    order_id: context?.orderId,
                    token: context.token!,
                  };
                },
                onDone: {
                  target: 'success',
                  actions: assign({
                    returns: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getReturns error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: { type: 'final' },
            error: {},
          },
        },
      },
      onDone: {
        target: 'fetchSelectionRelatedInfo',
        actions: assign(({ context }) => {
          const { orderInfo, grayFeatureEnabledMap } = context;
          const {
            returnableOrderItems: returnableItems,
            nonReturnableOrderItems: nonReturnableItems,
          } = getReturnableListAndNonReturnableList(orderInfo, grayFeatureEnabledMap);
          return {
            returnableItems,
            nonReturnableItems,
          };
        }),
      },
    },
    fetchSelectionRelatedInfo: {
      id: 'fetchSelectionRelatedInfo',
      type: 'parallel',
      states: {
        getReasons: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                input: ({ context }) => ({
                  returnableItems: context.returnableItems!,
                  orderId: context.orderInfo?.order?.id ?? '',
                  token: context.token!,
                }),
                src: 'getReasons',
                onDone: {
                  target: 'success',
                  actions: assign({
                    reasonsMatchings: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getReasons error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
            },
            error: {
              type: 'final',
            },
          },
        },
        getQuestions: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getQuestions',
                input: ({ context }) => ({
                  token: context.token!,
                }),
                onDone: {
                  target: 'success',
                  actions: assign({
                    questions: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getQuestions error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: { type: 'final' },
            error: { type: 'final' },
          },
        },
        getShopEligibilityRules: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getShopEligibilityRules',
                input: ({ context }) => ({
                  shopId: context.shopId,
                  token: context.token!,
                }),
                onDone: {
                  target: 'success',
                  actions: assign({
                    shopEligibilityRules: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getShopEligibilityRules error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
            },
            error: { type: 'final' },
          },
        },
        getItemSelectionGrouping: {
          initial: 'loading',
          states: {
            loading: {
              tags: 'loading',
              invoke: {
                src: 'getItemSelectionGrouping',
                input: ({ context }) => ({
                  token: context.token!,
                }),
                onDone: {
                  target: 'success',
                  actions: assign({
                    itemSelectionGrouping: ({ event }) => event.output,
                  }),
                },
                onError: [
                  {
                    guard: ({ event }) =>
                      isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
                    actions: enqueueActions(({ enqueue }) => {
                      console.log('getItemSelectionGrouping error');
                      enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                        type: 'HANDLE_JWT_ERROR',
                      });
                    }),
                  },
                  {
                    target: 'error',
                  },
                ],
              },
            },
            success: {
              type: 'final',
            },
            error: { type: 'final' },
          },
        },
      },
      onDone: {
        target: 'waitingForSelectItem',
      },
    },
    waitingForSelectItem: {},
    selectSingleItem: {},
    done: {
      type: 'final',
      entry: sendParent(({ context }): PrivateSelectionItemDoneEvent => {
        return {
          type: 'ITEM_SELECTION_DONE',
          data: {
            selectedItems: context.selectedItems,
          },
        };
      }),
    },
  },
  on: {
    REENTER: {
      target: '.fetchOrderAndReturns',
      reenter: true,
      actions: [
        assign({
          activeOrderItem: undefined,
          selectedItems: ({ context, event }) => {
            assertEvent(event, 'REENTER');
            const filterPreviousItem = context.selectedItems?.filter(
              (selectedItem) => selectedItem.itemId !== event.data.itemId,
            );
            return [...filterPreviousItem];
          },
        }),
      ],
    },
    SELECT_ITEM: {
      target: '.selectSingleItem',
      actions: [
        assign({
          activeOrderItem: ({ event }) => event.data.orderItem,
        }),
        spawnChild('selectSingleItemSubFlow', {
          id: ({ event }) => {
            assertEvent(event, 'SELECT_ITEM');
            return event.data.orderItem.external_id;
          },
          input: ({ context, event }): SelectSingleItemSubFlowInput => {
            assertEvent(event, 'SELECT_ITEM');
            const currentSelectedItem = event.data.orderItem;
            const itemId = currentSelectedItem.external_id;
            const groupId = context.reasonsMatchings?.items_return_reason_groups_mapping?.[itemId];

            const reasonGroup = context.reasonsMatchings?.reason_groups.find(
              (group) => group.id === groupId,
            ) as ReasonGroup;
            const questionWorkFlowItem = parseAskQuestion(
              context.questions ?? [],
              currentSelectedItem,
            );
            const selectedItem =
              context.selectedItems.find((selectedItem) => selectedItem.itemId === itemId) ?? {};
            return {
              orderId: context.orderId,
              token: context.token,
              reasonGroup,
              questionWorkFlowItem,
              shopEligibilityRules: context.shopEligibilityRules!,
              activeOrderItem: event.data.orderItem,
              ...selectedItem,
            };
          },
        }),
      ],
    },
    SELECT_ITEM_DONE: {
      target: '.waitingForSelectItem',
      actions: [
        assign({
          activeOrderItem: undefined,
          selectedItems: ({ context, event }) => {
            assertEvent(event, 'SELECT_ITEM_DONE');
            const filterPreviousItem = context.selectedItems.filter(
              (selectedItem) => selectedItem.itemId !== event.data.selectedItem.itemId,
            );
            return [...filterPreviousItem, event.data.selectedItem];
          },
        }),
        sendParent(({ event }) => ({
          type: 'ADD_SELECTED_ITEM',
          data: {
            selectedItem: event.data.selectedItem,
          },
        })),
        stopChild(({ event }) => {
          assertEvent(event, 'SELECT_ITEM_DONE');
          return event.data.selectedItem.itemId;
        }),
      ],
    },
    CANCEL_ITEM: {
      target: '.waitingForSelectItem',
      actions: [
        assign({
          activeOrderItem: undefined,
        }),
        stopChild(({ event }) => {
          assertEvent(event, 'CANCEL_ITEM');
          return event.data.itemId;
        }),
      ],
    },
    REMOVE_ITEM: {
      target: '.waitingForSelectItem',
      actions: [
        assign({
          activeOrderItem: undefined,
          selectedItems: ({ context, event }) => {
            assertEvent(event, 'REMOVE_ITEM');
            const filterPreviousItem = context.selectedItems?.filter(
              (selectedItem) => selectedItem.itemId !== event.data.itemId,
            );
            return [...filterPreviousItem];
          },
        }),
        sendParent(({ event }) => ({
          type: 'REMOVE_SELECTED_ITEM',
          data: {
            itemId: event.data.itemId,
          },
        })),
      ],
    },
    NEXT: {
      target: '.done',
    },
    PREVIEW_UPDATE_NON_RETURN_REASON: {
      actions: 'updateNonReturnReason',
    },
  },
});

export type ItemSelectionSubFlowActorRef = ActorRefFrom<typeof itemSelectionSubFlow>;

export type ItemSelectionSubFlowSnapshot = SnapshotFrom<typeof itemSelectionSubFlow>;
