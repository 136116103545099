import { useReturns } from '@/features/returns/hooks/useReturns';

const useStripeKey = () => {
  const returns = useReturns();

  const STRIPE_KEY = ['production', 'staging'].includes(returns?.APP_ENV)
    ? 'pk_live_51JpLWdCxdop2MWPtyuUfhQebcEw96ctkNkjmJrcP1i3UZNzBHJtM4OosE8YCFcgdLIxNG8BViCvrDWI7hxqUkaOr00zUVKYqgp'
    : 'pk_test_51M24oWEMx1Afn7K6EFRpSEhpDl766PCJ39ZOxPE9o6WAC5yZFoFYUZh6s7yR0Khb07WRuCFcUae4iwCP3baamgqn00noZ9INwm';

  return STRIPE_KEY;
};

export default useStripeKey;
