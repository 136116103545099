import { GiftReturnPage } from '@/features/gift-return/page';
import { OrderLookupPage } from '@/features/order-lookup/page';
import { RequestReturnsPage } from '@/features/request-returns/page';
import { ReturnResolutionPage } from '@/features/resolution/page';
import PrivacyPolicy from '@/features/return-policy/components/PrivacyPolicy';
import ReturnsList from '@/features/returns-list';
import { Container, Footer, Header, Layout } from '@/features/returns/components/ReturnsPage';
import { ReviewPage } from '@/features/review/page';

import { ComponentName } from './type';

import { ReturnMethodPage } from '../return-method/page';

const components: Record<ComponentName, React.FC<any>> = {
  [ComponentName.ReturnsPageLayout]: Layout,
  [ComponentName.ReturnsPageContainer]: Container,
  [ComponentName.ReturnsPageHeader]: Header,
  [ComponentName.ReturnsPageFooter]: Footer,
  [ComponentName.OrderLookup]: OrderLookupPage,
  [ComponentName.ReturnMethod]: ReturnMethodPage,
  [ComponentName.ReturnsList]: ReturnsList,
  [ComponentName.Review]: ReviewPage,
  [ComponentName.Resolution]: ReturnResolutionPage,
  [ComponentName.ItemSelection]: RequestReturnsPage,
  [ComponentName.GiftReturn]: GiftReturnPage,
  [ComponentName.ReturnPolicy]: PrivacyPolicy,
};

export const loader = (type: string, name: string) => {
  if (components[name as ComponentName]) {
    return components[name as ComponentName];
  } else {
    return () => null;
  }
};
