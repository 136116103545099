/* eslint-disable max-lines */
import {
  ActorRefFrom,
  ErrorActorEvent,
  SnapshotFrom,
  assign,
  enqueueActions,
  sendParent,
  setup,
  spawnChild,
  stopChild,
} from 'xstate';

import {
  Children,
  ResolutionContext,
  ResolutionEmitted,
  ResolutionEvent,
  ResolutionInput,
} from './types.ts';

import { Resolution } from '../../constant';
import { getMatchingMethod } from '../../promise';
import { getMatchingResolutions } from '../../promise/resolution.ts';
import { ErrorResponse } from '../../request/ErrorResponse.ts';
import { PrivateResolutionDoneEvent, ResolutionDescription, ReturnItemsPayload } from '../../types';
import { isJWTError, takeMainActorRef } from '../../utils/eventUtils.ts';
import { decisionMethodOrReviewActor } from '../DecisionMethodOrReview';
import { exchangeOrRefundSubFlow } from '../ExchangeOrRefundSubFlow';

export const resolutionSubFlow = setup({
  types: {
    input: {} as ResolutionInput,
    context: {} as ResolutionContext,
    events: {} as ResolutionEvent,
    children: {} as Children,
    emitted: {} as ResolutionEmitted,
  },
  actors: {
    getMatchingResolutions,
    getMatchingMethod,
    exchangeOrRefundSubFlow,
    decisionMethodOrReviewActor,
  },
  actions: {
    updatePreviewData: () => {},
  },
}).createMachine({
  initial: 'validateData',
  entry: 'updatePreviewData',
  context: ({ input }) => {
    return {
      ...input,
      skipReturnMethodAfterEFA: false,
      isWaitingSelectEfaOrRefund: false,
    };
  },
  states: {
    validateData: {
      always: [
        {
          guard: ({ context }) => Boolean(context.selectedItems.length),
          target: 'fetchMatchingResolutions',
        },
        {
          actions: sendParent(() => ({
            type: 'GO_TO_ORDER_LOOKUP',
          })),
        },
      ],
    },
    fetchMatchingResolutions: {
      tags: 'loading',
      invoke: {
        src: 'getMatchingResolutions',
        input: ({ context }) => {
          return {
            token: context.token,
            payload: {
              returnItems: context.selectedItems.map<ReturnItemsPayload>((item) => ({
                item_id: item.itemId,
                quantity: item.quantity,
                reason: item.selectedReason,
                subreason: item.selectedSubReason ?? '',
              })),
              isGiftReturn: context.isGiftReturn,
            },
          };
        },
        onDone: {
          target: 'waitingForSelectResolution',
          actions: assign({
            matchingResolutions: ({ context, event }) => {
              // 将真实可用的 resolution 存入 context 中
              return event.output.applied_resolutions
                .filter(
                  (item) =>
                    !context.resolutionWhiteList?.length ||
                    context.resolutionWhiteList.includes(item),
                )
                .map<ResolutionDescription>((resolutionKey) => {
                  // 数据层面保证必须存在
                  const resolutionDescription = event.output.resolutions.find(
                    (item) => resolutionKey === item.type,
                  )!;
                  return {
                    type: resolutionKey,
                    name: resolutionDescription.name,
                    description: resolutionDescription.description,
                  };
                });
            },
          }),
        },
        onError: [
          {
            guard: ({ event }) => isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
            actions: enqueueActions(({ enqueue }) => {
              console.log('getMatchingResolutions error');
              enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                type: 'HANDLE_JWT_ERROR',
              });
            }),
          },
          {
            target: 'error',
          },
        ],
      },
    },
    waitingForSelectResolution: {},
    waitingForSelectedRefund: {
      id: 'waitingForSelectedRefund',
    },
    waitingForSelectedEfaOrRefund: {},
    beforeSelectedRefundMe: {
      always: [
        {
          guard: ({ context }) => {
            // 如果只有一个 refund 类型的 resolution, 则自动选择
            const refundResolutions = [
              Resolution.OriginalPayment,
              Resolution.StoreCredit,
              Resolution.Refundid,
            ];
            return (
              context.matchingResolutions?.filter((resolution) =>
                refundResolutions.includes(resolution.type),
              )?.length === 1
            );
          },
          actions: assign({
            selectedResolution: ({ context }) => {
              // 如果只有一个 refund 类型的 resolution, 则自动选择
              const refundResolutions = [
                Resolution.OriginalPayment,
                Resolution.StoreCredit,
                Resolution.Refundid,
              ];
              const filterRefundResolutions = context.matchingResolutions?.filter((resolution) =>
                refundResolutions.includes(resolution.type),
              );
              return filterRefundResolutions?.[0]?.type;
            },
          }),
          target: 'beforeConfirmResolution',
        },
        {
          target: 'waitingForSelectedRefund',
        },
      ],
    },
    beforeConfirmResolution: {
      id: 'beforeConfirmResolution',
      tags: 'loading',
      invoke: {
        src: 'decisionMethodOrReviewActor',
        input: ({ context }) => ({
          token: context.token!,
          bypassReturnMethod: context.bypassReturnMethod,
          selectedItems: context.selectedItems,
          exchangeItems: [],
          selectedResolution: context.selectedResolution,
          isGiftReturnMode: context.isGiftReturn,
        }),
        onDone: {
          actions: enqueueActions(({ context, event, enqueue }) => {
            const selectedMethod = event.output.selectedMethod;
            enqueue.assign({
              selectedMethod: selectedMethod,
              skipReturnMethodAfterEFA:
                context.selectedResolution === Resolution.ExchangeForAnything && !!selectedMethod,
            });
          }),
          target: 'done',
        },
        onError: [
          {
            guard: ({ event }) => isJWTError((event as ErrorActorEvent<ErrorResponse>).error.code),
            actions: enqueueActions(({ enqueue, event }) => {
              console.log('decisionMethodOrReviewActor error', event);
              enqueue.sendTo(({ self }) => takeMainActorRef(self), {
                type: 'HANDLE_JWT_ERROR',
              });
            }),
          },
          {
            target: 'error',
          },
        ],
      },
    },
    error: {},
    done: {
      id: 'done',
      type: 'final',
      entry: sendParent(({ context }): PrivateResolutionDoneEvent => {
        return {
          type: 'resolution_done',
          data: {
            selectedResolution: context.selectedResolution!,
            selectedMethod: context.selectedMethod,
            skipReturnMethodAfterEFA: context.skipReturnMethodAfterEFA,
          },
        };
      }),
    },
  },
  on: {
    /* 来自 efa modal 的事件 */
    CANCEL_CHOOSE_EFA_OR_REFUND: {
      target: '.waitingForSelectResolution',
      actions: [
        assign({ isWaitingSelectEfaOrRefund: false }),
        stopChild('exchangeOrRefundSubFlow'),
      ],
    },
    CHOOSE_REFUND: {
      target: '.beforeSelectedRefundMe',
      actions: [
        assign({ isWaitingSelectEfaOrRefund: false }),
        stopChild('exchangeOrRefundSubFlow'),
      ],
    },
    CHOOSE_SHOP_NOW: {
      target: '.beforeConfirmResolution',
      actions: [
        assign({
          selectedResolution: Resolution.ExchangeForAnything,
          productUrl: void 0,
          productId: void 0,
        }),
        stopChild('exchangeOrRefundSubFlow'),
      ],
    },
    CHOOSE_PRODUCT: {
      target: '.beforeConfirmResolution',
      actions: [
        assign({
          selectedResolution: Resolution.ExchangeForAnything,
          productUrl: ({ event }) => event.data.productUrl,
          productId: ({ event }) => event.data.productId,
        }),
        stopChild('exchangeOrRefundSubFlow'),
      ],
    },
    /* 来自 resolution 的事件*/
    CHOOSE_EFA_OR_REFUND: {
      target: '.waitingForSelectedEfaOrRefund',
      actions: [
        assign({ isWaitingSelectEfaOrRefund: true }),
        spawnChild('exchangeOrRefundSubFlow', {
          id: 'exchangeOrRefundSubFlow',
          syncSnapshot: true,
          input: ({ context }) => {
            return {
              token: context.token,
              bypassReturnMethod: context.bypassReturnMethod,
              orderId: context.orderId,
              selectedItems: context.selectedItems,
              showRecommendProducts: context.showRecommendProducts,
              efaModel: context.efaModel,
            };
          },
        }),
      ],
    },
    CONFIRM_RESOLUTION: [
      // 灰度名单的用户,虽然选择的是 replace,但是要走 efa
      {
        guard: ({ context, event }) =>
          event.data.selectedResolution === Resolution.ReplaceTheSameItem &&
          context.forceEfaOnStoreWhenReplace,
        target: '.beforeConfirmResolution',
        actions: [
          assign({
            selectedResolution: Resolution.ExchangeForAnything,
            productUrl: ({ context }) => context.selectedItems[0].productUrl,
          }),
        ],
      },
      {
        guard: ({ event }) => event.data.selectedResolution === Resolution.ReplaceTheSameItem,
        target: '.done',
        actions: assign({
          selectedResolution: Resolution.ReplaceTheSameItem,
        }),
      },
      {
        target: '.beforeConfirmResolution',
        actions: assign({
          selectedResolution: ({ event }) => event.data.selectedResolution,
        }),
      },
    ],
  },
});

export type ResolutionSubFlowActorRef = ActorRefFrom<typeof resolutionSubFlow>;

export type ResolutionSubFlowSnapshot = SnapshotFrom<typeof resolutionSubFlow>;
