export enum ExceptionCode {
  // 非预期的 hostname
  UnexpectedHostnameError = 13001,
  UnexpectedSSRError = 13002,
}

export enum ExceptionName {
  UnexpectedHostnameError = 'UnexpectedHostnameError',
  UnexpectedSSRError = 'UnexpectedSSRError',
}

export class CustomException extends Error {
  code;

  constructor(
    name: ExceptionName,
    code: ExceptionCode,
    message = 'Unknown error message',
    options?: ErrorOptions,
  ) {
    super(message);
    this.name = name;
    this.code = code;
    this.cause = options?.cause;
  }
}

export class UnexpectedHostnameException extends CustomException {
  constructor(message: string, options?: ErrorOptions) {
    super(
      ExceptionName.UnexpectedHostnameError,
      ExceptionCode.UnexpectedHostnameError,
      message,
      options,
    );
  }
}

export class SSRException extends CustomException {
  constructor(message?: string, options?: ErrorOptions) {
    super(ExceptionName.UnexpectedSSRError, ExceptionCode.UnexpectedSSRError, message, options);
  }
}
