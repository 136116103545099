import React, { FC } from 'react';

import { Flex, Link, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

interface Props {
  isPreview?: boolean;
}

const PoweredBy: FC<Props> = () => {
  return (
    <Flex justifyContent='center'>
      <Link
        href='https://www.aftership.com/returns?utm_source=returns_page&utm_medium=referral&utm_content=poweredby'
        target='_blank'
      >
        <Text variant='caption2' color={ColorVars['Grey']['Grey1200']}>
          Powered by AfterShip
        </Text>
      </Link>
    </Flex>
  );
};

export default PoweredBy;
