'use client';

import { PropsWithChildren } from 'react';

import { flexRecipe } from './Flex.css';
import { FlexProps } from './type';

import { clsx } from '../../utils/clsx';
import {
  StyleHandlers,
  dimensionValue,
  passthroughStyle,
  useStyleProps,
} from '../../utils/styleProps';

const flexStyleProps: StyleHandlers = {
  direction: ['flexDirection', passthroughStyle],
  wrap: ['flexWrap', flexWrapValue],
  justifyContent: ['justifyContent', flexAlignValue],
  alignItems: ['alignItems', flexAlignValue],
  alignContent: ['alignContent', flexAlignValue],
  gap: ['gap', dimensionValue],
  columnGap: ['columnGap', dimensionValue],
  rowGap: ['rowGap', dimensionValue],
};

function flexWrapValue(value: any) {
  if (typeof value === 'boolean') {
    return value ? 'wrap' : 'nowrap';
  }

  return value;
}

function flexAlignValue(value: any) {
  if (value === 'start') {
    return 'flex-start';
  }

  if (value === 'end') {
    return 'flex-end';
  }

  return value;
}

const Flex = (props: PropsWithChildren<FlexProps>) => {
  const { children, className, ...rest } = props;
  const { styleProps } = useStyleProps(rest);
  const { styleProps: flexStyle } = useStyleProps(rest, flexStyleProps);

  return (
    <div
      style={{
        ...styleProps.style,
        ...flexStyle.style,
      }}
      className={clsx(flexRecipe(), className)}
    >
      {children}
    </div>
  );
};

export default Flex;
