import { useSyncExternalStore } from 'react';

let customTheme: Record<string, string | undefined | null> | undefined;
let listeners: VoidFunction[] = [];

export const customThemeStore = {
  updateCustomTheme: (theme: Record<string, string | undefined | null>) => {
    customTheme = theme;
    emitChange();
  },
  getSnapshot: () => customTheme,
  getServerSnapshot: () => customTheme,
  subscribe: (listener: VoidFunction) => {
    listeners.push(listener);
    return () => {
      listeners = listeners.filter((l) => l !== listener);
    };
  },
};

function emitChange() {
  for (let listener of listeners) {
    listener();
  }
}

export const useCustomThemeVars = () => {
  const subscribe = customThemeStore.subscribe;
  const getSnapshot = customThemeStore.getSnapshot;
  const getServerSnapshot = customThemeStore.getServerSnapshot;

  return useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);
};

/**
 * This method is used to update custom theme variables.
 * After calling this method, will merge the custom theme variables with the default theme variables and update the theme globally.
 * @param theme - The custom theme variables to update.
 */
export const updateCustomThemeVars = customThemeStore.updateCustomTheme;
