/* eslint-disable max-lines */
import * as Sentry from '@sentry/nextjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';

import { Banner, Box, Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { ErrorCode, FiledByType, OrderLookupType } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

import {
  ClickWrapWithPreviewSection,
  MainGiftReturnButton,
  OrderLookupFormTitleText,
  OrderLookupSubmitButton,
  PolicyWithPreviewSection,
} from '@/features/preview/components/WithPreviewSection';
import { useClickwrapContext } from '@/features/returns/components/ClickwrapProvider';
import { useShopInfo } from '@/features/returns/hooks/useShopInfo';
import { useUniversalRouting } from '@/features/returns/hooks/useUniversalRouting';
import queryParamsKeys from '@/features/returns/types/QueryParamsKeys';
import { IQueryParamOrderInfo } from '@/features/returns/types/externalConfig';
import decodeBase64JsonString from '@/features/returns/utils/decodeBase64JsonString';
import useDevice from '@/hooks/useDevice';
import { useGetExternalConfig } from '@/hooks/useGetExternalConfig';
import getTrackerInstance, { getBaseSid } from '@/utils/tracker';
import { EventName, PageId } from '@/utils/tracker/consts';
import { useReportPageViewEvent } from '@/utils/tracker/useReportPageViewEvent';

import { getVerifyMethodConfig } from './ChangeVerifyMethodAction';
import MainPageLayout from './MainPageLayout';
import OrderLookupForm, { ValidateData } from './OrderLookupForm';
import PoweredBy from './PoweredBy';

import { changeActions, orderFormItems } from '../css/style.css';
import { useFindOrder } from '../hooks/useFindOrder';
import { useOrderLookupSetting } from '../hooks/useOrderLookupSetting';

const robotError = 'validation.error.robot';
const matchingError = 'validation.error.wrongOrderNoEmail';

const getQSOrderLookupType = (
  qsData: {
    email?: string;
    postalCode?: string;
  },
  orderLookupSetting: OrderLookupType[],
) => {
  const { email, postalCode } = qsData;
  let orderLookupType = orderLookupSetting[0];
  if (email && orderLookupSetting.includes(OrderLookupType.EMAIL))
    orderLookupType = OrderLookupType.EMAIL;

  if (postalCode && orderLookupSetting.includes(OrderLookupType.ZIPCODE))
    orderLookupType = OrderLookupType.ZIPCODE;

  return orderLookupType;
};

export default function MainPage() {
  const isMobile = useDevice().mobile;
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    policy_url: policyUrl,
    external_return_policy_page: externalReturnPolicyPage,
    show_returns_page_powered_by: showPoweredBy,
    organization: { id: orgId },
  } = useShopInfo();

  const [isFetchingGoogleToken, setIsFetchingGoogleToken] = useState(false);

  const { dispatch, children, context } = useFlow();

  const { onlyEmail, orderLookupVarifyOptions } = useOrderLookupSetting();

  const verifyMethodConfig = getVerifyMethodConfig();

  const {
    clickwrapConfig,
    isClickwrapChecked,
    warningMsgVisible,
    setWarningMsgVisible,
    shouldCheckClickwrap,
    setShouldIgnoreClickwrap,
  } = useClickwrapContext();

  const { isLoading: isGettingOrder, findOrder } = useFindOrder({
    onSuccess: (order) => {
      getTrackerInstance().updateExtraDataByOrderInfo(order);
    },
    onError: (error) => {
      if (error.code === ErrorCode.OrderEmailNotFound) {
        setErrorMessage(t('validation.error.emailNotFound'));
        return;
      }
      if (verifyMethod) {
        const methods = t('orderLookup.methods.orderNumberAndEmail', {
          verifyMethod: verifyMethodConfig[verifyMethod]?.errorLabel,
        });
        setErrorMessage(
          t(matchingError, {
            verification_methods: methods,
          }),
        );
      }
    },
  });

  const orderLookupSubFlow = children?.orderLookupSubFlow;

  useEffect(() => {
    if (orgId) {
      Sentry.setTag('orgId', orgId);
    }

    if (orderLookupSubFlow?.snapshot?.status === 'done') {
      const email = orderLookupSubFlow?.snapshot?.output?.email;
      const orderNumber = orderLookupSubFlow?.snapshot?.output?.orderNumber;
      if (email) {
        Sentry.setUser({ email });
        Sentry.setTag('email', email);
      }
      if (orderNumber) {
        Sentry.setTag('orderNumber', orderNumber);
      }
    }
  }, [
    orgId,
    orderLookupSubFlow?.snapshot?.status,
    orderLookupSubFlow?.snapshot?.output?.email,
    orderLookupSubFlow?.snapshot?.output?.orderNumber,
  ]);

  const isLoading = isGettingOrder || isFetchingGoogleToken;

  const [errorMessage, setErrorMessage] = useState('');

  const { getSearchParam } = useUniversalRouting();

  const qsOrderValue = useMemo<IQueryParamOrderInfo>(() => {
    const defaultValue: IQueryParamOrderInfo = {
      order_number: '',
      email: '',
      type: '',
      filed_by: '',
      intention_id: '',
    };

    try {
      const qs = getSearchParam<string | undefined>(queryParamsKeys.ORDER_QS);

      if (qs) {
        const result = decodeBase64JsonString<IQueryParamOrderInfo>(qs);
        return result;
      }
    } catch (e) {
      return defaultValue;
    }

    return defaultValue;
  }, [getSearchParam]);

  const [verifyMethod, setVerifyMethod] = useState<OrderLookupType | null>(null);

  const externalConfig = useGetExternalConfig();

  const handleSubmit = useCallback(
    async ({
      orderNumber,
      email,
      postalCode,
      phoneNumber,
      orderLookupType,
    }: {
      orderNumber: string;
      email?: string;
      postalCode?: string;
      phoneNumber?: string;
      orderLookupType?: OrderLookupType;
    }) => {
      if (!orderLookupSubFlow?.ref || !orderLookupType) return;

      setVerifyMethod(orderLookupType);

      const isEmail = orderLookupType === OrderLookupType.EMAIL;
      if (shouldCheckClickwrap) {
        setWarningMsgVisible(true);
        return;
      }

      const commonPayload = {
        isMerchantMode: !!externalConfig?.merchantModeConfig,
        ...(qsOrderValue.intention_id && { intentionId: qsOrderValue.intention_id }),
        ...(qsOrderValue.filed_by && { filedBy: qsOrderValue.filed_by as FiledByType }),
        merchantModeToken: externalConfig?.merchantModeConfig?.session.token,
        resolutionWhiteList: externalConfig?.activedResolutionOptions,
        rmaId: qsOrderValue.rma_id,
        traceId: externalConfig?.merchantModeConfig?.trace_id || getBaseSid(),
      };

      if (isEmail) {
        findOrder({
          orderLookupType: OrderLookupType.EMAIL,
          email,
          orderNumber: orderNumber.trim(),
          ...commonPayload,
        });
      } else if (orderLookupType === OrderLookupType.ZIPCODE) {
        if (executeRecaptcha) {
          setIsFetchingGoogleToken(true);
          executeRecaptcha()
            .then((gReCaptchaToken) => {
              setIsFetchingGoogleToken(false);
              findOrder({
                orderLookupType: OrderLookupType.ZIPCODE,
                zipCode: postalCode,
                orderNumber: orderNumber.trim(),
                googleToken: gReCaptchaToken,
                ...commonPayload,
              });
            })
            .catch(() => {
              setErrorMessage(t(robotError));
            });
        }
      } else if (orderLookupType === OrderLookupType.PHONE) {
        findOrder({
          orderLookupType: OrderLookupType.PHONE,
          phoneNumber,
          orderNumber: orderNumber.trim(),
          ...commonPayload,
        });
      }
    },
    [
      executeRecaptcha,
      externalConfig?.merchantModeConfig,
      externalConfig?.activedResolutionOptions,
      qsOrderValue.intention_id,
      qsOrderValue.filed_by,
      qsOrderValue.rma_id,
      orderLookupSubFlow?.ref,
      findOrder,
      setWarningMsgVisible,
      shouldCheckClickwrap,
      t,
    ],
  );

  useReportPageViewEvent(PageId.orderLookup);

  const autoSubmitByQs = useCallback(async () => {
    const { order_number, email, type, postal_code } = qsOrderValue;

    const orderLookupType =
      getQSOrderLookupType({ email, postalCode: postal_code }, orderLookupVarifyOptions) ?? {};

    if (order_number && (email || postal_code)) {
      // when from admin onBoarding, not auto submit
      if (type === 'onBoarding') return;
      // getTrackerInstance().reportClickEvent(
      //   BizClickName.return_query_query_string
      // )

      // ignore the clickwrap for auto submit from admin
      setShouldIgnoreClickwrap(true);

      getTrackerInstance().reportClickEvent({
        eventName: EventName.findOrder,
        payload: {
          orderLookupType,
          source: 'auto',
        },
      });

      handleSubmit({
        orderNumber: order_number,
        postalCode: postal_code,
        email,
        orderLookupType,
      });
    }
  }, [qsOrderValue, handleSubmit, setShouldIgnoreClickwrap, orderLookupVarifyOptions]);

  useEffect(() => {
    if (onlyEmail || executeRecaptcha) {
      autoSubmitByQs();
    }
  }, [executeRecaptcha, onlyEmail, autoSubmitByQs]);

  const onValidate = ({ isValid, errorMessage }: ValidateData) => {
    if (isValid) return;

    setErrorMessage(errorMessage);
  };

  return (
    <MainPageLayout>
      <Flex flex={1} direction='column'>
        <Flex direction='column' alignItems='center' gap={SpacingVars['2']}>
          <Flex direction='column' alignSelf='stretch'>
            <Flex direction='column' gap={SpacingVars['6']}>
              <OrderLookupFormTitleText variant={isMobile ? 'title1' : 'title2'} textAlign='center'>
                {t('page.landing.title')}
              </OrderLookupFormTitleText>
              <Flex
                direction='column'
                gap={SpacingVars['4']}
                // paddingBottom={isMobile ? SpacingVars['8'] : SpacingVars['4']}
              >
                {errorMessage && (
                  <Banner
                    info={<Text wordBreak='break-word'>{errorMessage}</Text>}
                    variant='warning'
                  />
                )}
                <Flex direction='column' gap={SpacingVars['2']}>
                  <OrderLookupForm
                    initialEmail={qsOrderValue.email}
                    initialOrderNumber={qsOrderValue.order_number}
                    initialPostalCode={qsOrderValue.postal_code}
                    defaultOrderLookupType={getQSOrderLookupType(
                      { email: qsOrderValue.email, postalCode: qsOrderValue.postal_code },
                      orderLookupVarifyOptions,
                    )}
                    classNames={{
                      formItems: orderFormItems,
                      changeAction: changeActions,
                    }}
                    onValidate={onValidate}
                    renderActionButton={(isValid, data) => {
                      return (
                        <Box marginTop={SpacingVars['2']}>
                          <OrderLookupSubmitButton
                            isLoading={isLoading}
                            isDisabled={!isValid}
                            size='large'
                            onPress={() => {
                              if (!data.verifyMethod) return;

                              getTrackerInstance().reportClickEvent({
                                eventName: EventName.findOrder,
                                payload: {
                                  orderLookupType: data.verifyMethod,
                                  source: 'main_page_click',
                                },
                              });

                              handleSubmit({
                                orderNumber: data.orderNumber ?? '',
                                email: data.email,
                                phoneNumber: data.phoneNumber,
                                postalCode: data.postalCode,
                                orderLookupType: data.verifyMethod!,
                              });
                            }}
                          >
                            {t('page.landing.findYourOrder')}
                          </OrderLookupSubmitButton>
                        </Box>
                      );
                    }}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>

          <Flex direction='column' gap={SpacingVars['6']}>
            <Flex justifyContent='center'>
              {context?.storeConfig?.giftReturnSetting?.giftReturnAvailability && (
                <MainGiftReturnButton
                  variant='link'
                  onPress={() => {
                    getTrackerInstance().reportClickEvent({
                      eventName: EventName.clickGoToGiftReturn,
                    });
                    dispatch?.({ type: 'GO_TO_GIFT_RETURN' });
                  }}
                >
                  <Text variant='subtitle1'>{t('page.landing.action.giftReturn')}</Text>
                </MainGiftReturnButton>
              )}
            </Flex>
            <Flex direction='column' gap={SpacingVars['1']}>
              {clickwrapConfig?.feature_applied && (
                <ClickWrapWithPreviewSection
                  isClickwrapChecked={isClickwrapChecked}
                  clickwrapError={warningMsgVisible}
                />
              )}
              <PolicyWithPreviewSection
                policyTextHeight={90}
                {...{ externalReturnPolicyPage, policyUrl }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Box flex={1} />
        {showPoweredBy && (
          <Box paddingTop={SpacingVars['6']}>
            <PoweredBy isPreview={false} />
          </Box>
        )}
      </Flex>
    </MainPageLayout>
  );
}
