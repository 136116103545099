import { useEffect, useState } from 'react';
import { useHover } from 'react-aria';

import { carouselRecipe } from './Carousel.css';
import CarouselAnimateWrapper from './CarouselAnimateWrapper';
import CarouselIndicator from './CarouselIndicator';
import { useCarousel } from './hooks';
import { CarouselElementProps, CarouselProps } from './type';

import { clsx } from '../../utils/clsx';
import { useStyleProps } from '../../utils/styleProps';

const Carousel = <T extends any>(props: CarouselProps<T>) => {
  const {
    renderItem,
    items,
    className,
    actionArrows = false,
    transitionDuration = 300,
    ...rest
  } = props;
  /****************** State and Args  ******************/
  const { styleProps } = useStyleProps(rest);
  const [animated, setAnimated] = useState(true);

  const totalSlides = items.length > 1 ? items.length + 2 : 1;
  const duration = Math.max(100, transitionDuration);

  const { index, nextSilde, prevSilde, moveSildeTo, start, stop } = useCarousel({
    currentIndex: 1,
    total: totalSlides,
    transitionDuration,
    stepAction: () => setAnimated(true),
    ...rest,
  });

  /******************  Handle hover behavior ******************/
  const { hoverProps, isHovered } = useHover({});
  useEffect(() => {
    isHovered ? stop() : start();
  }, [isHovered, start, stop]);

  /******************  Render ******************/
  const carouselElemProps: CarouselElementProps = {
    animated,
    nextSilde,
    prevSilde,
    moveSildeTo,
    index,
    duration,
    actionArrows,
    setAnimated,
    ...props,
  };

  return (
    <div tabIndex={0} className={clsx(carouselRecipe(), className)} {...hoverProps} {...styleProps}>
      <CarouselAnimateWrapper {...carouselElemProps}>{renderItem}</CarouselAnimateWrapper>
      <CarouselIndicator {...carouselElemProps} />
    </div>
  );
};

export default Carousel;
