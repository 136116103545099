import { IconSource } from './type';

const ChevronDown: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='m3 6 4.823 4.823a.25.25 0 0 0 .354 0L13 6'
        stroke='#232933'
        strokeWidth={1.75}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default ChevronDown;
