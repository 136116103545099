import {
  AndCondition,
  Condition,
  OrCondition,
  QuestionWorkFlowItem,
  ReturnableOrderItem,
  RuleKey,
} from '../types';

export type Options = {
  // data 中的 字段, 与规则中的 key 可能不匹配,可以传入一个 mapper
  // 规则中的 key 作为 mapper 的 key, data 中的字段名称作为 value
  mapper: Record<string, string>;
};

export type ParseResult = {
  result: boolean;
  childResult?: Array<ParseResult>;
};

export const getIsOperatorResult = (key: string, value: string, data: any): ParseResult => {
  const compareValue = data[key];
  const result: ParseResult = { result: false };

  if (Array.isArray(compareValue)) {
    result.result =
      compareValue.length > 0 &&
      (compareValue as Array<any>).some(
        (item) => item.toLocaleUpperCase() === value.toLocaleUpperCase(),
      );
  } else {
    result.result = value.toLocaleUpperCase() === compareValue.toLocaleUpperCase();
  }
  return result;
};

export const getIsNotOperatorResult = (key: string, value: string, data: any): ParseResult => {
  const compareValue = data[key];
  const result: ParseResult = { result: false };

  if (Array.isArray(compareValue)) {
    result.result = compareValue.every(
      (item) => item.toLocaleUpperCase() !== value.toLocaleUpperCase(),
    );
  } else {
    result.result = value.toLocaleUpperCase() !== compareValue.toLocaleUpperCase();
  }
  return result;
};

export class ParseEngine {
  private parse(condition: Condition, data: any, options?: Options): ParseResult {
    if ('or' in condition) {
      return this.operations.or((condition as OrCondition).or, data, options);
    }

    if ('and' in condition) {
      return this.operations.and((condition as AndCondition).and, data, options);
    }

    if ('key' in condition) {
      const key = options?.mapper[condition.key] ?? condition.key;
      return this.operations[condition.operator](key, condition.value, data);
    }
    // 不支持的 operation, 在下面支持
    return { result: false };
  }

  // 以后再根据规范扩展操作符,譬如 < , > , = 等等
  private operations = {
    and: (conditionArray: Array<Condition>, data: any, options?: Options): ParseResult => {
      const result: ParseResult = { result: false };
      result.result =
        conditionArray.length > 0 &&
        conditionArray.every((condition) => {
          const singleParseResult = this.parse(condition, data, options);

          if (!result.childResult) {
            result.childResult = [];
          }
          result.childResult.push(singleParseResult);
          return singleParseResult.result;
        });

      return result;
    },
    or: (conditionArray: Array<Condition>, data: any, options?: Options): ParseResult => {
      const result: ParseResult = { result: false };
      result.result = conditionArray.some((condition) => {
        const singleParseResult = this.parse(condition, data, options);

        if (!result.childResult) {
          result.childResult = [];
        }
        result.childResult.push(singleParseResult);
        return singleParseResult.result;
      });
      return result;
    },
    is: getIsOperatorResult,
    is_value: getIsOperatorResult,
    is_not_value: getIsNotOperatorResult,
  };

  /**
   * 作用与 data 上的 condition 是否成立
   * ParseResult#result 为最终结果
   * 每一层级的 condition 运算结果会存入 ParseResult 中,并且一一对应
   * example:
   * condition:
   * {
   *    or: [
   *         {
   *           or: [{or: [{a is 1}, {a is 2}, {a is 3}]}, {b is 4}],
   *         },
   *          {
   *           and: [{a is4},{b is 5}],
   *         },
   *    ]
   * }
   * data:
   * {
   *     a: 4
   *     b: 5
   * }
   * ParseResult:
   * {
   *     result: true,
   *     childResult:[
   *         {result:false,childResult:[{result:false,childResult:[{result:false},{result:false},{result:false}]},{result:false}]},
   *         {result:true,childResult:[{result:true},{result:true}]}
   *     ]
   * }
   * @param condition 规则
   * @param data condition 作用的数据
   * @param options BaseCondition 中的 key 与 数据中的 key 可能不一致, 通过 options 做 mapping
   */
  check(condition: Condition, data: any, options?: Options): ParseResult {
    return this.parse(condition, data, options);
  }
}

/**
 * 使用 ParseEngine 解析当前选择要退的商品是否需要展示 ask question
 * @param questions
 * @param currentSelectedItem
 * @return {QuestionWorkFlowItem | undefined} 如果不需要弹出 ask question, 返回 undefined
 */
export const parseAskQuestion = (
  questions: QuestionWorkFlowItem[],
  currentSelectedItem?: ReturnableOrderItem,
): QuestionWorkFlowItem | undefined => {
  if (currentSelectedItem) {
    const engine = new ParseEngine();
    const condition: Condition = { or: [] };
    questions?.forEach((questionWorkflowItem) =>
      condition.or.push(questionWorkflowItem.conditions),
    );
    const result = engine.check(condition, currentSelectedItem, {
      mapper: {
        [RuleKey.productTag]: 'tags',
        [RuleKey.productType]: 'categories',
      },
    });
    const conditionsLength = result.childResult?.length ?? 0;
    if (result.result && conditionsLength > 0) {
      return questions?.[conditionsLength - 1];
    }
  }
};
