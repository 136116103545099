export enum PayloadType {
  Email = 'email',
  PostalCode = 'postalCode',
}
export interface EmailPayload {
  type: PayloadType.Email;
  orderNumber: string;
  customerEmail: string;
}
export interface PostalCodePayload {
  type: PayloadType.PostalCode;
  orderNumber: string;
  postalCode: string;
  gReCaptchaToken: string;
}
export type GetSessionPayload = EmailPayload | PostalCodePayload;

export interface Session {
  id: string;
  organization: {
    id: string;
  };
  customer_email: string;
  order_number: string;
  shop: {
    id: string;
  };
  token: string;
}
