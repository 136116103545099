export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload;

  if (typeof window !== 'undefined' && window.atob) {
    // 在浏览器中运行
    jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
  } else {
    // 在 Node.js 中运行
    jsonPayload = Buffer.from(base64, 'base64').toString();
  }

  return JSON.parse(jsonPayload);
}
