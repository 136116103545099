import React from 'react';
import { HTMLAttributes } from 'react';
import { StrokeWidthMode1ClassName } from './Mode1.css';

export * from './contract.css';

export const StrokeWidthThemeModeClassNames: Record<
  BorderSizingThemeMode,
  string
> = {
  Mode1: StrokeWidthMode1ClassName,
};

export type BorderSizingThemeMode = 'Mode1';

export interface StrokeWidthThemeProviderProps
  extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  mode?: BorderSizingThemeMode;
}

export const StrokeWidthThemeProvider = ({
  children,
  mode = 'Mode1',
  ...props
}: StrokeWidthThemeProviderProps) => {
  return (
    <div
      {...props}
      className={`${props.className} ${mode ? StrokeWidthThemeModeClassNames[mode] : ''}`}
    >
      {children}
    </div>
  );
};
