import { isValidElement } from 'react';

import {
  Box,
  DimensionValue,
  Flex,
  Icon,
  IconCross,
  LeftChevronThin,
  Popup,
  Pressable,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import {
  bodyMobileClassName,
  flexJustifyContentCenter,
  variantBodyMobileClassName,
} from './MobilePopup.css';

export interface IMobilePopupProps {
  isOpen: boolean;
  onClose?: VoidFunction;
  onBack?: VoidFunction;
  minHeight?: string;
  header?: React.ReactNode | React.ReactNode[];
  children?: React.ReactNode | React.ReactNode[];
  leftSlot?: React.ReactNode;
  rightSlot?: React.ReactNode;
  title?: React.ReactNode;
  footer?: React.ReactNode;
  height?: DimensionValue;
  layout?: 'center' | 'top';
}

const MobilePopup = ({
  isOpen,
  leftSlot,
  rightSlot,
  minHeight = '40%',
  height,
  header,
  footer,
  title,
  layout = 'center',
  children,
  onClose,
  onBack,
}: IMobilePopupProps) => {
  const renderHeader = () => {
    return (
      <Flex direction='row' height={68} width={'100%'} padding={SpacingVars['4']}>
        {header ?? [
          <Box key={'left'}>
            {leftSlot ? (
              leftSlot
            ) : (
              <Flex
                alignItems='center'
                justifyContent='center'
                width={SpacingVars['9']}
                height={SpacingVars['9']}
              >
                <Pressable onPress={onBack} width={24} height={24}>
                  <Icon
                    source={LeftChevronThin}
                    color={
                      onBack
                        ? ColorVars['Grey']['Grey1200']
                        : // TODO 透明或者移除
                          ColorVars['Transparency']['Transparency 1']
                    }
                    size={SpacingVars['6']}
                  />
                </Pressable>
              </Flex>
            )}
          </Box>,
          <Box flex={1} key={'middle'}>
            {isValidElement(title) ? (
              title
            ) : (
              <Flex direction='row' justifyContent='center' alignItems='center' height={'100%'}>
                <Box>
                  <Text textAlign='center' variant='title2'>
                    {title}
                  </Text>
                </Box>
              </Flex>
            )}
          </Box>,
          <Box key={'right'}>
            {rightSlot ? (
              rightSlot
            ) : (
              <Flex
                alignItems='center'
                justifyContent='center'
                width={SpacingVars['9']}
                height={SpacingVars['9']}
              >
                <Pressable onPress={onClose} width={24} height={24}>
                  <Icon
                    source={IconCross}
                    color={
                      onClose
                        ? ColorVars['Grey']['Grey1200']
                        : ColorVars['Transparency']['Transparency 1']
                    }
                    size={SpacingVars['7']}
                  />
                </Pressable>
              </Flex>
            )}
          </Box>,
        ]}
      </Flex>
    );
  };

  const layoutClassName = layout === 'center' ? flexJustifyContentCenter : '';
  return (
    <Popup
      minHeight={minHeight}
      height={height}
      isOpen={isOpen}
      onClose={onClose}
      header={renderHeader()}
    >
      <Box className={bodyMobileClassName}>
        <Box className={`${variantBodyMobileClassName} ${layoutClassName}`}>{children}</Box>
        {footer}
      </Box>
    </Popup>
  );
};

export default MobilePopup;
