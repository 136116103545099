import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Flex, Text } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import FormTextArea from '@/components/Form/FormTextArea';

export type Props = {
  control: Control<any, any>;
};

export const WhatOthers = ({ control }: Props) => {
  const { t } = useTranslation();

  return (
    <Flex direction='column' gap={SpacingVars['2']}>
      <Text variant={'subtitle1'}>{t('page.gift.description.whatOthers')}</Text>

      <FormTextArea control={control} name='notes' />
    </Flex>
  );
};
