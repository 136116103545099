import { fromPromise } from 'xstate';

import { v4hostName } from '../constant/hostname';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import {
  DeleteDraftReturnPayload,
  GetDraftReturnByIdPayload,
  GetDraftReturnPayload,
  GetDraftReturnsResponse,
  IDraftReturn,
  PostReturnPreviewInput,
  PostReturnsInput,
  Preview,
  ReturnsResponse,
} from '../types/review';

const postReturnPreview = async ({ input }: PostReturnPreviewInput) => {
  const { token, payload } = input;

  const url = `${v4hostName()}/return-previews`;
  return request<ResponseBody<Preview>>(url, {
    method: 'POST',
    payload,
    headers: {
      'returns-authorization': token ?? '',
    },
  }).then((res) => res.data || Promise.reject(res));
};

const postReturns = async ({ input }: PostReturnsInput) => {
  const { token, payload } = input;

  const url = `${v4hostName()}/returns`;
  return request<ResponseBody<ReturnsResponse>>(url, {
    method: 'POST',
    payload,
    headers: {
      'returns-authorization': token ?? '',
    },
  }).then((res) => res.data || Promise.reject(res));
};

const getDraftReturns = async ({ input }: GetDraftReturnPayload) => {
  const { token, orderId } = input;
  const url = `${v4hostName()}/draft-returns?exchange_from_order_id=${orderId}&completed=false`;
  return await request<ResponseBody<GetDraftReturnsResponse>>(url, {
    method: 'GET',
    headers: {
      'returns-authorization': token ?? '',
    },
  }).then((res) => res.data || Promise.reject(res));
};

const getDraftReturnById = async ({ input }: GetDraftReturnByIdPayload) => {
  const { token, draftId } = input;
  const url = `${v4hostName()}/draft-returns/${draftId}`;
  return await request<ResponseBody<IDraftReturn>>(url, {
    method: 'GET',
    headers: {
      'returns-authorization': token ?? '',
    },
  }).then((res) => res.data || Promise.reject(res));
};

const deleteDraftReturns = async ({ input }: DeleteDraftReturnPayload) => {
  const { token, draftId } = input;
  const url = `${v4hostName()}/draft-returns/${draftId}`;
  return await request(url, {
    method: 'DELETE',
    headers: {
      'returns-authorization': token ?? '',
    },
  });
};

const postReturnPreviewActor = fromPromise(postReturnPreview);
const postReturnsActor = fromPromise(postReturns);
const getDraftReturnsActor = fromPromise(getDraftReturns);
const deleteDraftReturnsActor = fromPromise(deleteDraftReturns);
const getDraftReturnByIdActor = fromPromise(getDraftReturnById);

export {
  postReturnsActor,
  postReturnPreviewActor,
  getDraftReturnsActor,
  deleteDraftReturnsActor,
  getDraftReturnByIdActor,
};
