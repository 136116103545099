import { Operations, QuestionWorkFlowItem, QuestionWorkflowStatus, RuleKey } from '../../../core';
import { mockTag } from '../order/defaultOrder.ts';

export const defaultQuestion: QuestionWorkFlowItem[] = [
  {
    id: '',
    name: 'Tag == 橙色',
    question: '{Your question｝',
    conditions: {
      or: [
        {
          or: [
            {
              key: RuleKey.productTag,
              operator: Operations.isValue,
              value: mockTag,
            },
          ],
        },
      ],
    },
    priority: 1,
    status: QuestionWorkflowStatus.active,
    answer_for_continue_flow: '{Secondary button}',
    answer_for_terminate_flow: '{Primary button}',
    terminate_flow_message: "Your answer didn't comply with the return policy. 红色1",
  },
];
