import { useMainFlowContext, useSelector } from 'returns-logics/react';

export const useMainFlow = () => {
  const mainFlow = useMainFlowContext();

  const data = useSelector(mainFlow, (state) => {
    return {
      id: mainFlow?.id,
      context: state?.context,
      currentStep: {
        name: state?.value,
        isLoading: state?.hasTag?.('loading'),
      },
      snapshot: state,
      ref: mainFlow,
      on: mainFlow?.on?.bind?.(mainFlow),
      dispatch: mainFlow?.send,
      matches: state?.matches?.bind?.(state),
      children: state?.children,
    };
  });

  return data;
};
