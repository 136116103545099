import { useCallback, useMemo, useState } from 'react';

// import { useTimeout } from '../../../hooks/useTimeout';
import { CarouselHookProps } from '../type';

type ToggleIndexHookProps = Pick<
  CarouselHookProps,
  'currentIndex' | 'total' | 'transitionDuration' | 'stepAction'
>;

export const useToggleIndex = ({ currentIndex, total, stepAction }: ToggleIndexHookProps) => {
  const [index, setIndex] = useState<number>(currentIndex);
  const currentTotal = useMemo(() => Math.max(1, total), [total]);

  const moveTo = useCallback(
    (index: number) => setIndex(Math.min(currentTotal - 1, Math.max(index, 0))),
    [currentTotal],
  );

  const next = useCallback(() => {
    stepAction?.();
    setIndex((index + 1) % currentTotal);
  }, [currentTotal, stepAction, index]);

  const prev = useCallback(() => {
    stepAction?.();
    setIndex((index - 1 + currentTotal) % currentTotal);
  }, [currentTotal, stepAction, index]);

  return { index, next, prev, moveTo };
};
