import { IconSource } from './type';

const Checked: IconSource = () => (
  <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM17.2325 9.18073C17.6085 8.77617 17.5853 8.14343 17.1807 7.76747C16.7762 7.39151 16.1434 7.4147 15.7675 7.81927L10.8443 13.1171L9.14917 11.6704C8.72908 11.3119 8.09788 11.3618 7.73936 11.7819C7.38083 12.202 7.43074 12.8332 7.85083 13.1917L10.2751 15.2606L11.0042 15.8829L11.6568 15.1807L17.2325 9.18073Z'
      fill='currentColor'
    />
  </svg>
);

export default Checked;
