interface CompressedMetaData {
  ext: string;
  data: string;
  alt: string;
}

export interface FileTransferURLResponse {
  upload_url: string;
  download_url: string;
  file_id: string;
  size_limit: string;
}

export function getLocalImageSize(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = (error) => {
      reject(error);
    };
    img.src = URL.createObjectURL(file);
  });
}

export const compressFile = async (file: File): Promise<File> => {
  const { default: Compress } = await import('compress.js');
  const compress = new Compress();
  const data: CompressedMetaData[] = await compress.compress([file], {
    size: 5,
    quality: 1,
    maxWidth: 1920,
    maxHeight: 1920,
    resize: true,
  });
  const fileInfo = data[0]!;
  const compressedBlob = Compress.convertBase64ToFile(fileInfo.data, fileInfo.ext);
  const compressedFile = new File([compressedBlob], fileInfo.alt, {
    type: fileInfo.ext,
  });
  return compressedFile;
};
