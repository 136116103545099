import { ExchangeChargeMode, ExchangeMode, GrayFeatureKey, Resolution } from 'returns-logics';
import { useFlow } from 'returns-logics/react';

import useGetGrayFeatureEnabled from '@/hooks/useGetGrayFeatureEnabled';

export const useGrayHideMode = ({ resolution }: { resolution?: Resolution }) => {
  const flow = useFlow();

  const {
    hide_replace_summary_and_price,
    exchange_mode,
    exchange_rule_item_price_difference_settlement,
  } = flow?.context?.storeConfig?.shopInfo ?? {};

  const hidePriceAndSummaryAlways = useGetGrayFeatureEnabled(
    GrayFeatureKey.HiddenSummaryAndPriceAlways,
  );

  const hideBundleProductsPrice = useGetGrayFeatureEnabled(
    GrayFeatureKey.HiddenBundleProductItemPrice,
  );

  const hiddenSumaryRefundTotal = useGetGrayFeatureEnabled(GrayFeatureKey.HiddenSumaryRefundTotal);

  const hidePriceAndSummaryOnlyReplace =
    resolution === Resolution.ReplaceTheSameItem &&
    hide_replace_summary_and_price &&
    exchange_mode === ExchangeMode.DifferentPrice &&
    exchange_rule_item_price_difference_settlement === ExchangeChargeMode.ChargeByOthers;

  const isCostOfReturnCheckoutGateway =
    !!flow?.context?.storeConfig?.shopInfo?.cost_of_return_charge_enabled;

  return {
    isCostOfReturnCheckoutGateway,
    hiddenSumaryRefundTotal,
    hideBundleProductsPrice: hideBundleProductsPrice,
    hideSummaryAlways: hidePriceAndSummaryAlways,
    hideSummaryOnlyReplace: !!hidePriceAndSummaryOnlyReplace,
    hidePriceAlways: hidePriceAndSummaryAlways,
    hidePriceOnlyReplace: hidePriceAndSummaryOnlyReplace,
  };
};
