import { PropsWithChildren } from 'react';

import { modalFooterRecipe } from './Modal.css';

import { ClassNameType } from '../../types/shared';
import { clsx } from '../../utils/clsx';

const ModalFooter = ({ children, className }: PropsWithChildren<ClassNameType>) => {
  return <div className={clsx(modalFooterRecipe(), className)}>{children}</div>;
};

export default ModalFooter;
