import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListBox, ListBoxItem } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import { SelectableItem } from '@/features/request-returns/components/SelectableItem';
import { genReturnReasonCode } from '@/i18n/dynamic.ts';

export interface ChooseReasonProps {
  reasons: { name: string; id: string }[];
  onSelectReason: (reasonId: string) => void;
  selectedReasonId?: string;
}
const ChooseReason = ({ reasons, selectedReasonId, onSelectReason }: ChooseReasonProps) => {
  const selectedKeys = selectedReasonId ? [selectedReasonId] : [];
  const { t } = useTranslation();
  return (
    <ListBox
      selectionMode='single'
      rowGap={SpacingVars['3']}
      items={reasons}
      selectedKeys={selectedKeys}
    >
      {(item) => {
        return (
          <ListBoxItem>
            <SelectableItem
              key={item.id}
              content={t(
                genReturnReasonCode({
                  reasonId: item.id,
                }),
                {
                  rawValue: item.name,
                  defaultValue: item.name,
                },
              )}
              onClick={() => onSelectReason(item.id)}
            />
          </ListBoxItem>
        );
      }}
    </ListBox>
  );
};

export default ChooseReason;
