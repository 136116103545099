export enum BundleEligibilityType {
  sameAsParent = 'inherit',
  independent = 'independent',
}
export interface ShopEligibilityRulesData {
  accept_discounted: boolean;
  accept_multiple_returns: boolean;
  ineligible_tags: string[];
  ineligible_categories: string[];
  return_window: number;
  return_window_base_on: string;
  return_window_base_on_fulfillment_days: number;
  return_window_base_on_delivery_days: number;
  bundle_line_item_eligibility: BundleEligibilityType;
  id: string;
  shop_id: string;
  created_at: string;
  updated_at: string;
  product_limit: {
    feature_applied: boolean;
    enabled: boolean;
    limit_single_item_per_return: {
      enabled: boolean;
      quantity_exactly_one: boolean;
    };
    limit_items_with_common_routing_rule: {
      enabled: boolean;
    };
  };
}
