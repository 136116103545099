export enum Operations {
  is = 'is',
  isValue = 'is_value',
  isNotValue = 'is_not_value',
}
export enum QuestionWorkflowStatus {
  active = 'active',
  draft = 'DRAFT',
}

export enum RuleKey {
  productType = 'product_type',
  productTag = 'product_tag',
}

export interface QuestionWorkFlowData {
  questions: Array<QuestionWorkFlowItem>;
}

export interface QuestionWorkFlowItem {
  id: string;
  name: string;
  status: QuestionWorkflowStatus; // draft 不会返回给 shopper
  conditions: Condition;
  question: string;
  priority: number;
  answer_for_continue_flow?: string;
  answer_for_terminate_flow?: string;
  terminate_flow_message?: string;
}

export type Condition = AndCondition | OrCondition | BaseCondition;

export interface AndCondition {
  and: Array<Condition>;
}

export interface OrCondition {
  or: Array<Condition>;
}

export interface BaseCondition {
  key: RuleKey; // 'product_tag' | 'product_type'
  operator: Operations; // 'is'
  value: string; // 'xxx'
}
