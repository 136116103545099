import { assignInlineVars } from '@vanilla-extract/dynamic';

import { iconRecipe } from './Icon.css';
import { IconThemeVar } from './IconToken.css';
import { IconProps } from './type';

import { clsx } from '../../utils/clsx';
import { withCls } from '../../utils/withCls';

function Icon({ variant, color, size, source }: IconProps) {
  const SourceComponent = source;
  return (
    <div
      className={clsx(iconRecipe({ variant }), withCls('Icon'))}
      style={{
        ...assignInlineVars({
          [IconThemeVar.Size]: size ?? null,
          [IconThemeVar.Color]: color ?? null,
        }),
      }}
    >
      <SourceComponent />
    </div>
  );
}

Icon.displayName = 'Icon';

export default Icon;
