import { debounce } from 'lodash-es';
import {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Card,
  CardHeader,
  CardSection,
  Flex,
  Icon,
  IconCross,
  NumberField,
  Pressable,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { usePreviewContext } from '@aftership/preview-kit/client';
import { Resolution } from 'returns-logics';

import { ExchangeItemsSectionTitleText } from '@/features/preview/components/WithPreviewSection';
import useCartStyle from '@/features/return-detail/hooks/useCartStyle';
import useHideSummaryWidth from '@/features/return-detail/hooks/useHideSummaryWidth';
import { productTitleContainerStyles } from '@/features/return-detail/styles/product.css';
import { getFullShippingAddress } from '@/features/return-detail/utils/shipment';

import RemoveItemModal from './RemoveItemModal';
import {
  ExchangeEditActionType,
  editExchangeItemReducer,
  formatExchangeItems,
} from './exchangeItemReducer';

import { PreviewExchangeItem } from '../../../../../../packages/returns-logics/core/machine/reviewSubFlow/type';
import ItemCard from '../../../../components/ItemCard';
import { useReturnPreviewFlow } from '../../hooks/useReturnPreviewFlow';

interface ExchangeItemsProps {
  resolution?: Resolution;
  exchangeItems: PreviewExchangeItem[];
}

export interface ExchangeAndEditItemsRef {
  saveExchangeItemsToIntention: VoidFunction;
}

const ExchangeAndEditItems = forwardRef(
  ({ exchangeItems, resolution }: ExchangeItemsProps, ref) => {
    const { isPreview } = usePreviewContext();
    const { normalStyle, mobileNoOutlineClassname, isMobile } = useCartStyle();
    const hideSummaryWidth = useHideSummaryWidth();
    const { t } = useTranslation();
    const [removeVariantId, setRemoveVariantId] = useState<string | null>(null);
    const { shippingAddress, returnPreviewFlowDispatch, isLoading } = useReturnPreviewFlow();
    const isShowShippingAddress = !!exchangeItems?.length && shippingAddress;
    const fullShippingAddress = getFullShippingAddress(shippingAddress);
    const [exchangeReducerItems, dispatch] = useReducer(editExchangeItemReducer, exchangeItems);

    useLayoutEffect(() => {
      dispatch({
        type: ExchangeEditActionType.Init,
        init: {
          items: exchangeItems,
        },
      });
    }, [exchangeItems]);

    const saveExchangeItemsToIntention = useMemo(
      () =>
        debounce(() => {
          returnPreviewFlowDispatch?.({
            type: 'UPDATE_INTENTION_DATA',
            data: {
              exchangeItems: formatExchangeItems(exchangeReducerItems),
            },
          });
        }, 1000),
      [returnPreviewFlowDispatch, exchangeReducerItems],
    );

    const updateExchangeItems = useMemo(
      () =>
        debounce((exchangeUpdatedItems: PreviewExchangeItem[]) => {
          returnPreviewFlowDispatch?.({
            type: 'UPDATE_EFA_ITEMS',
            data: {
              exchangeItems: exchangeUpdatedItems,
            },
          });
        }, 1000),
      [returnPreviewFlowDispatch],
    );

    const updateItemQuantity = (input: number, item: PreviewExchangeItem) => {
      dispatch({
        type: ExchangeEditActionType.UpdateItem,
        data: {
          variantId: item.variantId,
          value: input,
        },
      });

      const exchangeUpdatedItems = exchangeItems?.map((exchangeItem) => {
        if (exchangeItem.variantId === item.variantId) {
          return {
            ...exchangeItem,
            quantity: input,
          };
        }
        return exchangeItem;
      });
      updateExchangeItems(exchangeUpdatedItems);
    };

    const removeItem = (variantId: string) => {
      dispatch({
        type: ExchangeEditActionType.RemoveItem,
        data: {
          variantId,
        },
      });
      const exchangeUpdatedItems = exchangeItems?.filter(
        (item) => item.variantId !== removeVariantId,
      );
      updateExchangeItems(exchangeUpdatedItems);
      setRemoveVariantId(null);
    };
    useImperativeHandle(
      ref,
      () => ({
        saveExchangeItemsToIntention,
      }),
      [saveExchangeItemsToIntention],
    );

    const rightContent = (item: PreviewExchangeItem) => {
      if (
        !isPreview &&
        (item.resolution === Resolution.ExchangeForAnything ||
          resolution === Resolution.ExchangeForAnything)
      ) {
        if (isMobile) {
          return (
            <Flex direction='column' justifyContent='space-between' alignSelf='stretch'>
              <Box>
                <Flex
                  direction='row'
                  justifyContent='right'
                  width={'100%'}
                  paddingEnd={SpacingVars['1']}
                >
                  <Pressable
                    onPress={() => {
                      setRemoveVariantId(item.variantId);
                    }}
                    padding={SpacingVars['1']}
                  >
                    <Icon source={IconCross} size={SpacingVars['4']} />
                  </Pressable>
                </Flex>
              </Box>
              <Box>
                <NumberField
                  step={1}
                  minValue={1}
                  maxValue={item.maxQuantity}
                  defaultValue={1}
                  isWheelDisabled
                  isDisabled={isLoading}
                  value={item.quantity}
                  onChange={(value) => {
                    updateItemQuantity(value, item);
                  }}
                />
              </Box>
            </Flex>
          );
        }
        return (
          <Flex direction='column' justifyContent='space-between' alignSelf='stretch'>
            <Box>
              <NumberField
                step={1}
                minValue={1}
                maxValue={item.maxQuantity}
                defaultValue={1}
                isWheelDisabled
                isDisabled={isLoading}
                value={item.quantity}
                onChange={(value) => {
                  updateItemQuantity(value, item);
                }}
              />
            </Box>
            <Box>
              <Flex direction='row' justifyContent='end'>
                <Button
                  variant='plain'
                  isDisabled={isLoading}
                  onPress={() => {
                    setRemoveVariantId(item.variantId);
                  }}
                >
                  {t('page.request.remove')}
                </Button>
              </Flex>
            </Box>
          </Flex>
        );
      }
      return !isMobile && <Text variant='body2'>x {item.quantity}</Text>;
    };

    return (
      <>
        <Card
          {...normalStyle}
          width={hideSummaryWidth || normalStyle.width}
          className={mobileNoOutlineClassname}
        >
          <CardHeader>
            <ExchangeItemsSectionTitleText variant='title3'>
              {t('dynamic.requestReview.exchangeItems')}
            </ExchangeItemsSectionTitleText>
          </CardHeader>

          <CardSection>
            <Flex direction='column' gap={isMobile ? 32 : 0}>
              {exchangeReducerItems?.map((item) => (
                <ItemCard
                  key={item.variantId}
                  hideDivider={isMobile}
                  borderWidth='0'
                  productTitleContainerStyles={isMobile ? productTitleContainerStyles : undefined}
                  productInfo={{
                    productTitle: item?.productTitle || '',
                    price: item?.price,
                    originPrice: item.originPrice,
                    variantTitle: item?.variantTitle ?? '',
                    productCoverUrl: item.productCoverUrl,
                    productTags: item?.productTags ?? [],
                    quantity:
                      isMobile &&
                      !(
                        item.resolution === Resolution.ExchangeForAnything ||
                        resolution === Resolution.ExchangeForAnything
                      )
                        ? item.quantity
                        : void 0,
                  }}
                  rightContent={rightContent(item)}
                />
              ))}
            </Flex>
          </CardSection>

          {isShowShippingAddress && (
            <Flex gap={SpacingVars['2']} direction='column'>
              <Text variant='subtitle2'>{t('review.description.shippingAddress')}</Text>
              <Text variant='body2'>{fullShippingAddress}</Text>
            </Flex>
          )}
        </Card>
        <RemoveItemModal
          isOpen={!!removeVariantId}
          onClose={() => {
            setRemoveVariantId(null);
          }}
          onRemove={() => {
            if (removeVariantId && !isLoading) {
              removeItem(removeVariantId);
            }
          }}
        />
      </>
    );
  },
);

ExchangeAndEditItems.displayName = 'ExchangeAndEditItems';

export default ExchangeAndEditItems;
