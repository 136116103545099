import { IconSource } from './type';

const X: IconSource = () => (
  <svg width={36} height={36} viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M22.94 10h2.715l-5.93 6.777L26.7 26h-5.462l-4.278-5.593L12.065 26H9.35l6.342-7.25L9 10h5.6l3.868 5.113zm-.952 14.375h1.504L13.784 11.54H12.17z'
      fill='currentColor'
      fillOpacity={0.95}
    />
  </svg>
);

export default X;
