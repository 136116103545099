import * as yup from 'yup';

import { OrderLookupType, SubmitPayload } from './types';

import { isEmail } from '../../utils/validation';

export const orderLookupSchema = yup.object<SubmitPayload>({
  orderLookupType: yup.mixed<OrderLookupType>().oneOf(Object.values(OrderLookupType)),
  isMerchantMode: yup.boolean().optional(),
  shouldIgnoreClickwrap: yup.boolean().optional(),
  orderNumber: yup.string().required('Order number is required').trim(),

  // --- Conditional fields ---
  email: yup.string().when('orderLookupType', {
    is: OrderLookupType.EMAIL,
    then: (schema) =>
      schema.required('Email is required').test({
        test: isEmail,
        message: 'Invalid email',
        name: 'email',
      }),
    otherwise: (schema) => schema.notRequired(),
  }),
  // -- end of conditional fields --

  // --- Conditional fields ---
  zipCode: yup.string().when('orderLookupType', {
    is: OrderLookupType.ZIPCODE,
    then: (schema) => schema.required('Zip code is required').trim(),
    otherwise: (schema) => schema.notRequired(),
  }),
  googleToken: yup.string().when('orderLookupType', {
    is: OrderLookupType.ZIPCODE,
    then: (schema) => schema.required('Google token is required'),
    otherwise: (schema) => schema.notRequired(),
  }),
  // -- end of conditional fields --

  merchantModeToken: yup.string().when('isMerchantMode', {
    is: true,
    then: (schema) => schema.required('Merchant mode token is required'),
    otherwise: (schema) => schema.notRequired(),
  }),

  // clickWrapCheck: yup.boolean().when('shouldIgnoreClickwrap', {
  //   is: false,
  //   then: () => yup.boolean().isTrue('Clickwrap is required'),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
});
