const decodeBase64 = (base64str: string) => {
  const buffer = Buffer.from(base64str, 'base64');
  return buffer.toString('utf-8');
};

const decodeBase64JsonString = <T>(qs: string): T => {
  return JSON.parse(decodeURIComponent(decodeBase64(decodeURIComponent(qs))));
};

export default decodeBase64JsonString;
