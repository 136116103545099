'use client';

import { useFocusRing, useHover, usePress } from 'react-aria';

import { pressableRecipe } from './Pressable.css';
import { PressableProps } from './type';

import { clsx } from '../../utils/clsx';
import { useStyleProps, viewStyleProps } from '../../utils/styleProps';

const Pressable = (props: PressableProps) => {
  const { children, className, ...rest } = props;

  const { styleProps } = useStyleProps(rest, viewStyleProps);
  const { pressProps, isPressed } = usePress(props);
  const { focusProps, isFocused, isFocusVisible } = useFocusRing({ autoFocus: false });

  const { hoverProps, isHovered } = useHover(props);

  return (
    <div
      {...styleProps}
      {...pressProps}
      {...focusProps}
      {...hoverProps}
      data-hovered={isHovered || undefined}
      data-focused={isFocused || undefined}
      data-focus-visible={isFocusVisible || undefined}
      role='button'
      tabIndex={0}
      className={clsx(pressableRecipe(), className)}
    >
      {typeof children === 'function' ? children({ isPress: isPressed }) : children}
    </div>
  );
};

export default Pressable;
