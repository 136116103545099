import { useCallback, useEffect, useRef } from 'react';

// todo: 需要处理多个滚动同时进行的情况
export const useScrollAttribute = () => {
  const timer = useRef<number>();
  const timestamp = useRef<number>(0);

  const toggleScrollbar = useCallback((e: Event) => {
    const target = e.target as HTMLElement;
    target.toggleAttribute?.('scroll', true);
    timestamp.current = Date.now();
    if (!timer.current) {
      timer.current = window.setInterval(() => {
        const currentTime = Date.now();
        if (currentTime - timestamp.current > 500) {
          target.toggleAttribute?.('scroll', false);
          timer.current && clearInterval(timer.current);
          timer.current = undefined;
        }
      }, 500);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener?.('scroll', toggleScrollbar, true);
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener?.('scroll', toggleScrollbar);
      }
    };
  }, [toggleScrollbar]);
};
