import { IconSource } from './type';

const TwoArrow: IconSource = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='ic_two-arrow'>
        <path
          id='Polygon 2'
          d='M11.3687 4.98406C11.6638 4.52401 12.3362 4.52401 12.6313 4.98406L15.5892 9.59504C15.9094 10.0942 15.551 10.75 14.9579 10.75H9.04206C8.44901 10.75 8.09056 10.0942 8.41078 9.59504L11.3687 4.98406Z'
          fill='currentColor'
        />
        <path
          id='Polygon 2_2'
          d='M12.6313 19.0159C12.3362 19.476 11.6638 19.476 11.3687 19.0159L8.41078 14.405C8.09057 13.9058 8.449 13.25 9.04205 13.25L14.9579 13.25C15.551 13.25 15.9094 13.9058 15.5892 14.405L12.6313 19.0159Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export default TwoArrow;
