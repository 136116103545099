import { t } from 'i18next';
import { useFormContext } from 'react-hook-form';

import { Box, Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { CountryCodeMap, PickupAddressSource, PickupInfomationData } from 'returns-logics';

import { Divider } from '@/components/Divider';
import FormInput from '@/components/Form/FormInput';
import FormRadio from '@/components/Form/FormRadio';
import FormSelect from '@/components/Form/FormSelect';

import { PickupContactContent } from './PickupContactSection';
import { SectionWrapper } from './SectionWrapper';
import { anotherAddressWrapper } from './index.css';

import { usePickupInfomation } from '../../hooks/usePickupInfomation';

const getRadioOptions = () => [
  {
    value: PickupAddressSource.order,
    label: t('pickup.address.source.order'),
  },
  {
    value: PickupAddressSource.manual,
    label: t('pickup.address.source.manual'),
  },
];

export const PickupShippingAddress = ({ countries }: { countries?: CountryCodeMap }) => {
  const { control } = useFormContext<PickupInfomationData>();

  const { isShowAddressSection, isShowInnerContactSection } = usePickupInfomation();

  return (
    <SectionWrapper title={t('pickup.location.title')}>
      <Flex direction='column' gap={SpacingVars['2']}>
        <FormRadio control={control} name='pickupAddressSource' radioItems={getRadioOptions()} />
        {isShowAddressSection && (
          <Flex className={anotherAddressWrapper} direction='column'>
            {isShowInnerContactSection && (
              <Box>
                <SectionWrapper
                  title={
                    <Text color={ColorVars['Grey']['Grey 1100']} variant='caption1'>
                      Contact
                    </Text>
                  }
                >
                  <PickupContactContent />
                </SectionWrapper>
                <Divider spacing={16} />
              </Box>
            )}
            <AddressForm countries={countries} />
          </Flex>
        )}
      </Flex>
    </SectionWrapper>
  );
};

const AddressForm = ({ countries }: { countries?: CountryCodeMap }) => {
  const { control, watch } = useFormContext<PickupInfomationData>();

  const options =
    Object.values(countries || {}).map((country) => ({
      label: country.name,
      value: country.code,
    })) ?? [];

  const countryCode = watch('pickupAddress.country');
  const hasState = countryCode && !!countries?.[countryCode]?.sublist?.length;

  return (
    <Flex direction='column' gap={SpacingVars['4']}>
      <FormInput
        control={control}
        placeholder={t('address.placeholder')}
        name='pickupAddress.addressLine1'
        fullWidth
      />
      <FormSelect
        control={control}
        options={options}
        placeholder={t('page.gift.placeholder.country')}
        name='pickupAddress.country'
      />
      <Flex gap={SpacingVars['2']}>
        <FormInput
          control={control}
          placeholder={t('page.gift.placeholder.city')}
          name='pickupAddress.city'
          fullWidth
        />
        {hasState && (
          <FormInput
            control={control}
            placeholder={t('page.gift.placeholder.state')}
            name='pickupAddress.state'
            fullWidth
          />
        )}
      </Flex>
      <FormInput
        control={control}
        placeholder={t('page.gift.placeholder.postalCode')}
        name='pickupAddress.postalCode'
        fullWidth
      />
    </Flex>
  );
};
