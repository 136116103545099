import Image from 'next/image';

import { Box } from '@aftership/design-system';

import WarningImage from '@/assets/warning_outlined.svg';

const ErrorIcon = () => {
  return (
    <Box>
      <Image src={WarningImage.src} width={96} height={96} alt='' />
    </Box>
  );
};

export default ErrorIcon;
