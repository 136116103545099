'use client';

import { Button, Key, ListBox, ListBoxItem } from 'react-aria-components';

import {
  dropdownButtonRecipe,
  dropdownListBoxItemRecipe,
  dropdownListBoxRecipe,
} from './Dropdown.css';
import { DropdownBaseProps, DropdownItemProps } from './type';

import { clsx } from '../../utils/clsx';
import { AiraOverlayTriggerState, OverlayTrigger } from '../Overlay';
import { Popover } from '../Popover';
import { PopoverTriggerType } from '../Popover/type';

const DropdownBase = <T extends React.ElementType = PopoverTriggerType>(
  props: DropdownBaseProps<T>,
) => {
  const {
    children,
    pressedClose = true,
    items,
    selectionMode,
    renderChildren,
    onAction,
    arrow,
    trigger,
    placement,
    isOpen,
    offset,
    triggerProps,
    className,
    ...rest
  } = props;

  const popoverProps = {
    arrow,
    trigger,
    placement,
    isOpen,
    offset,
    triggerProps,
    className,
  };

  const handleAction = (key: Key, state?: AiraOverlayTriggerState) => {
    pressedClose && selectionMode !== 'multiple' && state?.close?.();
    onAction?.(key, state);
  };

  const contentRender = (
    <OverlayTrigger
      render={(state) => (
        <ListBox
          onAction={(key) => handleAction(key, state)}
          {...rest}
          selectionMode={selectionMode}
          className={clsx(dropdownListBoxRecipe())}
          items={items}
        >
          {(item: DropdownItemProps) => (
            <ListBoxItem
              className={clsx(dropdownListBoxItemRecipe())}
              textValue={item.name}
              id={item.id}
            >
              {item.render || item.name}
            </ListBoxItem>
          )}
        </ListBox>
      )}
    />
  );
  return (
    <Popover {...popoverProps} content={contentRender}>
      {renderChildren || <Button className={dropdownButtonRecipe()}>{children}</Button>}
    </Popover>
  );
};

export default DropdownBase;
