import React from 'react';
import { HTMLAttributes } from 'react';
import { ItemSizingMode1ClassName } from './Mode1.css';

export * from './contract.css';

export const ItemSizingThemeModeClassNames: Record<
  ItemSizingThemeMode,
  string
> = {
  Mode1: ItemSizingMode1ClassName,
};

export type ItemSizingThemeMode = 'Mode1';

export interface ItemSizingThemeProviderProps
  extends HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  mode?: ItemSizingThemeMode;
}

export const ItemSizingThemeProvider = ({
  children,
  mode = 'Mode1',
  ...props
}: ItemSizingThemeProviderProps) => {
  return (
    <div
      {...props}
      className={`${props.className} ${mode ? ItemSizingThemeModeClassNames[mode] : ''}`}
    >
      {children}
    </div>
  );
};
