import pino, { LoggerOptions } from 'pino';

// https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#logseverity
// https://getpino.io/#/docs/help?id=mapping-pino-log-levels-to-google-cloud-logging-stackdriver-severity-levels
const pinoLevelToSeverityLookup: Record<string, string> = {
  trace: 'DEBUG',
  debug: 'DEBUG',
  info: 'INFO',
  warn: 'WARNING',
  error: 'ERROR',
  fatal: 'CRITICAL',
};

const defaultOptions: LoggerOptions = {
  base: {
    collector: 'CODED',
  },
  messageKey: 'message',
  errorKey: 'error',
  formatters: {
    level: (label) => ({
      severity: pinoLevelToSeverityLookup[label] || pinoLevelToSeverityLookup['info'],
    }),
  },
  browser: {
    disabled: true,
  },
};

export const logger = pino(defaultOptions);
