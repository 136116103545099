import { fromPromise } from 'xstate';

import { SelectionGroupingItem } from '../../core';
import { GetItemSelectionGroupingInput } from '../../core/promise';
import { defaultItemSelectionGroup } from '../requestData/itemSelectionGroup/defaultItemSelectionGroup';

export const getItemSelectionGrouping = fromPromise<
  SelectionGroupingItem[],
  GetItemSelectionGroupingInput['input']
>(async () => {
  return defaultItemSelectionGroup;
});
