import { useFlow } from 'returns-logics/react';

import { useFieldAWithMemo } from '../../../hooks/useFieldAWithMemo';

export const useMemoGiftReturn = () => {
  const { children } = useFlow();

  const giftReturnSubFlow = children?.giftReturnSubFlow;

  return useFieldAWithMemo(giftReturnSubFlow);
};
