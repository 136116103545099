import { useToggleIndex } from '.';
import { useEffect } from 'react';

import { useTimeout } from '../../../hooks/useTimeout';
import { CarouselHookProps } from '../type';

export const useCarousel = ({
  currentIndex,
  total,
  stepAction,
  autoPlay = true,
  playDirection = 'right',
  playInterval = 3000,
}: CarouselHookProps) => {
  const interval = Math.max(500, playInterval);
  const {
    index,
    next: nextSilde,
    prev: prevSilde,
    moveTo: moveSildeTo,
  } = useToggleIndex({
    currentIndex,
    total,
    stepAction,
  });

  const { isWaiting, isStop, start, stop } = useTimeout({
    delay: interval,
    callback: () => (playDirection === 'right' ? nextSilde() : prevSilde()),
  });

  const formatAction = (action: () => void) => () => {
    stop();
    action();
  };

  useEffect(() => {
    !isWaiting && !isStop && autoPlay && start();
  }, [index, isStop, isWaiting, start, autoPlay]);

  return {
    index,
    start,
    stop,
    nextSilde: formatAction(nextSilde),
    prevSilde: formatAction(prevSilde),
    moveSildeTo,
  };
};
