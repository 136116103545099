import { assign, setup } from 'xstate';

import { Resolution, ReturnMethodSlug, maxHappyReturnSelectedItems } from '../../constant';
import { getMatchingMethod } from '../../promise';
import { ISelectedItem, SkuItem } from '../../types';
import { parseMatchingMethodPayload } from '../../utils/returnMethod.ts';
import { ISelectMethod } from '../returnMethodSubFlow/types.ts';

export type Input = {
  token: string;
  bypassReturnMethod: boolean;
  selectedItems: ISelectedItem[];
  selectedResolution?: Resolution;
  exchangeItems: SkuItem[];
  isGiftReturnMode: boolean;
};
export type Context = Input & { selectedMethod?: ISelectMethod };
export type Output = { selectedMethod?: ISelectMethod };

export const decisionMethodOrReviewActor = setup({
  types: {
    input: {} as Input,
    context: {} as Context,
    output: {} as Output,
  },
  actors: { getMatchingMethod },
}).createMachine({
  context: ({ input }) => ({ ...input }),
  initial: 'checkConfig',
  states: {
    checkConfig: {
      always: [
        {
          guard: ({ context }) => context.bypassReturnMethod,
          target: 'fetchReturnMethod',
        },
        {
          target: 'done',
        },
      ],
    },
    fetchReturnMethod: {
      tags: 'loading',
      invoke: {
        src: 'getMatchingMethod',
        input: ({ context }) => ({
          token: context.token,
          ...parseMatchingMethodPayload({
            resolution: context.selectedResolution,
            exchangeItems: context.exchangeItems,
            selectedItems: context.selectedItems,
            isGiftReturnMode: context.isGiftReturnMode,
          }),
        }),
        onDone: [
          {
            guard: ({ context, event }) => {
              const isSingleReturnMethod = event.output.length === 1;
              // 单个 ReturnMethod 不是 HR,或者是 HR 但是选择的 Quantity 总和不超过 9
              const isHRReturnMethodValid =
                event.output[0].slug !== ReturnMethodSlug.HappyReturns ||
                context.selectedItems.reduce((acc, cur) => {
                  return acc + cur.quantity;
                }, 0) > maxHappyReturnSelectedItems;

              const isCarrierPickupValid = event.output[0].slug !== ReturnMethodSlug.CarrierPickup;

              return isSingleReturnMethod && isHRReturnMethodValid && isCarrierPickupValid;
            },
            target: 'done',
            actions: assign({
              selectedMethod: ({ event }) => ({
                id: event.output[0].id,
                name: event.output[0].name,
                description: event.output[0].description,
              }),
            }),
          },
          {
            target: 'done',
          },
        ],

        onError: {
          actions: ({ event }) => {
            throw event.error;
          },
        },
      },
    },
    done: {
      type: 'final',
    },
  },
  output: ({ context }) => ({
    selectedMethod: context.selectedMethod,
  }),
});
