import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { useCallback, useEffect, useState } from 'react';

import { Flex, Spinner } from '@aftership/design-system';

import { useStripeContext } from './StripeProvider';

const unExpectErrorMsg = 'An unexpected error occurred.';

const paymentElementOptions: StripePaymentElementOptions = {
  layout: 'tabs',
};

const CheckoutForm = () => {
  const stripe = useStripe();

  const elements = useElements();

  const [loaderStart, setLoaderStart] = useState(false);

  const { setIsStripePaymentLoading, setStripeSubmit, setStripeReady } = useStripeContext();

  const handleSubmit = useCallback(() => {
    if (!stripe || !elements) {
      return null;
    }

    return new Promise((resolve, reject) => {
      stripe
        .confirmPayment({
          elements,
          redirect: 'if_required',
        })
        .then((result) => {
          setIsStripePaymentLoading?.(false);

          const { error, paymentIntent } = result;

          if (error) {
            if (error.type === 'validation_error') {
              reject(error.type);
            } else {
              const errorMsg = error?.message ?? unExpectErrorMsg;
              reject(errorMsg);
            }
          } else {
            resolve(paymentIntent?.status);
          }
        })
        .catch((error) => {
          reject(error.toString());
        });
      setIsStripePaymentLoading?.(true);
    });
  }, [stripe, elements, setIsStripePaymentLoading]);

  useEffect(() => {
    if (setStripeSubmit)
      setStripeSubmit(() => handleSubmit as unknown as (() => Promise<string>) | null);
  }, [handleSubmit, setStripeSubmit]);

  return (
    <>
      {!loaderStart && (
        <Flex direction='row' justifyContent='center'>
          <Spinner size='60px' />
        </Flex>
      )}
      <PaymentElement
        options={paymentElementOptions}
        onReady={() => setStripeReady?.(true)}
        onLoaderStart={() => setLoaderStart(true)}
      />
    </>
  );
};

export default CheckoutForm;
