import { IconSource } from './type';

const Loading: IconSource = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 3.125C8.48325 3.125 8.875 3.51675 8.875 4V9C8.875 9.48325 8.48325 9.875 8 9.875C7.51675 9.875 7.125 9.48325 7.125 9L7.125 4C7.125 3.51675 7.51675 3.125 8 3.125ZM7 12C7 12.2652 7.10535 12.5196 7.29289 12.7071C7.48043 12.8946 7.73478 13 8 13C8.26521 13 8.51957 12.8946 8.7071 12.7071C8.89464 12.5196 9 12.2652 9 12C9 11.7348 8.89464 11.4804 8.7071 11.2929C8.51957 11.1054 8.26521 11 8 11C7.73478 11 7.48043 11.1054 7.29289 11.2929C7.10535 11.4804 7 11.7348 7 12Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default Loading;
