import getTrackerInstance from '.';
import { useEffect } from 'react';

import { PageId } from './consts';

export const useReportPageViewEvent = (pageId: PageId) => {
  useEffect(() => {
    // const search = new URLSearchParams(location.search);

    // const isRedirectFromStore = search.get(RC_SHOP_NOW);

    // Delay to debounce to prevent push event repeatedly
    // when Page component mount and unmount quickly
    const timeoutId = setTimeout(() => {
      getTrackerInstance().reportPageEnterEvent({
        pageId: pageId,
        // previousPageId: isRedirectFromStore ? PageId.EFAOnStore : null,
      });
    }, 0);

    return () => {
      clearTimeout(timeoutId);
      getTrackerInstance().reportPageLeaveEvent({ pageId });
    };
  }, [pageId]);
};
