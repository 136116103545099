import { IconSource } from './type';

const Package: IconSource = () => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 .667A3.333 3.333 0 0 0 4.667 4H3.314a.667.667 0 0 0-.666.647l-.295 10a.667.667 0 0 0 .667.687h9.96c.376 0 .678-.31.666-.687l-.294-10A.667.667 0 0 0 12.686 4h-1.353A3.333 3.333 0 0 0 8 .667M10 4a2 2 0 1 0-4 0zm1.132 3.523c.264.257.269.68.012.943l-3.678 3.769-1.886-1.533a.667.667 0 0 1 .84-1.035l.942.765 2.828-2.897a.667.667 0 0 1 .942-.012'
        fill='#000'
      />
    </svg>
  );
};

export default Package;
