import { IconSource } from './type';

const Shop: IconSource = () => {
  return (
    <svg width={16} height={16} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.667 4.667A.667.667 0 0 1 1 4V2.667C1 2.298 1.298 2 1.667 2h12.666c.368 0 .667.298.667.667V4a.667.667 0 0 1-.667.667z'
        fill='#000'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.667 6H2.333v7.333c0 .737.597 1.334 1.334 1.334h8.666c.737 0 1.334-.597 1.334-1.334z'
        fill='#000'
      />
      <rect x={5} y={8} width={6} height={1.5} rx={0.75} fill='#fff' />
    </svg>
  );
};

export default Shop;
