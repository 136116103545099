import { HTMLAttributes, ReactNode } from 'react';
import { ColorLightClassName } from './Light.css';
import { ColorDarkClassName } from './Dark.css';

export * from './contract.css';

export const ColorThemeModeClassNames: Record<ColorThemeMode, string> = {
  Light: ColorLightClassName,
  Dark: ColorDarkClassName,
};

export type ColorThemeMode = 'Light' | 'Dark';

export interface ColorThemeProviderProps
  extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  mode?: ColorThemeMode;
}

export const ColorThemeProvider = ({
  children,
  mode = 'Light',
  ...props
}: ColorThemeProviderProps) => {
  return (
    <div
      {...props}
      className={`${props.className} ${mode ? ColorThemeModeClassNames[mode] : ''}`}
    >
      {children}
    </div>
  );
};
