import { t } from 'i18next';
import { get } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { PickupInfomationData } from 'returns-logics';

import { DatePicker } from '@/components/DatePicker/DatePicker';
import useDevice from '@/hooks/useDevice';

import { SectionWrapper } from './SectionWrapper';

import { PICK_UP_DATE_LIMIT, PICK_UP_MAX_DATE, PICK_UP_MIN_DATE } from '../../utils/constants';

export const PickupDateSection = () => {
  const { control } = useFormContext<PickupInfomationData>();
  const isMobile = useDevice().mobile;

  return (
    <SectionWrapper title={t('pickup.info.title')}>
      <Flex direction='column' gap={SpacingVars['2']}>
        <Controller
          control={control}
          name='pickupAppointmentDate'
          render={({ field: { value, onChange }, formState }) => {
            const msg = get(formState?.errors, 'pickupAppointmentDate')?.message as string;

            return (
              <Flex direction='column' gap={SpacingVars['2']}>
                <DatePicker
                  minDate={PICK_UP_MIN_DATE}
                  maxDate={PICK_UP_MAX_DATE}
                  limit={isMobile ? PICK_UP_DATE_LIMIT : 7}
                  value={value}
                  onSelect={onChange}
                  showPagination={!isMobile}
                  scrollable={isMobile}
                  minTooltip={t('pick_up.date.limitation')}
                  maxTooltip={t('pick_up.date.limitation')}
                />
                {msg && (
                  <Text variant='caption1' color={ColorVars['Red']['Red 1000']}>
                    {msg}
                  </Text>
                )}
              </Flex>
            );
          }}
        />
        <Text variant='body2' color={ColorVars['Grey']['Grey 1000']}>
          {t('pick_up.date.instructions')}
        </Text>
      </Flex>
    </SectionWrapper>
  );
};
