import { fromPromise } from 'xstate';

import { ProductEnhanceQuantity, ProductItem } from '../../core';
import { GetAllProductsInput, GetRecommendProductsInput } from '../../core/promise/products';
import { mockItemId } from '../requestData/order/defaultOrder.ts';

const defaultProduct: ProductItem = {
  external_id: '',
  id: '',
  title: '',
  image_urls: [],
  product_url: '',
  filtered_options: [],
  product_links: [],
  variants: [],
  lowest_price_variant: {
    id: '',
    external_id: '',
    title: '',
    image_urls: [],
    allow_backorder: true,
    availability: {
      available: true,
      all_of: {
        available_from_platform: true,
        minimum_inventory_level_passed: true,
        online_inventory_level_passed: true,
        only_active_products_passed: true,
        replace_price_comparison_passed: true,
      },
    },
    options: [],
    price_set: {
      presentment_money: {
        amount: '20.00',
        currency: 'USD',
      },
    },
    available: true,
    online_available_quantity: 1,
  },
};
const defaultProducts: ProductItem[] = [
  {
    ...defaultProduct,
    lowest_price_variant: {
      ...defaultProduct.lowest_price_variant,
      price_set: {
        presentment_money: {
          amount: '20.00',
          currency: 'USD',
        },
      },
    },
  },
  {
    ...defaultProduct,
    lowest_price_variant: {
      ...defaultProduct.lowest_price_variant,
      price_set: {
        presentment_money: {
          amount: '50.00',
          currency: 'USD',
        },
      },
    },
  },
  {
    ...defaultProduct,
    lowest_price_variant: {
      ...defaultProduct.lowest_price_variant,
      price_set: {
        presentment_money: {
          amount: '200.00',
          currency: 'USD',
        },
      },
    },
  },
  {
    ...defaultProduct,
    lowest_price_variant: {
      ...defaultProduct.lowest_price_variant,
      price_set: {
        presentment_money: {
          amount: '300.00',
          currency: 'USD',
        },
      },
    },
  },
  {
    ...defaultProduct,
    lowest_price_variant: {
      ...defaultProduct.lowest_price_variant,
      price_set: {
        presentment_money: {
          amount: '400.00',
          currency: 'USD',
        },
      },
    },
  },
  {
    ...defaultProduct,
    lowest_price_variant: {
      ...defaultProduct.lowest_price_variant,
      price_set: {
        presentment_money: {
          amount: '500.00',
          currency: 'USD',
        },
      },
    },
  },
];
export const getRecommendProducts = fromPromise<
  ProductItem[] | undefined,
  GetRecommendProductsInput['input']
>(async () => {
  return defaultProducts;
});

export const getAllProducts = fromPromise<
  Record<string, ProductEnhanceQuantity>,
  GetAllProductsInput['input']
>(async () => {
  return {
    [mockItemId]: { ...defaultProduct, selectedQuantity: 1 },
  };
});
