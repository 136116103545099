import { ReturnMethodSlug } from '../../constant';
import { Resolution } from '../../constant/refund';
import { SkuItem } from '../../types/exchangeInApp';
import { ISelectedItem } from '../../types/machine/ItemSelectionSubFlow';
import { DropoffLocation, DropoffLocations, ReturnMethod } from '../../types/returnMethod';

export interface ReturnMethodContext extends ReturnMethodInput {
  returnMethods: (ReturnMethod & { disabled?: boolean })[];
  dropoffLocations: DropoffLocations;
  nearbyLocations?: DropoffLocation;
}

export enum PickupAddressSource {
  order = 'order',
  manual = 'manual',
}

export type PickupInfomationData = {
  pickupContact?: {
    firstName?: string;
    lastName: string;
    phoneNumber: string;
  } | null;
  pickupAddress?: {
    addressLine1: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
  };
  pickupAppointmentDate: string;
  pickupInstructions?: string;
  pickupAddressSource: PickupAddressSource;
};

export interface ReturnMethodInput {
  selectedMethodId: string;
  selectedMethodSlug?: ReturnMethodSlug;
  pickupData?: PickupInfomationData;
  token: string;
  orderId: string;
  selectedItems: ISelectedItem[];
  exchangeItems: SkuItem[];
  intentionId?: string;
  resolution?: Resolution;
  isGiftReturn: boolean;
}

export interface ISelectMethod {
  id: string;
  name: string;
  description: string;
  slug?: ReturnMethodSlug;
  pickupData?: PickupInfomationData;
}

export interface ReturnMethodOutput {
  selectedMethod: ISelectMethod;
}

export type ReturnMethodEvent =
  | {
      type: 'SELECT_RETURN_METHOD';
      data: { methodId: string; methodSlug: ReturnMethodSlug };
    }
  | {
      type: 'SUBMIT_PICKUP_DATA';
      data: {
        pickupData: PickupInfomationData;
      };
    }
  | { type: 'LOAD_NEARBY_LOCATIONS'; data: { latitude: number; longitude: number } }
  | { type: 'SELECT_METHOD_DONE' };
