'use client';

import { SearchField as AriaSearchField, Button, Input } from 'react-aria-components';

import {
  textFieldClearAllIconRecipe,
  textFieldClearAllIconWrapperRecipe,
  textFieldInputRecipe,
  textFieldRecipe,
} from './TextField.css';
import { TextFieldProps } from './type';

import { clsx } from '../../utils/clsx';
import { isTrueAndPass } from '../../utils/isTrueAndPass';
import { Icon, Subtract } from '../Icon';

const TextField = ({
  className,
  placeholder,
  fullWidth,
  clearable = false,
  type = 'text',
  ...rest
}: TextFieldProps) => {
  return (
    <AriaSearchField
      className={clsx(
        textFieldRecipe({
          fullWidth,
        }),
        className,
      )}
      {...rest}
    >
      <Input type={type} className={textFieldInputRecipe()} placeholder={placeholder || ' '} />
      {isTrueAndPass(
        clearable === true,
        <div className={textFieldClearAllIconWrapperRecipe()}>
          <Button className={textFieldClearAllIconRecipe()}>
            <Icon color='currentColor' source={Subtract}></Icon>
          </Button>
        </div>,
      )}
    </AriaSearchField>
  );
};

export default TextField;
