import Image from 'next/image';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import stripeIcon from '@/assets/stripe.svg';
import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import StripeElement from './StripeElement';
import { stripeDesktopStyles } from './StripeModal.css';
import { useStripeContext } from './StripeProvider';

import StripeErrorModal from '../../../ErrorModals/StripeErrorModal';
import { modalScrollStyles } from '../../styles/modalStyle.css';
import PaymentDetail from '../PaymentDetail';
import { PaymentDetailItemProps } from '../PaymentDetail/PaymentDetail';

export interface StripeElementModalProps {
  isOpen: boolean;
  stripeKey: string;
  stripeToken: string;
  paymentDetail?: PaymentDetailItemProps[];
  hasChargePayment?: boolean;
  isReplacementNeedCostOfReturnCharge?: boolean;
  isInstantExchange?: boolean;
  onContinue: VoidFunction;
  onCancel: VoidFunction;
}

const StripeElementModal = ({
  isOpen,
  stripeKey,
  stripeToken,
  paymentDetail,
  onContinue,
  onCancel,
}: StripeElementModalProps) => {
  const { mobile } = useDevice();
  const { t } = useTranslation();
  const [errorModalVisible, setErrorModalVisible] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { stripeSubmit, isStripePaymentLoading, isStripeReady } = useStripeContext();

  const handleContinue = () => {
    stripeSubmit?.()
      .then(() => {
        onContinue();
      })
      .catch((error: string) => {
        if (error === 'validation_error') {
          //do nothing，比如校验错误，仍停留在本页面
        } else {
          setErrorModalVisible(true);
          setErrorMsg(error);
        }
      });
  };

  if (errorModalVisible) {
    return (
      <StripeErrorModal
        isOpen={errorModalVisible}
        errorMsg={errorMsg}
        onClose={() => {
          onCancel();
          setErrorModalVisible(false);
        }}
      />
    );
  }

  if (mobile) {
    return (
      <MobilePopup
        isOpen={isOpen}
        onClose={onCancel}
        title={t('ENTER_AC0975')}
        height={'90%'}
        layout='top'
        footer={
          <Flex
            direction='column'
            padding={SpacingVars['4']}
            paddingBottom={SpacingVars['4']}
            gap={SpacingVars['4']}
          >
            <Button
              onPress={handleContinue}
              isLoading={isStripePaymentLoading}
              isDisabled={!isStripeReady}
              size='large'
            >
              {t('popup.request.continue')}
            </Button>
            <Flex justifyContent='center' alignItems='center'>
              <Text variant='body3'>Powered by</Text>
              <Image src={stripeIcon.src} width={60} height={26} alt='stripe icon' />
            </Flex>
          </Flex>
        }
      >
        <StripeElement stripeKey={stripeKey} stripeToken={stripeToken} />
        {paymentDetail && <PaymentDetail paymentDetail={paymentDetail} />}
      </MobilePopup>
    );
  }

  return (
    <Modal isOpen={isOpen} className={modalScrollStyles}>
      <ModalHeader title={t('ENTER_AC0975')} onClose={onCancel} />

      <ModalBody>
        <Box className={stripeDesktopStyles}>
          <StripeElement stripeKey={stripeKey} stripeToken={stripeToken} />
        </Box>
        {paymentDetail && <PaymentDetail paymentDetail={paymentDetail} />}
      </ModalBody>

      <ModalFooter>
        <Flex alignItems='center' justifyContent='space-between'>
          <Flex alignItems='center'>
            <Text variant='body3'>Powered by</Text>
            <Image src={stripeIcon.src} width={60} height={26} alt='stripe icon' />
          </Flex>
          <Button
            onPress={handleContinue}
            isLoading={isStripePaymentLoading}
            isDisabled={!isStripeReady}
          >
            {t('popup.request.continue')}
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
};

export default StripeElementModal;
