import { Box, Flex, Spinner } from '@aftership/design-system';

import { overlayClassName } from './FullScreenLoading.css';

export interface IFullScreenLoadingProps {
  loading: boolean;
}
const FullScreenLoading = ({ loading }: IFullScreenLoadingProps) => {
  if (!loading) {
    return null;
  }

  return (
    <Box className={overlayClassName}>
      <Flex width='100%' height='100%' alignItems='center' justifyContent='center'>
        <Spinner />
      </Flex>
    </Box>
  );
};

export default FullScreenLoading;
