import { fromPromise } from 'xstate';

import { v4hostName } from '../constant';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { CourierList, GenerateLabelStatus, ShipmentsInfo, ShippingCourierList } from '../types';
import { makePollingRequest } from '../utils/makePollingRequest';

export const getCourierList = fromPromise(async ({ input }: { input: { token: string } }) => {
  const { token } = input;
  const res = await request<ResponseBody<CourierList>>(`${v4hostName()}/tracking-couriers`, {
    method: 'GET',
    headers: { 'returns-authorization': token },
  });

  return res?.data?.couriers || [];
});

export const getDisplayQrCodeSlugInfo = fromPromise(
  async ({ input }: { input: { token: string } }) => {
    const { token } = input;
    const res = await request<ResponseBody<ShippingCourierList>>(
      `${v4hostName()}/shipping-couriers`,
      {
        method: 'GET',
        headers: { 'returns-authorization': token },
      },
    );

    return res?.data?.couriers || [];
  },
);

interface GetShipmentByIdProps {
  returnId: string;
  token: string;
}
// 获取 shipments 信息
export const getShipmentsById = fromPromise(async ({ input }: { input: GetShipmentByIdProps }) => {
  const { returnId, token } = input;
  const res = await request<ResponseBody<{ shipments: ShipmentsInfo[] }>>(
    `${v4hostName()}/returns/${returnId}/shipments`,
    {
      method: 'GET',
      headers: { 'returns-authorization': token },
    },
  );

  return res?.data?.shipments || [];
});

interface PostShipmentPayload {
  returnId: string;
  trackingNumber: string;
  courierSlug: string;
}

// post shipments 信息
export const postShipmentsById = fromPromise(
  async ({ input }: { input: { token: string } & PostShipmentPayload }) => {
    const { returnId, token, ...payload } = input;
    const res = await request<ResponseBody<{}>>(`${v4hostName()}/returns/${returnId}/shipments`, {
      method: 'POST',
      payload: {
        tracking_number: payload.trackingNumber,
        courier_slug: payload.courierSlug,
      },
      headers: { 'returns-authorization': token },
    });

    return res?.data;
  },
);

export const pollingShipment = makePollingRequest<ShipmentsInfo[], GetShipmentByIdProps>().provide({
  actors: {
    retrieveResource: getShipmentsById,
  },
  guards: {
    resourceIsVerified: ({ context }) => {
      const labelsWithAdditionalLabel =
        context.response?.reduce?.(
          (acc, shipment) => {
            return [...acc, ...shipment.additional_labels];
          },
          context.response ?? ([] as ShipmentsInfo[]),
        ) ?? [];

      if (!labelsWithAdditionalLabel.length) return false;
      // 如果 label 都生成了，或者有失败的 label，那认为已经有结果了
      return (
        labelsWithAdditionalLabel.every(
          (label) => label.auto_label_generation_status === GenerateLabelStatus.Created,
        ) ||
        labelsWithAdditionalLabel.some(
          (label) => label.auto_label_generation_status === GenerateLabelStatus.Failed,
        )
      );
    },
  },
});
