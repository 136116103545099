import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  Icon,
  Modal,
  ModalHeader,
  Position,
  Spinner,
  Text,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { DistanceUnit, DropoffLocationItem, LngLat } from 'returns-logics';

import { LinkWithIcon } from '@/components/LinkWithIcon';
import useGeoLocation from '@/hooks/useGeoLocation';

import LocationItem from '../LocationItem';

interface DeniedPermissionContentProps {
  showAllLocations?: boolean;
  allLocationsLink?: string | null;
}
interface PromptPermissionContentProps {
  onPressPreciseLocation: () => void;
  showAllLocations?: boolean;
  allLocationsLink?: string | null;
}
interface HappyReturnModalProps {
  isLoading?: boolean;
  locations?: DropoffLocationItem[];
  allLocationsLink?: string | null;
  showAllLocations?: boolean;
  onNearByLocations?: (latitude: number, longitude: number) => void;
}

const DeniedPermissionContent = ({
  showAllLocations,
  allLocationsLink,
}: DeniedPermissionContentProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      flex={1}
      direction='column'
      alignItems='center'
      justifyContent='center'
      gap={SpacingVars['4']}
    >
      <Flex direction='column' alignItems='center' gap={SpacingVars['1']}>
        <Text variant='subtitle2'>{t('location.permission.deny.title')}</Text>
        <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
          {t('location.permission.deny.desc')}
        </Text>
      </Flex>
      {showAllLocations && allLocationsLink && (
        <LinkWithIcon link={allLocationsLink}>
          {t('page.happyReturn.action.viewAllLocations')}
        </LinkWithIcon>
      )}
    </Flex>
  );
};

const PromptPermissionContent = ({
  showAllLocations,
  allLocationsLink,
  onPressPreciseLocation,
}: PromptPermissionContentProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  return (
    <Flex
      flex={1}
      direction='column'
      alignItems='center'
      justifyContent='center'
      gap={SpacingVars['4']}
    >
      <Flex direction='column' alignItems='center' gap={SpacingVars['1']}>
        {/* todo: i18n key 拼写错误 */}
        <Text variant='subtitle2' color={ColorVars['Grey']['Grey1200']}>
          {t('location.permission.reques.title')}
        </Text>
        <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
          {t('location.permission.request.desc')}
        </Text>
      </Flex>
      <Button
        variant='secondary'
        onPress={() => {
          setIsLoading(true);
          onPressPreciseLocation();
        }}
        isLoading={isLoading}
      >
        {t('location.permission.request.preciseLocation')}
      </Button>
      {showAllLocations && allLocationsLink && (
        <LinkWithIcon link={allLocationsLink}>
          {t('page.happyReturn.action.viewAllLocations')}
        </LinkWithIcon>
      )}
    </Flex>
  );
};

const HappyReturnModal = ({
  isLoading,
  locations = [],
  allLocationsLink,
  showAllLocations = false,
  onNearByLocations,
}: HappyReturnModalProps) => {
  const { t } = useTranslation();
  const { location, locationPermission, requestPreciseLocation } = useGeoLocation();
  const locationRef = useRef<LngLat>();

  useEffect(() => {
    if (location && locationRef.current !== location) {
      locationRef.current = location;
      onNearByLocations?.(location.latitude, location.longitude);
    }
  }, [location, isLoading, onNearByLocations]);

  const contentMap = {
    denied: (
      <DeniedPermissionContent
        showAllLocations={showAllLocations}
        allLocationsLink={allLocationsLink}
      />
    ),
    prompt: (
      <PromptPermissionContent
        showAllLocations={showAllLocations}
        allLocationsLink={allLocationsLink}
        onPressPreciseLocation={requestPreciseLocation}
      />
    ),
    granted: (
      <>
        {locations.length <= 0 ? (
          <Flex
            flex={1}
            direction='column'
            gap={SpacingVars['4']}
            alignItems='center'
            justifyContent='center'
          >
            <Flex direction='column' gap={SpacingVars['1']} alignItems='center'>
              <Text variant='subtitle2' color={ColorVars['Grey']['Grey1200']}>
                {t('location.empty.title')}
              </Text>
              <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
                {t('location.empty.desc')}
              </Text>
            </Flex>
            {showAllLocations && allLocationsLink && (
              <LinkWithIcon link={allLocationsLink}>
                {t('page.happyReturn.action.viewAllLocations')}
              </LinkWithIcon>
            )}
          </Flex>
        ) : (
          <Flex direction='column' gap={SpacingVars['4']} paddingBottom={SpacingVars['6']}>
            <Flex alignItems='center' gap={SpacingVars['1']}>
              <Icon
                source={Position}
                size={SpacingVars['4']}
                color={ColorVars['Grey']['Grey 1100']}
              />
              <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
                {t('page.happyReturn.baseShippingAddress')}
              </Text>
            </Flex>
            {locations.map((location) => (
              <LocationItem
                key={location.name}
                isRedirectToMap
                location={location}
                distanceUnit={DistanceUnit.MI}
              />
            ))}
            {showAllLocations && allLocationsLink && (
              <Flex justifyContent='center' paddingTop={SpacingVars['2']}>
                <LinkWithIcon link={allLocationsLink}>
                  {t('page.happyReturn.action.viewAllLocations')}
                </LinkWithIcon>
              </Flex>
            )}
          </Flex>
        )}
      </>
    ),
  };

  return (
    <Modal isDismissable>
      <ModalHeader title={t('nearby_location.modal.title')} />
      <Box height={440} overflow='auto' padding={SpacingVars['6']}>
        <Flex height='100%' direction='column'>
          {isLoading ? (
            <Flex flex={1} direction='column' alignItems='center' justifyContent='center'>
              <Spinner />
            </Flex>
          ) : (
            locationPermission && contentMap[locationPermission]
          )}
        </Flex>
      </Box>
    </Modal>
  );
};

export default HappyReturnModal;
