import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Flex } from '@aftership/design-system';
import { OrderLookupType } from 'returns-logics';

import { mobileTextFieldClassName } from '@/components/Form/index.css';
import {
  EmailTextField,
  OrderNumberTextField,
  PhoneNumberTextField,
  PostalCodeTextField,
} from '@/features/preview/components/WithPreviewSection';
import useDevice from '@/hooks/useDevice';

import { ChangeVerifyMethodAction } from './ChangeVerifyMethodAction';

import { OrderLookupFormValues, getSchema } from '../constant/schema';
import { useOrderLookupSetting } from '../hooks/useOrderLookupSetting';

export type ValidateData = {
  isValid: boolean;
  errorMessage: string;
};

type OrderLookupFormProps = {
  initialEmail?: string;
  initialPostalCode?: string;
  initialOrderNumber?: string;
  defaultOrderLookupType?: OrderLookupType;
  onValidate?: (data: ValidateData) => void;
  renderActionButton?: (isValid: boolean, data: FormData) => JSX.Element;
  classNames?: {
    formItems?: string;
    changeAction?: string;
  };
};

export type FormData = {
  orderNumber?: string;
  email?: string;
  phoneNumber?: string;
  postalCode?: string;
  verifyMethod: OrderLookupType | null;
};

const getErrorMessage = (
  errors: FieldErrors<OrderLookupFormValues>,
  verifyMethod: OrderLookupType | null,
): string => {
  if (verifyMethod === OrderLookupType.EMAIL) {
    return errors.email?.message ?? '';
  }
  if (verifyMethod === OrderLookupType.ZIPCODE) {
    return errors.postalCode?.message ?? '';
  }
  if (verifyMethod === OrderLookupType.PHONE) {
    return errors.phoneNumber?.message ?? '';
  }
  return '';
};

const OrderLookupForm = ({
  initialEmail,
  initialPostalCode,
  initialOrderNumber,
  defaultOrderLookupType,
  renderActionButton,
  onValidate,
  classNames,
}: OrderLookupFormProps) => {
  const { orderLookupVarifyOptions } = useOrderLookupSetting();

  const { t } = useTranslation();

  const initialValues = useMemo(() => {
    return {
      orderNumber: initialOrderNumber,
      email: initialEmail,
      postalCode: initialPostalCode,
      verifyMethod: defaultOrderLookupType || orderLookupVarifyOptions[0],
    };
  }, [
    initialOrderNumber,
    initialEmail,
    defaultOrderLookupType,
    initialPostalCode,
    orderLookupVarifyOptions,
  ]);

  const form = useForm<OrderLookupFormValues>({
    defaultValues: initialValues,
    resolver: yupResolver(getSchema()) as any,
    mode: 'onBlur',
  });

  const isMobile = useDevice().mobile;

  const { isValid, errors } = form.formState;

  const { email, postalCode, callingCodeData, phoneNumber, orderNumber, verifyMethod } =
    form.watch();

  const formData: FormData = useMemo(() => {
    return {
      verifyMethod,
      email: email?.trim(),
      postalCode: postalCode?.trim(),
      phoneNumber: `${callingCodeData?.callingCode?.replace(/\s+/g, '')}${phoneNumber?.trim()}`,
      orderNumber: orderNumber?.trim(),
    };
  }, [email, postalCode, callingCodeData, phoneNumber, orderNumber, verifyMethod]);

  const onUpdateVarifyMethod = (verifyMethod: OrderLookupType) => {
    form.setValue('verifyMethod', verifyMethod);
    form.trigger([
      'email',
      'phoneNumber',
      'postalCode',
      'callingCodeData.callingCode',
      'orderNumber',
    ]);
  };

  useEffect(() => {
    form.reset(initialValues);
  }, [initialValues, form]);

  const validateData: ValidateData = useMemo(() => {
    return {
      isValid,
      errorMessage: getErrorMessage(errors, verifyMethod),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, JSON.stringify(errors), verifyMethod]);

  useEffect(() => {
    onValidate?.(validateData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateData]);

  return (
    <Flex direction='column'>
      <Box className={clsx(classNames?.formItems)}>
        <OrderNumberTextField
          control={form.control}
          name='orderNumber'
          fullWidth
          placeholder={t('page.landing.orderNumber')}
          autoComplete='off'
          clearable
          hiddenError
          className={isMobile ? mobileTextFieldClassName : undefined}
          aria-label={t('page.landing.orderNumber')}
        />

        {verifyMethod === OrderLookupType.EMAIL && (
          <EmailTextField
            control={form.control}
            name='email'
            autoComplete='off'
            clearable
            hiddenError
            placeholder={t('page.landing.emailAddress')}
            fullWidth
          />
        )}
        {verifyMethod === OrderLookupType.ZIPCODE && (
          <PostalCodeTextField
            control={form.control}
            name='postalCode'
            autoComplete='off'
            clearable
            hiddenError
            placeholder={t('page.landing.postalCode')}
            transform={(v) => v.toUpperCase()}
            fullWidth
          />
        )}
        {verifyMethod === OrderLookupType.PHONE && <PhoneNumberTextField control={form.control} />}
      </Box>
      <ChangeVerifyMethodAction
        currentVerifyMethod={verifyMethod}
        onUpdateVarifyMethod={onUpdateVarifyMethod}
        allActiveVerifyMethods={orderLookupVarifyOptions}
        className={classNames?.changeAction}
      />
      {renderActionButton && renderActionButton(validateData?.isValid, formData)}
    </Flex>
  );
};

export default OrderLookupForm;
