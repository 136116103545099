'use client';

import { PropsWithChildren, createContext, useCallback, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import Toast from './Toast';
import { toastProviderRecipe } from './Toast.css';
import { ToastQueue, useToastQueue } from './ToastQueue';
import { ToastContextType, ToastOptions, ToastProviderProps } from './type';

import { TOAST_DEFAULT_DURATION, TOAST_DEFAULT_MAX_VISIBLE } from '../../constant';
import { useIsMounted } from '../../hooks/useIsMounted';
import { clsx } from '../../utils/clsx';
import { usePortalContainer } from '../ThemeProvider/AppContainerContext';

export const ToastContext = createContext<ToastContextType | null>(null);

const ToastProvider = ({
  children,
  maxVisibleToast = TOAST_DEFAULT_MAX_VISIBLE,
  defaultDuration = TOAST_DEFAULT_DURATION,
  outOfQueueStrategy = 'ignore',
}: PropsWithChildren<ToastProviderProps>) => {
  const [toastQueue] = useState(
    () => new ToastQueue({ maxVisibleToast, outOfQueueStrategy, defaultDuration }),
  );

  const visibleToasts = useToastQueue(toastQueue);

  const isMounted = useIsMounted();

  const showToast = useCallback(
    (content: string, options?: ToastOptions) => {
      toastQueue.add(content, options);
    },
    [toastQueue],
  );

  const info = useCallback(
    (content: string, options?: ToastOptions) => {
      showToast(content, { ...options, type: 'info' });
    },
    [showToast],
  );

  const error = useCallback(
    (content: string, options?: ToastOptions) => {
      showToast(content, { ...options, type: 'error' });
    },
    [showToast],
  );

  const ctxValue = useMemo<ToastContextType>(() => {
    return { showToast, info, error };
  }, [showToast, info, error]);

  const portalContainer = usePortalContainer();

  return (
    <ToastContext.Provider value={ctxValue}>
      {children}

      {isMounted &&
        visibleToasts.length > 0 &&
        createPortal(
          <div className={clsx(toastProviderRecipe())}>
            {visibleToasts.map((toast) => (
              <Toast
                toast={toast}
                state={{
                  visibleToasts,
                  add: toastQueue.add.bind(toastQueue),
                  close: toastQueue.close.bind(toastQueue),
                }}
                key={toast.key}
              />
            ))}
          </div>,
          portalContainer!,
        )}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
