import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';

import MobilePopup from '@/components/MobilePopup';
import useDevice from '@/hooks/useDevice';

import ModalButtonGroup from '../ModalButtonGroup/ModalButtonGroup';

export interface ExpiredModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const WarningImage = require('@/assets/warning_outlined.svg');

const ExpiredModal = ({ isOpen, onClose }: ExpiredModalProps) => {
  const { t } = useTranslation();
  const { mobile } = useDevice();

  const Container = () => {
    return (
      <Flex direction='column' justifyContent='center' alignItems='center' gap={SpacingVars['6']}>
        <Box>
          <Image src={WarningImage} width={72} height={72} alt='' />
        </Box>
        <Flex direction='column' justifyContent='center'>
          {t('page.request.payExpired')}
        </Flex>
      </Flex>
    );
  };

  const Footer = () => {
    return (
      <ModalButtonGroup>
        <Button size='large' onPress={onClose}>
          {t('page.request.payExpired.confirm')}
        </Button>
      </ModalButtonGroup>
    );
  };

  if (mobile) {
    return (
      <MobilePopup
        isOpen={isOpen}
        title={t('page.request.payExpiredTitle')}
        onClose={onClose}
        footer={<Footer />}
      >
        <Container />
      </MobilePopup>
    );
  }
  return (
    <Modal isOpen={isOpen}>
      <ModalHeader title={t('page.request.payExpiredTitle')} onClose={onClose} />

      <ModalBody>
        <Container />
      </ModalBody>

      <ModalFooter>
        <Footer />
      </ModalFooter>
    </Modal>
  );
};

export default ExpiredModal;
