import {
  IconThemeVar,
  buttonThemeVars,
  checkboxThemeVars,
  linkThemeVars,
  listBoxVars,
  progressBarThemeVars,
  radioThemeVars,
  tagGroupThemeVars,
  textAreaVars,
  textFieldVars,
} from '@aftership/design-system';
import { selectThemeVars } from '@aftership/design-system';
import { TypographyVars } from '@aftership/design-tokens';

import { datePickerThemeVars } from '@/components/DatePicker/DatePickerTokens.css';
import { getOpacityColors } from '@/utils/colors';

export interface UserThemeConfig {
  primaryFont: string;
  secondaryFont: string;
  primaryColor: string;
  [key: string]: string | undefined | null;
}

const bodyFontTypography = [
  TypographyVars.Outline.Font,
  TypographyVars['Body 1'].Font,
  TypographyVars['Body 2'].Font,
  TypographyVars['Body 3'].Font,
  TypographyVars['Caption 2'].Font,
  TypographyVars['Caption 1'].Font,
  TypographyVars['Large Body'].Font,
  TypographyVars['Subtitle 1'].Font,
  TypographyVars['Subtitle 2'].Font,
];

const primaryFontTypography = [
  TypographyVars['Title 1'].Font,
  TypographyVars['Title 2'].Font,
  TypographyVars['Title 3'].Font,
  TypographyVars['Title 4'].Font,
];

export function getUserTheme(config: UserThemeConfig): Record<string, string | undefined | null> {
  const { primaryFont, secondaryFont, primaryColor } = config;
  let fullOpacityColor, eightyOpacityColor;

  try {
    [fullOpacityColor, eightyOpacityColor] = getOpacityColors(primaryColor, [1, 0.8]);
  } catch (e) {
    [fullOpacityColor, eightyOpacityColor] = [primaryColor, primaryColor];
  }

  const primaryFontMap = primaryFontTypography.reduce(
    (prev, cur) => {
      prev[cur] = primaryFont;
      return prev;
    },
    {} as Record<string, string>,
  );

  const secondaryFontMap = bodyFontTypography.reduce(
    (prev, cur) => {
      prev[cur] = secondaryFont;
      return prev;
    },
    {} as Record<string, string>,
  );

  return {
    ...primaryFontMap,
    ...secondaryFontMap,
    [buttonThemeVars.Color.Button.Primary_Background]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Primary_Hover_Background]: eightyOpacityColor,
    [buttonThemeVars.Color.Button.Primary_Pressed_Background]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Primary_Border]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Primary_Hover_Border]: eightyOpacityColor,
    [buttonThemeVars.Color.Button.Primary_Pressed_Border]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Small_Border]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Small_Hover_Border]: eightyOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Small_Pressed_Border]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Large_Border]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Large_Hover_Border]: eightyOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Large_Pressed_Border]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Secondary]: fullOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Hover]: eightyOpacityColor,
    [buttonThemeVars.Color.Button.Secondary_Pressed]: fullOpacityColor,
    [linkThemeVars.Color.Link.Default]: fullOpacityColor,
    [linkThemeVars.Color.Link.Hovered]: eightyOpacityColor,

    [listBoxVars.Color.ListBoxItem.Border_Selected]: fullOpacityColor,
    [IconThemeVar.PrimaryColor]: fullOpacityColor,

    [tagGroupThemeVars.Color.Tag.Selected_Border]: fullOpacityColor,

    [checkboxThemeVars.Color.IconWrapper]: fullOpacityColor,
    [checkboxThemeVars.Color.Checkbox.Hovered_BorderColor]: eightyOpacityColor,

    [textFieldVars.Color.Input.Focus_Border]: fullOpacityColor,
    [textFieldVars.Color.Input.Hover_Border]: eightyOpacityColor,

    [selectThemeVars.Color.TriggerButton.OutlineColor.Focused]: fullOpacityColor,
    [selectThemeVars.Color.TriggerButton.OutlineColor.Hovered]: eightyOpacityColor,

    [textFieldVars.Color.Input.Focus_Border]: fullOpacityColor,

    [progressBarThemeVars.Color.Fill.Background]: fullOpacityColor,
    [progressBarThemeVars.Color.Indeterminate.Border]: fullOpacityColor,

    [datePickerThemeVars.Color.Date_Item.Selected_Background]: fullOpacityColor,

    [radioThemeVars.Color.Icon.Selected]: fullOpacityColor,

    [textAreaVars.Color.TextArea.Focus_Border]: fullOpacityColor,
    [textAreaVars.Color.TextArea.Hover_Border]: eightyOpacityColor,
  };
}
