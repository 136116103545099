import { updateAppEnv } from '../../config';
import { internalHostname, v3hostName, v4hostName } from '../constant/hostname';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { IClickwrap } from '../types';
import { GiftReturnSettings, GiftReturns, Shops } from '../types/shopInfo';
import { IGrayFeatureEnabledMap } from '../types/shopInfo/ReturnsSettings';
import { UnexpectedHostnameException } from '../utils/errorHandler';

export interface GetStoreConfigPayload {
  orgId?: string;
  shopHostName?: string;
  APP_ENV?: string;
  AM_API_KEY: string;
}

const getGiftReturnsSettings = async (orgId: string) => {
  const uri = `${v3hostName()}/gift-returns/setting?organization_id=${orgId}`;
  const response = await request<ResponseBody<GiftReturns>>(uri);
  return response.data || Promise.reject(response);
};

export const getClickWrapConfig = async (orgId: string) => {
  const res = await request<ResponseBody<IClickwrap>>(
    `${v4hostName()}/setting/clickwrap?organization_id=${orgId}`,
    {
      method: 'GET',
    },
  );

  return res?.data;
};

export const HOSTNAME_PORT_REGEX = /:\d+$/;

const getShopInfo = async (payload: {
  shopHostName?: string;
  orgId?: string;
  AM_API_KEY: string;
}): Promise<Shops> => {
  const { shopHostName, orgId, AM_API_KEY } = payload;

  if (!shopHostName && !orgId) {
    throw new UnexpectedHostnameException('[getShopInfo]: shopHostName or orgId is required');
  }

  let searchQuery = '';
  if (orgId) {
    searchQuery = `organization_id=${orgId}`;
  } else if (shopHostName) {
    const hostNameWithoutPort = shopHostName.replace(HOSTNAME_PORT_REGEX, '');
    searchQuery = `hostname=${hostNameWithoutPort}`;
  }

  const uri = `${internalHostname()}/shops?${searchQuery}`;
  const response = await request<ResponseBody<Shops>>(uri, {
    headers: { 'am-api-key': AM_API_KEY },
  });

  return response.data || Promise.reject(response);
};

export const getShopConfig = async ({ input }: { input: GetStoreConfigPayload }) => {
  const { orgId, shopHostName, APP_ENV, AM_API_KEY } = input;

  APP_ENV && updateAppEnv(APP_ENV);

  let shopInfo = {} as Shops;
  let giftReturnSetting = {} as GiftReturnSettings;
  let grayFeatureEnabledMap = {} as IGrayFeatureEnabledMap;

  shopInfo = await getShopInfo({ orgId, shopHostName, AM_API_KEY });

  const giftReturnSettingData = await getGiftReturnsSettings(shopInfo?.organization?.id!);

  giftReturnSetting = {
    ...giftReturnSettingData,
    giftReturnAvailability:
      giftReturnSettingData.feature_applied &&
      (giftReturnSettingData.resolutions.refund_to_store_credit_active ||
        giftReturnSettingData.resolutions.replace_with_the_same_item_active),
  };
  grayFeatureEnabledMap = shopInfo.feature_flags;

  return {
    shopInfo,
    giftReturnSetting,
    grayFeatureEnabledMap,
  };
};
