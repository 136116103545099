import React from 'react';

import { Flex, Uploader } from '@aftership/design-system';
import { SpacingVars } from '@aftership/design-tokens';
import { Reason, ReturnImage } from 'returns-logics';

import { CommentsArea } from '../CommentsArea';
import { UploadImages } from '../UploadImages';

export interface FillMoreDetailProps {
  selectedReason: Reason;
  comments?: string;
  images?: Array<ReturnImage>;
  onCommentsChange: (comments: string) => void;
  onImagesChange: (images: Array<ReturnImage>) => void;
  onUploadingChange: (status: Uploader['status']) => void;
  onUploadError: (error: Uploader['uploadError']) => void;
}
const FillMoreDetail = ({
  selectedReason,
  comments,
  images,
  onCommentsChange,
  onImagesChange,
  onUploadingChange,
  onUploadError,
}: FillMoreDetailProps) => {
  const {
    id: reasonId,
    comments_enabled: commentsEnabled,
    comments_required: commentsRequired,
    comments_description: commentsDescription,
    images_enabled: imagesEnabled,
    images_required: imagesRequired,
    images_description: imagesDescription,
  } = selectedReason;

  return (
    <Flex direction={'column'} gap={SpacingVars['3']}>
      {commentsEnabled && (
        <CommentsArea
          reasonId={reasonId}
          comments={comments}
          commentsDescription={commentsDescription}
          required={commentsRequired}
          onCommentsChange={onCommentsChange}
        />
      )}
      {imagesEnabled && (
        <UploadImages
          reasonId={reasonId}
          images={images}
          imagesDescription={imagesDescription}
          required={imagesRequired}
          onImagesChange={(images) => onImagesChange(images)}
          onUploadingStatusChange={onUploadingChange}
          onUploadError={onUploadError}
        />
      )}
    </Flex>
  );
};

export default FillMoreDetail;
