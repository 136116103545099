'use client';

import { Select as AriaSelect, Button, Key, SelectValue } from 'react-aria-components';

import {
  selectClassName,
  selectTriggerButtonRecipe,
  selectTriggerIconStyle,
  selectTriggerRecipe,
  selectTriggerWrapperRecipe,
} from './Select.css';
import { SelectProps } from './type';

import { clsx } from '../../utils/clsx';
import { withCls } from '../../utils/withCls';
import DropdownBase from '../Dropdown/DropdownBase';
import { Icon } from '../Icon';
import Trailing from '../Icon/Trailing';

const Select = (props: SelectProps) => {
  const { options, placeholder, onChange, value, defaultValue, className, customActivatorContent } =
    props;

  const renderChildren = (
    <>
      <Button className={clsx(selectTriggerButtonRecipe())}>
        <SelectValue>
          {customActivatorContent
            ? customActivatorContent
            : (value) => {
                return (
                  <div className={selectTriggerWrapperRecipe()}>
                    <span
                      className={clsx(selectTriggerRecipe({ placeholder: value.isPlaceholder }))}
                    >
                      {value.isPlaceholder ? placeholder : value.selectedText}
                    </span>
                    <div className={selectTriggerIconStyle}>
                      <Icon source={Trailing}></Icon>
                    </div>
                  </div>
                );
              }}
        </SelectValue>
      </Button>
    </>
  );

  const onAction = (key: Key, state?: any) => {
    onChange?.(key);
    state?.setSelectedKey?.(key);
  };

  return (
    <DropdownBase
      trigger={AriaSelect}
      triggerProps={{
        selectedKey: value,
        defaultSelectedKey: defaultValue,
        onSelectionChange: onAction,
        className: clsx(withCls('Select'), selectClassName, className),
      }}
      className={withCls('Select-Popover')}
      onAction={onAction}
      selectionMode='single'
      items={options.map((item) => ({ id: item.value, name: item.label, render: item.render }))}
      renderChildren={renderChildren}
    />
  );
};

export default Select;
