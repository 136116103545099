import { t } from 'i18next';
import React from 'react';

import { Flex, Text } from '@aftership/design-system';
import { ColorVars } from '@aftership/design-tokens';

export interface Props {
  tags: Array<string>;
}
const CustomizationsTags = ({ tags }: Props) => {
  if (!tags.length) {
    return null;
  }
  return (
    <Flex direction={'column'}>
      <Text variant={'caption2'} color={ColorVars.Grey['Grey 1000']}>
        {t('page.description.customizationAdded')}
      </Text>

      {tags.map((tag, index) => (
        <Text
          key={`${tag}-${index}`}
          variant={'caption2'}
          color={ColorVars.Grey['Grey 1000']}
        >{`• ${tag}`}</Text>
      ))}
    </Flex>
  );
};

export default CustomizationsTags;
