export enum PayStatus {
  pay = 'pay',
  alreadyPay = 'alreadyPay',
  paid = 'paid',
  repeatedPay = 'repeatedPay',
  error = 'error',
  // 轮询超时
  timeout = 'timeout',
  // draftReturn 被删除
  checkoutCanceled = 'checkoutCanceled',
}
