'use client';

import { useContext } from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';

import { closeButtonClassName } from './Modal.css';
import { ClostButtonProps } from './types';

import { useStyleProps } from '../../utils/styleProps';
import { Button } from '../Button';
import { Icon, IconCross } from '../Icon';

export default function CloseButton(props: ClostButtonProps) {
  const { onClose, ...rest } = props;
  const { styleProps } = useStyleProps(rest);

  let state = useContext(OverlayTriggerStateContext)!;

  const handleClose = () => {
    setTimeout(() => {
      onClose?.();
    }, 20);
    state.close();
  };

  return (
    <Button
      variant='plain'
      onPress={handleClose}
      style={{ ...styleProps.style }}
      className={closeButtonClassName}
    >
      <Icon source={IconCross} color='currentColor' size={'36px'} />
    </Button>
  );
}
