'use client';

import { TagGroup as AriaTagGroup, Label, TagList, Text } from 'react-aria-components';
import type { Selection } from 'react-aria-components';

import Tag from './Tag';
import { tagGroupRecipe, tagListRecipe } from './TagGroup.css';
import { TagGroupProps } from './type';

import { ItemOptionBase } from '../../types/shared';

const TagGroup = <T extends ItemOptionBase>({
  children,
  items,
  label,
  description,
  renderEmptyState,
  selectionMode = 'single',
  onSelectionChange,
  onSelectAll,
  onItemChange,
  ...props
}: TagGroupProps<T>) => {
  const handleSelectionChange = (values: Selection) => {
    if (values === 'all') {
      onSelectAll?.();
    } else {
      onSelectionChange?.([...values] as string[]);
    }
  };

  let renderChildren;
  if (typeof children === 'undefined') {
    renderChildren = (
      <TagList items={items} renderEmptyState={renderEmptyState} className={tagListRecipe()}>
        {(item) => <Tag onPress={() => onItemChange?.(item)}>{item.name}</Tag>}
      </TagList>
    );
  } else if (typeof children === 'function') {
    renderChildren = (
      <TagList items={items} renderEmptyState={renderEmptyState} className={tagListRecipe()}>
        {children}
      </TagList>
    );
  } else {
    renderChildren = children;
  }

  return (
    <AriaTagGroup
      {...props}
      selectionMode={selectionMode}
      className={tagGroupRecipe()}
      onSelectionChange={handleSelectionChange}
    >
      <Label>{label}</Label>
      {renderChildren}
      {description && <Text slot='description'>{description}</Text>}
    </AriaTagGroup>
  );
};

export default TagGroup;
