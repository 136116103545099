import useDevice from '@/hooks/useDevice';

import { useSharePropsContext } from '../context/SharePropsContext';

const useHideSummaryWidth = () => {
  const isMobile = useDevice().mobile;
  const { hideSummary } = useSharePropsContext();
  return !isMobile && hideSummary ? 720 : void 0;
};

export default useHideSummaryWidth;
