'use client';

import { PropsWithChildren, useContext } from 'react';
import { OverlayTriggerStateContext } from 'react-aria-components';

import { OverlayTriggerProps } from './type';

import { clsx } from '../../utils/clsx';
import { Button } from '../Button';

export default function OverlayTrigger(props: PropsWithChildren<OverlayTriggerProps>) {
  const { onPress, className, render, style, children } = props;

  const state = useContext(OverlayTriggerStateContext)!;
  const handlePress = () => {
    onPress?.(state);
  };

  return (
    render?.(state) || (
      <Button variant='plain' onPress={handlePress} style={style} className={clsx(className)}>
        {children}
      </Button>
    )
  );
}
