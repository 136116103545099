import { useTranslation } from 'react-i18next';

import { Box, Flex, Icon, Modal, ModalHeader, Position, Text } from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';
import { DistanceUnit, DropoffLocationItem } from 'returns-logics';

import LocationItem from '../LocationItem';

interface RetailReworkModalProps {
  locations?: DropoffLocationItem[];
}

const RetailReworkModal = ({ locations = [] }: RetailReworkModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isDismissable>
      <ModalHeader divider title={t('nearby_location.modal.title')} />
      <Box height={440} overflow='auto' padding={SpacingVars['6']}>
        <Flex direction='column' gap={SpacingVars['4']}>
          <Flex alignItems='center' gap={SpacingVars['1']}>
            <Icon source={Position} size={SpacingVars['4']} />
            <Text variant='caption1' color={ColorVars['Grey']['Grey 1100']}>
              {t('page.happyReturn.baseShippingAddress')}
            </Text>
          </Flex>
          {locations.map((location) => (
            <LocationItem
              key={location.name}
              isRedirectToMap
              location={location}
              distanceUnit={DistanceUnit.MI}
            />
          ))}
        </Flex>
      </Box>
    </Modal>
  );
};

export default RetailReworkModal;
