// Only deep merge two levels of nested object
export const simpleMerge = (objs: object[], isIgnoreCaseFlag?: boolean) => {
  const result: Record<string, any> = {};

  objs.forEach((obj) => {
    for (const [key, value] of Object.entries(obj)) {
      const transformedKey = isIgnoreCaseFlag ? key.toLowerCase() : key;

      const existKey = result[transformedKey];

      if (existKey) {
        if (Array.isArray(existKey)) {
          result[transformedKey].push(...value);
        } else if (existKey instanceof Object) {
          result[transformedKey] = Object.assign(
            existKey,
            ...Object.keys(value).map((key) => ({
              [isIgnoreCaseFlag ? key.toLowerCase() : key]: value[key],
            })),
          );
        } else {
          result[transformedKey] = value;
        }
      } else {
        result[transformedKey] = value;
      }
    }
  });

  return result;
};
