'use client';

import { dialogClassName, modalOverlayRecipe, modalRecipe } from './Modal.css';
import { ModalProps } from './types';

import { clsx } from '../../utils/clsx';
import { Overlay } from '../Overlay';

const Modal = ({ children, className, size, ...rest }: ModalProps) => {
  return (
    <Overlay
      maskClassName={modalOverlayRecipe()}
      className={clsx(modalRecipe({ size }), className)}
      bodyClassName={dialogClassName}
      isDismissable={false}
      {...rest}
    >
      {children}
    </Overlay>
  );
};

export default Modal;
