'use client';

import React, { FC, PropsWithChildren } from 'react';

import {
  Box,
  Card,
  CardProps,
  Flex,
  Icon,
  LeftChevronThin,
  Pressable,
} from '@aftership/design-system';
import { ColorVars, SpacingVars } from '@aftership/design-tokens';

import { Header } from '@/components/Header';
import useDevice from '@/hooks/useDevice';

import { cardClassName, mobileBodyContainer } from './StepCard.css';

export interface Props
  extends Omit<
    PropsWithChildren<CardProps>,
    | 'padding'
    | 'paddingX'
    | 'paddingY'
    | 'paddingTop'
    | 'paddingBottom'
    | 'paddingStart'
    | 'paddingEnd'
  > {
  title: React.ReactNode;
  onBack?: VoidFunction;
  backgroundColor?: string;
  headerContainerAlignItems?: 'center' | 'start';
  rightSlot?: React.ReactNode;
  hiddenHeader?: boolean;
}

const StepCard: FC<Props> = ({
  onBack,
  title,
  rightSlot,
  children: body,
  headerContainerAlignItems = 'center',
  width = '1080px',
  hiddenHeader,
  ...rest
}) => {
  const isMobile = useDevice().mobile;

  if (isMobile) {
    return (
      <Box flex={1} height={'100%'} backgroundColor={ColorVars['B&W']['Background']}>
        <Flex direction='column' height='100%'>
          <Flex
            justifyContent={'space-between'}
            paddingX={SpacingVars[4]}
            paddingTop={SpacingVars[7]}
            paddingBottom={SpacingVars[4]}
          >
            {!hiddenHeader && (
              <Pressable
                onPress={() => {
                  onBack?.();
                }}
              >
                <Icon source={LeftChevronThin} color='#232933' size={SpacingVars['6']} />
              </Pressable>
            )}
            <Box width={SpacingVars['6']} height={SpacingVars['6']} />
          </Flex>
          <Box className={mobileBodyContainer}>{body}</Box>
        </Flex>
      </Box>
    );
  }

  return (
    <Card className={cardClassName} width={width} {...rest}>
      {!hiddenHeader && (
        <Box
          paddingX={SpacingVars['10']}
          paddingTop={SpacingVars['8']}
          paddingBottom={SpacingVars['2']}
        >
          <Header
            onBack={onBack}
            title={title}
            rightSlot={rightSlot}
            alignItems={headerContainerAlignItems}
          />
        </Box>
      )}
      {body}
    </Card>
  );
};
export default StepCard;
