import { MatchingOrderFormType, NotMatchingOrderFormType } from './types';

export const formDataWithoutMatchingOrderDefaultValues: NotMatchingOrderFormType = {
  productName: '',
  option: '',
  notes: '',
  address: {
    country: '',
    state: '',
    city: '',
    postalCode: '',
    line1: '',
    line2: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
  },
  recipient: {
    firstName: '',
    lastName: '',
    email: '',
  },
  customer: {
    firstName: '',
    lastName: '',
    email: '',
  },
};

export const formDataMatchingOrderDefaultValues: MatchingOrderFormType = {
  productName: '',
  option: '',
  notes: '',
  address: {
    country: '',
    state: '',
    city: '',
    postalCode: '',
    line1: '',
    line2: '',
    phoneNumber: '',
    firstName: '',
    lastName: '',
  },
  recipient: {
    firstName: '',
    lastName: '',
    email: '',
  },
};
