import { AnyActorRef, ErrorActorEvent, EventObject } from 'xstate';

import { RequestError } from './errorHandler.ts';

import { ErrorResponse } from '../request/ErrorResponse.ts';
import { ErrorCode } from '../types';

/**
 * 如果给定的 code 是下面中的一个,则认为是 jwt 错误
 */
export const isJWTError = (code: number) => {
  return [
    ErrorCode.JwtValidationForbidden,
    ErrorCode.JwtValidationFailed,
    ErrorCode.JwtExpired,
    ErrorCode.JwtValueNotMatched,
    ErrorCode.JwtUnknownError,
  ].includes(code);
};
/**
 * task _parent unit is undefined
 * @param anyActor
 */
export const takeMainActorRef = (anyActor: AnyActorRef) => {
  let actor = anyActor;
  while (actor._parent) {
    actor = actor._parent;
  }
  return actor;
};
export const createRequestEmittedError = (anyActor: AnyActorRef, event: EventObject) => {
  return {
    type: 'error',
    data: {
      error: new RequestError(
        anyActor.getSnapshot().value,
        (event as unknown as ErrorActorEvent<ErrorResponse>).error.code,
        (event as unknown as ErrorActorEvent<ErrorResponse>).error.message,
      ),
    },
  } as const;
};
