'use client';

import { GridList } from 'react-aria-components';

import { listBoxRecipe } from './ListBox.css';
import { ListBoxProps } from './type';

import { clsx } from '../../utils/clsx';
import { StyleHandlers, dimensionValue, useStyleProps } from '../../utils/styleProps';

const ListBoxStylePropsHandler: StyleHandlers = {
  rowGap: ['rowGap', dimensionValue],
};

const ListBox = <T extends object>({ children, className, ...rest }: ListBoxProps<T>) => {
  const { styleProps } = useStyleProps(rest);
  const { styleProps: boxStyle } = useStyleProps(rest, ListBoxStylePropsHandler);

  return (
    <GridList
      className={clsx(listBoxRecipe(), className)}
      style={{ ...styleProps.style, ...boxStyle.style }}
      {...rest}
    >
      {children}
    </GridList>
  );
};

export default ListBox;
