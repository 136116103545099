import { fromPromise } from 'xstate';

import { DropoffStatus, v4hostName } from '../constant';
import { request } from '../request/request';
import { ResponseBody } from '../request/response';
import { DropoffItemDetail } from '../types';
import { makePollingRequest } from '../utils/makePollingRequest';

interface GetDropOffInput {
  returnId: string;
  token: string;
}

// 获取 dropoffs
export const getDropoffs = fromPromise(async ({ input }: { input: GetDropOffInput }) => {
  const { returnId, token } = input;
  const res = await request<ResponseBody<{ dropoffs: DropoffItemDetail[] }>>(
    `${v4hostName()}/returns/${returnId}/dropoffs`,
    {
      method: 'GET',
      headers: { 'returns-authorization': token },
    },
  );

  return res?.data?.dropoffs || [];
});

export const pollingDropoffs = makePollingRequest<DropoffItemDetail[], GetDropOffInput>().provide({
  actors: {
    retrieveResource: getDropoffs,
  },
  guards: {
    resourceIsVerified: ({ context }) =>
      context.response?.some((dropoff) => dropoff.status !== DropoffStatus.Creating),
  },
});
