export enum ErrorCode {
  CancelConfirmedDraftReturn = 42239,
  MultiplePendingDraftReturns = 42231,
  TooManyRequests = 42911,
  MaximumReturnableQuantityExceeded = 42232,
  ReturnLabelRateUnavailable = 42223,
  ParamsValidationFailed = 42200,
  ReturnsQuotaLimitForFree = 42215,
  HooksParamsValidationFailed = 42250,
  CaptchaScoreError = 40101,
  JwtValidationForbidden = 40300,
  JwtValidationFailed = 40310,
  JwtExpired = 40311,
  JwtValueNotMatched = 40312,
  JwtUnknownError = 40313,
  CreateReturnErrorsDetail = 42210,
  MultiplePendingExchangeError = 40904,
  MultiplePendingCheckoutsError = 40904,
  OrderEmailNotFound = 40432,
}

// export enum RCErrorCode {
//   MULTIPLE_PENDING_EXCHANGE_ERROR = 40904,
//   MULTIPLE_PENDING_CHECKOUTS_ERROR = 40904,
//   TOO_MANY_REQUESTS = 42903,
//   CREATE_RETURN_ERRORS_DETAIL = 42210,

//   EXCEED_EXTRA_CREDIT = 42217,
//   INSUFFICIENT_INVENTORY = 42235,
//   EXCEED_QUANTITY = 42236,
//   INVALID_ITEM = 42237,
//   PAYMENT_IN_PROGRESS = 42238,

//   RC_BUSINESS_ERROR = 42250,

//   // 临时
//   RETURN_METHOD_NOT_FOUND_ = 42299,

//   SHIPPING_ADDRESS_INVALID = 42204,
// }

export enum RCErrorDetailCode {
  MultiplePendingDraftReturns = 61103,
  ReturnsQuotaLimitForFree = 60203,
  MinimumInventoryError = 61105,
}

export enum YupIssueCode {
  optionality = 'optionality', // 必填
  required = 'required', // 必填
  email = 'email', // 当字符串不满足 email() 方法定义的电子邮件格式时
}
