'use client';

import { PropsWithChildren } from 'react';

import { cardRecipe } from './Card.css';
import { CardProps } from './type';

import { clsx } from '../../utils/clsx';
import { useStyleProps, viewStyleProps } from '../../utils/styleProps';

const Card = ({ children, mode, className, ...rest }: PropsWithChildren<CardProps>) => {
  const { styleProps } = useStyleProps(rest, viewStyleProps);

  return (
    <div className={clsx(cardRecipe({ mode }), className)} {...styleProps}>
      {children}
    </div>
  );
};

export default Card;
